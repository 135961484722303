import React from 'react'
import ImageBg from '../assets/images/common/bg-404.svg'
import IconLogo from '../assets/icons/auth/logo.svg'
export const Error: React.FC = () => {
  return (
    <div className="w-screen h-screen min-h-screen bg-[#242424] flex items-center justify-center">
      <img src={ImageBg} className="absolute top-2 right-0 w-full z-0" alt="" />
      <div className="z-10">
        <h1 className="text-[28px] text-white font-semibold mb-2">
          お探しのページが見つかりません
        </h1>
        <h3 className="text-white text-[16px] text-center mb-[54px]">
          URLの間違いがないか、再度ご確認ください
        </h3>
        <div className="flex justify-center">
          <img src={IconLogo} alt="" />
          <div className="text-white font-medium text-lg ml-2">WHERENESS</div>
        </div>
      </div>
    </div>
  )
}
