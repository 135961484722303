import { gql } from '@apollo/client'

export const ORGANIZATION_SUBSCRIPTION = gql`
  subscription organizationListener($input: OrganizationListenerInput!) {
    organizationListener(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        event
        data {
          mediaProcess {
            organizationId
            projectId
            layerId
            spaceId
            sceneId
            folderId
            status
            progress
            key
            resourceId
            contextUrl {
              vp9
              h265Dash
              h265Hls
            }
            thumbnail
            name
            mediaType
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`
