import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'

interface Props {
  children: React.ReactNode
}
const CardInfoLayout: React.FC<Props> = ({ children }): React.ReactElement => {
  const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY as string)
  return <Elements stripe={stripePromise}>{children}</Elements>
}

export default CardInfoLayout
