import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, FormikHelpers, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import { openNotification } from '../../utils/notification'
import { TYPE } from '../../models/common'
import { ERROR_AUTH } from '../../models/auth'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import {
  createProfileAction,
  forgotPasswordAction,
  loginAction,
} from '../../store/auth/auth.action'
import IconLogo from '../../assets/icons/auth/logo.svg'
import InputField from '../../components/common/InputField'
import { RootState } from '../../store/store'
import { actions as authActions } from '../../store/auth/auth.reducer'
import { TYPES_AUTH } from '../../store/auth/auth.type'
import Button from '../../components/common/Button'
import { PATHNAME } from '@constants/common'
import IconWhereness from '@assets/icons/common/whereness-icon.svg'
import { useParamsToStore } from '../../hooks/useParamsToStore'

interface values {
  vEmail: string
  password: string
}

const Login: FC = () => {
  useParamsToStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { emailAutofill } = useAppSelector((state: RootState) => state.auth)

  const SignInSchema = Yup.object().shape({
    password: Yup.string().required(
      t('validate.required', { name: t('common.password').toLowerCase() })
    ),
    vEmail: Yup.string().required(
      t('validate.required', { name: t('common.email').toLowerCase() })
    ),
  })

  const handleLogin = async (
    values: values,
    actions: FormikHelpers<values>
  ) => {
    const { vEmail, password } = values
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      await dispatch(
        loginAction({ email: vEmail, password, isRememberMe: false })
      ).unwrap()
    } catch ({ message }) {
      renderError(message as string)
    } finally {
      setSubmitting(false)
    }
  }

  const renderError = (messageCode: string) => {
    switch (messageCode) {
      case ERROR_AUTH.EMAIL_NOT_FOUND:
        openNotification({
          type: TYPE.ERROR,
          key: 'login',
          message: t('errorFromBE.auth.userNotFound.first'),
          description: t('errorFromBE.auth.userNotFound.login'),
        })
        break
      case ERROR_AUTH.INCORRECT_PASSWORD:
        openNotification({
          type: TYPE.ERROR,
          key: 'login',
          message: t('errorFromBE.auth.incorrectPassword'),
        })
        break
      default:
        openNotification({
          type: TYPE.ERROR,
          key: 'login',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
    }
  }

  const handleBlurEmail = async (email: string) => {
    if (email !== emailAutofill) {
      try {
        dispatch(authActions[TYPES_AUTH.REDUCERS.SET_EMAIL_AUTOFILL](email))
        const result = await dispatch(createProfileAction({ email })).unwrap()
        if (
          result.error &&
          result.error.message === ERROR_AUTH.USER_NOT_FOUND
        ) {
          navigate(PATHNAME.SIGN_UP)
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'createProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleSendEmailResetPassword = () => {
    sendEmailForgotPassword()
  }

  const sendEmailForgotPassword = async () => {
    const email = emailAutofill
    if (email) {
      try {
        const res = await dispatch(forgotPasswordAction(email)).unwrap()
        if (!res.error) {
          navigate(PATHNAME.FORGOT_PASSWORD)
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'forgotPassword',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="flex justify-center items-center mb-4 pt-3.5 py-3">
        <img src={IconLogo} alt="" className="mr-1.5" />
        <img src={IconWhereness} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center z-10">
        <Formik
          initialValues={{
            vEmail: emailAutofill || '',
            password: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form className="w-full" autoComplete="off" role="presentation">
              <InputField
                name="vEmail"
                placeholder={t('common.email')}
                type="text"
                required
                onBlur={() => handleBlurEmail(values.vEmail)}
                isTransition
              />
              <InputField
                name="password"
                placeholder={t('common.password')}
                type="password"
                customClassFormControl="mb-4"
                required
                autoComplete="new-password"
                isTransition
              />
              <Button.Normal
                type="submit"
                className="!py-3 font-semibold text-sm"
                title={t('common.login')}
                disabled={isSubmitting || !dirty || !isValid}
                isTransition
              />
            </Form>
          )}
        </Formik>
      </div>
      <div className="flex items-center justify-center">
        <Button.Normal
          type="submit"
          className="!py-3 font-semibold text-sm"
          title={t('common.forgetPassword')}
          onClick={handleSendEmailResetPassword}
          color="transparent"
          isTransition
        />
      </div>
    </React.Fragment>
  )
}

export default Login
