import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { RootState } from '@stores/store'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import {
  deleteProjectAction,
  updateProjectAction,
} from '@stores/project/project.action'
import { TYPE, TypeLanguage } from '@models/common'
import { IRequestUpdateProject } from '@models/project'
import { openNotification } from '@utils/notification'
import { PATHNAME } from '@constants/common'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import Button from '@components/common/Button'
import Popup from '@components/common/Popup'
import Icon from '@components/common/Icon'
import Input from '@components/common/Input'
import PopupConfirm from '@components/common/PopupConfirm'

//icon
import IconArrowBottomLight from '@assets/icons/sceneSetting/arrowBottomLight.svg'
import IconEdit from '@assets/icons/sceneSetting/edit.svg'
import IconDelete from '@assets/icons/sceneSetting/delete.svg'
// import IconDuplicate from '@assets/icons/sceneSetting/duplicate.svg'
// import IconHistory from '@assets/icons/sceneSetting/history.svg'
import IconPjSetting from '@assets/icons/sceneSetting/cusDomain.svg'
interface IPropTypes {
  handleSwitchProjectPublishSetting: () => Promise<void>
}
const MoreProjectSetting: React.FC<IPropTypes> = (props) => {
  const { handleSwitchProjectPublishSetting } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)

  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false)
  const [isRename, setIsRename] = useState<boolean>(false)
  const [pjName, setPjName] = useState<string>(projectInfo?.projectName ?? '')
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)

  useEffect(() => {
    if (projectInfo) {
      setPjName(projectInfo.projectName)
    }
  }, [projectInfo?.projectName])

  const handleChangePjName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPjName(e.target.value)
  }

  const handleUpdatePjName = async () => {
    if (
      projectInfo &&
      projectInfo.projectId &&
      projectInfo.projectName !== pjName &&
      pjName
    ) {
      await handleUpdateProject(projectInfo.projectId, {
        projectName: pjName,
        langCode: TypeLanguage.ENLISH,
      })
    } else {
      setPjName(projectInfo?.projectName || '')
    }
    setIsRename(false)
  }

  const handleKeyDownPjName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdatePjName()
    }
  }

  const handleUpdateProject = async (
    projectId: number,
    dataUpdate: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: dataUpdate })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleOpenEditName = () => {
    setIsRename(true)
    setIsOpenPopup(false)
  }

  const handleOpenConfirmDelete = () => {
    setIsOpenPopup(false)
    setIsShowConfirmDelete(true)
  }

  const handleCancelDeleteProject = () => {
    setIsShowConfirmDelete(false)
  }

  const handleDeleteProject = async () => {
    if (user && projectInfo) {
      const data = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
      }
      try {
        const res = await dispatch(deleteProjectAction(data)).unwrap()
        if (!res.error) {
          setIsShowConfirmDelete(false)
          sceneSettingHelper.clearCatchProject(projectInfo.projectId)
          navigate(PATHNAME.DASHBOARD)
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteProject',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }
      } catch {
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteProject',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } else {
      throw new Error('project id invalid')
    }
  }

  const handleOpenPublishSetting = async () => {
    await handleSwitchProjectPublishSetting()
    setIsOpenPopup(false)
  }

  return (
    <>
      <div className="pt-2 pl-4 pr-1 flex justify-between items-center">
        {isRename ? (
          <div className="relative w-full">
            <Input
              className="py-0 px-1 box-border leading-4 text-xs font-lato"
              customClassContainer="py-0"
              key="pjName"
              type="text"
              value={pjName}
              onChange={handleChangePjName}
              onBlur={handleUpdatePjName}
              handleKeyDown={handleKeyDownPjName}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              isValid={pjName ? false : true}
            />
            {!pjName && (
              <p className="text-red-500 absolute top-full font-lato text-xs leading-5">
                Required
              </p>
            )}
          </div>
        ) : (
          <div className="w-full truncate bg-transparent text-white cursor-pointer text-base font-semibold leading-6">
            {projectInfo?.projectName}
          </div>
        )}

        <Popup
          open={isOpenPopup}
          close={() => setIsOpenPopup(false)}
          claassNamePopup="max-w-[200px] !right-0"
          content={
            <div className="flex flex-col font-lato">
              <Button.MenuItem
                icon={<img src={IconEdit} alt="" />}
                title={t('common.rename')}
                onClick={handleOpenEditName}
              />
              <Button.MenuItem
                icon={<img src={IconPjSetting} alt="" />}
                title={t('common.publishSetting')}
                onClick={handleOpenPublishSetting}
              />
              {/* <Button.MenuItem
                icon={<img src={IconHistory} alt="" />}
                title={t('sceneSetting.sideBar.showVersion')}
              /> */}
              <hr className="my-2 border-white__op-50" />
              {/* <Button.MenuItem
                icon={<img src={IconDuplicate} alt="" />}
                title={t('common.duplicate')}
              /> */}
              <Button.MenuItem
                icon={<img src={IconDelete} alt="" />}
                title={t('common.delete')}
                onClick={handleOpenConfirmDelete}
              />
            </div>
          }
        >
          <Icon.ButtonIcon
            icon={<img src={IconArrowBottomLight} alt="" />}
            size="xs"
            onClick={() => setIsOpenPopup(true)}
            customClass={classNames('focus:bg-transparent', {
              '!bg-blue-700 !outline-transparent': isOpenPopup,
            })}
          />
        </Popup>
      </div>
      {isShowConfirmDelete && (
        <PopupConfirm
          title={t('popup.confirm.confirmDeleteProject.title')}
          visible={isShowConfirmDelete}
          messageFirst={t('popup.confirm.confirmDeleteProject.description')}
          isDelete={true}
          okLabel={t('common.delete')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCancelDeleteProject}
          handleOk={handleDeleteProject}
        />
      )}
    </>
  )
}

export default MoreProjectSetting
