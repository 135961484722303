import React from 'react'
import * as Yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import InputField from '../../common/InputField'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store/hook'
import { TYPE } from '../../../models/common'
import { changePasswordAction } from '../../../store/profile/profile.action'
import { openNotification } from '../../../utils/notification'
import { ERROR_PROFILE } from '../../../models/profile'
import { VALID_PASSWORD_REGEX } from '@constants/common'
import Button from '@components/common/Button'

interface IPropsType {
  closeEditPassword: () => void
}

interface IValuesUpdatePassword {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}

const UpdatePasswordForm: React.FC<IPropsType> = ({
  closeEditPassword,
}): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      t('validate.required', {
        name: t('common.currentPassword').toLowerCase(),
      })
    ),
    newPassword: Yup.string()
      .min(
        6,
        t('validate.tooShort', { name: t('common.newPassword'), length: 6 })
      )
      .matches(
        VALID_PASSWORD_REGEX,
        t('validate.invalidFormat', {
          name: t('common.newPassword').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.newPassword').toLowerCase() })
      ),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('validate.passwordNotMatch'))
      .required(t('validate.confirmPasswordRequired')),
  })

  const handleChangePassword = async (
    values: IValuesUpdatePassword,
    actions: FormikHelpers<IValuesUpdatePassword>
  ) => {
    const { setSubmitting, resetForm } = actions
    const { currentPassword, newPassword } = values
    const data = {
      password: currentPassword,
      newPassword: newPassword,
    }
    setSubmitting(true)
    try {
      const res = await dispatch(changePasswordAction(data)).unwrap()
      if (!res.error) {
        closeEditPassword()
        resetForm()
      } else {
        renderError(res.error.message)
      }
      setSubmitting(false)
    } catch ({}) {
      setSubmitting(false)
      openNotification({
        type: TYPE.ERROR,
        key: 'changePassword',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }
  const renderError = (messageCode: string) => {
    switch (messageCode) {
      case ERROR_PROFILE.INCORRECT_PASSWORD:
        openNotification({
          type: TYPE.ERROR,
          key: 'forgotPassword',
          message: t('errorFromBE.profile.incorrectPassword'),
        })
        break
      default:
        openNotification({
          type: TYPE.ERROR,
          key: 'forgotPassword',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
    }
  }
  return (
    <div className="w-full">
      <Formik
        initialValues={{
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={changePasswordSchema}
        onSubmit={handleChangePassword}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <InputField
              name="currentPassword"
              placeholder={t('common.currentPassword')}
              type="password"
              customClassFormInput="!text-sm !leading-6"
              required
              autoComplete="new-password"
            />
            <InputField
              name="newPassword"
              placeholder={t('common.newPassword')}
              type="password"
              customClassFormInput="!text-sm !leading-6"
              required
              autoComplete="new-password"
            />
            <InputField
              name="confirmNewPassword"
              placeholder={t('common.confirmPassword')}
              type="password"
              customClassFormInput="!text-sm !leading-6"
              required
              autoComplete="new-password"
            />
            <div className="w-full flex justify-end">
              <Button.Normal
                type="submit"
                className="font-semibold text-sm"
                classNameContainer="!w-auto"
                title={t('common.update')}
                disabled={isSubmitting || !dirty || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdatePasswordForm
