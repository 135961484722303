import React from 'react'
import classNames from 'classnames'

interface IPropsType {
  isFlexible?: boolean
  isInline?: boolean
  isNoMarginTop?: boolean
}
const SpinComponent: React.FC<IPropsType> = (props) => {
  const { isFlexible, isInline, isNoMarginTop } = props
  return (
    <div
      className={
        isInline
          ? undefined
          : classNames('inset-0 flex items-center justify-center z-[9999]', {
              fixed: !isFlexible,
              absolute: isFlexible,
            })
      }
    >
      <div
        className={classNames('loading', { 'mt-0': isInline || isNoMarginTop })}
      >
        <div className="loading__back">
          <div className="loading__face loading__face--1"></div>
          <div className="loading__face loading__face--2"></div>
          <div className="loading__face loading__face--3"></div>
        </div>
        <div className="loading__front">
          <div className="loading__face loading__face--4"></div>
          <div className="loading__face loading__face--5"></div>
        </div>
      </div>
    </div>
  )
}

export default SpinComponent
