import { gql } from '@apollo/client'

export const GET_USER_PLAN_BY_USER_ID = gql`
  query getUserPlanByUserId($userId: Int!) {
    getUserPlanByUserId(userId: $userId) {
      id
      userId
      planId
      plan {
        id
        name
        price
        metadata
        planFeatures {
          id
          feature {
            id
            key
          }
        }
      }
      expirationFrom
      expirationTo
    }
  }
`
