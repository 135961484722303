import { gql } from '@apollo/client'

export const GET_SIGNED_URL_UPLOAD_MEDIA = gql`
  query getSignedUrlUploadMedia(
    $fileName: String!
    $fileType: String!
    $projectId: Int!
  ) {
    getSignedUrlUploadMedia(
      input: { fileName: $fileName, fileType: $fileType, projectId: $projectId }
    ) {
      statusCode
      message
      data {
        key
        bucket
        algorithm
        credential
        date
        policy
        signature
        contentType
        url
        expires
        securityToken
      }
      error {
        errorCode
        message
      }
    }
  }
`
