import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  IreqCmsProductBillings,
  IreqGetCurrentPlanAndCard,
  IreqListCmsProduct,
  IreqRegisterServicePackage,
  IReqUpdatePaymentCard,
  IResCmsProductBillings,
  IResCurrentPlanAndCardInfo,
} from '@models/organization'

import { graphqlApi } from '../../api/graphql'
import {
  CANCEL_CMS_SUBSCRIPTION,
  DOWNGRADE_ADD_ON,
  DOWNGRADE_SERVICE_PACKAGE,
  REGISTER_SERVICE_PACKAGE,
  UPGRADE_ADD_ON,
  UPGRADE_SERVICE_PACKAGE,
} from '../../graphql/organization/mutation'
import {
  GET_CMS_PRODUCT_BILLINGS,
  GET_CURRENT_PLAN_AND_CARD_INFO,
  GET_LIST_CMS_PRODUCT,
} from '../../graphql/organization/query'
import { API_TYPE_ORGANIZATION } from '../../graphql/organization/type'
import { TYPE_ORGANIZATION } from './organization.type'
import { IReqUpAndDownPlan } from '@models/plan'
import { UPDATE_PAYMENT_CARD } from '../../graphql/paymentCard/mutation'
import { API_TYPE_PAYMENT } from '../../graphql/paymentCard/type'

export const getListCmsProductAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.GET_LIST_CMS_PRODUCT,
  async (input: IreqListCmsProduct) => {
    const result = await graphqlApi.queryRequest(
      GET_LIST_CMS_PRODUCT,
      API_TYPE_ORGANIZATION.QUERY.GET_LIST_CMS_PRODUCT,
      { input }
    )
    return result
  }
)

export const regisrerServicePackageAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.REGISTER_SERVICE_PACKAGE,
  async (input: IreqRegisterServicePackage) => {
    const result = await graphqlApi.mutationRequest(
      REGISTER_SERVICE_PACKAGE,
      API_TYPE_ORGANIZATION.MUTATION.REGISTER_SERVICE_PACKAGE,
      { input }
    )
    return result
  }
)

export const getCurrentPlanAndCardInfoAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.GET_CURRENT_PLAN_AND_CARD_INFO,
  async (input: IreqGetCurrentPlanAndCard, { rejectWithValue }) => {
    const { organizationId, isCurrentPlanOnly, isGetCardOnly } = input
    const result = await graphqlApi.queryRequest(
      GET_CURRENT_PLAN_AND_CARD_INFO,
      API_TYPE_ORGANIZATION.QUERY.GET_CURRENT_PLAN_AND_CARD_INFO,
      {
        organizationId,
        isGetCardOnly: !!isGetCardOnly,
        isCurrentPlanOnly: !!isCurrentPlanOnly,
      }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data as IResCurrentPlanAndCardInfo
  }
)

export const getCmsProductBillingsAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.GET_CMS_PRODUCT_BILLINGS,
  async (input: IreqCmsProductBillings, { rejectWithValue }) => {
    const { organizationId, pager } = input
    const result = await graphqlApi.queryRequest(
      GET_CMS_PRODUCT_BILLINGS,
      API_TYPE_ORGANIZATION.QUERY.GET_CMS_PRODUCT_BILLINGS,
      { organizationId, pager }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data as IResCmsProductBillings
  }
)

export const cancelCmsSubscriptionAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.CANCEL_CMS_SUBSCRIPTION,
  async (organizationId: string, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      CANCEL_CMS_SUBSCRIPTION,
      API_TYPE_ORGANIZATION.MUTATION.CANCEL_CMS_SUBSCRIPTION,
      { organizationId }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return true
  }
)

export const upgradeServicePackageAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.UPGRADE_SERVICE_PACKAGE,
  async (input: IReqUpAndDownPlan, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      UPGRADE_SERVICE_PACKAGE,
      API_TYPE_ORGANIZATION.MUTATION.UPGRADE_SERVICE_PACKAGE,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return true
  }
)

export const downgradeServicePackageAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.DOWNGRADE_SERVICE_PACKAGE,
  async (input: IReqUpAndDownPlan, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      DOWNGRADE_SERVICE_PACKAGE,
      API_TYPE_ORGANIZATION.MUTATION.DOWNGRADE_SERVICE_PACKAGE,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return true
  }
)
//Upgrade add-on
export const upgradeAddOnAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.UPGRADE_ADD_ON,
  async (input: IReqUpAndDownPlan, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      UPGRADE_ADD_ON,
      API_TYPE_ORGANIZATION.MUTATION.UPGRADE_ADD_ON,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return true
  }
)
//Downgrade add-on
export const downgradeAddOnAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.DOWNGRADE_ADD_ON,
  async (input: IReqUpAndDownPlan, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      DOWNGRADE_ADD_ON,
      API_TYPE_ORGANIZATION.MUTATION.DOWNGRADE_ADD_ON,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return true
  }
)
//update payment card
export const updatePaymentCardAction = createAsyncThunk(
  TYPE_ORGANIZATION.ACTIONS.UPDATE_PAYMENT_CARD,
  async (input: IReqUpdatePaymentCard, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_PAYMENT_CARD,
      API_TYPE_PAYMENT.MUTATION.UPDATE_PAYMENT_CARD,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return true
  }
)
