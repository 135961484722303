import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  ICoordinates,
  IRequestSetInfoTag,
  TagTypeInfoTagEnum,
  TypeContentInfoTag,
} from '@models/sceneSetting'
import Select, { IValueSelected } from '@components/common/selectInput/Select'
//icon
import IconText from '@assets/icons/sceneSetting/rightSidebar/infoTag/text.svg'
import IconFile from '@assets/icons/sceneSetting/rightSidebar/infoTag/file.svg'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'

interface IPropsType {
  dataInfoTag: {
    coordinates: ICoordinates
    tagType: TagTypeInfoTagEnum
    id: number
  }
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<unknown>
}

const InfoTagTypeSelect: React.FC<IPropsType> = (props) => {
  const { dataInfoTag, handleSetInfoTag } = props
  const { t } = useTranslation()
  const optionContent = [
    {
      value: TypeContentInfoTag.TEXT,
      label: (
        <>
          <div className="w-5 h-5 mr-3 flex items-center justify-center">
            <img src={IconText} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('sceneSetting.rightSideBar.infoTag.content.text')}
          </p>
        </>
      ),
    },
    {
      value: TypeContentInfoTag.FILE,
      label: (
        <>
          <div className="w-5 h-5 mr-3 flex items-center justify-center">
            <img src={IconFile} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('sceneSetting.rightSideBar.infoTag.content.file')}
          </p>
        </>
      ),
    },
  ]
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [typeContent, setTypeContent] = useState<IValueSelected>(
    _.find(optionContent, (content) => content.value === dataInfoTag.tagType) ||
      null
  )

  useEffect(() => {
    if (dataInfoTag) {
      setTypeContent(
        _.find(
          optionContent,
          (content) => content.value === dataInfoTag.tagType
        ) || null
      )
    }
  }, [dataInfoTag])

  const handleChangeContent = async (value: IValueSelected) => {
    if (
      projectInfo &&
      user &&
      dataScenePreview &&
      value &&
      !Array.isArray(value)
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          tagType: value.value as TagTypeInfoTagEnum,
        },
      }
      const resUpdateType = await handleSetInfoTag(req)
      if (resUpdateType) {
        setTypeContent(value)
      }
    }
  }

  return (
    <Select
      value={typeContent}
      onChange={handleChangeContent}
      options={optionContent}
      isLabelIcon={true}
    />
  )
}

export default React.memo(InfoTagTypeSelect)
