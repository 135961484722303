import { Form, Formik, FormikHelpers } from 'formik'
import React from 'react'
import * as Yup from 'yup'

import InputField from '../../common/InputField'
import { actions as authActions } from '../../../store/auth/auth.reducer'
import { TYPES_AUTH } from '../../../store/auth/auth.type'
import { helper } from '../../../utils/helper/common'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../store/hook'
import { updateUserProfileAction } from '../../../store/profile/profile.action'
import { openNotification } from '../../../utils/notification'
import { TYPE } from '../../../models/common'
import Button from '@components/common/Button'
interface IValuesUpdateEmail {
  newEmail: string
  password: string
}
interface IPropsType {
  closeEditEmail: () => void
}
const UpdateEmailForm: React.FC<IPropsType> = ({
  closeEditEmail,
}): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const changeEmailSchema = Yup.object().shape({
    password: Yup.string().required(
      t('validate.required', { name: t('common.password').toLowerCase() })
    ),
    newEmail: Yup.string()
      .email(
        t('validate.invalidFormat', {
          name: t('common.emailAddress').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.email').toLowerCase() })
      ),
  })

  const handleChangeEmail = async (
    values: IValuesUpdateEmail,
    actions: FormikHelpers<IValuesUpdateEmail>
  ) => {
    const { password, newEmail } = values
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      const res = await dispatch(
        updateUserProfileAction({ email: newEmail, password })
      ).unwrap()
      if (!res.error) {
        const accessToken = res.data.info.accessToken
        const refeshToken = res.data.info.refreshToken
        dispatch(authActions[TYPES_AUTH.REDUCERS.SET_ACCESS_TOKEN](accessToken))
        dispatch(authActions[TYPES_AUTH.REDUCERS.SET_ACCESS_TOKEN](refeshToken))
        helper.updateAccessTokenAndRefreshToken(accessToken, refeshToken)
        closeEditEmail()
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'updateProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch ({}) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProfile',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <div className="w-full">
      <Formik
        initialValues={{
          newEmail: '',
          password: '',
        }}
        validationSchema={changeEmailSchema}
        onSubmit={handleChangeEmail}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <InputField
              name="password"
              placeholder={t('common.currentPassword')}
              type="password"
              customClassFormInput="!text-sm !leading-6"
              required
              autoComplete="new-password"
            />
            <InputField
              name="newEmail"
              placeholder={t('common.newEmail')}
              type="text"
              customClassFormInput="!text-sm !leading-6"
              required
            />
            <div className="w-full flex justify-end">
              <Button.Normal
                type="submit"
                className="font-semibold text-sm"
                classNameContainer="!w-auto"
                title={t('common.update')}
                disabled={isSubmitting || !dirty || !isValid}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdateEmailForm
