import { createAsyncThunk } from '@reduxjs/toolkit'
import { graphqlApi } from '../../api/graphql'
import {
  CREATE_PACKAGE,
  CREATE_PAYMENT,
  CREATE_PROFILE,
  FORGOT_PASSWORD,
  LOGIN,
  LOGIN_WITH_GOOGLE,
  LOGOUT,
  REFRESH_TOKEN,
  RESET_PASSWORD,
  SEND_CONFIRM_SUCCESS,
  SEND_EMAIL_CONFIRM_REGISTER,
  UPDATE_PROFILE,
} from '../../graphql/auth/mutation'
import { GET_SIGNED_URL_UPLOAD_MEDIA } from '../../graphql/auth/query'
import { API_TYPE_AUTH } from '../../graphql/auth/type'
import {
  ICreatePayment,
  ICreateProfile,
  IDataLogin,
  IRequestLogin,
  IRequestLoginWithGoogle,
} from '../../models/auth'
import { IFileInfo } from '../../models/common'
import { TYPES_AUTH } from './auth.type'

export const loginAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.LOGIN,
  async (data: IRequestLogin, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      LOGIN,
      API_TYPE_AUTH.MUTATION.LOGIN,
      data
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data as IDataLogin
  }
)

export const loginWithGoogleAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.LOGIN_WITH_GOOGLE,
  async (input: IRequestLoginWithGoogle, { rejectWithValue }) => {
    const result = await graphqlApi.mutationRequest(
      LOGIN_WITH_GOOGLE,
      API_TYPE_AUTH.MUTATION.LOGIN_WITH_GOOGLE,
      { input }
    )
    if (result.error) {
      return rejectWithValue(result.error)
    }
    return result.data as IDataLogin
  }
)

export const createProfileAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.CREATE_PROFILE,
  async (data: ICreateProfile) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_PROFILE,
      API_TYPE_AUTH.MUTATION.CREATE_USER,
      data
    )
    return result
  }
)

export const getSignedUrlMediaAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.GET_SIGNED_URL_UPLOAD_MEDIA,
  async (data: IFileInfo) => {
    const result = await graphqlApi.queryRequest(
      GET_SIGNED_URL_UPLOAD_MEDIA,
      API_TYPE_AUTH.QUERY.GET_SIGNED_URL_UPLOAD_MEDIA,
      data
    )
    return result
  }
)

export const updateProfileAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.UPDATE_PROFILE,
  async (data: ICreateProfile) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_PROFILE,
      API_TYPE_AUTH.MUTATION.UPDATE_USER_PROFILE,
      data
    )
    return result
  }
)

export const selectPackageAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.SELECT_PACKAGE,
  async (servicePackageId: number) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_PACKAGE,
      API_TYPE_AUTH.MUTATION.UPDATE_REGISTRATION,
      {
        servicePackageId,
      }
    )
    return result
  }
)

export const createPaymentAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.CREATE_PAYMENT,
  async (data: ICreatePayment) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_PAYMENT,
      API_TYPE_AUTH.MUTATION.UPDATE_REGISTRATION,
      data
    )
    return result
  }
)

export const sendEmailConfirmRegistertAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.SEND_EMAIL_CONFIRM_REGISTER,
  async () => {
    const result = await graphqlApi.mutationRequest(
      SEND_EMAIL_CONFIRM_REGISTER,
      API_TYPE_AUTH.MUTATION.SEND_MAIL_CONFIRM_REGISTER
    )
    return result
  }
)

export const sendEmailRegisterSuccessAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.SEND_EMAIL_REGISTER_SUCCESS,
  async () => {
    const result = await graphqlApi.mutationRequest(
      SEND_CONFIRM_SUCCESS,
      API_TYPE_AUTH.MUTATION.CONFIRM_CREATE_ACCOUNT
    )
    return result
  }
)

export const forgotPasswordAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.FORGOT_PASSWORD,
  async (email: string) => {
    const result = await graphqlApi.mutationRequest(
      FORGOT_PASSWORD,
      API_TYPE_AUTH.MUTATION.FORGOT_PASSWORD,
      { email }
    )
    return result
  }
)

export const resetPasswordAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.RESET_PASSWORD,
  async (data: {
    password: string
    headers: {
      'reset-token': string
    }
  }) => {
    const result = await graphqlApi.mutationRequest(
      RESET_PASSWORD,
      API_TYPE_AUTH.MUTATION.RESET_PASSWORD,
      { password: data.password },
      { headers: data.headers }
    )
    return result
  }
)

export const refreshTokenAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.REFRESH_TOKEN,
  async (data: { refreshToken: string; rememberMeToken?: string }) => {
    const result = await graphqlApi.mutationRequest(
      REFRESH_TOKEN,
      API_TYPE_AUTH.MUTATION.REFRESH_TOKEN,
      data
    )
    return result
  }
)

export const logoutAction = createAsyncThunk(
  TYPES_AUTH.ACTIONS.LOGOUT,
  async () => {
    const result = await graphqlApi.mutationRequest(
      LOGOUT,
      API_TYPE_AUTH.MUTATION.LOGOUT
    )
    return result
  }
)
