import classNames from 'classnames'
import React, { ReactNode } from 'react'

type ButtonTypeColor = 'transparent' | 'ghost' | 'solid'
type ButtonSize = 'vxs' | 'xs' | 'sm' | 'md' | 'lg'
interface IconButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  onMouseUp?: React.MouseEventHandler<HTMLButtonElement>
  icon: ReactNode
  customClass?: string
  customClassContainer?: string
  children?: ReactNode
  color?: ButtonTypeColor
  size?: ButtonSize
  isActive?: boolean
  disabled?: boolean
}

interface IconProps {
  icon: ReactNode
  customClass?: string
}
const ActionIcon: React.ForwardRefRenderFunction<unknown, IconProps> = (
  props
) => {
  const { icon, customClass } = props
  return (
    <div
      className={classNames(
        'w-5 h-5 flex-shrink-0 flex items-center justify-center',
        customClass
      )}
    >
      {icon}
    </div>
  )
}

const ButtonIcon: React.ForwardRefRenderFunction<unknown, IconButtonProps> = (
  props
) => {
  const {
    onClick,
    onMouseUp,
    icon,
    customClass,
    customClassContainer,
    children,
    color = 'transparent',
    size = 'xs',
    isActive,
    disabled = false,
  } = props
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    ;(
      onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    )?.(e)
  }
  const handleMouseUp = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    ;(
      onMouseUp as React.MouseEventHandler<
        HTMLButtonElement | HTMLAnchorElement
      >
    )?.(e)
  }
  const genClassSizeButton = () => {
    switch (size) {
      case 'vxs':
        return 'h-5 w-5 rounded-xl'
      case 'xs':
        return 'h-8 w-8 rounded-xl'
      case 'sm':
        return 'h-10 w-10 rounded-xl'
      case 'md':
        return 'h-12 w-12 rounded-2xl'
      case 'lg':
        return 'h-14 w-14 rounded-2xl'
      default:
        return 'h-8 w-8 rounded-xl'
    }
  }

  const genColorButton = () => {
    let className = ''
    if (!isActive) {
      switch (color) {
        case 'ghost':
          className = ''
          break

        case 'solid':
          className = ''
          break
        case 'transparent':
          className =
            'bg-transparent group-two-hover:bg-white__op-50 group-two-active:bg-white__op-100 group-two-target:!bg-blue-500'
          break

        default:
          className = ''
          break
      }
    } else {
      className = 'bg-blue-700'
    }
    return className
  }

  return (
    <button
      className={classNames('p-1 group-two', customClassContainer)}
      onClick={handleClick}
      onMouseDown={handleMouseUp}
      disabled={disabled}
    >
      <div
        className={classNames(
          'flex-shrink-0 flex items-center justify-center relative outline outline-2 outline-transparent',
          genClassSizeButton(),
          genColorButton(),
          customClass
        )}
      >
        {icon}
        {children}
      </div>
    </button>
  )
}

const Icon = { ActionIcon, ButtonIcon }
export default Icon
