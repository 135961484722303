import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import Icon from '@components/common/Icon'
import { TypeFilterProject } from '@models/project'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { actions as projectAction } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { getUserProfileAction } from '@stores/profile/profile.action'
import MoreAccountSettingPopup from '@components/popups/MoreAccountSettingPopup'

//icon
import arrowDropDownIcon from '@assets/icons/mainLayout/arrow_drop_down.svg'
import folderIcon from '@assets/icons/mainLayout/folder.svg'
import frameIcon from '@assets/icons/mainLayout/Frame.svg'
// import groupIcon from '@assets/icons/mainLayout/group.svg'
import settingIcon from '@assets/icons/mainLayout/setting.svg'
// import notificationIcon from '@assets/icons/mainLayout/notifications.svg'
import watchIcon from '@assets/icons/mainLayout/watch_later.svg'
import chevronRightIcon from '@assets/icons/mainLayout/chevron_right.svg'
import DefaultAvatar from '@assets/images/default-avt.svg'
import { IS_SHOWED_ALERT_KEY, LANGUAGE, PATHNAME } from '@constants/common'
import Button from '@components/common/Button'
import { IResponseUserProfile } from '@models/profile'
import { getLocalCookie, setLocalCookie } from '@utils/cookies'
import i18next from 'i18next'
import { helper } from '@utils/helper/common'

const Sidebar: React.FC = (): ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const lang = i18next.language
  const { typeFilterListProject } = useAppSelector(
    (state: RootState) => state.project
  )
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  //State
  const [open] = useState<boolean>(false)
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  //onClick function
  // const toggleOpen = (event: { preventDefault: () => void }): void => {
  //   event.preventDefault()
  //   setOpen(!open)
  // }

  //Data
  const menus = [
    {
      title: t('dashboard.sideBar.recents'),
      src: watchIcon,
      key: TypeFilterProject.RECENT,
    },
    // {
    //   title: t('dashboard.sideBar.shared'),
    //   src: groupIcon,
    //   key: TypeFilterProject.SHARED,
    // },
  ]

  const subMenus = [
    {
      id: 0,
      title: 'Folder',
      src: folderIcon,
    },
    {
      id: 1,
      title: 'Folder',
      src: folderIcon,
    },
    {
      id: 2,
      title: 'Folder',
      src: folderIcon,
    },
  ]

  useEffect(() => {
    getUserProfile()
  }, [])

  const handleSetFilterListProject = async (type: string) => {
    dispatch(
      projectAction[TYPE_PROJECT.REDUCERS.SET_TYPE_FILTER_LIST_PROJECT](type)
    )
    if (pathname !== PATHNAME.DASHBOARD) {
      navigate(PATHNAME.DASHBOARD)
    }
  }

  const getUserProfile = async () => {
    try {
      const res = await dispatch(getUserProfileAction()).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getUserProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      } else {
        const data = res.data as IResponseUserProfile
        const isShowedAlert = getLocalCookie(IS_SHOWED_ALERT_KEY)
        if (data.info.organizationRemainingDays === 0 && !isShowedAlert) {
          dispatch(
            projectAction[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](
              true
            )
          )
          setLocalCookie(IS_SHOWED_ALERT_KEY, JSON.stringify(true))
        }
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getUserProfile',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleRedirectToSelectPackage = useCallback(() => {
    helper.redirectToContact()
  }, [])

  return (
    <>
      <div className="flex flex-col flex-shrink-0 w-60 h-screen bg-gray-900 relative">
        <div className="pl-4 py-5 flex-1">
          <div
            className="inline-block cursor-pointer"
            onClick={() => navigate(PATHNAME.DASHBOARD)}
          >
            <img src={frameIcon} alt="icon" />
          </div>
        </div>
        <div className="py-2 h-full">
          <ul>
            {menus.map((menu, index) => (
              <li
                key={index}
                className={classNames('cursor-pointer select-none', {
                  'bg-blue-700':
                    pathname === PATHNAME.DASHBOARD &&
                    menu.key === typeFilterListProject,
                  'hover:bg-white__op-50':
                    pathname !== PATHNAME.DASHBOARD ||
                    menu.key !== typeFilterListProject,
                })}
                onClick={() => handleSetFilterListProject(menu.key)}
              >
                <a className="block py-3 px-4" href="#">
                  <div className="flex items-center">
                    <img src={menu.src} alt="icon" />
                    <span className="text-white__op-900 ml-4 text-base font-semibold">
                      {menu.title}
                    </span>
                  </div>
                </a>
              </li>
            ))}
          </ul>
          <div>
            <a
              className={classNames(
                'flex items-center py-3 px-4 cursor-pointer select-none',
                {
                  'bg-blue-700':
                    pathname === PATHNAME.DASHBOARD &&
                    typeFilterListProject === TypeFilterProject.YOUR_PROJECT,
                  'hover:bg-white__op-50':
                    pathname !== PATHNAME.DASHBOARD ||
                    typeFilterListProject !== TypeFilterProject.YOUR_PROJECT,
                }
              )}
              onClick={() =>
                handleSetFilterListProject(TypeFilterProject.YOUR_PROJECT)
              }
            >
              <img
                src={chevronRightIcon}
                alt="icon"
                className={classNames({
                  'rotate-0': open,
                  '-rotate-90': !open,
                })}
              />
              <span className="text-white__op-900 ml-4 text-base font-semibold">
                {t('dashboard.sideBar.yourProject')}
              </span>
            </a>

            <ul
              className={classNames({
                'block cursor-pointer': open,
                invisible: !open,
              })}
            >
              {subMenus.map((subMenu, index) => (
                <li
                  key={index}
                  // className={classNames('cursor-pointer select-none', {
                  //   'bg-blue-700': pathname === 'your-project',
                  //   'hover:bg-white__op-50': pathname !== 'your-project',
                  // })}
                  className="cursor-pointer select-none hover:bg-white__op-50 focus:bg-blue-700"
                >
                  <a className="block py-2 pl-6 pr-4">
                    <div className="flex items-center">
                      <img src={subMenu.src} alt="icon" />
                      <span className="text-white__op-900 ml-4 text-sm font-normal font-sans">
                        {subMenu.title}
                      </span>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* {userProfile && userProfile.info.organizationRemainingDays !== null && (
          <div className="mb-5 bg-white__op-50 rounded-xl mx-3 pt-3 px-1 text-white__op-900">
            <div className="px-3 leading-5 font-lato">
              {t('dashboard.trial.trailEnd')}
              {lang === LANGUAGE.EN && ' '}
              {userProfile.info.organizationRemainingDays}
              {lang === LANGUAGE.EN && ' '}
              {t('dashboard.trial.day')}
              {lang === LANGUAGE.EN &&
                userProfile.info.organizationRemainingDays > 1 &&
                's'}
            </div>
            <Button.Normal
              color="transparent"
              classNameContainer="!w-auto"
              className="!px-3 font-semibold"
              onClick={handleRedirectToSelectPackage}
            >
              {t('dashboard.trial.selectPlan')}
            </Button.Normal>
          </div>
        )} */}

        <div className="flex flex-shrink-0 justify-between w-full px-2">
          <div className="flex items-center justify-center p-3">
            <button
              className="w-8 h-8 flex-shrink-0 rounded-full overflow-hidden"
              onClick={() => setIsOpenSetting(true)}
            >
              <img
                src={
                  userProfile?.info.avatar
                    ? userProfile?.info.avatar
                    : DefaultAvatar
                }
                className="w-full h-full object-cover"
                alt=""
              />
            </button>
            <MoreAccountSettingPopup
              open={isOpenSetting}
              close={() => setIsOpenSetting(false)}
            >
              <button onClick={() => setIsOpenSetting(true)}>
                <Icon.ActionIcon
                  icon={<img src={arrowDropDownIcon} alt="icon" />}
                  customClass="ml-2.5 w-6 h-6 cursor-pointer"
                />
              </button>
            </MoreAccountSettingPopup>
          </div>
          <div className="flex items-center h-full py-2 ml-[38px]">
            <Icon.ButtonIcon
              icon={<img src={settingIcon} alt="icon" />}
              customClass={classNames(
                'w-8 h-8 rounded-xl focus:bg-blue-700 hover:bg-white__op-50',
                {
                  '!bg-blue-700': pathname === PATHNAME.PROFILE_SETTING,
                }
              )}
              onClick={() => navigate(PATHNAME.PROFILE_SETTING)}
            />

            {/* <Icon.ButtonIcon
              icon={<img src={notificationIcon} alt="icon" />}
              customClass="w-[52px] h-8 rounded-xl focus:bg-blue-700 hover:bg-white__op-50 !justify-start px-2 py-1.5"
            >
              <span className="flex items-center content-center bg-red-600 text-white__op-900 absolute top-1/2 -translate-y-1/2 right-2 rounded-lg w-6 h-4 px-1 py-0.5 text-xs mx-auto select-none font-normal font-sans">
                56
              </span>
            </Icon.ButtonIcon> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
