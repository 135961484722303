import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Icon from '@components/common/Icon'
import { IProject } from '@models/project'
//icon
import DefaultImage from '@assets/images/project-thumb-default.svg'
import DefaultImage2 from '@assets/images/project-thumb-default-2.svg'
import IconMore from '@assets/icons/common/more-icon.svg'
import { helper } from '@utils/helper/common'
import { PROJECT_SIZE_TYPE } from '@constants/plan'
import { ProjectPublishStatusName } from '@models/organization'
import { useTranslation } from 'react-i18next'
import { LANGUAGE } from '@constants/common'
import i18next from 'i18next'

interface IPropsType {
  projectEdit: IProject | null
  handleOpenMoreSettingProject: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    indexOpen: number,
    project: IProject
  ) => void
  handleGetInfoProject: (projectId: number) => void
}
const ListModeComponent: React.FC<IPropsType> = (props): React.ReactElement => {
  const lang = i18next.language
  const { projectEdit, handleGetInfoProject, handleOpenMoreSettingProject } =
    props
  const { t } = useTranslation()
  const { listProject } = useAppSelector((state: RootState) => state.project)

  return (
    <>
      {listProject &&
        listProject.projects &&
        listProject.projects.map((project, index) => (
          <div key={index}>
            <div
              className="px-4 py-3 flex hover:bg-white__50 items-center rounded-xl hover:bg-white__op-50 cursor-pointer overflow-x-hidden"
              onClick={() => handleGetInfoProject(project.projectId)}
            >
              <div className="w-[6.625rem] h-16 mr-6 rounded-lg overflow-hidden flex-shrink-0 relative bg-gray-700">
                {project.projectImageUrl ? (
                  <img
                    src={project.projectImageUrl}
                    className="w-full h-full object-cover"
                    alt=""
                  />
                ) : (
                  <>
                    <img
                      src={DefaultImage}
                      className="absolute inset-0 mix-blend-multiply"
                      alt=""
                    />
                    <img
                      src={DefaultImage2}
                      className="absolute inset-0 mix-blend-soft-light"
                      alt=""
                    />
                  </>
                )}
              </div>
              <div className="w-full mr-6">
                <h1 className="font-semibold text-base text-white__op-900 max-w-xs truncate 2xl:max-w-sm">
                  {project.projectName}
                </h1>
                <div className="text-sm leading-6 font-normal text-white__op-600 max-w-xs truncate 2xl:max-w-sm">
                  {project.description}
                </div>
              </div>
              <div className="flex text-white__op-500 items-center flex-shrink-0">
                {project.publishStatus === ProjectPublishStatusName.PUBLISH && (
                  <div className="px-3 py-[2px] text-xs leading-5 bg-white__op-50 rounded-full whitespace-nowrap text-white__op-600 mr-4 font-lato">
                    {t('common.public')}
                  </div>
                )}
                {project.publishStatus ===
                  ProjectPublishStatusName.PASSWORD && (
                  <div className="px-3 py-[2px] text-xs leading-5 bg-white__op-50 rounded-full whitespace-nowrap text-white__op-600 mr-4 font-lato">
                    {t('common.protected')}
                  </div>
                )}
                <div className="mr-4 flex text-xs leading-5 font-lato">
                  <div className="whitespace-nowrap flex">
                    {moment(project.lastEditedAt).format(
                      lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'YYYY/MM/DD'
                    )}
                  </div>
                  <div className="whitespace-pre">, </div>
                  <div className="flex-shrink-0">
                    {helper.formatBytes(
                      Number(project.currentProjectStorage),
                      2,
                      PROJECT_SIZE_TYPE.GB
                    )}
                  </div>
                </div>
                <div className="p-1" id={`arrow${index}`}>
                  <div className="relative">
                    <Icon.ButtonIcon
                      icon={<img src={IconMore} alt="" />}
                      onClick={(e) =>
                        handleOpenMoreSettingProject(e, index, project)
                      }
                      customClass={classNames('focus:bg-transparent', {
                        '!bg-blue-700 !outline-transparent':
                          projectEdit?.projectId === project.projectId,
                        'hover:bg-white__op-50':
                          projectEdit?.projectId !== project.projectId,
                      })}
                      size="sm"
                    />
                  </div>
                </div>
              </div>
            </div>
            {listProject.projects &&
              index + 1 < listProject.projects.length && (
                <hr className="border-white__op-50 ml-32 mr-4" />
              )}
          </div>
        ))}
    </>
  )
}

export default ListModeComponent
