import {
  NOTIFICATION_ACTIONS,
  NOTIFICATION_TYPES,
  PARAM_KEYS,
} from '@models/auth'
import { useAppDispatch } from '@stores/hook'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { actions as authActions } from '../store/auth/auth.reducer'
import { TYPES_AUTH } from '../store/auth/auth.type'

export const useParamsToStore = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!location.search) {
      return
    }

    const params = new URLSearchParams(location.search)
    const matchedParams = Object.values(PARAM_KEYS).reduce(
      (acc: { [key: string]: string }, key: string) => {
        const value = params.get(key)
        if (value !== null) {
          acc[key] = value
        }
        return acc
      },
      {}
    )

    if (matchedParams[PARAM_KEYS.PARTNER]) {
      dispatch(
        authActions[TYPES_AUTH.REDUCERS.SET_PARTNER_PARAM](
          matchedParams[PARAM_KEYS.PARTNER]
        )
      )
    }
    if (matchedParams[PARAM_KEYS.NOTIFICATION]) {
      switch (matchedParams[PARAM_KEYS.NOTIFICATION]) {
        case NOTIFICATION_TYPES.EMAIL:
          dispatch(
            authActions[TYPES_AUTH.REDUCERS.SET_NOTIFICATION_PARAM](
              NOTIFICATION_ACTIONS.SEND
            )
          )
          break
        case NOTIFICATION_TYPES.EMAIL_WITH_PASSWORD:
          dispatch(
            authActions[TYPES_AUTH.REDUCERS.SET_NOTIFICATION_PARAM](
              NOTIFICATION_ACTIONS.SEND_WITH_PASSWORD
            )
          )
          break
        case NOTIFICATION_TYPES.NONE:
          dispatch(
            authActions[TYPES_AUTH.REDUCERS.SET_NOTIFICATION_PARAM](
              NOTIFICATION_ACTIONS.NOT_SEND
            )
          )
          break
        default:
          break
      }
    }
  }, [location.search, dispatch])
}
