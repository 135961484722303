import { StripeCardElementOptions } from '@stripe/stripe-js'

export const CARD_OPTIONS: StripeCardElementOptions = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      color: '#fffffff7',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#ffffff99' },
    },
    invalid: {
      iconColor: '#F26C57',
      color: '#F26C57',
    },
  },
}
