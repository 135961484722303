import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { TreeView } from '@components/common/draggableTree/react-draggable-tree'
import {
  ILayer,
  IRequestUpdateScene,
  IRequestUpdateSpace,
  IResLayerOfGetSpaceAndScene,
  IResSceneOfGetSpaceAndScene,
  IResSpaceOfGetSpaceAndScene,
  NodeTreeViewItem,
  TypeActionUndoRedo,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import TreeRowSpaceOrScene from './NodeSpaceOrScene'
import SpaceSceneContextMenu from './SpaceSceneContextMenu'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { helper } from '@utils/helper/common'
import { IRequestUpdateProject, IResponseProjectInfo } from '@models/project'
import { updateProjectAction } from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { TypeOfNode } from '@components/common/draggableTree/node'
import {
  updateSceneAction,
  updateSpaceAction,
} from '@stores/scene/scene.action'

const ListLayerComponent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { dataSpaceAndScene, dataEditContextMenuLSS, contextCodec } =
    useAppSelector((state: RootState) => state.scene)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [root, setRoot] = useState(() =>
    helper.generateNodeLayer(dataSpaceAndScene as ILayer[])
  )
  const [item, setItem] = useState(() => helper.createItem(root))
  const [itemSlecting, setItemSelecting] = useState<NodeTreeViewItem | null>(
    null
  )
  const update = () => {
    setRoot(root)
    setItem(helper.createItem(root))
  }

  useEffect(() => {
    if (dataSpaceAndScene) {
      const newRoot = helper.generateNodeLayer(dataSpaceAndScene)
      const newItem = helper.createItem(newRoot)
      setRoot(newRoot)
      setItem(newItem)
    }
  }, [dataSpaceAndScene])

  useEffect(() => {
    if (itemSlecting) {
      switch (itemSlecting.node.type) {
        case TypeOfNode.SCENE:
          root.children.forEach((layer) => {
            layer.children.forEach((space) => {
              space.children.forEach((scene) => {
                if (scene.metadata?.id === itemSlecting.node.metadata?.id) {
                  scene.root.deselect()
                  scene.select()
                }
              })
            })
          })
          update()
          break
        case TypeOfNode.SPACE:
          root.children.forEach((layer) => {
            layer.children.forEach((space) => {
              if (space.metadata?.id === itemSlecting.node.metadata?.id) {
                space.root.deselect()
                space.select()
              }
            })
          })
          update()
          break
        case TypeOfNode.LAYER:
          root.children.forEach((layer) => {
            if (layer.metadata?.id === itemSlecting.node.metadata?.id) {
              layer.root.deselect()
              layer.select()
            }
          })
          update()
          break

        default:
          break
      }
    }
  }, [root])

  useEffect(() => {
    if (
      dataEditContextMenuLSS &&
      dataEditContextMenuLSS.type &&
      dataSpaceAndScene
    ) {
      const { type, data } = dataEditContextMenuLSS
      switch (type) {
        case TYPE_SETTING.LAYER:
          const newListSpaceAndSceneRL = helper.removeLayer(
            dataSpaceAndScene,
            data.id
          )
          const checkLayer = helper.checkHasScene(newListSpaceAndSceneRL)
          if (!checkLayer) {
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS](true)
            )
          } else {
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS](false)
            )
          }
          break
        case TYPE_SETTING.SPACE:
          const newListSpaceAndSceneRSp = helper.removeSpace(
            dataSpaceAndScene,
            data.id
          )
          const checkSpace = helper.checkHasScene(newListSpaceAndSceneRSp)
          if (!checkSpace) {
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS](true)
            )
          } else {
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS](false)
            )
          }
          break
        case TYPE_SETTING.SCENE:
          const newListSpaceAndSceneSc = helper.removeScene(
            dataSpaceAndScene,
            data.id
          )
          const checkScene = helper.checkHasScene(newListSpaceAndSceneSc)
          if (!checkScene) {
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS](true)
            )
          } else {
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS](false)
            )
          }
          break

        default:
          throw new Error('Something bug with check delete')
      }
    }
  }, [dataEditContextMenuLSS])

  const handleOpenUpdateName = () => {
    closeContextMenu()
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_IS_EDIT_NAME_LSS](true))
  }

  const closeContextMenu = () => {
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_OPEN_CONTEXT_MENU_LSS](false))
  }

  const handleUpdateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data as IResponseProjectInfo
    }
  }

  const converToTypeSetting = (typeNum: number) => {
    switch (typeNum) {
      case TypeOfNode.LAYER:
        return TYPE_SETTING.LAYER
      case TypeOfNode.SPACE:
        return TYPE_SETTING.SPACE
      case TypeOfNode.SCENE:
        return TYPE_SETTING.SCENE
      default:
        return TYPE_SETTING.SCENE
    }
  }

  const updateSpace = useCallback(async (dataReq: IRequestUpdateSpace) => {
    const res = await dispatch(updateSpaceAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [])

  const updateScene = useCallback(async (dataReq: IRequestUpdateScene) => {
    const res = await dispatch(updateSceneAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [])

  return (
    <>
      <div className="py-2 h-full">
        <TreeView
          className="bg-gray-800 overflow-y-auto h-full w-full"
          rootItem={item}
          background={
            <div
              className="absolute inset-0"
              onClick={() => {
                item.node.deselect()
                update()
              }}
            />
          }
          dropBetweenIndicator={({ top, left }) => (
            <div
              style={{
                left: `${left}px`,
                top: `${top}px`,
              }}
              className="absolute pointer-events-none right-0 h-0.5 bg-blue-500"
            />
          )}
          dropOverIndicator={({ top, height }) => (
            <div
              style={{
                top: `${top}px`,
                height: `${height}px`,
              }}
              className="absolute pointer-events-none inset-x-0 box-border border bg-blue-500 border-blue-500 -z-10"
            />
          )}
          handleDragStart={({ item }) => {
            setItemSelecting(item)
            if (!item.node.selected) {
              item.node.root.deselect()
              item.node.select()
              update()
            }
            return true
          }}
          canDrop={({ item, draggedItem }) => {
            if (
              draggedItem &&
              item.node.type === TypeOfNode.LAYER &&
              draggedItem.node.type > item.node.type
            ) {
              const layer = _.find(
                dataSpaceAndScene,
                (layer) =>
                  layer.info.id === item.node.metadata?.id && layer.collapsed
              )
              if (layer) {
                const { newListLayer } = helper.expandLayer(
                  dataSpaceAndScene ?? [],
                  layer.info.id
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayer
                  )
                )
              }
            }
            if (
              draggedItem &&
              item.node.type === TypeOfNode.SPACE &&
              draggedItem.node.type > item.node.type
            ) {
              const layer = _.find(
                dataSpaceAndScene,
                (layer) => layer.info.id === item.parent?.node.metadata?.id
              )
              const space = _.find(
                layer?.spaces,
                (space) =>
                  space.info.id === item.node.metadata?.id && space.collapsed
              )
              if (space) {
                const { newListLayer } = helper.expandSpace(
                  dataSpaceAndScene ?? [],
                  space.info.id
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayer
                  )
                )
              }
            }
            return (
              !!draggedItem &&
              draggedItem.node.type >= item.node.type &&
              draggedItem.node.type - item.node.type === 1
            )
          }}
          handleDrop={async ({ item, draggedItem, before, event }) => {
            event.currentTarget.classList.remove('!bg-blue-700', 'dragging')
            if (draggedItem) {
              for (const node of item.node.root.selectedDescendants) {
                item.node.insertBefore(node, before?.node)
                const newListLayer: Array<ILayer> = item.node.root.children.map(
                  (layer) => {
                    const listSpace = layer.children.map((space) => {
                      const listScene = space.children.map((scene) => {
                        return {
                          info: scene.metadata as IResSceneOfGetSpaceAndScene,
                        }
                      })
                      return {
                        info: space.metadata as IResSpaceOfGetSpaceAndScene,
                        collapsed: space.collapsed,
                        scenes: listScene,
                      }
                    })
                    return {
                      info: layer.metadata as IResLayerOfGetSpaceAndScene,
                      collapsed: layer.collapsed,
                      spaces: listSpace,
                    }
                  }
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayer
                  )
                )
                const reqUpdatePj: IRequestUpdateProject = {}
                if (draggedItem.node.type === TypeOfNode.SCENE) {
                  const layerId = item.parent?.node.metadata?.id
                  const spaceId = item.node.metadata?.id
                  const sceneId = draggedItem.node.metadata?.id
                  if (
                    layerId &&
                    spaceId &&
                    sceneId &&
                    sceneId === projectInfo?.defaultSceneSet.sceneId
                  ) {
                    reqUpdatePj.defaultSceneSet = {
                      layerId,
                      spaceId,
                      sceneId,
                    }
                  }
                  if (sceneId && spaceId && user && projectInfo) {
                    const reqUpdateScene: IRequestUpdateScene = {
                      organizationId: user.organizationId,
                      projectId: projectInfo.projectId,
                      sceneId,
                      spaceId,
                      contextCodec,
                    }
                    await updateScene(reqUpdateScene)
                  }
                }
                if (draggedItem.node.type === TypeOfNode.SPACE) {
                  const layerId = item.node.metadata?.id
                  const spaceId = draggedItem.node.metadata?.id
                  if (layerId && spaceId && user && projectInfo) {
                    const reqUpdateScene: IRequestUpdateSpace = {
                      organizationId: user.organizationId,
                      projectId: projectInfo.projectId,
                      layerId,
                      id: spaceId,
                    }
                    await updateSpace(reqUpdateScene)
                  }
                }
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayer)
                if (projectInfo) {
                  reqUpdatePj.orderStructure = { layers: orderStructuer }
                  const resUpdateProject = await handleUpdateProject(
                    projectInfo.projectId,
                    reqUpdatePj
                  )
                  if (resUpdateProject && draggedItem.node.metadata) {
                    const { isRedo, isUndo } =
                      sceneSettingHelper.saveUndoRedoToStorage({
                        projectId: projectInfo.projectId,
                        dataVersion: {
                          version: resUpdateProject.version,
                          data: {
                            type: converToTypeSetting(draggedItem.node.type),
                            id: draggedItem.node.metadata.id,
                            action: TypeActionUndoRedo.DRAG_DROP,
                            listLayer: newListLayer,
                          },
                        },
                      })
                    dispatch(
                      sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                        isUndo,
                        isRedo,
                      })
                    )
                  }
                }
                setItemSelecting(null)
                draggedItem.node.root.deselect()
                draggedItem.node.deselect()
                update()
              }
            }
          }}
          renderRow={(props) => <TreeRowSpaceOrScene {...props} />}
        />
      </div>
      <SpaceSceneContextMenu
        close={closeContextMenu}
        handleOpenUpdateName={handleOpenUpdateName}
      />
    </>
  )
}

export default ListLayerComponent
