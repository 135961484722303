export const TAX = 1

export const PROJECT_VIEW_KEY = {
  _100VIEWS: '100VIEWS',
  _500VIEWS: '500VIEWS',
  _1000VIEWS: '1000VIEWS',
  _2500VIEWS: '2500VIEWS',
  _5000VIEWS: '5000VIEWS',
}

export const PROJECT_SIZE_KEY = {
  _500MB: '500MB',
  _1GB: '1GB',
  _3GB: '3GB',
  _6GB: '6GB',
  _12GB: '12GB',
}

export const PROJECT_SIZE_TYPE = {
  GB: 'GB',
  MB: 'MB',
}

export const LIST_SERVICE_PACKAGE = [
  { name: 'Starter', key: 'SERVICE_PACKAGE_STARTER_V1' },
  { name: 'Basic', key: 'SERVICE_PACKAGE_BASIC_V1' },
  { name: 'Pro', key: 'SERVICE_PACKAGE_PRO_V1' },
  { name: 'Extreme', key: 'SERVICE_PACKAGE_EXTREME_V1' },
]

export const LIST_PER_SIZE_ADD_ON = [
  PROJECT_SIZE_KEY._500MB,
  PROJECT_SIZE_KEY._1GB,
  PROJECT_SIZE_KEY._3GB,
  PROJECT_SIZE_KEY._6GB,
  PROJECT_SIZE_KEY._12GB,
]
