import React from 'react'
import { IMedia } from '@models/media'
import ArrowUpIcon from '@assets/icons/common/arrow-up.svg'
import { useTranslation } from 'react-i18next'
import FixedTooltip, { TooltipPlacement } from '@components/common/FixedTooltip'

interface PropTypes {
  media: IMedia
  tooltipPosition?: TooltipPlacement
  handleCancel?: (folderId: number | null, media: IMedia) => void
  folderId?: number | null
}

const UploadingMedia: React.FC<PropTypes> = ({
  media,
  tooltipPosition = 'right',
  handleCancel,
  folderId = null,
}) => {
  const { t } = useTranslation()

  return (
    <FixedTooltip
      title={t('popup.mediaLib.uploading')}
      position={tooltipPosition}
    >
      <div className="group flex relative items-center px-4 py-1 hover:cursor-default font-lato text-xs">
        <div className="mr-2 w-8 h-8 rounded-lg flex-shrink-0 bg-white__op-100" />
        <span className="truncate w-full text-white__op-300 mr-2">
          {media.name}
        </span>
        <div className="flex-none flex flex-col w-5 h-5 justify-center items-center">
          <img src={ArrowUpIcon} className="w-2.5 mb-[2.5px]" alt="" />
          <div className="bg-white__op-100 h-0.5 w-[16.67px]">
            <div
              className="bg-blue-300 h-full"
              style={{ width: media.progress + '%' }}
            ></div>
          </div>
        </div>
        {handleCancel && media.progress === 0 && (
          <span
            className="absolute hidden group-hover:block right-1.5 text-white__op-300 hover:cursor-pointer"
            onClick={() => handleCancel(folderId, media)}
          >
            x
          </span>
        )}
      </div>
    </FixedTooltip>
  )
}

export default UploadingMedia
