import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import Icon from '@components/common/Icon'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import IconAdd from '@assets/icons/sceneSetting/add.svg'
import {
  ILayer,
  IRequestCreateScene,
  IResponseCreateScene,
  IScene,
  ISpace,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import IconBack from '@assets/icons/common/back-icon.svg'
import ButtonAddScene from './ButtonAddScene'
import ListSpaceComponent from './ListSpaceComponent'
import ListLayerComponent from './ListLayerComponent'
import useOnClickOutside from '@components/common/hooks'
import { createSceneAction } from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { helper } from '@utils/helper/common'
import { IRequestUpdateProject } from '@models/project'
import { updateProjectAction } from '@stores/project/project.action'

interface ListSceneProps {
  open: boolean
  close: () => void
}
const ListScenePopup: React.FC<ListSceneProps> = (props) => {
  const { open, close } = props
  const dispatch = useAppDispatch()
  const listScenePopupRef = useRef(null)
  const { t } = useTranslation()
  const { dataSpaceAndScene } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const [dataLayerSpaceSelected, setDataLayerSpaceSelected] = useState<{
    type: TYPE_RESOURCE_OF_PROJECT
    id: number
  } | null>(null)
  const [isExpandLayer, setIsExpandLayer] = useState<boolean>(false)
  const [dataExpandLayer, setDataExpandLayer] = useState<ILayer | null>(null)
  const [dataExpandSpace, setDataExpandSpace] = useState<ISpace | null>(null)

  useEffect(() => {
    const media = document.querySelector('#listScene')
    const overlayCl = media as Element
    if (open) {
      showMediaPopup(overlayCl)
    } else {
      setDataLayerSpaceSelected(null)
      hideMediaPopup(overlayCl)
    }
    selectDefaultSpace()
  }, [open])

  const selectDefaultSpace = async () => {
    if (projectInfo) {
      await handleExpandLayer(projectInfo.defaultSceneSet.layerId)
      handleOpenSpace(projectInfo.defaultSceneSet.spaceId)
    }
  }

  const handleOpenSpace = useCallback((id: number) => {
    const arrowSpace = document.querySelector(`#arrowSpacePopup${id}`)
    const eleListSpace = document.querySelector(`#spacePopup${id}`)
    if (arrowSpace && eleListSpace) {
      arrowSpace.classList.add('rotate-90')
      eleListSpace.classList.remove('h-0')
      eleListSpace.classList.add('h-auto')
    }
  }, [])

  useOnClickOutside(listScenePopupRef, () => {
    close()
  })

  const showMediaPopup = (element: Element) => {
    element.classList.remove('w-0')
    element.classList.remove('opacity-0')
    element.classList.add('w-[280px]')

    setTimeout(() => {
      element.classList.add('opacity-100')
    }, 100)
  }

  const hideMediaPopup = (element: Element) => {
    element.classList.remove('w-[280px]')
    element.classList.remove('opacity-100')
    element.classList.add('w-0')

    setTimeout(() => {
      element.classList.add('opacity-0')
    }, 100)
  }

  const handleSelectLayer = useCallback((id: number) => {
    setDataLayerSpaceSelected({
      type: TYPE_RESOURCE_OF_PROJECT.LAYER,
      id,
    })
  }, [])

  const handleExpandLayer = (
    id: number,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault()
    e?.stopPropagation()
    const layer = _.find(dataSpaceAndScene, (layer) => layer.info.id === id)
    if (layer) {
      setDataExpandLayer(layer)
      setIsExpandLayer(true)
    }
  }

  const handleExpandSpace = useCallback(
    (id: number, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.preventDefault()
      e?.stopPropagation()
      const arrowSpace = document.querySelector(`#arrowSpacePopup${id}`)
      const eleListSpace = document.querySelector(`#spacePopup${id}`)
      if (arrowSpace && eleListSpace) {
        arrowSpace.classList.toggle('rotate-90')
        eleListSpace.classList.toggle('h-0')
        eleListSpace.classList.toggle('h-auto')
      }
    },
    []
  )

  const handleBackToListLayer = () => {
    setIsExpandLayer(false)
    setDataLayerSpaceSelected(null)
    setDataExpandLayer(null)
  }

  const handleSetSpace = useCallback((space: ISpace) => {
    setDataLayerSpaceSelected({
      type: TYPE_RESOURCE_OF_PROJECT.SPACE,
      id: space.info.id,
    })
    setDataExpandSpace(space)
  }, [])

  const handleCreateScene = async (data: IRequestCreateScene) => {
    const resCreateScene = await dispatch(createSceneAction(data)).unwrap()
    if (resCreateScene.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'createScene',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return resCreateScene.data as IResponseCreateScene
    }
  }

  const handleAddScene = async () => {
    if (user && projectInfo) {
      let dataAddScene: IRequestCreateScene | null = null
      if (!dataLayerSpaceSelected) {
        const { layerId, sceneId, spaceId } = projectInfo.defaultSceneSet
        const layer = _.find(
          dataSpaceAndScene,
          (layer) => layer.info.id === layerId
        )
        const space = _.find(
          layer?.spaces,
          (space) => space.info.id === spaceId
        )
        const scene = _.find(
          space?.scenes,
          (scene) => scene.info.id === sceneId
        )
        if (space && scene) {
          dataAddScene = {
            organizationId: user.organizationId,
            projectId: projectInfo.projectId,
            title: `Scene ${space.scenes.length + 1}`,
            spaceId: projectInfo.defaultSceneSet.spaceId,
          }
        }
      } else if (
        dataLayerSpaceSelected.type === TYPE_RESOURCE_OF_PROJECT.SPACE &&
        dataExpandSpace
      ) {
        dataAddScene = {
          organizationId: user.organizationId,
          projectId: projectInfo.projectId,
          title: `Scene ${dataExpandSpace.scenes.length + 1}`,
          spaceId: dataExpandSpace.info.id,
        }
      } else {
        throw new Error('request add scene invalid')
      }
      if (dataAddScene) {
        const res = await handleCreateScene(dataAddScene)
        if (res && dataSpaceAndScene) {
          const resScene = res as IResponseCreateScene
          const newScene: IScene = {
            info: {
              id: resScene.sceneId,
              title: resScene.title,
              thumbnailUrl: null,
              imageThumbnailId: null,
              videoThumbnailId: null,
            },
          }
          const newListLayer: Array<ILayer> = helper.addSceneToSpace(
            dataSpaceAndScene,
            newScene,
            true,
            dataExpandLayer?.info.id || projectInfo.defaultSceneSet.layerId,
            resScene.spaceId
          )
          const orderStructuer = helper.getStructureSpaceAndScene(newListLayer)
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
              newListLayer
            )
          )
          await updateProject(projectInfo.projectId, {
            orderStructure: {
              layers: orderStructuer,
            },
          })
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_ID_SCENE_SELECT](
              resScene.sceneId
            )
          )
        }
      }
    }
  }

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const resUpdateProject = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (resUpdateProject.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return resUpdateProject.data
    }
  }

  return (
    <div
      id="listScene"
      className="w-0 h-[480px] rounded-2xl mr-1 mt-[90px] bg-gray-800 shadow-dark__popup opacity-0 overflow-hidden flex flex-col absolute right-full"
      ref={listScenePopupRef}
    >
      <div
        className={classNames(
          'pr-1 py-1 pl-4 flex justify-between items-center flex-shrink-0',
          {
            '!pl-1':
              isExpandLayer &&
              dataSpaceAndScene &&
              dataSpaceAndScene.length > 1,
          }
        )}
      >
        {dataSpaceAndScene &&
        dataSpaceAndScene.length > 1 &&
        isExpandLayer &&
        dataExpandLayer ? (
          <div className="flex items-center">
            <Icon.ButtonIcon
              icon={<img src={IconBack} alt="" />}
              onClick={handleBackToListLayer}
            />
            <div className="text-white text-base leading-6 font-semibold">
              {dataExpandLayer.info.title}
            </div>
          </div>
        ) : (
          <div className="text-white text-base leading-6 font-semibold">
            {t('popup.mediaLib.scene')}
          </div>
        )}
        <Icon.ButtonIcon
          icon={<img src={IconAdd} alt="" />}
          size="xs"
          disabled={
            dataLayerSpaceSelected &&
            dataLayerSpaceSelected.type === TYPE_RESOURCE_OF_PROJECT.LAYER
              ? true
              : false
          }
          onClick={handleAddScene}
        />
      </div>
      <div className="overflow-y-auto h-full">
        {isExpandLayer ? (
          <>
            {dataExpandLayer && dataExpandLayer.spaces.length > 0 && (
              <ListSpaceComponent
                listSpace={dataExpandLayer.spaces}
                idSpace={
                  dataLayerSpaceSelected &&
                  dataLayerSpaceSelected.type === TYPE_RESOURCE_OF_PROJECT.SPACE
                    ? dataLayerSpaceSelected.id
                    : null
                }
                handleSelectSpace={handleSetSpace}
                handleExpandSpace={handleExpandSpace}
              />
            )}
          </>
        ) : (
          <>
            {dataSpaceAndScene && (
              <ListLayerComponent
                listLayer={dataSpaceAndScene}
                idLayer={
                  dataLayerSpaceSelected &&
                  dataLayerSpaceSelected.type === TYPE_RESOURCE_OF_PROJECT.LAYER
                    ? dataLayerSpaceSelected.id
                    : null
                }
                handleSelectLayer={handleSelectLayer}
                handleExpandLayer={handleExpandLayer}
              />
            )}
          </>
        )}
        {dataSpaceAndScene &&
          dataSpaceAndScene.length === 1 &&
          dataSpaceAndScene[0].spaces.length === 1 &&
          dataSpaceAndScene[0].spaces[0].scenes.length === 1 && (
            <ButtonAddScene handleCreateScene={handleCreateScene} />
          )}
      </div>
    </div>
  )
}

export default React.memo(ListScenePopup)
