import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { IMediaFolder } from '@models/media'
import IconArrowRight from '@assets/icons/sceneSetting/arrowRight.svg'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { RootState } from '../../../../../store/store'
import { getMediaAction } from '../../../../../store/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { useTranslation } from 'react-i18next'
import { actions } from '../../../../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../../../../store/project/project.type'

interface PropTypes {
  folder: IMediaFolder
  handleMediaRightClick: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
  isExtentFolder: boolean
  setIsExtentFolder: Dispatch<SetStateAction<boolean>>
}

const NormalFolder: React.FC<PropTypes> = ({
  folder,
  handleMediaRightClick,
  isExtentFolder,
  setIsExtentFolder,
}) => {
  const [isLoadedData, setIsloadedData] = useState(false)

  const { user } = useAppSelector((state: RootState) => state.auth)
  const {
    projectInfo,
    selectedFolderInAsset,
    dragOverFolder,
    folderCreateNewRecord,
  } = useAppSelector((state: RootState) => state.project)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoadedData && isExtentFolder) {
      getMedias()
      setIsloadedData(true)
    }
  }, [isExtentFolder])

  useEffect(() => {
    if (
      !isExtentFolder &&
      folderCreateNewRecord &&
      folderCreateNewRecord === folder.id
    ) {
      handleExtendFolder()
    }
  }, [folderCreateNewRecord])

  const getMedias = useCallback(async () => {
    try {
      const res = await dispatch(
        getMediaAction({
          organizationId: user?.organizationId,
          projectId: projectInfo?.projectId,
          folderId: folder.id,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      console.log({ error })
      openNotification({
        type: TYPE.ERROR,
        key: 'getMedias',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [])

  const handleExtendFolder = () => {
    setIsExtentFolder(!isExtentFolder)
  }

  const handleSelectFolder = () => {
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_ASSET](folder.id)
    )
  }

  const handleExtendFolderWhenDragging = () => {
    if (!isExtentFolder) {
      setIsExtentFolder(!isExtentFolder)
    }
  }

  return (
    <div
      className={classNames(
        'flex items-center h-10 group ease-in-out justify-between cursor-pointer',
        {
          'bg-blue-700':
            folder.id === selectedFolderInAsset &&
            folder.id !== dragOverFolder?.folderId,
          'hover:bg-white__op-100': folder.id !== selectedFolderInAsset,
          'bg-blue-500': folder.id === dragOverFolder?.folderId,
        }
      )}
      onDragEnter={handleExtendFolderWhenDragging}
      onContextMenu={(event) => handleMediaRightClick(event)}
    >
      <button
        className="w-4 h-9 -my-2 flex-shrink-0 flex items-center justify-center"
        onMouseDown={() => handleExtendFolder()}
      >
        <img
          src={IconArrowRight}
          className={classNames(
            'hidden group-hover:!block transition-transform duration-300',
            {
              'rotate-90 h-auto': isExtentFolder,
            }
          )}
          alt=""
        />
      </button>
      <div
        className="w-full h-full flex items-center leading-5 text-xs font-semibold text-white__op-600 truncate"
        onMouseDown={() => handleSelectFolder()}
      >
        {folder.name}
      </div>
    </div>
  )
}

export default NormalFolder
