export const TYPE_PROJECT = {
  REDUCERS: {
    SET_STATUS_POPUP_CREATE_NEW_PROJECT: 'changeStatusPopupCreateNewProject',
    SET_TAB: 'setTabSetting',
    SET_ADD_MEMBER: 'setAddMember',
    SET_STATUS_POPUP_MEDIA_LIB: 'changeStatusPopupMediaLib',
    SET_STATUS_POPUP_SHARING_URL: 'changeStatusPopupSharingUrl',
    SET_LIST_PROJECT: 'setListProject',
    SET_MEDIA_SELECT: 'setMediaSelect',
    SET_PROJECT_SETTING: 'setProjectSetting',
    SET_MODE_VIEW_PROJECT: 'setModeViewProject',
    SET_MODE_OF_MEDIA_LIB_POPUP: 'setModeOfMediaLibPopup',
    SET_SELECTED_FOLDER_IN_MEDIA_LIB_POPUP: 'setSelectedFolderInMediaLibPopup',
    SET_SELECTED_FOLDER_IN_ASSET: 'setSelectedFolderInAsset',
    SET_SELECTED_MEDIA_IN_ASSET: 'setSelectedMediaInAsset',
    SET_EDITED_ITEM_NAME_IN_ASSET: 'setEditedItemNameInAsset',
    SET_STATUS_MEDIA_LIB_POPUP: 'changeStatusMedialibPopup',
    SET_TYPE_FILTER_LIST_PROJECT: 'changeTypeFilterListProject',
    SET_LIST_FILE_UPLOAD_WAITTING: 'setListFileUploadWaitting',
    SET_FILE_UPLOADING: 'setFileUploading',
    SET_FILE_COMPLETE: 'setFileComplete',
    UPDATE_CONVERTED_MEDIA: 'updateConvertedMedia',
    ADD_MEDIA: 'addMedia',
    REMOVE_MEDIA: 'removeMedia',
    UPDATE_MEDIA: 'updateMedia',
    REMOVE_UPLOADING_MEDIAS_GLOBAL: 'removeUploadingMediasGlobal',
    MOVE_MEDIA: 'moveMedia',
    SET_DRAG_OVER_FOLDER: 'setDragOverFolder',
    SET_OPEN_SETTING_PUBLISH: 'setOpenSettingPublish',
    SET_OPEN_ALERT_OVER_SPACE_S3: 'setOpenAlertOverSpaceS3',
    SET_OPEN_ALERT_EXPIRED_TRIAL: 'setOpenAlertExpiredTrial',
    SET_IS_ORG_STORAGE_OVER_LIMIT: 'setIsOrgStorageOverLimit',
    SET_IS_PRJ_STORAGE_OVER_LIMIT: 'setIsPrjStorageOverLimit',
    SET_FOLDER_CREATE_NERW_RECORD: 'setFolderCreateNewRecord',
    SET_IS_STORAGE_CHANGED: 'setIsStorageChanged',
    SET_IS_SHOW_PUBLISH_SUCCESS: 'setIsShowPublishSuccess',
    SET_MODAL_OPEN: 'SET_MODAL_OPEN',
    SET_MODAL_CLOSE: 'SET_MODAL_CLOSE',
  },
  ACTIONS: {
    CREATE_PROJECT: 'project/createProject',
    GET_LIST_PROJECT: 'project/getListProject',
    GET_INFO_PROJECT: 'project/getInfoProject',
    UPDATE_PROJECT: 'project/updateProject',
    DELETE_PROJECT: 'project/deleteProject',
    UPLOAD_MEDIA: 'project/uploadMedia',
    GET_MEDIA_LIST: 'project/getMediaList',
    DELETE_MEDIA: 'project/deleteMedia',
    UPDATE_MEDIA: 'project/updateMedia',
    GET_INFO_AND_LIST_SPACE_SCENE: 'project/getInfoAndListSpaceScene',
    GET_MEDIA_FOLDERS: 'project/getMediaFolders',
    CREATE_MEDIA_FOLDER: 'project/createMediaFolder',
    DELETE_MEDIA_FOLDER: 'project/deleteMediaFolder',
    UPDATE_MEDIA_FOLDER: 'project/updateMediaFolder',
    GET_PROJECT_PUBLISH: 'project/getProjectPublish',
    UPDATE_PROJECT_PUBLISH: 'project/updateProjectPublish',
    AVAILABLE_STREAM: 'project/availableStream',
    SET_MODAL_OPEN: 'SET_MODAL_OPEN',
    SET_MODAL_CLOSE: 'SET_MODAL_CLOSE', 
    GET_LIST_PROJECT_STORAGE: 'project/getListProjectStorage',
  },
}
