import { gql } from '@apollo/client'

export const REGISTER_SERVICE_PACKAGE = gql`
  mutation registerServicePackage($input: RegisterServicePackageInput!) {
    registerServicePackage(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const CANCEL_CMS_SUBSCRIPTION = gql`
  mutation cancelCmsProductSubscription($organizationId: String!) {
    cancelCmsProductSubscription(organizationId: $organizationId) {
      message
      statusCode
      error {
        errorCode
        message
      }
    }
  }
`

export const UPGRADE_SERVICE_PACKAGE = gql`
  mutation upgradeServicePackage($input: UpgradeServicePackageInput!) {
    upgradeServicePackage(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const DOWNGRADE_SERVICE_PACKAGE = gql`
  mutation downgradeServicePackage($input: DowngradeServicePackageInput!) {
    downgradeServicePackage(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const UPGRADE_ADD_ON = gql`
  mutation upgradeAddOn($input: UpgradeAddOnInput!) {
    upgradeAddOn(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const DOWNGRADE_ADD_ON = gql`
  mutation downgradeAddOn($input: DowngradeAddOnInput!) {
    downgradeAddOn(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`
