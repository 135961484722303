import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { RootState } from '../../../../store/store'
// import IconSearch from '../../../../assets/icons/sceneSetting/search.svg'
import Media from './Media'
import Folder from './Folder'
import { actions } from '../../../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../../../store/project/project.type'
import ItemMenu from './ItemMenu'
import { IMedia, ISelectedMedia } from '../../../../models/media'
import { updateMediaAction } from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import SpinComponent from '@components/common/SpinComponent'

interface PropTypes {
  handleCancelUpload: (folderId: number | null, media: IMedia) => void
}

const ListMedias: React.FC<PropTypes> = ({ handleCancelUpload }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    listMedia,
    mediaFolders,
    selectedFolderInAsset,
    selectedMediaInAsset,
    projectInfo,
    isLoading,
  } = useAppSelector((state: RootState) => state.project)

  const [isOpenContextMenu, setIsOpenContextMenu] = useState<boolean>(false)
  const [positionContextMenu, setPositionContextMenu] = useState<{
    xPos: string
    yPos: string
  } | null>(null)
  const [editedFolder, setEditedFolder] = useState<number | null>(null)
  const [editedMedia, setEditedMedia] = useState<ISelectedMedia | null>(null)

  const handleClickOutsideMedias = () => {
    dispatch(actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_ASSET](null))
    dispatch(actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_MEDIA_IN_ASSET](null))
  }

  const handleMediaRightClick = (
    event: React.MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const xPos = event.pageX + 'px'
    const yPos = event.pageY + 'px'
    setPositionContextMenu({ xPos, yPos })
    setIsOpenContextMenu(true)
  }

  const handleEditingFolder = () => {
    const selectedFolder = mediaFolders.find(
      (folder) => folder.id === selectedFolderInAsset
    )
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_EDITED_ITEM_NAME_IN_ASSET](
        selectedFolder?.name
      )
    )
    setEditedFolder(selectedFolderInAsset)
  }

  const handleEditingMedia = () => {
    if (!selectedMediaInAsset) {
      throw new Error('Media is invalid')
    }
    if (selectedMediaInAsset?.folderId) {
      const folder = mediaFolders.find(
        (folder) => folder.id === selectedMediaInAsset.folderId
      )
      const selectedMedia = folder?.children.find(
        (media) => media.key === selectedMediaInAsset.key
      )
      dispatch(
        actions[TYPE_PROJECT.REDUCERS.SET_EDITED_ITEM_NAME_IN_ASSET](
          selectedMedia?.name
        )
      )
    } else {
      const selectedMedia = listMedia.find(
        (media) => media.key === selectedMediaInAsset.key
      )
      dispatch(
        actions[TYPE_PROJECT.REDUCERS.SET_EDITED_ITEM_NAME_IN_ASSET](
          selectedMedia?.name
        )
      )
    }
    setEditedMedia({
      key: selectedMediaInAsset.key,
      resourceId: selectedMediaInAsset.resourceId,
      mediaType: selectedMediaInAsset.mediaType,
    })
  }

  const handleEditingItemName = () => {
    setIsOpenContextMenu(false)
    if (selectedFolderInAsset) {
      handleEditingFolder()
    } else if (selectedMediaInAsset?.key) {
      handleEditingMedia()
    }
  }

  const handleOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    dispatch(actions[TYPE_PROJECT.REDUCERS.SET_DRAG_OVER_FOLDER](null))

    const dataTransfer: ISelectedMedia = JSON.parse(
      event.dataTransfer.getData('media')
    )
    await handleUpdateMedia(dataTransfer)
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.MOVE_MEDIA]({
        media: dataTransfer,
        folderIdTarget: null,
      })
    )
  }

  const handleUpdateMedia = async (media: ISelectedMedia) => {
    try {
      if (!projectInfo?.projectId || !media.resourceId || !media.mediaType) {
        throw new Error('update media input is invalid')
      }

      const res = await dispatch(
        updateMediaAction({
          projectId: projectInfo?.projectId,
          resourceId: media.resourceId,
          mediaType: media.mediaType,
          folderId: null,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateMedia',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  return (
    <React.Fragment>
      <div className="h-full flex flex-col">
        <div className="text-white overflow-y-auto scrollbar-hide">
          {mediaFolders?.map((mediaFolder, index) => (
            <Folder
              key={index}
              folder={mediaFolder}
              handleMediaRightClick={handleMediaRightClick}
              isEditing={editedFolder === mediaFolder.id ? true : false}
              setEditedFolder={setEditedFolder}
              setEditedMedia={setEditedMedia}
              editedMedia={editedMedia}
              handleCancelUpload={handleCancelUpload}
            />
          ))}

          {mediaFolders?.length > 0 && listMedia?.length > 0 && (
            <hr className="mx-4 my-2 border-white/10" />
          )}

          <div
            onDragOver={(event) => event.preventDefault()}
            onDrop={handleOnDrop}
          >
            {listMedia?.map((media, index) => (
              <Media
                key={index}
                media={media}
                handleMediaRightClick={handleMediaRightClick}
                isEditing={editedMedia?.key === media.key ? true : false}
                setEditedMedia={setEditedMedia}
                handleCancelUpload={handleCancelUpload}
              />
            ))}
          </div>
        </div>
        <div
          className="flex-1"
          onDrop={handleOnDrop}
          onDragOver={(event) => {
            event.preventDefault()
          }}
          onMouseDown={() => handleClickOutsideMedias()}
        ></div>
      </div>

      <ItemMenu
        open={isOpenContextMenu}
        position={positionContextMenu}
        close={() => setIsOpenContextMenu(false)}
        handleEditingItemName={handleEditingItemName}
      />
      {isLoading && <SpinComponent isFlexible />}
    </React.Fragment>
  )
}

export default ListMedias
