import React, { useEffect, useState } from 'react'

import Input from '@components/common/Input'
import { IInfoTagResponse, IRequestSetInfoTag } from '@models/sceneSetting'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { useTranslation } from 'react-i18next'

interface IPropsType {
  dataInfoTag: IInfoTagResponse
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<IInfoTagResponse>
}
const InfoTagTitleCom: React.FC<IPropsType> = (props): React.ReactElement => {
  const { handleSetInfoTag, dataInfoTag } = props
  const { t } = useTranslation()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [title, setTitle] = useState<string>(dataInfoTag.title ?? '')

  useEffect(() => {
    setTitle(dataInfoTag.title ?? '')
  }, [dataInfoTag.id, dataInfoTag.title])

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setTitle(e.target.value)
  }

  const handleUpdateMediaTitle = async () => {
    if (
      projectInfo &&
      user &&
      dataScenePreview &&
      title !== dataInfoTag.title
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          title,
        },
      }
      await handleSetInfoTag(req)
    }
  }

  return (
    <Input
      id="sceneName"
      type="text"
      color="ghost"
      placeholder={t('sceneSetting.rightSideBar.infoTag.title')}
      className="leading-5 font-lato"
      value={title}
      onChange={handleChangeTitle}
      onBlur={handleUpdateMediaTitle}
    />
  )
}

export default InfoTagTitleCom
