import { MouseEvent, useCallback, useEffect, useState } from 'react'
import {
  IMedia,
  IMediaFolder,
  ISelectedMedia,
  TYPE_MEDIA_LIB,
} from '@models/media'
import Media from '../Media'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { RootState } from '../../../../../store/store'
import {
  getMediaAction,
  updateMediaAction,
} from '../../../../../store/project/project.action'
import { openNotification } from '../../../../../utils/notification'
import { TYPE } from '../../../../../models/common'
import { useTranslation } from 'react-i18next'
import EditingFolder from './EdittingFolder'
import NormalFolder from './NormalFolder'
import { actions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'

interface PropTypes {
  folder: IMediaFolder
  handleMediaRightClick: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
  isEditing?: boolean
  setEditedFolder: React.Dispatch<React.SetStateAction<number | null>>
  setEditedMedia: React.Dispatch<React.SetStateAction<ISelectedMedia | null>>
  editedMedia: ISelectedMedia | null
  handleCancelUpload: (folderId: number | null, media: IMedia) => void
}

const Folder: React.FC<PropTypes> = ({
  folder,
  handleMediaRightClick,
  isEditing,
  setEditedFolder,
  editedMedia,
  setEditedMedia,
  handleCancelUpload,
}) => {
  const [isExtentFolder, setIsExtentFolder] = useState(false)
  const [isLoadedData, setIsloadedData] = useState(false)
  const [dragCounter, setDragCounter] = useState(0)

  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoadedData && isExtentFolder) {
      getMedias()
      setIsloadedData(true)
    }
  }, [isExtentFolder])

  const getMedias = useCallback(async () => {
    try {
      const res = await dispatch(
        getMediaAction({
          organizationId: user?.organizationId,
          projectId: projectInfo?.projectId,
          folderId: folder.id,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      console.log({ error })
      openNotification({
        type: TYPE.ERROR,
        key: 'getMedias',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [])

  const handleOnDragEnter = () => {
    setDragCounter(dragCounter + 1)

    // add to event loop for waiting drag leave event before enter event
    setTimeout(() =>
      dispatch(
        actions[TYPE_PROJECT.REDUCERS.SET_DRAG_OVER_FOLDER]({
          type: TYPE_MEDIA_LIB.ASSET,
          folderId: folder.id,
        })
      )
    )
  }

  const handleOnDragLeave = () => {
    setDragCounter(dragCounter - 1)

    const isDragLeave = dragCounter - 1 === 0
    if (isDragLeave) {
      dispatch(actions[TYPE_PROJECT.REDUCERS.SET_DRAG_OVER_FOLDER](null))
    }
  }

  const handleOnDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    setDragCounter(dragCounter - 1)
    dispatch(actions[TYPE_PROJECT.REDUCERS.SET_DRAG_OVER_FOLDER](null))

    const dataTransfer: ISelectedMedia = JSON.parse(
      event.dataTransfer.getData('media')
    )
    await handleUpdateMedia(dataTransfer)
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.MOVE_MEDIA]({
        media: dataTransfer,
        folderIdTarget: folder.id,
      })
    )
  }

  const handleUpdateMedia = async (media: ISelectedMedia) => {
    try {
      if (!projectInfo?.projectId || !media.resourceId || !media.mediaType) {
        throw new Error('update media input is invalid')
      }

      const res = await dispatch(
        updateMediaAction({
          projectId: projectInfo?.projectId,
          resourceId: media.resourceId,
          mediaType: media.mediaType,
          folderId: folder.id,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateMedia',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  return (
    <div
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDragOver={(event) => {
        event.preventDefault()
      }}
      onDrop={handleOnDrop}
    >
      {isEditing ? (
        <EditingFolder folder={folder} setEditedFolder={setEditedFolder} />
      ) : (
        <NormalFolder
          folder={folder}
          handleMediaRightClick={handleMediaRightClick}
          isExtentFolder={isExtentFolder}
          setIsExtentFolder={setIsExtentFolder}
        />
      )}
      {isExtentFolder &&
        folder.children?.map((media, index) => (
          <Media
            key={index}
            media={media}
            handleMediaRightClick={handleMediaRightClick}
            folderId={folder.id}
            isEditing={editedMedia?.key === media.key}
            setEditedMedia={setEditedMedia}
            handleCancelUpload={handleCancelUpload}
          />
        ))}
    </div>
  )
}

export default Folder
