import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TYPE, TypeLanguage } from '@models/common'
import { IRequestUpdateProject, IResponseProjectInfo } from '@models/project'
import {
  ILayer,
  IRequestDeleteSpace,
  IRequestGetScene,
  IRequestUpdateSpace,
  IScene,
  ISpace,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import {
  deleteSpaceAction,
  getSceneAction,
  updateSpaceAction,
} from '@stores/scene/scene.action'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { RootState } from '@stores/store'
import { helper } from '@utils/helper/common'
import { openNotification } from '@utils/notification'

import ThumbnailComponent from './ThumbnailComponent'
import classNames from 'classnames'
import Input from '@components/common/Input'
import Popup from '@components/common/Popup'
import Icon from '@components/common/Icon'
import SpinComponent from '@components/common/SpinComponent'
import Button from '@components/common/Button'
//icon
import IconOption from '@assets/icons/sceneSetting/option.svg'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'

interface IPropsType {
  updateProject: (
    projectId: number,
    data: IRequestUpdateProject
  ) => Promise<IResponseProjectInfo | undefined>
}
const SpaceSettingComponent: React.FC<IPropsType> = (
  props
): React.ReactElement => {
  const { updateProject } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { dataTypeEdit, dataSpaceAndScene, activeView, contextCodec } =
    useAppSelector((state: RootState) => state.scene)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  const [dataSpace, setDataSpace] = useState<ISpace>(
    dataTypeEdit?.data as ISpace
  )
  const [isOpenEditSpaceName, setIsOpenEditSpaceName] = useState<boolean>(false)
  const [spaceName, setSpaceName] = useState<string>(dataSpace.info.title)
  const [isDeletingSpace, setIsDeletingSpace] = useState<boolean>(false)
  const [isDisabledDeleteSpace, setIsDisabledDeleteSpace] =
    useState<boolean>(false)

  useEffect(() => {
    if (dataTypeEdit) {
      setDataSpace(dataTypeEdit.data as ISpace)
      setSpaceName((dataTypeEdit.data as ISpace).info.title)
    }
  }, [dataTypeEdit])

  useEffect(() => {
    if (dataTypeEdit && dataSpaceAndScene) {
      const newListSpaceAndScene = helper.removeSpace(
        dataSpaceAndScene,
        (dataTypeEdit.data as ISpace).info.id
      )
      const check = helper.checkHasScene(newListSpaceAndScene)
      if (!check) {
        setIsDisabledDeleteSpace(true)
      } else {
        setIsDisabledDeleteSpace(false)
      }
    }
    return () => setIsDisabledDeleteSpace(false)
  }, [(dataTypeEdit?.data as ISpace).info.id])

  const handleDeleteSpace = async () => {
    const idDelete = dataSpace.info.id
    if (user && projectInfo && idDelete) {
      const data: IRequestDeleteSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        spaceId: idDelete,
      }
      try {
        setIsDeletingSpace(true)
        const res = await dispatch(deleteSpaceAction(data)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteSpace',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          setIsOpenSetting(false)
          const { layer } = activeView
          const { sceneId, spaceId, layerId } = projectInfo.defaultSceneSet
          if (layer && dataSpaceAndScene) {
            const newListData = helper.removeSpace(dataSpaceAndScene, idDelete)
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                newListData
              )
            )
            const hasScenePreview = _.find(dataSpace.scenes, {
              id: projectInfo.defaultSceneSet.sceneId,
            })
            let newIdSceneActive: number | null = null
            let newLayerActive: ILayer | null = null
            let newSpaceActive: ISpace | null = null
            if (hasScenePreview) {
              const listSpaceBeforeUpdate = layer.spaces
              const layerAfterUpdate = _.find(
                newListData,
                (layerF) => layerF.info.id === layer.info.id
              )
              if (layerAfterUpdate) {
                const { idScene, newLayer, newSpace } =
                  helper.deleteSceneAndUpdateAnotherSpace(
                    newListData,
                    listSpaceBeforeUpdate,
                    dataSpace.info.id,
                    layerAfterUpdate
                  )
                newIdSceneActive = idScene
                newLayerActive = newLayer
                newSpaceActive = newSpace
              }
            } else {
              const layerActive = _.find(
                newListData,
                (layer) => layer.info.id === layerId
              )
              const spaceActive = _.find(
                layerActive?.spaces,
                (space) => space.info.id === spaceId
              )
              if (layerActive && spaceActive && sceneId) {
                newIdSceneActive = sceneId
                newLayerActive = layerActive
                newSpaceActive = spaceActive
              }
            }
            if (newIdSceneActive && newLayerActive && newSpaceActive) {
              const dataGetScene: IRequestGetScene = {
                projectId: projectInfo.projectId,
                sceneId: newIdSceneActive,
                contextCodec,
              }
              const resGetInfoScene = await dispatch(
                getSceneAction(dataGetScene)
              ).unwrap()
              if (resGetInfoScene.data) {
                const scene: IScene = {
                  info: {
                    id: resGetInfoScene.data.sceneId,
                    title: resGetInfoScene.data.title,
                    thumbnailUrl: resGetInfoScene.data.thumbnailUrl,
                    imageThumbnailId: resGetInfoScene.data.imageThumbnailId,
                    videoThumbnailId: null,
                  },
                }
                const idLayer = newLayerActive.info.id
                const idSpace = newSpaceActive.info.id
                const newListLayerCo = helper.openScene(
                  newListData,
                  idLayer,
                  idSpace
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayerCo
                  )
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                    isTypeEdit: TYPE_SETTING.SCENE,
                    data: resGetInfoScene.data,
                  })
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                    layer: newLayerActive,
                    space: newSpaceActive,
                    scene,
                  })
                )
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayerCo)
                const resUpdateProject = await updateProject(
                  projectInfo.projectId,
                  {
                    orderStructure: {
                      layers: orderStructuer,
                    },
                    defaultSceneSet: {
                      layerId: newLayerActive.info.id,
                      spaceId: newSpaceActive.info.id,
                      sceneId: resGetInfoScene.data.sceneId,
                    },
                  }
                )
                if (resUpdateProject) {
                  const { isRedo, isUndo } =
                    sceneSettingHelper.saveUndoRedoToStorage({
                      projectId: projectInfo.projectId,
                      dataVersion: {
                        version: resUpdateProject.version,
                        data: {
                          type: TYPE_SETTING.SPACE,
                          id: resGetInfoScene.data.sceneId,
                          action: TypeActionUndoRedo.DELETE,
                          listLayer: newListLayerCo,
                        },
                      },
                    })
                  dispatch(
                    sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                      isUndo,
                      isRedo,
                    })
                  )
                }
              }
            }
          }
        }
      } catch (error) {
        setIsDeletingSpace(false)
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteSpace',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      } finally {
        setIsDeletingSpace(false)
      }
    }
  }

  const handleSetEitSpaceName = async () => {
    setIsOpenSetting(false)
    await setIsOpenEditSpaceName(true)
    const input = document.getElementById('spaceName')
    if (input) {
      input.focus()
    }
  }

  const handleChangeSpaceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpaceName(e.target.value)
  }

  const handleKeyDownSpaceName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateSpaceName()
    }
  }

  const handleUpdateSpaceName = async () => {
    if (
      helper.removeWhiteSpaceStart(spaceName) &&
      spaceName !== dataSpace.info.title &&
      user &&
      projectInfo
    ) {
      const data: IRequestUpdateSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        id: dataSpace.info.id,
        title: helper.removeWhiteSpaceStart(spaceName),
        langCode: TypeLanguage.ENLISH,
      }
      const res = await updateSpace(data)
      if (res.data) {
        setIsOpenEditSpaceName(false)
      }
    } else {
      setSpaceName(dataSpace.info.title)
      setIsOpenEditSpaceName(false)
    }
  }

  const updateSpace = async (dataReq: IRequestUpdateSpace) => {
    const res = await dispatch(updateSpaceAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
    return res
  }

  return (
    <div className="bg-gray-800 h-full right-0 transform w-[240px] overflow-y-auto overflow-x-hidden">
      {isDeletingSpace ? (
        <SpinComponent />
      ) : (
        <>
          <div className="flex justify-between items-center text-white py-2 pr-1.5 pl-4">
            {isOpenEditSpaceName ? (
              <div className="relative w-full">
                <Input
                  id="spaceName"
                  type="text"
                  className="!py-0 !px-1 box-border !leading-5 text-xs font-lato"
                  customClassContainer="!py-0 h-5"
                  value={spaceName}
                  onChange={handleChangeSpaceName}
                  onBlur={handleUpdateSpaceName}
                  handleKeyDown={handleKeyDownSpaceName}
                  isValid={
                    helper.removeWhiteSpaceStart(spaceName) ? false : true
                  }
                />
                {!helper.removeWhiteSpaceStart(spaceName) && (
                  <p className="text-red-500 absolute top-full font-lato text-xs leading-5">
                    Required
                  </p>
                )}
              </div>
            ) : (
              <div className="text-base font-semibold leading-6 truncate">
                {dataSpace.info.title}
              </div>
            )}

            <Popup
              open={isOpenSetting}
              close={() => setIsOpenSetting(false)}
              content={
                <div className="flex flex-col font-lato">
                  <Button.MenuItem
                    className="justify-between"
                    onClick={handleSetEitSpaceName}
                  >
                    <span className="text-white__op-900">
                      {t('common.rename')}
                    </span>
                    {/* <span className="text-white__op-500">⌘R</span> */}
                  </Button.MenuItem>
                  {/* <Button.MenuItem className="justify-between">
                    <span className="text-white__op-900">
                      {t('common.copy')}
                    </span>
                    <span className="text-white__op-500">⌘C</span>
                  </Button.MenuItem>
                  <Button.MenuItem className="justify-between">
                    <span className="text-white__op-900">
                      {t('common.duplicate')}
                    </span>
                    <span className="text-white__op-500">⌘D</span>
                  </Button.MenuItem> */}
                  <Button.MenuItem
                    className="justify-between group"
                    onClick={() => handleDeleteSpace()}
                    disabled={isDisabledDeleteSpace}
                  >
                    <span className="text-white__op-900 group-disabled:text-white__op-300">
                      {t('common.delete')}
                    </span>
                    {/* <span className="text-white__op-500 group-disabled:text-white__op-300">
                      Del
                    </span> */}
                  </Button.MenuItem>
                </div>
              }
              claassNamePopup="!w-40"
            >
              <Icon.ButtonIcon
                icon={<img src={IconOption} alt="" />}
                onClick={() => setIsOpenSetting(true)}
                customClass={classNames('focus:bg-transparent', {
                  '!bg-blue-700 !outline-transparent': isOpenSetting,
                })}
              />
            </Popup>
          </div>
          <div className="text-white__op-900 text-sm leading-6 py-3 px-4 font-lato">
            {dataSpace.scenes.length} {t('common.scenes')}
          </div>
          <hr className="border-white__op-100 mx-4" />
          <div className="text-xs mt-2 py-1.5 px-4 font-semibold leading-5 text-white__op-600">
            {t('sceneSetting.rightSideBar.thumbnail')}
          </div>
          <ThumbnailComponent dataSpace={dataSpace} updateSpace={updateSpace} />
        </>
      )}
    </div>
  )
}

export default SpaceSettingComponent
