export const TYPE_PROFILE = {
  REDUCERS: {
    SET_STATUS_POPUP_CHANGE_PASS: 'changeStatusPopupPassword',
    SET_STATUS_POPUP_DELETE_ACCOUNT: 'changeStatusPopupDeleteAccount',
    SET_DATA_EDIT: 'setDataEdit',
    SET_DELETE_PASSWORD: 'setDeletePassword',
    SET_TAB_SETTING: 'setTabSetting',
    SET_USER_PROFILE: 'setUserProfile',
  },
  ACTIONS: {
    CHANGE_PASSWORD: 'profile/changePassword',
    GET_USER_PROFILE: 'profile/getUserProfile',
    GET_STORAGE: 'profile/getStorage',
    UPDATE_USER_PROFILE: 'profile/updateUserProfile',
    DELETE_ACCOUNT: 'profile/deleteAccount',
    GET_SIGNED_URL_UPLOAD_AVATAR: 'profile/getSignedUrlUploadAvatar',
    SET_PARTNER_CODE: 'profile/setPartnerCode',
  },
}
