import React from 'react'
import Button from '@components/common/Button'
//icon
import IconAdd from '@assets/icons/sceneSetting/add.svg'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  ILayer,
  IRequestCreateScene,
  IResponseCreateScene,
  IScene,
} from '@models/sceneSetting'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { helper } from '@utils/helper/common'
import { IRequestUpdateProject } from '@models/project'
import { updateProjectAction } from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { useTranslation } from 'react-i18next'

interface IPropsType {
  handleCreateScene: (
    data: IRequestCreateScene
  ) => Promise<IResponseCreateScene | undefined>
}
const ButtonAddScene: React.FC<IPropsType> = (props) => {
  const { t } = useTranslation()
  const { handleCreateScene } = props
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { dataSpaceAndScene } = useAppSelector(
    (state: RootState) => state.scene
  )

  const handleAddScene = async () => {
    if (user && projectInfo) {
      const dataAddScene: IRequestCreateScene = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        title: `Scene 2`,
        spaceId: projectInfo.defaultSceneSet.spaceId,
      }
      const res = await handleCreateScene(dataAddScene)
      if (res && dataSpaceAndScene) {
        const newScene: IScene = {
          info: {
            id: res.sceneId,
            title: res.title,
            thumbnailUrl: null,
            imageThumbnailId: null,
            videoThumbnailId: null,
          },
        }
        const newListLayer: Array<ILayer> = helper.addSceneToSpace(
          dataSpaceAndScene,
          newScene,
          true,
          projectInfo.defaultSceneSet.layerId,
          res.spaceId
        )
        const orderStructuer = helper.getStructureSpaceAndScene(newListLayer)
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
            newListLayer
          )
        )
        await updateProject(projectInfo.projectId, {
          orderStructure: {
            layers: orderStructuer,
          },
        })
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_ID_SCENE_SELECT](res.sceneId)
        )
      }
    }
  }

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const resUpdateProject = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (resUpdateProject.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return resUpdateProject.data
    }
  }

  return (
    <div className="px-4">
      <Button.Normal
        icon={<img src={IconAdd} alt="" />}
        title={t('popup.listScene.addNewScene')}
        className="font-semibold leading-5"
        onClick={handleAddScene}
      />
    </div>
  )
}

export default ButtonAddScene
