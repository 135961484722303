import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@components/common/Button'
import { helper } from '@utils/helper/common'

const BillingTrialComponent: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="pl-8">
      <div className="w-full py-3 px-6 bg-gray-700 rounded-2xl flex text-white__op-900 items-center">
        <div className="w-full mr-8">
          <div className="font-semibold text-xl py-2">
            {t('profileSetting.billing.currentPlanFree')}
          </div>
          <div className="font-lato leading-6">
            {t('profileSetting.billing.toEditAndPublish')}
          </div>
          <div className="font-lato leading-6">
            ({t('profileSetting.billing.startFrom')})
          </div>
        </div>
        <div className="w-[7.25rem] flex-shrink-0">
          <Button.Normal
            title={t('profileSetting.billing.selectPlan')}
            className="font-semibold leading-5"
            color="solid"
            onClick={() => helper.redirectToContact()}
          />
        </div>
      </div>
    </div>
  )
}

export default BillingTrialComponent
