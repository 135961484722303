import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { helper } from '@utils/helper/common'
import IconAdd2 from '@assets/icons/sceneSetting/add2.svg'
import { TYPE } from '@models/common'
import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'
import { IRequestUpdateProject } from '@models/project'
import {
  ILayer,
  IRequestCreateScene,
  IScene,
  ISpace,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { updateProjectAction } from '@stores/project/project.action'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { createSceneAction } from '@stores/scene/scene.action'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { RootState } from '@stores/store'
import { openNotification } from '@utils/notification'
import Icon from '@components/common/Icon'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'

interface IPropsType {
  item: ILayer
  itemS: ISpace
}
const AddSceneButton: React.FC<IPropsType> = (props) => {
  const { item, itemS } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { activeView, dataSpaceAndScene } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)

  const handleAddScene = async (dataLayer: ILayer, dataSpace: ISpace) => {
    if (user && projectInfo && dataSpaceAndScene) {
      const dataAddScene = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        title: `Scene ${dataSpace.scenes.length + 1}`,
        spaceId: dataSpace.info.id,
      }
      const res = await handleCreateScene(dataAddScene)
      if (res) {
        const idSpace = dataSpace.info.id
        const idLayer = dataLayer.info.id
        const newSceneActive: IScene = {
          info: {
            id: res.sceneId,
            title: res.title,
            thumbnailUrl: null,
            imageThumbnailId: null,
            videoThumbnailId: null,
          },
        }
        const newListLayer: Array<ILayer> = helper.addSceneToTailSpace(
          dataSpaceAndScene,
          newSceneActive,
          idLayer,
          idSpace
        )
        const orderStructuer = helper.getStructureSpaceAndScene(newListLayer)
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
            newListLayer
          )
        )
        const resUpdateProject = await updateProject(projectInfo.projectId, {
          orderStructure: {
            layers: orderStructuer,
          },
          defaultSceneSet: {
            layerId: idLayer,
            spaceId: idSpace,
            sceneId: res.sceneId,
          },
        })
        if (resUpdateProject) {
          const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
            projectId: projectInfo.projectId,
            dataVersion: {
              version: res.version,
              data: {
                type: TYPE_SETTING.SCENE,
                id: res.sceneId,
                action: TypeActionUndoRedo.CREATE,
                listLayer: newListLayer,
              },
            },
          })
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
              isUndo,
              isRedo,
            })
          )
        }
        const newLayerActive = _.find(
          newListLayer,
          (layer) => layer.info.id === idLayer
        )
        const newSpaceActive = _.find(
          newLayerActive?.spaces,
          (space) => space.info.id === idSpace
        )

        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
            type: TYPE_RESOURCE_OF_PROJECT.SCENE,
            space: newSpaceActive ?? dataSpace,
            scene: newSceneActive,
            layer: newLayerActive ?? dataLayer,
          })
        )
        const data = {
          isTypeEdit: TYPE_SETTING.SCENE,
          data: res,
        }
        dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](data))
        dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_SCENE_PREVIEW](res))
        openAsset()
      }
    }
  }

  const handleCreateScene = async (data: IRequestCreateScene) => {
    const resCreateScene = await dispatch(createSceneAction(data)).unwrap()
    if (resCreateScene.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'createScene',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return resCreateScene.data
    }
  }

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const resUpdateProject = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (resUpdateProject.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return resUpdateProject.data
    }
  }

  const openAsset = () => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
        MODE_OF_MEDIA_LIB_POPUP.FILE_3D
      )
    )
  }
  return (
    <Icon.ButtonIcon
      icon={<img src={IconAdd2} alt="" />}
      customClassContainer={classNames(
        'absolute right-1 top-1/2 -translate-y-1/2 group-one-hover:!block',
        {
          block:
            activeView.type === TYPE_RESOURCE_OF_PROJECT.SPACE &&
            activeView.space?.info.id === itemS.info.id,
          hidden:
            activeView.type !== TYPE_RESOURCE_OF_PROJECT.SPACE ||
            activeView.space?.info.id !== itemS.info.id,
        }
      )}
      size="xs"
      onClick={(e) => {
        e.stopPropagation()
        handleAddScene(item, itemS)
      }}
    />
  )
}

export default React.memo(AddSceneButton)
