import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'
import { TYPE, TypeLanguage } from '@models/common'
import {
  ILayer,
  IRequestDeleteSpace,
  IRequestGetScene,
  IRequestUpdateSpace,
  IScene,
  ISpace,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import {
  deleteSpaceAction,
  getSceneAction,
  updateSpaceAction,
} from '@stores/scene/scene.action'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
// import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { RootState } from '@stores/store'
import { helper } from '@utils/helper/common'
import { openNotification } from '@utils/notification'
import Icon from '@components/common/Icon'
import Popup from '@components/common/Popup'
// import { TYPE_PROJECT } from '@stores/project/project.type'
import Input from '@components/common/Input'
import SpinComponent from '@components/common/SpinComponent'
import { IRequestUpdateProject, IResponseProjectInfo } from '@models/project'
import classNames from 'classnames'
import Button from '@components/common/Button'
//icon
// import IconAdd from '@assets/icons/sceneSetting/add.svg'
import IconOption from '@assets/icons/sceneSetting/option.svg'
// import {
//   DEFAULT_WIDTH_RIGHT_SETTING,
//   REF_SELECT_MEDIA,
// } from '@constants/sceneSetting'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { useGetMapInfos } from '../../../hooks/useGetMapInfos'

interface IPropsType {
  updateProject: (
    projectId: number,
    data: IRequestUpdateProject
  ) => Promise<IResponseProjectInfo | undefined>
}
const LayerSettingComponent: React.FC<IPropsType> = (
  props
): React.ReactElement => {
  const { updateProject } = props
  const dispatch = useAppDispatch()
  const { dataSpaceAndScene, dataTypeEdit, contextCodec } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const [dataLayer, setDataLayer] = useState<ILayer>(
    dataTypeEdit?.data as ILayer
  )
  const { t } = useTranslation()
  const { getMapInfos } = useGetMapInfos()
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  const [isOpenEditLayerName, setIsOpenEditLayerName] = useState<boolean>(false)
  const [layerName, setLayerName] = useState<string>(dataLayer.info.title)
  const [isDeletingLayer, setIsDeletingLayer] = useState<boolean>(false)
  const [isDisabledDeleteLayer, setIsDisabledDeleteLayer] =
    useState<boolean>(false)

  useEffect(() => {
    if (dataTypeEdit) {
      setDataLayer(dataTypeEdit.data as ILayer)
      setLayerName((dataTypeEdit.data as ILayer).info.title)
    }
  }, [dataTypeEdit])

  useEffect(() => {
    if (dataTypeEdit && dataSpaceAndScene) {
      const newListSpaceAndScene = helper.removeLayer(
        dataSpaceAndScene,
        (dataTypeEdit.data as ILayer).info.id
      )
      const check = helper.checkHasScene(newListSpaceAndScene)
      if (!check) {
        setIsDisabledDeleteLayer(true)
      } else {
        setIsDisabledDeleteLayer(false)
      }
    }
    return () => setIsDisabledDeleteLayer(false)
  }, [(dataTypeEdit?.data as ILayer).info.id])

  // const handleOpenAsset = () => {
  //   if (modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OFF) {
  //     const mapLayerElm = document.getElementById(REF_SELECT_MEDIA.MAP_OF_LAYER)
  //     if (mapLayerElm) {
  //       dispatch(
  //         sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
  //           top: mapLayerElm.offsetTop,
  //           right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
  //         })
  //       )
  //     }
  //     dispatch(
  //       projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
  //         MODE_OF_MEDIA_LIB_POPUP.IMAGE
  //       )
  //     )
  //     dispatch(
  //       sceneActions[TYPE_SCENE.REDUCERS.SET_REF_BUTTON_SELECT_MEDIA](
  //         REF_SELECT_MEDIA.MAP_OF_LAYER
  //       )
  //     )
  //   } else {
  //     dispatch(
  //       projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
  //         MODE_OF_MEDIA_LIB_POPUP.OFF
  //       )
  //     )
  //     dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
  //   }
  // }

  const handleDeleteLayer = async () => {
    const idDelete = dataLayer.info.id
    if (user && projectInfo && idDelete) {
      const data: IRequestDeleteSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        spaceId: idDelete,
      }
      try {
        setIsDeletingLayer(true)
        const res = await dispatch(deleteSpaceAction(data)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteLayer',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          setIsOpenSetting(false)
          if (dataSpaceAndScene) {
            const { layerId, spaceId, sceneId } = projectInfo.defaultSceneSet
            let newIdSceneActive: number | null = null
            let newLayerActive: ILayer | null = null
            let newSpaceActive: ISpace | null = null
            const newListData = helper.removeLayer(dataSpaceAndScene, idDelete)
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                newListData
              )
            )
            if (idDelete === layerId) {
              const { dataNewLayer, dataNewScene, dataNewSpace } =
                helper.deleteSceneAndUpdateAnotherLayer(
                  dataSpaceAndScene,
                  dataLayer
                )
              newIdSceneActive = dataNewScene
                ? (dataNewScene as IScene).info.id
                : null
              newLayerActive = dataNewLayer
              newSpaceActive = dataNewSpace
            } else {
              const layerActive = _.find(
                newListData,
                (layer) => layer.info.id === layerId
              )
              const spaceActive = _.find(
                layerActive?.spaces,
                (space) => space.info.id === spaceId
              )
              if (layerActive && spaceActive && sceneId) {
                newIdSceneActive = sceneId
                newLayerActive = layerActive
                newSpaceActive = spaceActive
              }
            }
            if (newIdSceneActive && newLayerActive && newSpaceActive) {
              const dataGetScene: IRequestGetScene = {
                projectId: projectInfo.projectId,
                sceneId: newIdSceneActive,
                contextCodec,
              }
              const resGetInfoScene = await dispatch(
                getSceneAction(dataGetScene)
              ).unwrap()
              if (resGetInfoScene.data) {
                const scene: IScene = {
                  info: {
                    id: resGetInfoScene.data.sceneId,
                    title: resGetInfoScene.data.title,
                    thumbnailUrl: resGetInfoScene.data.thumbnailUrl,
                    imageThumbnailId: resGetInfoScene.data.imageThumbnailId,
                    videoThumbnailId: null,
                  },
                }
                const idLayer = newLayerActive.info.id
                const idSpace = newSpaceActive.info.id
                const newListLayerCo = helper.openScene(
                  newListData,
                  idLayer,
                  idSpace
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayerCo
                  )
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                    isTypeEdit: TYPE_SETTING.SCENE,
                    data: resGetInfoScene.data,
                  })
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                    layer: newLayerActive,
                    space: newSpaceActive,
                    scene,
                  })
                )
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayerCo)
                const resUpdateProject = await updateProject(
                  projectInfo.projectId,
                  {
                    orderStructure: {
                      layers: orderStructuer,
                    },
                    defaultSceneSet: {
                      layerId: newLayerActive.info.id,
                      spaceId: newSpaceActive.info.id,
                      sceneId: resGetInfoScene.data.sceneId,
                    },
                  }
                )
                if (resUpdateProject) {
                  const { isRedo, isUndo } =
                    sceneSettingHelper.saveUndoRedoToStorage({
                      projectId: projectInfo.projectId,
                      dataVersion: {
                        version: resUpdateProject.version,
                        data: {
                          type: TYPE_SETTING.LAYER,
                          id: resGetInfoScene.data.sceneId,
                          action: TypeActionUndoRedo.DELETE,
                          listLayer: newListLayerCo,
                        },
                      },
                    })
                  dispatch(
                    sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                      isUndo,
                      isRedo,
                    })
                  )
                }
              }
            }
          }
          getMapInfos(projectInfo?.projectId)
        }
      } catch ({}) {
        setIsDeletingLayer(false)
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteLayer',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      } finally {
        setIsDeletingLayer(false)
      }
    }
  }

  const handleSetEitLayerName = async () => {
    setIsOpenSetting(false)
    await setIsOpenEditLayerName(true)
    const input = document.getElementById('layerName')
    if (input) {
      input.focus()
    }
  }

  const handleChangeLayerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLayerName(e.target.value)
  }

  const handleKeyDownLayerName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateLayerName()
    }
  }

  const handleUpdateLayerName = async () => {
    if (
      helper.removeWhiteSpaceStart(layerName) &&
      layerName !== dataLayer.info.title &&
      user &&
      projectInfo
    ) {
      const data: IRequestUpdateSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        id: dataLayer.info.id,
        title: helper.removeWhiteSpaceStart(layerName),
        langCode: TypeLanguage.ENLISH,
      }
      const res = await updateSpace(data)
      if (res.data) {
        setIsOpenEditLayerName(false)
      }
    } else {
      setLayerName(dataLayer.info.title)
      setIsOpenEditLayerName(false)
    }
  }

  const updateSpace = async (dataReq: IRequestUpdateSpace) => {
    const res = await dispatch(updateSpaceAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
    return res
  }

  return (
    <div className="bg-gray-800 h-full right-0 transform w-[240px] overflow-y-auto overflow-x-hidden">
      {isDeletingLayer ? (
        <SpinComponent />
      ) : (
        <>
          <div className="flex justify-between items-center text-white py-2 pr-1.5 pl-4">
            {isOpenEditLayerName ? (
              <div className="relative w-full">
                <Input
                  id="layerName"
                  className="!py-0 !px-1 box-border !leading-5 text-xs font-lato"
                  customClassContainer="!py-0 h-5"
                  type="text"
                  value={layerName}
                  onChange={handleChangeLayerName}
                  onBlur={handleUpdateLayerName}
                  handleKeyDown={handleKeyDownLayerName}
                  isValid={
                    helper.removeWhiteSpaceStart(layerName) ? false : true
                  }
                />
                {!helper.removeWhiteSpaceStart(layerName) && (
                  <p className="text-red-500 absolute top-full font-lato text-xs leading-5">
                    Required
                  </p>
                )}
              </div>
            ) : (
              <span className="text-base font-semibold leading-6 truncate">
                {dataLayer.info.title}
              </span>
            )}

            <Popup
              open={isOpenSetting}
              close={() => setIsOpenSetting(false)}
              content={
                <div className="flex flex-col font-lato">
                  <Button.MenuItem
                    className="justify-between"
                    onClick={handleSetEitLayerName}
                  >
                    <span className="text-white__op-900">
                      {t('common.rename')}
                    </span>
                    {/* <span className="text-white__op-500">⌘R</span> */}
                  </Button.MenuItem>
                  <Button.MenuItem
                    className="justify-between group"
                    onClick={() => handleDeleteLayer()}
                    disabled={isDisabledDeleteLayer}
                  >
                    <span className="text-white__op-900 group-disabled:text-white__op-300">
                      {t('common.delete')}
                    </span>
                    {/* <span className="text-white__op-500 group-disabled:text-white__op-300">
                      Del
                    </span> */}
                  </Button.MenuItem>
                </div>
              }
              claassNamePopup="!w-40"
            >
              <Icon.ButtonIcon
                icon={<img src={IconOption} alt="" />}
                onClick={() => setIsOpenSetting(true)}
                customClass={classNames('focus:bg-transparent', {
                  '!bg-blue-700 !outline-transparent': isOpenSetting,
                })}
              />
            </Popup>
          </div>
          <div className="text-white__op-900 text-sm leading-6 py-3 px-4 font-lato">
            {dataLayer.spaces.length} {t('common.spaces')}
          </div>
          {/* <hr className="border-white__op-100 mx-4" />
          <div className="text-xs mb-1.5 font-semibold leading-5 text-white__op-600 mt-2 py-1.5 px-4">
            {t('sceneSetting.rightSideBar.map')}
          </div>
          <div className="px-4" id={REF_SELECT_MEDIA.MAP_OF_LAYER}>
            <button
              className="flex items-end w-full px-4 py-[10px] rounded-lg h-[117px] focus:outline focus:outline-2 focus:outline-blue-500 bg-white__op-50"
              onClick={handleOpenAsset}
            >
              <div className="flex items-center">
                <img src={IconAdd} className="mr-4" alt="" />
                <div className="text-blue-300 font-semibold leading-5 text-xs">
                  Select Map Image
                </div>
              </div>
            </button>
          </div> */}
        </>
      )}
    </div>
  )
}

export default LayerSettingComponent
