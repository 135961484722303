import { gql } from '@apollo/client'

export const GET_MULTIPART_SIGNED_URLS = gql`
  query getMultipartSignedUrls($input: GetMultipartSignedUrlsInput) {
    getMultipartSignedUrls(input: $input) {
      message
      statusCode
      data {
        bucket
        key
        uploadId
        partsInfo {
          signedUrl
          partNumber
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`
