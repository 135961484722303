import React, { useEffect, useState } from 'react'
import Icon from '@components/common/Icon'
import { IRequestUpdateSpace, ISpace } from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'
//icon
import IconAdd from '@assets/icons/common/add-icon.svg'
import IconDelete from '@assets/icons/common/delete-icon.svg'
import { useTranslation } from 'react-i18next'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import {
  DEFAULT_WIDTH_RIGHT_SETTING,
  REF_SELECT_MEDIA,
} from '@constants/sceneSetting'

interface IPropTypes {
  dataSpace: ISpace
  updateSpace: (dataReq: IRequestUpdateSpace) => Promise<unknown>
}
const ThumbnailComponent: React.FC<IPropTypes> = (props) => {
  const { t } = useTranslation()
  const { dataSpace, updateSpace } = props
  const dispatch = useAppDispatch()
  const { mediaSelected, projectInfo } = useAppSelector(
    (state: RootState) => state.project
  )
  //store
  const { user } = useAppSelector((state: RootState) => state.auth)
  //state
  const [isUpdateThubnail, setIsUpdateThubnail] = useState<boolean>(false)

  useEffect(() => {
    if (mediaSelected && isUpdateThubnail && projectInfo && user) {
      updateSpace({
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        id: dataSpace.info.id,
        thumbnailId: mediaSelected.id,
      }).finally(() => {
        setIsUpdateThubnail(false)
        dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
            MODE_OF_MEDIA_LIB_POPUP.OFF
          )
        )
      })
    }
  }, [mediaSelected, isUpdateThubnail])

  const handleOpenImageAsset = () => {
    const thumbSpaceElm = document.getElementById(
      REF_SELECT_MEDIA.THUMB_OF_SPACE
    )
    if (thumbSpaceElm) {
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
          top: thumbSpaceElm.offsetTop,
          right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
        })
      )
    }
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
        MODE_OF_MEDIA_LIB_POPUP.IMAGE
      )
    )
    setIsUpdateThubnail(true)
  }

  const handleRomoveThumbnail = async () => {
    if (user && projectInfo) {
      await updateSpace({
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        id: dataSpace.info.id,
        thumbnailId: null,
      })
    }
  }

  const ButtonOpenAsset = () => (
    <button className="p-1" onClick={handleOpenImageAsset}>
      <div className="py-1.5 pl-2 pr-3 flex">
        <Icon.ActionIcon
          customClass="mr-3"
          icon={<img src={IconAdd} alt="" />}
        />
        <span className="text-xs leading-5 font-semibold text-blue-300">
          {t('common.selectImage')}
        </span>
      </div>
    </button>
  )

  return (
    <div
      className="py-1.5 px-4 h-[13.75rem]"
      id={REF_SELECT_MEDIA.THUMB_OF_SPACE}
    >
      {dataSpace.info.thumbnail.url ||
      dataSpace.info.thumbnail.sceneThumbnail.url ? (
        <div className="w-full h-full relative group rounded-lg overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={
              dataSpace.info.thumbnail.url ||
              (dataSpace.info.thumbnail.sceneThumbnail.url as string)
            }
            alt=""
          />
          <div className="absolute inset-0 bg-black__op-500 justify-between items-end w-full hidden group-hover:!flex">
            <ButtonOpenAsset />
            {dataSpace.info.thumbnail.url && (
              <Icon.ButtonIcon
                icon={<img src={IconDelete} alt="" />}
                size="sm"
                onClick={handleRomoveThumbnail}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full h-full flex items-end bg-white__op-50 rounded-lg group">
          <div className="justify-between items-center w-full hidden group-hover:!flex">
            <ButtonOpenAsset />
          </div>
        </div>
      )}
    </div>
  )
}

export default ThumbnailComponent
