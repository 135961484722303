import React from 'react'
import videoPhotoIcon from '../../../assets/icons/leftSidebar/360.svg'
import uploadIcon from '../../../assets/icons/mediaIcon/upload.svg'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Button from '@components/common/Button'

interface PropTypes {
  openFileUploadWindow: () => void
}

const DefaultUploadMedia: React.FC<PropTypes> = ({ openFileUploadWindow }) => {
  const { t } = useTranslation()
  const { modeOfMediaLibPopup } = useAppSelector(
    (state: RootState) => state.project
  )
  return (
    <>
      <div className="text-sm font-semibold leading-5 px-4 text-white__op-600 mb-7 mt-6">
        {t('popup.mediaLib.titleNoFile')}
      </div>
      <div className="px-4 mb-2.5">
        <Button.Normal
          title={modeOfMediaLibPopup === 1 ? t('popup.mediaLib.upload360video/image') : t('popup.mediaLib.btnUpload')}
          icon={<img src={modeOfMediaLibPopup === 1 ? videoPhotoIcon : uploadIcon} />}
          onClick={() => openFileUploadWindow()}
          className='font-semibold'
        />
      </div>

      {/* <div className="text-center text-white__op-500 text-xs leading-5 font-normal">
        {t('popup.mediaLib.fileDrop')}
      </div> */}
    </>
  )
}

export default React.memo(DefaultUploadMedia)
