import classNames from 'classnames'
import React, { ReactNode } from 'react'
import Button from './Button'
import ModalComponent from './Modal'

interface IPopupOptions {
  title?: string
  messageFirst?: string
  messageSecond?: string
  okLabel?: string
  cancelLabel?: string
  isDelete?: boolean
  visible: boolean
  handleOk?: () => void
  handleCancel: () => void
  isDisabledOk?: boolean
  children?: ReactNode
  isCloseOutside?: boolean
}

const PopupConfirm: React.FC<IPopupOptions> = (option): React.ReactElement => {
  const {
    title,
    messageFirst,
    messageSecond,
    okLabel,
    cancelLabel,
    visible,
    handleOk,
    handleCancel,
    isDisabledOk = false,
    children,
    isCloseOutside,
  } = option

  const Footer = (
    <div className="pr-3 pb-2 pl-1 text-right">
      {cancelLabel && (
        <Button.Normal
          className="font-semibold text-sm"
          classNameContainer="!w-auto !px-1"
          color="transparent"
          title={cancelLabel}
          onClick={handleCancel}
        />
      )}
      {okLabel && (
        <Button.Normal
          className="font-semibold text-sm"
          classNameContainer="!w-auto !px-1"
          title={okLabel}
          onClick={handleOk}
          disabled={isDisabledOk}
        />
      )}
    </div>
  )

  return (
    <ModalComponent
      title={title}
      open={visible}
      closable={false}
      modalClassName="text-white__op-900"
      footer={Footer}
      centered
      onClose={handleCancel}
      isCloseOutside={isCloseOutside}
    >
      <div
        className={classNames('font-lato text-sm leading-6', {
          'pt-6': !title,
        })}
      >
        {messageFirst && decodeURIComponent(messageFirst)}
      </div>
      <div className="font-lato text-sm leading-6">{messageSecond}</div>
      {children}
    </ModalComponent>
  )
}

export default PopupConfirm
