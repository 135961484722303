import Input from '@components/common/Input'
import { IMedia, ISelectedMedia } from '@models/media'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { actions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { RootState } from '@stores/store'
import { helper } from '@utils/helper/common'
import { updateMediaAction } from '@stores/project/project.action'
import { useTranslation } from 'react-i18next'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'

interface PropTypes {
  media: IMedia
  icon: string | undefined
  setEditedMedia: React.Dispatch<React.SetStateAction<ISelectedMedia | null>>
}

const EditingMedia: React.FC<PropTypes> = ({ media, icon, setEditedMedia }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { editedItemNameInAsset, projectInfo } = useAppSelector(
    (state: RootState) => state.project
  )

  const handleChangeEditedItemName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_EDITED_ITEM_NAME_IN_ASSET](
        e.target.value
      )
    )
  }

  const handleEnterToUpdateMedia = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleUpdateMedia()
    }
  }

  const handleUpdateMedia = async () => {
    setEditedMedia(null)

    if (
      editedItemNameInAsset === media.name ||
      !helper.removeWhiteSpaceStart(editedItemNameInAsset)
    ) {
      return
    }

    try {
      if (!projectInfo?.projectId || !media.resourceId) {
        throw new Error('update media input is invalid')
      }

      const res = await dispatch(
        updateMediaAction({
          projectId: projectInfo?.projectId,
          resourceId: media.resourceId,
          mediaType: media.mediaType,
          name: helper.removeWhiteSpaceStart(editedItemNameInAsset),
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateMedia',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  return (
    <div className="flex pl-4 pr-2 items-center h-10 bg-blue-700">
      <img
        src={icon}
        className="mr-2 w-8 h-8 rounded-lg flex-shrink-0"
        alt=""
      />
      <Input
        className="w-full text-xs font-lato text-white__op-600 h-6 bg-gray-800 px-2 rounded-lg"
        type="text"
        value={editedItemNameInAsset}
        onChange={handleChangeEditedItemName}
        onBlur={handleUpdateMedia}
        handleKeyDown={handleEnterToUpdateMedia}
        isValid={
          helper.removeWhiteSpaceStart(editedItemNameInAsset) ? false : true
        }
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
      />
    </div>
  )
}

export default EditingMedia
