import React, { useMemo } from 'react'
import { MODE_OF_MEDIA_LIB_POPUP } from '../../../models/media'
import NoAudioIcon from '../../../assets/icons/mediaIcon/no-audio.svg'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { actions as projectActions } from '../../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../../store/project/project.type'
import { RootState } from '../../../store/store'
import { UNSELECT_MEDIA } from '../../../models/project'

const NoMedia = () => {
  const dispatch = useAppDispatch()

  const { modeOfMediaLibPopup } = useAppSelector(
    (state: RootState) => state.project
  )

  const noMedia = useMemo(() => {
    switch (modeOfMediaLibPopup) {
      case MODE_OF_MEDIA_LIB_POPUP.BGM:
        return {
          iconSrc: NoAudioIcon,
          name: 'No Background music',
        }
      default:
        return null
    }
  }, [modeOfMediaLibPopup])

  const handleUnSelectAsset = () => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT]({
        id: UNSELECT_MEDIA,
        type: '',
        url: '',
      })
    )
  }

  return noMedia ? (
    <>
      <div
        className="flex items-center px-4 py-1 hover:cursor-pointer hover:bg-white__op-100 font-lato text-xs"
        onClick={() => handleUnSelectAsset()}
      >
        <img src={noMedia.iconSrc} className="mr-2 w-8 h-8 rounded-lg" alt="" />
        <span className="w-52 truncate text-white__op-800">{noMedia.name}</span>
      </div>
      <hr className="mx-4 my-2 border-white/10" />
    </>
  ) : null
}

export default NoMedia
