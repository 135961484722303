import { IProject } from '@models/project'
import _ from 'lodash'

class Helper {
  public updateStatusProjectOfList(
    listProject: Array<IProject>,
    idProject: number,
    status: string
  ) {
    const newListProject = _.cloneDeep(listProject)
    let isStop = false
    for (const project of newListProject) {
      if (isStop) {
        break
      }
      if (project.projectId === idProject) {
        project.publishStatus = status
        isStop = true
        break
      }
    }

    return newListProject
  }
}
export const projectHelper = new Helper()
