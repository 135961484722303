import { LANGUAGE } from '@constants/common'
import { TAX } from '@constants/plan'
import { ICmsProductBilling } from '@models/organization'
import { ILanguageType } from '@stores/common/common.reducer'
import i18next from 'i18next'
import moment from 'moment'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useTable, Column } from 'react-table'
interface IPropTypes {
  listBilling: Array<ICmsProductBilling>
}
const BillingTable: React.FC<IPropTypes> = (props): React.ReactElement => {
  const { listBilling } = props
  const { t } = useTranslation()
  const lang = i18next.language
  const tableColumns = useMemo<Array<Column<ICmsProductBilling>>>(
    () => [
      {
        Header: t('profileSetting.billing.date'),
        accessor: 'createdAt',
        width: 106,
        minWidth: 106,
        Cell: (data) => (
          <>
            {moment(new Date(data.cell.value)).format(
              lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'YYYY/MM/DD'
            )}
          </>
        ),
      },
      {
        Header: t('common.plan'),
        accessor: 'name',
        Cell: (data) => <>{data.cell.value[lang as ILanguageType]}</>,
        width: 260,
      },
      {
        Header: t('profileSetting.billing.storage'),
        accessor: 'organizationStorage',
        width: 72,
        Cell: (data) => <>{data.cell.value ? data.cell.value : '__'}</>,
      },
      {
        Header: t('profileSetting.billing.publishStorage'),
        accessor: 'publishStorage',
        Cell: (data) => <>{data.cell.value ? data.cell.value : '__'}</>,
      },
      {
        Header: t('profileSetting.billing.views'),
        accessor: 'views',
        Cell: (data) => <>{data.cell.value ? data.cell.value : '__'}</>,
      },
      {
        Header: t('profileSetting.billing.price'),
        accessor: 'price',
        width: 96,
        Cell: (data) => <>¥{(data.cell.value * TAX).toLocaleString('en-US')}</>,
      },
      {
        accessor: 'billingUrl',
        id: 'edit',
        Cell: (data) => (
          <button
            className="text-blue-300 text-xs leading-5 font-semibold text-center"
            onClick={() => openInvoice(data.cell.value)}
          >
            {t('profileSetting.billing.invoiceDetail')}
          </button>
        ),
        width: 118,
      },
    ],
    []
  )
  const tableInstance = useTable({
    columns: tableColumns,
    data: listBilling,
  })

  const openInvoice = (billingUrl: string) => {
    window.open(billingUrl)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance
  return (
    <table {...getTableProps()} className="w-full text-white__op-900">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={index}
            className="text-left"
          >
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps({
                  style: { width: column.width, minWidth: column.minWidth },
                })}
                className="py-3 text-xs leading-5 font-semibold"
                key={index}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              key={index}
              className="border-t font-lato leading-6 border-white__op-50"
            >
              {row.cells.map((cell, index) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="py-3 text-sm leading-6"
                    key={index}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default BillingTable
