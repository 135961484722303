import './style.scss'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ApolloProvider } from '@apollo/client'
import { WherenessProvider } from '@actualinc/whereness_player_package'
import { Router } from './router'
import { store } from './store/store'
import { Loader } from './components/common/Loader'
import client from './api/client'

export const App = () => {
  return (
    <GoogleOAuthProvider
      clientId={process.env.GOOGLE_CLOUD_CLIENT_ID as string}
    >
      <Provider store={store}>
        <ApolloProvider client={client}>
          <WherenessProvider>
            <React.StrictMode>
              <Suspense fallback={<Loader />}>
                <Router />
              </Suspense>
            </React.StrictMode>
          </WherenessProvider>
        </ApolloProvider>
      </Provider>
    </GoogleOAuthProvider>
  )
}
