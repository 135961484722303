import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IInfoTagResponse, IRequestSetInfoTag } from '@models/sceneSetting'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { TYPE_MEDIA } from '@models/media'
import InfoTagTitleCom from './InfoTagTitleCom'
import classNames from 'classnames'
import { MAX_LENGTH_CREDIT } from '@constants/sceneSetting'

interface IPropsType {
  dataInfoTag: IInfoTagResponse
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<IInfoTagResponse>
}
const MediaInfoComponent: React.FC<IPropsType> = (
  props
): React.ReactElement => {
  const { handleSetInfoTag, dataInfoTag } = props
  const { t } = useTranslation()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [credits, setCredits] = useState<string>(dataInfoTag.credit ?? '')
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    setCredits(dataInfoTag.credit ?? '')
  }, [dataInfoTag.id, dataInfoTag.credit])

  const handleChangeCredits = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setCredits(e.target.value.substring(0, MAX_LENGTH_CREDIT))
  }

  const handleUpdateMediaCredis = async () => {
    if (
      projectInfo &&
      user &&
      dataScenePreview &&
      credits.length <= MAX_LENGTH_CREDIT &&
      credits !== dataInfoTag.credit
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          credit: credits,
        },
      }
      await handleSetInfoTag(req)
    }
  }

  return (
    <div className="px-2">
      <InfoTagTitleCom
        dataInfoTag={dataInfoTag}
        handleSetInfoTag={handleSetInfoTag}
      />
      {(dataInfoTag.media.mediaType === TYPE_MEDIA.IMAGE ||
        dataInfoTag.media.mediaType === TYPE_MEDIA.VIDEO) && (
        <div className="py-1.5">
          <textarea
            id="pjDescription"
            rows={5}
            ref={ref}
            className={classNames(
              'w-full text-xs leading-5 py-1.5 px-2 text-white__op-900 placeholder:text-white__op-500 bg-white__op-50 rounded-lg focus:outline focus:outline-2 focus:outline-blue-500 font-lato resize-none',
              {
                '!outline-red-500':
                  document.activeElement === ref.current &&
                  credits &&
                  credits.length === MAX_LENGTH_CREDIT,
              }
            )}
            placeholder={t('sceneSetting.rightSideBar.infoTag.credit')}
            onChange={handleChangeCredits}
            value={credits}
            onBlur={handleUpdateMediaCredis}
          />
          {ref.current &&
            document.activeElement === ref.current &&
            credits &&
            credits.length === MAX_LENGTH_CREDIT && (
              <p className="px-2 text-red-500 font-lato text-xs leading-5">
                {t('validate.tooLong', {
                  name: t('sceneSetting.rightSideBar.infoTag.credit'),
                  length: MAX_LENGTH_CREDIT,
                })}
              </p>
            )}
        </div>
      )}
    </div>
  )
}

export default MediaInfoComponent
