import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from '../../store/hook'
import { forgotPasswordAction } from '../../store/auth/auth.action'
import { openNotification } from '../../utils/notification'
import { RootState } from '../../store/store'
import { TYPE } from '../../models/common'

import IconLogo from '../../assets/icons/auth/logo.svg'
import IconWhereness from '@assets/icons/common/whereness-icon.svg'
import Button from '@components/common/Button'
import { useNavigate } from 'react-router-dom'

const ForgotPassword: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { emailAutofill } = useAppSelector((state: RootState) => state.auth)

  const handleSendEmailResetPassword = () => {
    sendEmailForgotPassword()
  }
  const sendEmailForgotPassword = async () => {
    const email = emailAutofill
    if (email) {
      try {
        const res = await dispatch(forgotPasswordAction(email)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'forgotPassword',
            message: (
              <>
                <div>{t('errorFromBE.auth.userNotFound.first')}</div>
                <div>{t('errorFromBE.auth.userNotFound.second')}</div>
              </>
            ),
          })
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'forgotPassword',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  return (
    <React.Fragment>
      <div className="flex pt-3.5 py-3 justify-center items-center">
        <img src={IconLogo} alt="" className="mr-1.5" />
        <img src={IconWhereness} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center text-base leading-7 text-white__op-600 font-lato mb-6">
        <span className="text-center">
          {t('auth.forgetPassword.title', { email: emailAutofill })}
        </span>
      </div>
      <div className="flex items-center justify-center">
        <Button.Normal
          type="submit"
          className="!py-3 font-semibold text-sm"
          title={t('auth.forgetPassword.resendEmail')}
          isTransition
          onClick={handleSendEmailResetPassword}
        />
      </div>
      <div className="flex items-center justify-center">
        <Button.Normal
          type="submit"
          className="!py-3 font-semibold text-sm"
          title={t('auth.forgetPassword.closeWindow')}
          isTransition
          onClick={() => navigate('/sign-in')}
          color="transparent"
        />
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword
