import { createSlice } from '@reduxjs/toolkit'

export interface DragDropItem {
  id: string
  title: string
}

export interface DragDropState {
  items: DragDropItem[]
  draggingItemId: string | null
  isDefaultDropValue: Boolean
  dropDataId: string | null
  dragging: boolean
  dropDataItem: any[]
}

const initialState: DragDropState = {
  items: [],
  draggingItemId: null,
  isDefaultDropValue: false,
  dropDataId: null,
  dragging: false,
  dropDataItem: [],
}

const dragDropSlice = createSlice({
  name: 'dragDrop',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload
    },
    startDrag: (state, action) => {
      state.items = [...state.items, action.payload]
    },
    endDrag: (state) => {
      state.draggingItemId = null
    },
    dropItem: (state, action) => {
      const { targetIndex } = action.payload
      const draggingItem = state.items.find(
        (item) => item.id === state.draggingItemId
      )
      if (draggingItem) {
        state.items = state.items.filter(
          (item) => item.id !== state.draggingItemId
        )
        state.items.splice(targetIndex, 0, draggingItem)
      }
      state.draggingItemId = null
    },
    dropState: (state, action) => {
      state.isDefaultDropValue = action.payload
    },
    dropId: (state, action) => {
      state.dropDataId = action.payload
    },
    clearDrop: (state, action) => {
      state.dropDataId = action.payload
    },
    isDragging: (state, action) => {
      state.dragging = action.payload
    },
    isInfoItem: (state, action) => {
      state.dropDataItem = action.payload
    },
  },
})

export const { actions } = dragDropSlice

export default dragDropSlice.reducer
