import _ from 'lodash'
import {
  ILayer,
  IScene,
  ISpace,
  IValueGetUndoRedo,
  IValueSetUndoRedo,
} from '@models/sceneSetting'
import {
  LIST_PROJECT_VERSION_KEY,
  MAX_SIZE_FILE,
  MAX_TOTAL_SAVE,
} from '@constants/sceneSetting'
import { IBillingPeriodType, IProduct } from '@models/organization'
import {
  IUnitAddOnDetail,
  IUnitServicePackageDetail,
} from '@stores/organization/organization.reducer'
import { IProductType } from '@models/plan'

class Helper {
  public saveUndoRedoToStorage(value: IValueSetUndoRedo) {
    const storage = sessionStorage.getItem(LIST_PROJECT_VERSION_KEY)
    const newProjectVersion: IValueGetUndoRedo = {
      projectId: value.projectId,
      listVersion: [value.dataVersion],
      idCurrentVersion: value.dataVersion.version,
    }
    let isDisUndo = false
    let isDisRedo = false
    if (storage) {
      let isStop = false
      const newStorage = JSON.parse(storage) as Array<IValueGetUndoRedo>
      const isExistVersionProject = _.some(newStorage, {
        projectId: value.projectId,
      })
      if (isExistVersionProject) {
        for (const project of newStorage) {
          if (isStop) {
            break
          }

          if (project.projectId === value.projectId) {
            const indexVer = _.findIndex(project.listVersion, {
              version: project.idCurrentVersion,
            })
            if (indexVer + 1 < project.listVersion.length && indexVer !== -1) {
              project.listVersion.splice(
                indexVer + 1,
                project.listVersion.length - (indexVer + 1),
                value.dataVersion
              )
            } else {
              project.listVersion.push(value.dataVersion)
            }
            project.idCurrentVersion = value.dataVersion.version
            isDisRedo = true
            isStop = true
            break
          }
          if (project.listVersion.length > MAX_TOTAL_SAVE) {
            project.listVersion = _.takeRight(
              project.listVersion,
              MAX_TOTAL_SAVE
            )
          }
        }
      } else {
        newStorage.push(newProjectVersion)
        isDisRedo = true
        isDisUndo = true
      }
      sessionStorage.setItem(
        LIST_PROJECT_VERSION_KEY,
        JSON.stringify(newStorage)
      )
    } else {
      sessionStorage.setItem(
        LIST_PROJECT_VERSION_KEY,
        JSON.stringify([newProjectVersion])
      )
      isDisRedo = true
      isDisRedo = true
    }

    return { isRedo: isDisRedo, isUndo: isDisUndo }
  }

  public getUndoVersionFromStorage(projectId: number) {
    const storage = sessionStorage.getItem(LIST_PROJECT_VERSION_KEY)
    if (storage) {
      const newStorage = JSON.parse(storage) as Array<IValueGetUndoRedo>
      const projectVersion = _.find(newStorage, { projectId })
      const indexCurrentVersion = _.findIndex(projectVersion?.listVersion, {
        version: projectVersion?.idCurrentVersion,
      })
      if (projectVersion && indexCurrentVersion) {
        let isStop = false
        for (const project of newStorage) {
          if (isStop) {
            break
          }
          if (project.projectId === projectId) {
            project.idCurrentVersion =
              projectVersion.listVersion[indexCurrentVersion - 1].version
            isStop = true
            break
          }
        }
        return {
          undoVer: projectVersion.listVersion[indexCurrentVersion - 1],
          newlistProjectVer: newStorage,
        }
      }
    }
    return
  }

  public getRedoVersionFromStorage(projectId: number) {
    const storage = sessionStorage.getItem(LIST_PROJECT_VERSION_KEY)
    if (storage) {
      const newStorage = JSON.parse(storage) as Array<IValueGetUndoRedo>
      const projectVersion = _.find(newStorage, { projectId })
      const indexCurrentVersion = _.findIndex(projectVersion?.listVersion, {
        version: projectVersion?.idCurrentVersion,
      })
      if (
        projectVersion &&
        indexCurrentVersion >= 0 &&
        projectVersion.listVersion.length > indexCurrentVersion + 1
      ) {
        let isStop = false
        for (const project of newStorage) {
          if (isStop) {
            break
          }
          if (project.projectId === projectId) {
            project.idCurrentVersion =
              projectVersion.listVersion[indexCurrentVersion + 1].version
            isStop = true
            break
          }
        }
        return {
          undoVer: projectVersion.listVersion[indexCurrentVersion + 1],
          newlistProjectVer: newStorage,
        }
      }
    }
    return
  }

  public updateCurrentVersionToStorage(
    listProjectVer: Array<IValueGetUndoRedo>,
    idProject: number,
    idExistVer: number,
    idVersion: number
  ) {
    const newStorage = _.cloneDeep(listProjectVer)
    let isStop = false
    let isDisUndo = false
    let isDisRedo = false
    for (const project of newStorage) {
      if (isStop) {
        break
      }
      if (project.projectId === idProject) {
        for (const version of project.listVersion) {
          if (version.version === idExistVer) {
            project.idCurrentVersion = idVersion
            version.version = idVersion
            isStop = true
            break
          }
        }
        isStop = true
        break
      }
    }
    const projectVer = _.find(newStorage, { projectId: idProject })
    const indexVer = _.findIndex(projectVer?.listVersion, {
      version: idVersion,
    })
    if (indexVer === 0) {
      isDisUndo = true
    }
    if (indexVer + 1 === projectVer?.listVersion.length) {
      isDisRedo = true
    }
    sessionStorage.setItem(LIST_PROJECT_VERSION_KEY, JSON.stringify(newStorage))
    return { isDisRedo, isDisUndo }
  }

  public getLayerSpaceFromSpaceId(listLayer: Array<ILayer>, idSpace: number) {
    let isStop = false
    let dataLayer: ILayer | null = null
    let dataSpace: ISpace | null = null
    for (const layer of listLayer) {
      if (isStop) {
        break
      }
      if (layer.spaces.length > 0) {
        for (const space of layer.spaces) {
          if (isStop) {
            break
          }
          if (space.info.id === idSpace) {
            dataLayer = layer
            dataSpace = space
            isStop = true
            break
          }
        }
      }
    }
    return { layer: dataLayer, space: dataSpace }
  }

  public getLayerSpaceSceneFromSceneId(
    listLayer: Array<ILayer>,
    idScene: number
  ) {
    let isStop = false
    let dataLayer: ILayer | null = null
    let dataSpace: ISpace | null = null
    let dataScene: IScene | null = null
    for (const layer of listLayer) {
      if (isStop) {
        break
      }
      if (layer.spaces.length > 0) {
        for (const space of layer.spaces) {
          if (isStop) {
            break
          }
          if (space.scenes.length > 0) {
            for (const scene of space.scenes) {
              if (scene.info.id === idScene) {
                dataLayer = layer
                dataSpace = space
                dataScene = scene
                isStop = true
                break
              }
            }
          }
        }
      }
    }
    return { layer: dataLayer, space: dataSpace, scene: dataScene }
  }

  public clearCatchProject(idProject: number) {
    const storage = sessionStorage.getItem(LIST_PROJECT_VERSION_KEY)
    if (storage) {
      const newStorage = JSON.parse(storage) as Array<IValueGetUndoRedo>
      newStorage.forEach((project, index) => {
        if (project.projectId === idProject) {
          newStorage.splice(index, 1)
        }
      })
      sessionStorage.setItem(
        LIST_PROJECT_VERSION_KEY,
        JSON.stringify(newStorage)
      )
    }
    return
  }

  public checkHasProjectVersion(idProject: number) {
    const storage = sessionStorage.getItem(LIST_PROJECT_VERSION_KEY)
    if (storage) {
      const newStorage = JSON.parse(storage) as Array<IValueGetUndoRedo>
      const isExistVersionProject = _.some(newStorage, {
        projectId: idProject,
      })
      if (isExistVersionProject) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  public checkCanUndoOrRedo(idProject: number) {
    let isDisRedo = false
    let isDisUndo = false
    const storage = sessionStorage.getItem(LIST_PROJECT_VERSION_KEY)
    if (storage) {
      const newStorage = JSON.parse(storage) as Array<IValueGetUndoRedo>
      const projectVersion = _.find(newStorage, {
        projectId: idProject,
      })
      if (projectVersion) {
        const indexVer = _.findIndex(projectVersion.listVersion, {
          version: projectVersion.idCurrentVersion,
        })
        if (indexVer === 0) {
          isDisUndo = true
        }
        if (indexVer + 1 === projectVersion.listVersion.length) {
          isDisRedo = true
        }
      } else {
        isDisUndo = true
        isDisRedo = true
      }
    }
    return { isDisRedo, isDisUndo }
  }

  public checkCodecBrowser() {
    const codecH265 = 'application/vnd.apple.mpegurl; codecs="hvc1.1.6.L93.90"'
    const codecDash = 'video/mp4; codecs="hev1.1.6.L93.90"'
    const agent = window.navigator.userAgent.toLowerCase()
    if (
      agent.indexOf('edg') > 0 ||
      agent.indexOf('edge') > 0 ||
      agent.indexOf('android') > 0
    ) {
      return 'VP9'
    }
    const video: HTMLVideoElement = document.createElement('video')
    if (
      video.canPlayType(codecH265) === 'probably' ||
      video.canPlayType(codecH265) === 'maybe'
    ) {
      video.remove()
      return 'H265_HLS'
    } else {
      if (
        window.MediaSource &&
        typeof window.MediaSource.isTypeSupported === 'function'
      ) {
        if (window.MediaSource.isTypeSupported(codecDash)) {
          video.remove()
          return 'H265_DASH'
        }
      }
      return 'VP9'
    }
  }

  public getFilesHaveNotLimit(files: FileList) {
    const dataTransfer = new DataTransfer()
    Array.from(files).forEach((file) => {
      if (file.size < MAX_SIZE_FILE) {
        dataTransfer.items.add(file)
      }
    })
    return dataTransfer.files
  }

  public chargeProration(
    currentSubscription: IProduct,
    targetProduct: IUnitServicePackageDetail | IUnitAddOnDetail,
    productType: IProductType
  ) {
    let currentPrice = currentSubscription.price
    const targetPrice = targetProduct.price.productPrice

    if (currentSubscription.billingPeriodType === IBillingPeriodType.BY_YEAR) {
      currentPrice /= 12
    }
    let diffMonths = 0
    if (productType === IProductType.SERVICE_PACKAGE) {
      diffMonths = this.getDiffMonthsServicePackage(
        Date.now(),
        currentSubscription.currentPaymentExpireDate
      )
    } else if (productType === IProductType.ADD_ON) {
      diffMonths = this.getDiffMonthsAddOn(
        Date.now(),
        currentSubscription.currentPaymentExpireDate
      )
    }
    let prorationPrice = targetPrice - diffMonths * currentPrice
    prorationPrice = prorationPrice < 0 ? 0 : prorationPrice
    return prorationPrice
  }

  private getDiffMonthsServicePackage(
    startDateTimestamp: number,
    endDateTimestamp: number
  ) {
    const startDate = new Date(
      startDateTimestamp.toString().length === 13
        ? startDateTimestamp
        : startDateTimestamp * 1000
    )
    const endDate = new Date(
      endDateTimestamp.toString().length === 13
        ? endDateTimestamp
        : endDateTimestamp * 1000
    )

    let diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12
    diffMonths += endDate.getMonth() - startDate.getMonth()

    if (endDate.getDate() - startDate.getDate() > 14) {
      diffMonths += 1
    } else if (startDate.getDate() - endDate.getDate() > 14) {
      diffMonths -= 1
    }

    return diffMonths
  }

  private getDiffMonthsAddOn(
    startDateTimestamp: number,
    endDateTimestamp: number
  ) {
    const startDate = new Date(
      startDateTimestamp.toString().length === 13
        ? startDateTimestamp
        : startDateTimestamp * 1000
    )
    const endDate = new Date(
      endDateTimestamp.toString().length === 13
        ? endDateTimestamp
        : endDateTimestamp * 1000
    )

    let diffMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12
    diffMonths += endDate.getMonth() - startDate.getMonth()

    if (endDate.getDate() - startDate.getDate() > 0) {
      diffMonths += 1
    }

    return diffMonths
  }

  public getNextMonth = (type: IBillingPeriodType) => {
    const now = new Date()
    if (type === IBillingPeriodType.BY_YEAR) {
      return new Date(now.getFullYear() + 1, now.getMonth(), now.getDate())
    }
    if (now.getMonth() == 11) {
      return new Date(now.getFullYear() + 1, 0, now.getDate())
    } else {
      return new Date(now.getFullYear(), now.getMonth() + 1, now.getDate())
    }
  }
}
export const sceneSettingHelper = new Helper()
