import { ReactNode } from 'react'

//enum
export enum TYPE {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

export enum TYPE_ERROR {
  JWT_EXPIRED = 'TOKEN_EXPIRED',
  UNAUTHORIZED = 'UNAUTHORIZED',
  REQUEST_INVALID = 'REQUEST_INVALID',
  RESOURCE_IS_LOCKED = 'RESOURCE_IS_LOCKED',
  ORGANIZATION_STORAGE_OVER_LIMIT = 'ORGANIZATION_STORAGE_OVER_LIMIT',
  PUBLISH_PROJECT_STORAGE_OVER_LIMIT = 'PUBLISH_PROJECT_STORAGE_OVER_LIMIT',
  PUBLISH_PROJECT_VIEW_OVER_LIMIT = 'PUBLISH_PROJECT_VIEW_OVER_LIMIT',
  SUBSCRIPTION_ERROR = 'Subscription field must return Async Iterable.',
}

export enum TypeLanguage {
  ENLISH = 'en',
  JAPAN = 'ja',
}

//interface
export interface INotification {
  type: TYPE
  key?: string
  message?: string | ReactNode
  description?: string | ReactNode
}

export interface IErrorResponse {
  errorCode: string
  message: string
}

export interface IMessage {
  statusCode: string
  message: string
  error: IErrorResponse | null
}

export interface IFileInfo {
  fileName: string
  fileType: string
  projectId: number
}

export interface IRequestSignedUrlUploadAvatar {
  fileName: string
  fileType: string
}

export interface IPositionContextMenu {
  xPos: string
  yPos: string
}
