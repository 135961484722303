import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import Select, { IValueSelected } from '@components/common/selectInput/Select'
import { TYPE } from '@models/common'
import MoreSettingInfoTagButton from './MoreSettingInfoTagButton'
import {
  IInfoTagResponse,
  IRequestSetInfoTag,
  TypeContentInfoTag,
} from '@models/sceneSetting'
import { useAppDispatch } from '@stores/hook'
import { setInfoTagAction } from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import InfoTagNamComponent from './InfoTagNamComponent'
import InfoTagColorSelect from './InfoTagColorSelect'
import InfoTagSizeSelect from './InfoTagSizeSelect'
import InfoTagTypeSelect from './InfoTagTypeSelect'
//icon
import TagtypeFileSelect from './TagtypeFileSelect'
import MediaContentComponent from './MediaContentComponent'
import InfoTagDescription from './InfoTagDescription'
import SpinComponent from '@components/common/SpinComponent'
import MediaInfoComponent from './MediaInfoComponent'
import InfoTagTitleCom from './InfoTagTitleCom'

interface IPropsType {
  dataInfoTag: IInfoTagResponse
}

const InfoTagSettingComponent: React.FC<IPropsType> = (props) => {
  const { dataInfoTag } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  // const optionsLanguage = [
  //   {
  //     value: TypeLanguage.ENLISH,
  //     label: 'English',
  //   },
  //   {
  //     value: TypeLanguage.JAPAN,
  //     label: '日本語',
  //   },
  // ]

  // const [typeLanguage, setTypeLanguage] = useState<IValueSelected>(
  //   optionsLanguage[0]
  // )

  const [infoTag, setInfoTag] = useState<IInfoTagResponse>(dataInfoTag)
  const [isOpenEditInfoTagName, setIsOpenEditInfoTagName] =
    useState<boolean>(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)

  useEffect(() => {
    if (dataInfoTag) {
      setInfoTag(dataInfoTag)
    }
  }, [dataInfoTag])

  // const handleChangeLanguage = (value: IValueSelected) => {
  //   setTypeLanguage(value)
  // }

  const handleSetInfoTag = useCallback(async (req: IRequestSetInfoTag) => {
    const resSetInfoTag = await dispatch(setInfoTagAction(req)).unwrap()
    if (resSetInfoTag.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'setInfoTag',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
    return resSetInfoTag.data as IInfoTagResponse
  }, [])

  const handleCloseEditInfoTagName = useCallback(() => {
    setIsOpenEditInfoTagName(false)
  }, [])

  const handleOpenEditInfoTagName = useCallback(() => {
    setIsOpenEditInfoTagName(true)
  }, [])

  const handleChangeLoadingDelete = useCallback(
    (isLoading: boolean) => {
      setIsLoadingDelete(isLoading)
    },
    [isLoadingDelete]
  )

  return (
    <div className="bg-gray-800 h-full transform w-[240px] overflow-y-auto">
      {isLoadingDelete ? (
        <SpinComponent />
      ) : (
        <>
          <div className="flex justify-between items-center text-white pl-4 pr-1.5 py-2">
            <InfoTagNamComponent
              dataInfoTag={{
                coordinates: infoTag.coordinates,
                id: infoTag.id,
                name: infoTag.name,
              }}
              isOpenEditInfoTagName={isOpenEditInfoTagName}
              handleCloseEditInfoTagName={handleCloseEditInfoTagName}
              handleSetInfoTag={handleSetInfoTag}
            />
            <MoreSettingInfoTagButton
              dataInfoTag={{ coordinates: infoTag.coordinates, id: infoTag.id }}
              handleOpenEditInfoTagName={handleOpenEditInfoTagName}
              handleChangeLoadingDelete={handleChangeLoadingDelete}
            />
          </div>
          <div className="py-2">
            <div className="text-xs font-semibold leading-5 text-white__op-600 px-4 py-1.5">
              {t('sceneSetting.rightSideBar.infoTag.apperance')}
            </div>
            <div className="px-2">
              <InfoTagColorSelect
                dataInfoTag={{
                  coordinates: infoTag.coordinates,
                  id: infoTag.id,
                  color: infoTag.color,
                }}
                handleSetInfoTag={handleSetInfoTag}
              />
              <InfoTagSizeSelect
                dataInfoTag={{
                  coordinates: infoTag.coordinates,
                  id: infoTag.id,
                  size: infoTag.size,
                }}
                handleSetInfoTag={handleSetInfoTag}
              />
            </div>
          </div>
          <hr className="border-white__op-100 mx-4" />
          <div className="py-2">
            <div className="relative text-xs py-1.5 px-4 font-semibold leading-5 text-white__op-600 flex justify-between items-center">
              <div className="w-full mr-auto">{t('common.content')}</div>
              {/* {infoTag && infoTag.tagType === TypeContentInfoTag.TEXT && (
                <div className="absolute top-1/2 -translate-y-1/2 right-4">
                  <Select
                    value={typeLanguage}
                    onChange={handleChangeLanguage}
                    options={optionsLanguage}
                    classNameValue="!text-xs !leading-5"
                    classNameSelect="!w-auto -mr-2"
                  />
                </div>
              )} */}
            </div>
            <div className="px-2">
              <InfoTagTypeSelect
                dataInfoTag={{
                  coordinates: infoTag.coordinates,
                  id: infoTag.id,
                  tagType: infoTag.tagType,
                }}
                handleSetInfoTag={handleSetInfoTag}
              />
            </div>
            {infoTag && infoTag.tagType === TypeContentInfoTag.TEXT && (
              <div className="px-2">
                <InfoTagTitleCom
                  dataInfoTag={infoTag}
                  handleSetInfoTag={handleSetInfoTag}
                />
              </div>
            )}
            {infoTag && infoTag.tagType === TypeContentInfoTag.TEXT ? (
              <InfoTagDescription
                dataInfoTag={{
                  coordinates: infoTag.coordinates,
                  id: infoTag.id,
                  description: infoTag.description,
                }}
                handleSetInfoTag={handleSetInfoTag}
              />
            ) : (
              <TagtypeFileSelect
                dataInfoTag={{
                  coordinates: infoTag.coordinates,
                  id: infoTag.id,
                  media: infoTag.media,
                }}
                handleSetInfoTag={handleSetInfoTag}
              />
            )}
            {infoTag && infoTag.media.mediaType && (
              <MediaContentComponent
                media={{
                  type: infoTag.media.mediaType,
                  url: infoTag.media.mediaUrl,
                  thumb: infoTag.media.mediaThumbnailUrl,
                }}
              />
            )}
            {infoTag && infoTag.media.mediaType && (
              <MediaInfoComponent
                dataInfoTag={infoTag}
                handleSetInfoTag={handleSetInfoTag}
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default React.memo(InfoTagSettingComponent)
