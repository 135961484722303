/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import Restful from './restful'
import {
  IOnProgressFnEvent,
  IS3MultipleUploaderOptions,
  IS3MultipleUploaderPart,
  IS3UploadRequest,
} from '../models/s3'
import axios from 'axios'
import { IDataSignedUrl } from '../models/auth'
import { graphqlApi } from './graphql'
import { GET_MULTIPART_SIGNED_URLS } from '../graphql/s3Uploader/query'
import { TYPE_S3_UPLOADER } from '../graphql/s3Uploader/type'
import {
  SET_ABORT_MULTIPART_UPLOAD,
  SET_COMPLETE_MULTIPART_UPLOAD,
} from '../graphql/s3Uploader/mutation'
import { mediaLibHelper } from '../utils/helper/mediaLib'
import { TYPE_MEDIA } from '../models/media'
class S3 extends Restful {
  public async upload(
    url: string,
    params: IS3UploadRequest
  ): Promise<{ status: number }> {
    const data = new FormData()
    for (const [name, value] of Object.entries(params)) {
      data.append(name, value || '')
    }

    return await axios.request({
      method: 'post',
      url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Methods':
          'GET, PUT, PATCH, POST, DELETE, OPTIONS',
        'Access-Control-Allow-Origin': '*',
      },
      data,
    })
  }

  async uploadMediaToS3(file: File | Blob, dataSigned: IDataSignedUrl) {
    const {
      algorithm,
      bucket,
      contentType,
      credential,
      date,
      key,
      policy,
      signature,
      url,
      securityToken,
    } = dataSigned
    const data: IS3UploadRequest = {
      bucket: bucket,
      'X-Amz-Security-Token': securityToken,
      'X-Amz-Algorithm': algorithm,
      'X-Amz-Credential': credential,
      'X-Amz-Date': date,
      'X-Amz-Signature': signature,
      'Content-Type': contentType,
      key: key,
      Policy: policy,
      file,
    }
    return await this.upload(url, data)
  }
}

export class S3MultipleUploader {
  private chunkSize: number
  private threadsQuantity: number
  private file: File | Blob
  private mediaType: TYPE_MEDIA | undefined
  private is360: boolean
  private projectId: number
  private uploadedSize: number
  private progressCache: any
  private activeConnections: any
  private parts: IS3MultipleUploaderPart[]
  private uploadedParts: IS3MultipleUploaderPart[]
  private bucket: string | null
  private fileKey: string | null
  private uploadId: string | null
  private onProgressFn: (event: IOnProgressFnEvent) => void
  private onErrorFn: Function

  constructor(options: IS3MultipleUploaderOptions) {
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    this.chunkSize = options.chunkSize || 1024 * 1024 * 5
    // number of parallel uploads
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15)
    this.is360 = options.is360 ?? false
    this.file = options.file
    this.projectId = options.projectId
    this.mediaType = options.mediaType
    this.uploadedSize = 0
    this.progressCache = {}
    this.activeConnections = {}
    this.parts = []
    this.uploadedParts = []
    this.fileKey = null
    this.bucket = null
    this.uploadId = null
    this.onProgressFn = () => {}
    this.onErrorFn = () => {}
  }

  public async initialize() {
    const partNumber = Math.ceil(this.file.size / this.chunkSize)
    const mediaType =
      this.mediaType ??
      mediaLibHelper.detectMediaType(this.file.type, this.is360)

    const getMultipartSignedUrlsInput = {
      fileName:
        this.file instanceof File ? this.file.name : 'thumbnail-cap.jpeg',
      fileType: this.file.type,
      mediaType,
      projectId: this.projectId,
      partNumber: partNumber,
    }

    const getMultipartSignedUrlsRes = await graphqlApi.queryRequest(
      GET_MULTIPART_SIGNED_URLS,
      TYPE_S3_UPLOADER.QUERY.GET_MULTIPART_SIGNED_URLS,
      { input: getMultipartSignedUrlsInput }
    )

    if (!getMultipartSignedUrlsRes.error) {
      this.fileKey = getMultipartSignedUrlsRes.data.key
      this.bucket = getMultipartSignedUrlsRes.data.bucket
      this.uploadId = getMultipartSignedUrlsRes.data.uploadId

      const newParts = getMultipartSignedUrlsRes.data.partsInfo
      this.parts.push(...newParts)
    }
    return getMultipartSignedUrlsRes
  }

  private async sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length
    if (activeConnections >= this.threadsQuantity) {
      return
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        await this.complete()
      }
      return
    }

    const processedParts = await Promise.allSettled(
      this.parts.splice(-this.threadsQuantity).map((part) => {
        const sentSize = (part.partNumber - 1) * this.chunkSize
        const chunk = this.file.slice(sentSize, sentSize + this.chunkSize)

        return this.upload(chunk, part).then((status) => {
          if (status !== 200) {
            throw new Error('Failed chunk upload')
          }
        })
      })
    )

    const failedParts = processedParts
      .filter((part) => part.status === 'rejected')
      .map((part: any) => part?.reason)
    if (failedParts.length > 0) {
      await Promise.all(
        failedParts.map((item) => {
          const requestUrl = new URL(item.config.url)
          const part: IS3MultipleUploaderPart = {
            partNumber: parseInt(
              requestUrl.searchParams.get('partNumber') as string
            ),
            signedUrl: item.config.url,
          }
          return this.upload(item.config.data, part).then((status) => {
            if (status !== 200) {
              throw new Error('Failed chunk upload')
            }
          })
        })
      )
    }

    await this.sendNext()
  }

  private async complete(error?: Error | unknown) {
    if (error) {
      this.onErrorFn(error)
      await this.sendAbortRequest()
      return
    }

    try {
      await this.sendCompleteRequest()
    } catch (error) {
      this.onErrorFn(error)
    } finally {
      this.abort()
    }
  }

  private async sendCompleteRequest() {
    const setCompleteMultipartUploadInput = {
      key: this.fileKey,
      bucket: this.bucket,
      projectId: this.projectId,
      uploadId: this.uploadId,
      parts: this.uploadedParts,
    }

    await graphqlApi.mutationRequest(
      SET_COMPLETE_MULTIPART_UPLOAD,
      TYPE_S3_UPLOADER.MUTATION.SET_COMPLETE_MULTIPART_UPLOAD,
      { input: setCompleteMultipartUploadInput }
    )
  }

  private async sendAbortRequest() {
    const setAbortMultipartUploadInput = {
      key: this.fileKey,
      bucket: this.bucket,
      projectId: this.projectId,
      uploadId: this.uploadId,
    }

    await graphqlApi.mutationRequest(
      SET_ABORT_MULTIPART_UPLOAD,
      TYPE_S3_UPLOADER.MUTATION.SET_ABORT_MULTIPART_UPLOAD,
      { input: setAbortMultipartUploadInput }
    )
    this.abort()
  }

  private handleProgress(
    part: number,
    event: ProgressEvent<XMLHttpRequestEventTarget>
  ) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0
        delete this.progressCache[part]
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0)

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size)

      const total = this.file.size

      const percentage = Math.round((sent / total) * 100)

      this.onProgressFn({
        sent: sent,
        total: total,
        percentage: percentage,
      })
    }
  }

  private upload(file: Blob, part: IS3MultipleUploaderPart) {
    return new Promise((resolve, reject) => {
      if (this.bucket && this.fileKey) {
        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest())

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1
        )

        xhr.upload.addEventListener('progress', progressListener)

        xhr.addEventListener('error', progressListener)
        xhr.addEventListener('abort', progressListener)
        xhr.addEventListener('loadend', progressListener)

        xhr.open('PUT', part.signedUrl as string)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const eTag = xhr.getResponseHeader('ETag')

            if (eTag) {
              const uploadedPart = {
                partNumber: part.partNumber,
                eTag,
              }

              this.uploadedParts.push(uploadedPart)

              resolve(xhr.status)
              delete this.activeConnections[part.partNumber - 1]
            }
          }
        }

        xhr.onerror = (error) => {
          reject(error)
          delete this.activeConnections[part.partNumber - 1]
        }

        xhr.onabort = () => {
          reject(new Error('Upload canceled by user'))
          delete this.activeConnections[part.partNumber - 1]
        }

        xhr.send(file)
      }
    })
  }

  private abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort()
      })
  }

  async start() {
    if (!this.fileKey || !this.bucket || !this.uploadId) {
      await this.initialize()
    }

    try {
      await this.sendNext()
    } catch (error) {
      await this.complete(error)
    }

    return this.fileKey
  }

  onProgress(onProgress: (event: IOnProgressFnEvent) => void) {
    this.onProgressFn = onProgress
    return this
  }

  onError(onError: Function) {
    this.onErrorFn = onError
    return this
  }

  getFileInfo() {
    return {
      key: this.fileKey,
      bucket: this.bucket,
      file: this.file,
    }
  }
}

export const s3Api = new S3()
