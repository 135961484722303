export const API_TYPE_PROFILE = {
  MUTATION: {
    DELETE_ACCOUNT: 'cancelAccount',
    SET_PARTNER_CODE: 'setPartnerCode',
  },
  QUERY: {
    GET_SIGNED_URL_UPLOAD_AVATAR: 'getSignedUrlUploadAvatar',
    GET_USER_PROFILE: 'getUserProfile',
  },
}
