import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/hook'
import { RootState } from '../../../../store/store'
import {
  deleteMediaAction,
  deleteMediaFolderAction,
} from '../../../../store/project/project.action'
import { IRequestDeleteMediaFolder } from '../../../../models/media'
import { openNotification } from '../../../../utils/notification'
import { TYPE } from '../../../../models/common'
import { useTranslation } from 'react-i18next'
import ContextMenu from '../../ContextMenu'
import PopupConfirm from '../../../common/PopupConfirm'
import { actions as profileActions } from '@stores/profile/profile.reducer'
import { TYPE_PROFILE } from '@stores/profile/profile.type'
import { IResponseUserProfile } from '@models/profile'

interface PropTypes {
  open: boolean
  position: {
    xPos: string
    yPos: string
  } | null
  close: () => void
  handleEditingItemName: () => void
}

const ItemMenu: React.FC<PropTypes> = ({
  open,
  position,
  close,
  handleEditingItemName,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { selectedFolderInAsset, selectedMediaInAsset, projectInfo } =
    useAppSelector((state: RootState) => state.project)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)

  const handleDeleteFolder = async (data: IRequestDeleteMediaFolder) => {
    try {
      const res = await dispatch(deleteMediaFolderAction(data)).unwrap()
      if (res.error) {
        throw new Error('Delete fail')
      }
      if (userProfile) {
        const newData: IResponseUserProfile = {
          ...userProfile,
          info: {
            ...userProfile.info,
            currentOrganizationStorage: res.data.currentOrganizationStorage,
          },
        }
        dispatch(
          profileActions[TYPE_PROFILE.REDUCERS.SET_USER_PROFILE](newData)
        )
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'deleteFolder',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleDeleteMedia = async () => {
    const organizationId = user?.organizationId
    const projectId = projectInfo?.projectId
    if (!projectId || !organizationId) {
      throw new Error('Project or organization is invalid')
    }

    if (
      !selectedMediaInAsset?.key ||
      !selectedMediaInAsset.mediaType ||
      !selectedMediaInAsset.resourceId
    ) {
      throw new Error('Delete media input is invalid')
    }

    try {
      const res = await dispatch(
        deleteMediaAction({
          organizationId,
          projectId,
          mediaType: selectedMediaInAsset?.mediaType,
          resourceId: selectedMediaInAsset?.resourceId,
        })
      ).unwrap()

      if (res.error) {
        throw new Error()
      }

      if (userProfile) {
        const newData: IResponseUserProfile = {
          ...userProfile,
          info: {
            ...userProfile.info,
            currentOrganizationStorage: res.data.currentOrganizationStorage,
          },
        }
        dispatch(
          profileActions[TYPE_PROFILE.REDUCERS.SET_USER_PROFILE](newData)
        )
      }
    } catch {
      throw new Error()
    }
  }

  const handleDeleteItem = async () => {
    setIsShowConfirmDelete(false)
    try {
      const projectId = projectInfo?.projectId
      if (!projectId) {
        throw new Error('Project id is invalid')
      }

      if (selectedFolderInAsset) {
        await handleDeleteFolder({
          projectId,
          folderId: selectedFolderInAsset,
        })
      } else if (selectedMediaInAsset) {
        await handleDeleteMedia()
      }
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'deleteMedia',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }
  return (
    <>
      <ContextMenu
        open={open}
        position={position}
        close={() => close()}
        options={
          <div className="flex flex-col font-lato">
            <button
              className="flex items-center justify-between px-4 py-1.5 text-sm leading-5 hover:bg-white__op-50"
              onClick={handleEditingItemName}
            >
              <div className="text-white__op-900 mr-3">
                {t('common.rename')}
              </div>
              {/* <div className="text-white__op-500">⌘R</div> */}
            </button>
            <button
              className="flex items-center justify-between px-4 py-1.5 text-sm leading-5 hover:bg-white__op-50"
              onClick={() => setIsShowConfirmDelete(true)}
            >
              <div className="text-white__op-900 mr-3">
                {t('common.delete')}
              </div>
              {/* <div className="text-white__op-500">Del</div> */}
            </button>
          </div>
        }
      />
      {isShowConfirmDelete && (
        <PopupConfirm
          title={
            selectedFolderInAsset
              ? t('popup.confirm.confirmDeleteMediaFolder.title')
              : t('popup.confirm.confirmDeleteMediaFile.title')
          }
          visible={isShowConfirmDelete}
          messageFirst={
            selectedFolderInAsset
              ? t('popup.confirm.confirmDeleteMediaFolder.messageFirst')
              : t('popup.confirm.confirmDeleteMediaFile.messageFirst')
          }
          isDelete={true}
          okLabel={t('common.delete')}
          cancelLabel={t('common.cancel')}
          handleCancel={() => setIsShowConfirmDelete(false)}
          handleOk={handleDeleteItem}
        />
      )}
    </>
  )
}

export default ItemMenu
