import classNames from 'classnames'
import React, { ReactNode, useEffect } from 'react'
import { useAppSelector } from '../../store/hook'
import { RootState } from '../../store/store'
import Icon from '@components/common/Icon'
import IconClose from '@assets/icons/icon-close.svg'

interface ModalProps {
  centered?: boolean
  sidebarEnabled?: boolean
  modalClassName?: string
  closable?: boolean
  title?: ReactNode
  children?: ReactNode
  footer?: ReactNode
  okText?: ReactNode
  cancleText?: ReactNode
  isCloseOutside?: boolean
  lowerZIndex?: boolean
  open: boolean
  onClose: () => void
  onOk?: (e: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
}

const ModalComponent: React.FC<ModalProps> = (props) => {
  const {
    centered,
    sidebarEnabled,
    modalClassName,
    closable,
    title,
    children,
    footer,
    okText,
    cancleText,
    isCloseOutside,
    lowerZIndex,
    open,
    onClose,
    onCancel,
    onOk,
  } = props
  const { sidebarWidth } = useAppSelector((state: RootState) => state.scene)

  useEffect(() => {
    const modal_overlay = document.querySelector('#modal_overlay')
    const modal = document.querySelector('#modal')
    const modalCl = (modal as Element).classList
    const overlayCl = modal_overlay as Element
    if (open) {
      overlayCl.classList.remove('hidden')
      modalCl.remove('opacity-0')
    } else {
      modalCl.add('opacity-0')
      overlayCl.classList.add('hidden')
    }
  }, [open])

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCancel?.(e)
  }

  const handleOk = (e: React.MouseEvent<HTMLButtonElement>) => {
    onOk?.(e)
  }

  const renderFooter = () => (
    <div className="px-3 py-2 border-t flex justify-end">
      <button
        className="px-3 py-2 border rounded-md border-gray-400 text-black mr-2 hover:!text-blue-400 hover:border-blue-400"
        onClick={handleCancel}
      >
        {cancleText || 'Cancel'}
      </button>
      <button
        className="px-3 py-2 border rounded-md bg-[#2067e3] text-white hover:bg-blue-500"
        onClick={handleOk}
      >
        {okText || 'Ok'}
      </button>
    </div>
  )

  const setClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    if (isCloseOutside) {
      const modal_overlay = document.querySelector('#modal_overlay')
      const modal = document.querySelector('#modal')
      const modalCl = (modal as Element).classList
      const overlayCl = modal_overlay as Element
      if (!(modal as Element).contains(event.target as Node)) {
        modalCl.add('opacity-0')
        overlayCl.classList.add('hidden')
        onClose()
      }
    }
  }

  return (
    <div
      id="modal_overlay"
      className={classNames(
        'w-screen min-h-screen h-screen fixed bg-black bg-opacity-40 inset-0 hidden',
        {
          'flex items-center justify-center': centered,
          'flex justify-center items-start pt-5 h-auto': !centered,
          'z-[510]': lowerZIndex,
          'z-[99999]': !lowerZIndex,
        }
      )}
      style={
        sidebarEnabled
          ? {
              width: `calc(100svw - ${sidebarWidth + 64}px)`,
              marginLeft: `${sidebarWidth + 64}px`,
            }
          : {}
      }
      onClick={(event) => setClick(event)}
    >
      <div
        id="modal"
        className={classNames(
          'bg-gray-800 w-[30rem] rounded-2xl opacity-0',
          modalClassName
        )}
      >
        {title && typeof title === 'string' ? (
          <div className="w-full pr-2 pb-4 pl-6 pt-6 text-xl relative font-semibold modal__title">
            {title}
            {closable && (
              <div className=" absolute top-1/2 right-4 -translate-y-1/2 cursor-pointer">
                <Icon.ButtonIcon
                  icon={<img src={IconClose} alt="close" />}
                  size="sm"
                  onClick={() => onClose()}
                />
              </div>
            )}
          </div>
        ) : (
          title
        )}
        <div className="px-6 pb-4 modal__body">{children}</div>

        {footer === undefined ? renderFooter() : footer}
      </div>
    </div>
  )
}

export default ModalComponent
