import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { ICoordinates, IRequestSetInfoTag } from '@models/sceneSetting'
import Input from '@components/common/Input'
import { helper } from '@utils/helper/common'

interface IPropsType {
  isOpenEditInfoTagName: boolean
  dataInfoTag: {
    coordinates: ICoordinates
    name: string
    id: number
  }
  handleCloseEditInfoTagName: () => void
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<unknown>
}
const InfoTagNamComponent: React.FC<IPropsType> = (props) => {
  const {
    isOpenEditInfoTagName,
    handleCloseEditInfoTagName,
    handleSetInfoTag,
    dataInfoTag,
  } = props
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)

  const [infoTagName, setInfoTagName] = useState<string>(dataInfoTag.name || '')

  useEffect(() => {
    if (dataInfoTag) {
      setInfoTagName(dataInfoTag.name || '')
    }
  }, [dataInfoTag.id, dataInfoTag.name])

  const handleChangeInfoTagName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfoTagName(e.target.value)
  }

  const handleKeyDownInfoTagName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateInfoTagName()
    }
  }

  const handleUpdateInfoTagName = async () => {
    if (
      helper.removeWhiteSpaceStart(infoTagName) &&
      infoTagName !== dataInfoTag.name &&
      projectInfo &&
      user &&
      dataScenePreview
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          name: infoTagName,
        },
      }
      handleSetInfoTag(req).finally(() => {
        handleCloseEditInfoTagName()
      })
    } else {
      setInfoTagName(dataInfoTag.name || '')
      handleCloseEditInfoTagName()
    }
  }
  return isOpenEditInfoTagName ? (
    <div className="relative w-full">
      <Input
        id="sceneName"
        type="text"
        className="py-0 px-1 box-border leading-4 text-xs font-lato"
        customClassContainer="py-0"
        value={infoTagName}
        onChange={handleChangeInfoTagName}
        onBlur={handleUpdateInfoTagName}
        handleKeyDown={handleKeyDownInfoTagName}
        isValid={helper.removeWhiteSpaceStart(infoTagName) ? false : true}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {!helper.removeWhiteSpaceStart(infoTagName) && (
        <p className="text-red-500 absolute top-full font-lato text-xs leading-5">
          Required
        </p>
      )}
    </div>
  ) : (
    <div className="text-base font-semibold leading-6 w-full truncate">
      {infoTagName}
    </div>
  )
}

export default React.memo(InfoTagNamComponent)
