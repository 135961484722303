import { gql } from '@apollo/client'

export const GET_LIST_CMS_PRODUCT = gql`
  query listCmsProduct($input: listCmsProductInput!) {
    listCmsProduct(input: $input) {
      message
      statusCode
      data {
        productId
        productName {
          en
          ja
        }
        productKey
        productType
        prices {
          byYear {
            priceId
            productPrice
            productCurrency
          }
          byMonth {
            priceId
            productPrice
            productCurrency
          }
        }
        productExtension {
          extensionKey
          limitedValue
          expiredTime
          expiredUnit
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_CURRENT_PLAN_AND_CARD_INFO = gql`
  query getCurrentPlanAndCardInfo(
    $organizationId: String!
    $isGetCardOnly: Boolean!
    $isCurrentPlanOnly: Boolean!
  ) {
    getCurrentPlanAndCardInfo(organizationId: $organizationId) {
      message
      statusCode
      data {
        servicePackage @skip(if: $isGetCardOnly) {
          productId
          productKey
          productName {
            en
            ja
          }
          billingPeriodType
          price
          nextPaymentDate
          nextPaymentPrice
          currentPaymentExpireDate
        }
        addOn @skip(if: $isGetCardOnly) {
          productId
          productName {
            en
            ja
          }
          productKey
          billingPeriodType
          price
          nextPaymentDate
          nextPaymentPrice
          currentPaymentExpireDate
          publishStorageLimit
          currentViews
          viewsLimit
        }
        cardInfo @skip(if: $isCurrentPlanOnly) {
          brand
          lastFour
        }
      }
      error {
        message
        errorCode
      }
    }
  }
`

export const GET_CMS_PRODUCT_BILLINGS = gql`
  query getCmsProductBillings($organizationId: String!, $pager: PagerInput) {
    getCmsProductBillings(organizationId: $organizationId, pager: $pager) {
      statusCode
      message
      data {
        billings {
          name {
            ja
            en
          }
          organizationStorage
          publishStorage
          views
          price
          status
          billingUrl
          createdAt
        }
      }
      error {
        message
        errorCode
      }
    }
  }
`
