import { TYPE } from '@models/common'
import { IResponseUserProfile } from '@models/profile'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { getStorageAction } from '@stores/profile/profile.action'
import { RootState } from '@stores/store'
import { openNotification } from '@utils/notification'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { actions as profileActions } from '@stores/profile/profile.reducer'
import { TYPE_PROFILE } from '@stores/profile/profile.type'

export const useStorage = () => {
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const updateCurrentStorage = useCallback(async () => {
    try {
      const res = await dispatch(getStorageAction()).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getStorage',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
        return
      }
      if (!userProfile) {
        return
      }
      const currentOrganizationStorage =
        res.data.info.currentOrganizationStorage
      const newData: IResponseUserProfile = {
        ...userProfile,
        info: {
          ...userProfile.info,
          currentOrganizationStorage,
        },
      }
      dispatch(profileActions[TYPE_PROFILE.REDUCERS.SET_USER_PROFILE](newData))
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateCurrentStorage',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [])
  return { updateCurrentStorage }
}
