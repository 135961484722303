import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  getListProjectAction,
  getListProjectStorageAction,
} from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import ImageNoProject from '@assets/images/project/no-project.svg'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import {
  IProject,
  IRequestListProject,
  TypeFilterProject,
} from '@models/project'
import ListModeComponent from '@components/dashboard/ListModeComponent'
import ThumModeComponent from '@components/dashboard/ThumModeComponent'
import MoreSettingPopup from '@components/dashboard/MoreSettingPopup'
import { PATHNAME } from '@constants/common'
import SpinComponent from '@components/common/SpinComponent'
import PopupConfirm from '@components/common/PopupConfirm'
import { helper } from '@utils/helper/common'

const DEFAULT_PER_PAGE = 24
const Dashboard: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const listInnerRef = useRef<HTMLDivElement>(null)
  const {
    listProject,
    modeViewProject,
    typeFilterListProject,
    isShowAlertExpiredTimeTrial,
    isStorageChanged,
  } = useAppSelector((state: RootState) => state.project)
  const [projectEdit, setProjectEdit] = useState<IProject | null>(null)
  const [skip, setSkip] = useState<number>(0)
  const [indexOpenSetting, setIndexOpenSetting] = useState<number>(0)
  const [isLoadingGetProject, setIsLoadingGetProject] = useState<boolean>(false)

  useEffect(() => {
    setSkip(0)
  }, [typeFilterListProject])

  useEffect(() => {
    if (isStorageChanged) {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_IS_STORAGE_CHANGED](false)
      )
    }
    getListProjectFilter()
  }, [typeFilterListProject, skip])

  useEffect(() => {
    if (!isStorageChanged) {
      return
    }
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_IS_STORAGE_CHANGED](false)
    )
    getListProjectStorageFilter()
  }, [isStorageChanged])

  useEffect(() => {
    if (
      listProject.projects.length === 0 ||
      isLoadingGetProject ||
      !listInnerRef.current
    ) {
      return
    }

    const { scrollHeight, clientHeight } = listInnerRef.current
    if (scrollHeight <= clientHeight) {
      setSkipIfMoreProjectsExist()
    }
  }, [listProject, isLoadingGetProject])

  const handleOpenMoreSettingProject = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    indexOpen: number,
    project: IProject
  ) => {
    e.preventDefault()
    e.stopPropagation()
    await setProjectEdit(project)
    setIndexOpenSetting(indexOpen)
    const popup = document.querySelector('#popupMoreSettingProject')
    if (popup) {
      const defaulHeight = 8
      const eleArrow = document.getElementById(`arrow${indexOpen}`)
      if (eleArrow) {
        const htnl = popup as HTMLElement
        const offsetArrow = eleArrow.getBoundingClientRect()
        const disTop = offsetArrow.top
        const disRight = offsetArrow.right
        popup.classList.add('!block')
        const left = disRight - htnl.clientWidth + 'px'
        const top = disTop + offsetArrow.height - defaulHeight + 'px'
        htnl.style.top = top
        htnl.style.left = left
      }

      if (e.pageY + popup.clientHeight + 20 > window.innerHeight) {
        const defaulHeight = 4
        const eleArrow = document.getElementById(`arrow${indexOpen}`)
        if (eleArrow) {
          const htnl = popup as HTMLElement
          const offsetArrow = eleArrow.getBoundingClientRect()
          const disTop = offsetArrow.top
          const disRight = offsetArrow.right
          const left = disRight - htnl.clientWidth + 'px'
          const top = disTop - defaulHeight - htnl.clientHeight + 'px'
          htnl.style.top = top
          htnl.style.left = left
        }
      }
    }
  }

  const handleGetInfoProject = async (projectId: number) => {
    navigate(`${PATHNAME.SCENE_SETTING}/${projectId}`)
  }

  const handleScrollListProject = () => {
    if (!listInnerRef.current) {
      return
    }

    const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
    if (scrollTop + clientHeight === scrollHeight) {
      setSkipIfMoreProjectsExist()
    }
  }

  const getListProjectFilter = () => {
    switch (typeFilterListProject) {
      case TypeFilterProject.RECENT:
        if (user && user.organizationId) {
          const dataReq: IRequestListProject = {
            organizationId: user.organizationId,
            pager: {
              take: DEFAULT_PER_PAGE,
              skip: skip,
              orderBy: [{ fieldName: 'lastEditedAt', sortType: 'DESC' }],
            },
          }
          getListProject(dataReq)
        }
        break
      case TypeFilterProject.SHARED:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_LIST_PROJECT]({
            projects: [],
            pager: null,
          })
        )
        break
      case TypeFilterProject.YOUR_PROJECT:
        if (user && user.organizationId) {
          const dataReq = {
            organizationId: user.organizationId,
            pager: { take: DEFAULT_PER_PAGE, skip: skip },
          }
          getListProject(dataReq)
        }
        break
      default:
        break
    }
  }

  const getListProject = async (reqGetList: IRequestListProject) => {
    setIsLoadingGetProject(true)

    try {
      const res = await dispatch(getListProjectAction(reqGetList)).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getListProject',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getListProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsLoadingGetProject(false)
    }
  }

  const getListProjectStorageFilter = () => {
    switch (typeFilterListProject) {
      case TypeFilterProject.RECENT:
        if (user && user.organizationId) {
          const dataReq: IRequestListProject = {
            organizationId: user.organizationId,
            pager: {
              take: DEFAULT_PER_PAGE,
              skip: skip,
              orderBy: [{ fieldName: 'lastEditedAt', sortType: 'DESC' }],
            },
          }
          getListProjectStorage(dataReq)
        }
        break
      case TypeFilterProject.YOUR_PROJECT:
        if (user && user.organizationId) {
          const dataReq = {
            organizationId: user.organizationId,
            pager: { take: DEFAULT_PER_PAGE, skip: skip },
          }
          getListProjectStorage(dataReq)
        }
        break
      default:
        break
    }
  }

  const getListProjectStorage = async (reqGetList: IRequestListProject) => {
    try {
      const res = await dispatch(
        getListProjectStorageAction(reqGetList)
      ).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getListProject',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getListProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleRedirectToSelectPackage = useCallback(() => {
    if (isShowAlertExpiredTimeTrial) {
      handleCloseAlertExpiredTrial()
    }
    helper.redirectToContact()
  }, [])

  const handleCloseAlertExpiredTrial = () => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](false)
    )
  }

  const setSkipIfMoreProjectsExist = () => {
    if (!listProject?.pager?.hasNext) {
      return
    }

    setSkip(skip + listProject.pager.take)
  }

  if (isLoadingGetProject && listProject?.projects.length === 0)
    return <SpinComponent />

  return (
    <React.Fragment>
      <div
        className="my-4 h-auto max-h-full overflow-y-auto w-full"
        onScroll={handleScrollListProject}
        ref={listInnerRef}
      >
        <div className="h-auto w-[80%] min-w-[60rem] max-w-[90rem] mx-auto px-6">
          {listProject && listProject.projects.length === 0 && (
            <div className="px-4">
              <div className="relative">
                <img src={ImageNoProject} alt="" />
                <div className="absolute left-14 top-1/2 -translate-y-1/2 text-white__op-600">
                  <div className="font-semibold text-xl">
                    {typeFilterListProject === TypeFilterProject.SHARED
                      ? t('dashboard.emptyProject.share.first')
                      : t('dashboard.emptyProject.your.first')}
                  </div>
                  <div className="text-base leading-7 font-lato">
                    {typeFilterListProject === TypeFilterProject.SHARED
                      ? t('dashboard.emptyProject.share.second')
                      : t('dashboard.emptyProject.your.second')}
                  </div>
                </div>
              </div>
            </div>
          )}
          {modeViewProject ? (
            <ThumModeComponent
              projectEdit={projectEdit}
              handleOpenMoreSettingProject={handleOpenMoreSettingProject}
              handleGetInfoProject={handleGetInfoProject}
            />
          ) : (
            <ListModeComponent
              projectEdit={projectEdit}
              handleOpenMoreSettingProject={handleOpenMoreSettingProject}
              handleGetInfoProject={handleGetInfoProject}
            />
          )}
        </div>
        {isLoadingGetProject && (
          <div className="relative h-64">
            <SpinComponent isFlexible />
          </div>
        )}
      </div>
      <MoreSettingPopup
        index={indexOpenSetting}
        project={projectEdit}
        handleGetInfoProject={handleGetInfoProject}
        getListProject={getListProject}
        clearProjectEdit={() => setProjectEdit(null)}
      />
      {isShowAlertExpiredTimeTrial && (
        <PopupConfirm
          title={t('popup.alertExpiredTrial.title')}
          visible={isShowAlertExpiredTimeTrial}
          messageFirst={t('popup.alertExpiredTrial.messageFirst')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertExpiredTrial}
          handleOk={handleRedirectToSelectPackage}
        />
      )}
    </React.Fragment>
  )
}

export default Dashboard
