import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
// import SelectButton from '@components/common/SelectButton'
// import Select, { IValueSelected } from '@components/common/selectInput/Select'
import Popup from '@components/common/Popup'
import Icon from '@components/common/Icon'
import { RootState } from '@stores/store'
import {
  IRequestDeleteScene,
  IRequestGetScene,
  IRequestUpdateScene,
  IResponseSceneInfo,
  IScene,
  TypeActionUndoRedo,
} from '@models/sceneSetting'
import {
  deleteSceneAction,
  getSceneAction,
  updateSceneAction,
} from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import { TYPE, TypeLanguage } from '@models/common'
import { IRequestUpdateProject, IResponseProjectInfo } from '@models/project'
import Input from '@components/common/Input'
import { helper } from '@utils/helper/common'
import SpinComponent from '@components/common/SpinComponent'
import BgmComponent from './BgmComponent'
import DescriptionTextarea from './DescriptionTextarea'
import Button from '@components/common/Button'
import ContextComponent from './ContextComponent'
import ThumbSceneComponent from './ThumbSceneComponent'
// import SceneInfoPopup from '@components/popups/SceneInfoPopup'
//icon
// import IconLoop from '@assets/icons/sceneSetting/loop.svg'
// import IconInfo from '@assets/icons/sceneSetting/info.svg'
import IconOption from '@assets/icons/sceneSetting/option.svg'
// import IconMoveNext from '@assets/icons/sceneSetting/moveNext.svg'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { useGetMapInfos } from '../../../../hooks/useGetMapInfos'

interface IPropsType {
  sceneInfo: IResponseSceneInfo
  updateProject: (
    projectId: number,
    data: IRequestUpdateProject
  ) => Promise<IResponseProjectInfo | undefined>
}
const SceneSettingComponent: React.FC<IPropsType> = (props) => {
  const { updateProject, sceneInfo } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { getMapInfos } = useGetMapInfos()
  // const optionsLoop = [
  //   {
  //     value: 'loop',
  //     label: (
  //       <>
  //         <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //           <img src={IconLoop} alt="" />
  //         </div>
  //         <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //           {t('sceneSetting.optionPlay.loop')}
  //         </p>
  //       </>
  //     ),
  //   },
  //   {
  //     value: 'nextScene',
  //     label: (
  //       <>
  //         <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //           <img src={IconMoveNext} alt="" />
  //         </div>
  //         <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //           {t('sceneSetting.optionPlay.moveNext')}
  //         </p>
  //       </>
  //     ),
  //   },
  // ]

  const {
    contextCodec,
    dataTypeEdit,
    activeView,
    dataSpaceAndScene /*isShowSceneInfoPopup*/,
  } = useAppSelector((state: RootState) => state.scene)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [dataScene, setDataScene] = useState<IResponseSceneInfo>(sceneInfo)

  // const [loop, setLoop] = useState<IValueSelected>({
  //   value: 'loop',
  //   label: (
  //     <>
  //       <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //         <img src={IconLoop} alt="" />
  //       </div>
  //       <p className="w-full truncate cursor-pointer select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //         Loop
  //       </p>
  //     </>
  //   ),
  // })
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  const [isOpenEditSceneName, setIsOpenEditSceneName] = useState<boolean>(false)
  const [sceneName, setSceneName] = useState<string>(sceneInfo.title)
  const [isDeletingScene, setIsDeletingScene] = useState<boolean>(false)
  const [isDisabledDeleteScene, setIsDisabledDeleteScene] =
    useState<boolean>(false)

  useEffect(() => {
    if (sceneInfo) {
      setDataScene(sceneInfo)
      setSceneName(sceneInfo.title)
    }
  }, [sceneInfo])

  useEffect(() => {
    if (dataTypeEdit && dataSpaceAndScene) {
      const newListSpaceAndScene = helper.removeScene(
        dataSpaceAndScene,
        (dataTypeEdit.data as IResponseSceneInfo).sceneId
      )
      const check = helper.checkHasScene(newListSpaceAndScene)
      if (!check) {
        setIsDisabledDeleteScene(true)
      } else {
        setIsDisabledDeleteScene(false)
      }
    }
    return () => setIsDisabledDeleteScene(false)
  }, [(dataTypeEdit?.data as IResponseSceneInfo).sceneId])

  // const handleOpenSceneInfoPopup = () => {
  //   dispatch(
  //     sceneActions[TYPE_SCENE.REDUCERS.SET_STATUS_SCENE_INFO_POPUP](true)
  //   )
  // }

  // const handleChangeLoop = (value: IValueSelected) => {
  //   setLoop(value)
  // }

  const handleDeleteScene = async () => {
    if (user && projectInfo && dataScene) {
      const data: IRequestDeleteScene = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataScene.sceneId,
      }
      try {
        setIsDeletingScene(true)
        const res = await dispatch(deleteSceneAction(data)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteScene',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          setIsOpenSetting(false)
          const { space, layer } = activeView
          if (space && layer && dataSpaceAndScene) {
            const newListSpaceAndScene = helper.removeScene(
              dataSpaceAndScene,
              dataScene.sceneId
            )
            const { idNewSceneActive, newLayerActive, newSpaceActive } =
              helper.deleteSceneAndUpdateActive(
                newListSpaceAndScene,
                layer,
                space,
                dataScene.sceneId
              )
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                newListSpaceAndScene
              )
            )
            if (idNewSceneActive && newLayerActive && newSpaceActive) {
              const dataGetScene: IRequestGetScene = {
                projectId: projectInfo.projectId,
                sceneId: idNewSceneActive,
                contextCodec,
              }
              const resGetInfoScene = await dispatch(
                getSceneAction(dataGetScene)
              ).unwrap()
              if (resGetInfoScene.data) {
                const scene: IScene = {
                  info: {
                    id: resGetInfoScene.data.sceneId,
                    title: resGetInfoScene.data.title,
                    thumbnailUrl: resGetInfoScene.data.thumbnailUrl,
                    imageThumbnailId: resGetInfoScene.data.imageThumbnailId,
                    videoThumbnailId: null,
                  },
                }
                const idLayer = newLayerActive.info.id
                const idSpace = newSpaceActive.info.id
                const newListLayerCo = helper.openScene(
                  newListSpaceAndScene,
                  idLayer,
                  idSpace
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayerCo
                  )
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                    ...dataTypeEdit,
                    data: resGetInfoScene.data,
                  })
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    ...activeView,
                    layer: newLayerActive,
                    space: newSpaceActive,
                    scene,
                  })
                )
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayerCo)
                const resUpdateProject = await updateProject(
                  projectInfo.projectId,
                  {
                    orderStructure: {
                      layers: orderStructuer,
                    },
                    defaultSceneSet: {
                      layerId: newLayerActive.info.id,
                      spaceId: newSpaceActive.info.id,
                      sceneId: resGetInfoScene.data.sceneId,
                    },
                  }
                )
                if (resUpdateProject) {
                  const { isRedo, isUndo } =
                    sceneSettingHelper.saveUndoRedoToStorage({
                      projectId: projectInfo.projectId,
                      dataVersion: {
                        version: resUpdateProject.version,
                        data: {
                          type: TYPE_SETTING.SCENE,
                          id: resGetInfoScene.data.sceneId,
                          action: TypeActionUndoRedo.DELETE,
                          listLayer: newListLayerCo,
                        },
                      },
                    })
                  dispatch(
                    sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                      isUndo,
                      isRedo,
                    })
                  )
                }
              }
            }
          }
          getMapInfos(projectInfo?.projectId)
        }
      } catch {
        setIsDeletingScene(false)
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteScene',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      } finally {
        setIsDeletingScene(false)
      }
    }
  }

  const handleSetEitSceneName = async () => {
    setIsOpenSetting(false)
    await setIsOpenEditSceneName(true)
    const input = document.getElementById('sceneName')
    if (input) {
      input.focus()
    }
  }

  const handleChangeSceneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSceneName(e.target.value)
  }

  const handleKeyDownSceneName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateSceneName()
    }
  }

  const handleUpdateSceneName = async () => {
    if (
      helper.removeWhiteSpaceStart(sceneName) &&
      sceneName !== dataScene.title &&
      user &&
      projectInfo
    ) {
      const data: IRequestUpdateScene = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataScene.sceneId,
        title: helper.removeWhiteSpaceStart(sceneName),
        langCode: TypeLanguage.ENLISH,
        contextCodec,
      }
      const res = await updateScene(data)
      if (res.data) {
        setIsOpenEditSceneName(false)
        getMapInfos(projectInfo?.projectId)
      }
    } else {
      setSceneName(dataScene.title)
      setIsOpenEditSceneName(false)
    }
  }

  const updateScene = useCallback(async (dataReq: IRequestUpdateScene) => {
    const res = await dispatch(updateSceneAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
    return res
  }, [])

  return (
    <div className="bg-gray-800 bg-opacity-20 h-full right-0 transform w-full overflow-y-auto overflow-x-hidden">
      {isDeletingScene ? (
        <SpinComponent />
      ) : (
        <>
          <div className="flex justify-between items-center text-white py-2 pl-4 pr-1.5">
            {isOpenEditSceneName ? (
              <div className="relative w-full">
                <Input
                  id="sceneName"
                  type="text"
                  className="!py-0 !px-1 box-border !leading-5 text-xs font-lato"
                  customClassContainer="!py-0 h-5"
                  value={sceneName}
                  onChange={handleChangeSceneName}
                  onBlur={handleUpdateSceneName}
                  handleKeyDown={handleKeyDownSceneName}
                  isValid={
                    helper.removeWhiteSpaceStart(sceneName) ? false : true
                  }
                />
                {!helper.removeWhiteSpaceStart(sceneName) && (
                  <p className="text-red-500 absolute top-full font-lato text-xs leading-5">
                    Required
                  </p>
                )}
              </div>
            ) : (
              <div className="text-base font-semibold truncate">
                {sceneName}
              </div>
            )}

            <Popup
              open={isOpenSetting}
              close={() => setIsOpenSetting(false)}
              content={
                <div className="flex flex-col font-lato">
                  <Button.MenuItem
                    className="justify-between"
                    onClick={handleSetEitSceneName}
                  >
                    <span className="text-white__op-900">
                      {t('common.rename')}
                    </span>
                    {/* <span className="text-white__op-500">⌘R</span> */}
                  </Button.MenuItem>
                  {/* <Button.MenuItem className="justify-between">
                    <span className="text-white__op-900">
                      {t('common.copy')}
                    </span>
                    <span className="text-white__op-500">⌘C</span>
                  </Button.MenuItem>
                  <Button.MenuItem className="justify-between">
                    <span className="text-white__op-900">
                      {t('common.duplicate')}
                    </span>
                    <span className="text-white__op-500">⌘D</span>
                  </Button.MenuItem> */}
                  <Button.MenuItem
                    className="justify-between group"
                    onClick={() => handleDeleteScene()}
                    disabled={isDisabledDeleteScene}
                  >
                    <span className="text-white__op-900 group-disabled:text-white__op-300">
                      {t('common.delete')}
                    </span>
                    {/* <span className="text-white__op-500 group-disabled:text-white__op-300">
                      Del
                    </span> */}
                  </Button.MenuItem>
                </div>
              }
              claassNamePopup="!w-40"
            >
              <Icon.ButtonIcon
                icon={<img src={IconOption} alt="" />}
                onClick={() => setIsOpenSetting(true)}
                customClass={classNames('focus:bg-transparent', {
                  '!bg-blue-700 !outline-transparent': isOpenSetting,
                })}
              />
            </Popup>
          </div>
          <div className="py-2">
            <div className="text-xs font-semibold leading-5 text-white__op-600 px-4 py-1.5">
              {t('sceneSetting.rightSideBar.media')}
            </div>
            <ContextComponent
              dataScene={{
                sceneId: dataScene.sceneId,
                contextName: dataScene.contextName || '',
              }}
              updateScene={updateScene}
            />
            {/* {dataScene.contextName && (
              <div className="px-2">
                <Select
                  value={loop}
                  onChange={handleChangeLoop}
                  options={optionsLoop}
                  isLabelIcon={true}
                />
              </div>
            )} */}

            <BgmComponent dataScene={dataScene} updateScene={updateScene} />
          </div>
          {dataScene.contextName && (
            <>
              <hr className="border-white__op-100 mx-4" />
              <div className="p-2">
                <div className="text-xs px-2 py-1.5 font-semibold leading-5 text-white__op-600 capitalize">
                  {t('sceneSetting.rightSideBar.scene.sceneSum')}
                </div>
                <DescriptionTextarea
                  dataScene={dataScene}
                  updateScene={updateScene}
                />
              </div>
              <hr className="border-white__op-100 mx-4" />
              {/* <div className="p-2">
                <div className="text-xs px-2 py-1.5 font-semibold leading-5 text-white__op-600">
                  {t('sceneSetting.rightSideBar.sceneInfo')}
                </div>
                <div>
                  <SelectButton
                    icon={<img src={IconInfo} alt="" />}
                    onClick={() => handleOpenSceneInfoPopup()}
                    customClassName={classNames({
                      'outline outline-2 outline-blue-500 bg-white__op-50':
                        isShowSceneInfoPopup,
                    })}
                  >
                    <span className="w-full text-left text-white__op-500 text-sm font-lato">
                      no scene info
                    </span>
                  </SelectButton>
                </div>
              </div>
              <SceneInfoPopup />
              <hr className="border-white__op-100 mx-4" /> */}
              <div className="px-4 py-2">
                <div className="text-xs py-1.5 font-semibold leading-5 text-white__op-600">
                  {t('sceneSetting.rightSideBar.thumbnail')}
                </div>
                <ThumbSceneComponent
                  dataScene={{
                    sceneId: dataScene.sceneId,
                    thumbnailUrl: dataScene.thumbnailUrl || '',
                    contextThumbnailUrl: dataScene.contextThumbnailUrl || '',
                  }}
                  updateScene={updateScene}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(SceneSettingComponent)
