export const MARKER_MESH_Y = -560
export const REF_SELECT_MEDIA = {
  CONTEXT_MEDIA_OF_SCENE: 'contextMediaOfScene',
  BGM_OF_SCENE: 'bgmScene',
  THUMB_OF_SCENE: 'thumbScene',
  THUMB_OF_SPACE: 'thumbSpace',
  MAP_OF_LAYER: 'mapLayer',
  THUMB_OF_PROJECT: 'thumbProject',
}
export const MAX_LENGTH_DES = 100
export const MAX_LENGTH_CREDIT = Number.MAX_SAFE_INTEGER
export const LIST_PROJECT_VERSION_KEY = 'list_project_ver'
export const ID_PROJECT_KEY = 'pj_id'
export const MAX_TOTAL_SAVE = 100
export const DEFAULT_WIDTH_RIGHT_SETTING = {
  PROJECT: 320,
  OTHER: 240,
}
export const MAX_SIZE_FILE = 322122547200 //300GB
export const DEFAULT_WIDTH_SIDEBAR = 202
export const KEY_QUERY_PLAYER = 'player_is_project_setting'
