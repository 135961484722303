import React, { useState } from 'react'
import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { DRAG_POSITION, IResponseMapInfos } from '@models/map'
import Input from '@components/common/Input'
import { helper } from '@utils/helper/common'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { MAX_LENGTH_NAME } from '@constants/map'

interface IPropsType {
  index: number
  map: IResponseMapInfos
  isEditing: boolean
  isActive: boolean
  dragPosition: DRAG_POSITION | null
  handleClickThumb: (index: number) => void
  handleMouseMoveThumb: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isSorting: boolean
  ) => void
  handleRightCLick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
  handleUpdateName: (mapName: string, map: IResponseMapInfos) => void
  closeUpdateName: () => void
}

const MapThumbnail: React.FC<IPropsType> = (props) => {
  const {
    index,
    map,
    isEditing,
    isActive,
    dragPosition,
    handleClickThumb,
    handleMouseMoveThumb,
    handleRightCLick,
    handleUpdateName,
    closeUpdateName,
  } = props
  const { t } = useTranslation()
  const { isSorting, setNodeRef, transform, transition, listeners } =
    useSortable({
      id: index + 1,
      animateLayoutChanges: true
        ? ({ isSorting }) => !isSorting
        : defaultAnimateLayoutChanges,
    })
  const [mapName, setMapName] = useState<string>(map.name)
  const [isOver, setIsOver] = useState(false)
  const refOldMapName = React.useRef<string>(map.name)
  const refIsMouseDown = React.useRef<boolean>(false)

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMapName(event.target.value.substring(0, MAX_LENGTH_NAME))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleChackName()
  }

  const handleChackName = () => {
    closeUpdateName()
    if (refOldMapName.current === mapName || mapName.length > MAX_LENGTH_NAME) {
      return
    }
    if (mapName === '') {
      setMapName(refOldMapName.current)
      return
    }
    handleUpdateName(mapName, map)
    refOldMapName.current = mapName
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button !== 0) {
      return
    }
    refIsMouseDown.current = true
  }

  const handleMouseUp = (event: React.MouseEvent) => {
    if (refIsMouseDown.current && event.button === 0) {
      handleClickThumb(index)
    }
    refIsMouseDown.current = false
  }

  const handleMouseOver = () => {
    if (!isSorting) {
      return
    }
    setIsOver(true)
  }

  const handleMouseOut = () => {
    if (!isOver) {
      return
    }
    setIsOver(false)
  }

  return (
    <div
      className={classNames('modal-map__thumb', {
        'modal-map__thumb--linetop':
          isOver && dragPosition === DRAG_POSITION.TOP,
        'modal-map__thumb--linebottom':
          isOver && dragPosition === DRAG_POSITION.BOTTOM,
        'modal-map__thumb--active': isActive,
        'modal-map__thumb--sorting': isSorting,
      })}
      ref={setNodeRef}
      {...listeners}
      style={{
        transform: !isSorting ? CSS.Transform.toString(transform) : undefined,
        transition,
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={() => {
        refIsMouseDown.current = false
      }}
      onMouseMove={(event) => {
        handleMouseMoveThumb(event, isSorting)
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onContextMenu={handleRightCLick}
    >
      <img
        draggable={false}
        src={map.thumbnailObjectKey}
        alt={mapName}
        className="modal-map__thumb__image"
      />
      {isEditing ? (
        <div className="w-full">
          <form onSubmit={handleSubmit}>
            <Input
              className={classNames(
                '!py-0 !pl-2 !pr-3 box-border !leading-5 !text-xs',
                {
                  '!outline-red-500': mapName.length === MAX_LENGTH_NAME,
                }
              )}
              customClassContainer="!py-0 !leading-4"
              color="transparent"
              type="text"
              value={mapName}
              onChange={handleChangeName}
              onBlur={handleChackName}
              autoFocus
              isValid={helper.removeWhiteSpaceStart(mapName) ? false : true}
            />
            {mapName.length === MAX_LENGTH_NAME && (
              <p className="pl-2 pt-2 text-red-500 font-lato text-xs leading-5">
                {t('validate.tooLong', {
                  name: t('map.name'),
                  length: MAX_LENGTH_NAME,
                })}
              </p>
            )}
          </form>
        </div>
      ) : (
        <p className="modal-map__thumb__text">{mapName}</p>
      )}
    </div>
  )
}

export default MapThumbnail
