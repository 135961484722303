import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import SelectButton from '@components/common/SelectButton'
import { actions, TYPE_SETTING } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import IconLocation from '@assets/icons/sceneSetting/location.svg'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { IMarkerResponse, IRequestSetMarker } from '@models/sceneSetting'
import { IErrorResponse } from '@models/common'

interface IPropsType {
  handleSetMarker: (req: IRequestSetMarker) => Promise<{
    data: Array<IMarkerResponse> | null
    statusCode: string
    message: string
    error: IErrorResponse | null
  }>
  markerLink: number | null
  markerName: string | null
}
const SelectLinkScene: React.FC<IPropsType> = (props) => {
  const { handleSetMarker, markerLink, markerName } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { idSceneSelect, dataTypeEdit, dataScenePreview } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [isUpdateLink, setIsUpdateLink] = useState<boolean>(false)

  useEffect(() => {
    if (
      idSceneSelect &&
      isUpdateLink &&
      projectInfo &&
      user &&
      dataScenePreview &&
      dataTypeEdit.data
    ) {
      const { id } = dataTypeEdit.data as IMarkerResponse
      const req: IRequestSetMarker = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        marker: {
          id,
          linkedSceneId: idSceneSelect,
        },
      }
      handleSetMarker(req)
        .then((res) => {
          if (res.data) {
            const newMarker = _.find(res.data, {
              id: (dataTypeEdit.data as IMarkerResponse).id,
            })
            const newDataEdit = {
              isTypeEdit: TYPE_SETTING.MARKER,
              data: newMarker,
            }
            dispatch(
              actions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](newDataEdit)
            )
          }
        })
        .finally(() => {
          setIsUpdateLink(false)
          dispatch(actions[TYPE_SCENE.REDUCERS.SET_ID_SCENE_SELECT](null))
          dispatch(
            actions[TYPE_SCENE.REDUCERS.SET_STATUS_LIST_SCENE_POPUP](false)
          )
        })
    }
  }, [idSceneSelect, isUpdateLink])
  const handleOpenListScene = () => {
    dispatch(actions[TYPE_SCENE.REDUCERS.SET_STATUS_LIST_SCENE_POPUP](true))
    setIsUpdateLink(true)
  }
  return (
    <div className="mt-1.5">
      <SelectButton
        icon={<img src={IconLocation} alt="" />}
        onClick={handleOpenListScene}
      >
        <span
          className={classNames(
            'w-full text-left text-sm leading-6 font-lato truncate',
            {
              'text-white__op-900': markerLink,
              'text-white__op-500': !markerLink,
            }
          )}
        >
          {markerLink
            ? markerName
            : t('sceneSetting.rightSideBar.marker.selectScene')}
        </span>
      </SelectButton>
    </div>
  )
}

export default SelectLinkScene
