import React from 'react'
import IconArrowBottom from '../../../assets/icons/sceneSetting/arrowBt2.svg'
import IconArrowBottomDis from '../../../assets/icons/common/arrow-down-dis-icon.svg'

interface Props {
  className?: string
}

export const CloseIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export const ChevronIcon: React.FC<Props> = ({ className = '' }) => {
  return <img src={IconArrowBottom} className={className} alt="" />
}

export const ChevronIconDis: React.FC<Props> = ({ className = '' }) => {
  return <img src={IconArrowBottomDis} className={className} alt="" />
}

export const SearchIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      className={className}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  )
}
