import React from 'react'
import classNames from 'classnames'
import _ from 'lodash'
import Button from '@components/common/Button'
import { useTranslation } from 'react-i18next'
import { TYPE } from '@models/common'
import { IRequestUpdateProject } from '@models/project'
import {
  ILayer,
  IRequestDeleteScene,
  IRequestDeleteSpace,
  IRequestGetScene,
  IScene,
  ISpace,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { updateProjectAction } from '@stores/project/project.action'
import {
  deleteSceneAction,
  deleteSpaceAction,
  getSceneAction,
} from '@stores/scene/scene.action'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { RootState } from '@stores/store'
import { helper } from '@utils/helper/common'
import { openNotification } from '@utils/notification'
import ContextMenu from '../../ContextMenu'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'

interface IPropsType {
  close: () => void
  handleOpenUpdateName: () => void
}
const SpaceSceneContextMenu: React.FC<IPropsType> = (props) => {
  const { close, handleOpenUpdateName } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    dataTypeEdit,
    dataSpaceAndScene,
    activeView,
    isOpenContextMenuLSS,
    positionContextMenuLSS,
    isDisableDeleteLSS,
    dataEditContextMenuLSS,
    contextCodec,
  } = useAppSelector((state: RootState) => state.scene)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)

  const handleDelete = async () => {
    switch (dataEditContextMenuLSS?.type) {
      case TYPE_SETTING.LAYER:
        await handleDeleteLayer()
        break
      case TYPE_SETTING.SPACE:
        await handleDeleteSpace()
        break
      case TYPE_SETTING.SCENE:
        await handleDeleteScene()
        break

      default:
        throw new Error('Item delete invalid')
    }
  }

  const handleDeleteLayer = async () => {
    if (
      user &&
      projectInfo &&
      dataTypeEdit &&
      dataTypeEdit.isTypeEdit === TYPE_SETTING.LAYER
    ) {
      const layerDelete = dataTypeEdit.data as ILayer
      const data: IRequestDeleteSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        spaceId: layerDelete.info.id,
      }
      try {
        const res = await dispatch(deleteSpaceAction(data)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteLayer',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          close()
          if (dataSpaceAndScene) {
            const { layerId, spaceId, sceneId } = projectInfo.defaultSceneSet
            let newIdSceneActive: number | null = null
            let newLayerActive: ILayer | null = null
            let newSpaceActive: ISpace | null = null
            const newListData = helper.removeLayer(
              dataSpaceAndScene,
              layerDelete.info.id
            )
            if (layerDelete.info.id === layerId) {
              const { dataNewLayer, dataNewScene, dataNewSpace } =
                helper.deleteSceneAndUpdateAnotherLayer(
                  dataSpaceAndScene,
                  layerDelete
                )
              newIdSceneActive = dataNewScene
                ? (dataNewScene as IScene).info.id
                : null
              newLayerActive = dataNewLayer
              newSpaceActive = dataNewSpace
            } else {
              const layerActive = _.find(
                newListData,
                (layer) => layer.info.id === layerId
              )
              const spaceActive = _.find(
                layerActive?.spaces,
                (space) => space.info.id === spaceId
              )
              if (layerActive && spaceActive && sceneId) {
                newIdSceneActive = sceneId
                newLayerActive = layerActive
                newSpaceActive = spaceActive
              }
            }
            if (newIdSceneActive && newLayerActive && newSpaceActive) {
              const dataGetScene: IRequestGetScene = {
                projectId: projectInfo.projectId,
                sceneId: newIdSceneActive,
                contextCodec,
              }
              const resGetInfoScene = await dispatch(
                getSceneAction(dataGetScene)
              ).unwrap()
              if (resGetInfoScene.data) {
                const scene: IScene = {
                  info: {
                    id: resGetInfoScene.data.sceneId,
                    title: resGetInfoScene.data.title,
                    thumbnailUrl: resGetInfoScene.data.thumbnailUrl,
                    imageThumbnailId: resGetInfoScene.data.imageThumbnailId,
                    videoThumbnailId: null,
                  },
                }
                const idLayer = newLayerActive.info.id
                const idSpace = newSpaceActive.info.id
                const newListLayerCo = helper.openScene(
                  newListData,
                  idLayer,
                  idSpace
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayerCo
                  )
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                    isTypeEdit: TYPE_SETTING.SCENE,
                    data: resGetInfoScene.data,
                  })
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                    layer: newLayerActive,
                    space: newSpaceActive,
                    scene,
                  })
                )
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayerCo)
                const resUpdateProject = await updateProject(
                  projectInfo.projectId,
                  {
                    orderStructure: {
                      layers: orderStructuer,
                    },
                    defaultSceneSet: {
                      layerId: newLayerActive.info.id,
                      spaceId: newSpaceActive.info.id,
                      sceneId: resGetInfoScene.data.sceneId,
                    },
                  }
                )
                if (resUpdateProject) {
                  const { isRedo, isUndo } =
                    sceneSettingHelper.saveUndoRedoToStorage({
                      projectId: projectInfo.projectId,
                      dataVersion: {
                        version: resUpdateProject.version,
                        data: {
                          type: TYPE_SETTING.LAYER,
                          id: resGetInfoScene.data.sceneId,
                          action: TypeActionUndoRedo.DELETE,
                          listLayer: newListLayerCo,
                        },
                      },
                    })
                  dispatch(
                    sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                      isUndo,
                      isRedo,
                    })
                  )
                }
              }
            }
          }
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteLayer',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleDeleteSpace = async () => {
    if (
      user &&
      projectInfo &&
      dataTypeEdit &&
      dataTypeEdit.isTypeEdit === TYPE_SETTING.SPACE
    ) {
      const spaceDelete = dataTypeEdit.data as ISpace
      const data: IRequestDeleteSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        spaceId: spaceDelete.info.id,
      }
      try {
        const res = await dispatch(deleteSpaceAction(data)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteSpace',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          close()
          const { layer } = activeView
          const { sceneId, spaceId, layerId } = projectInfo.defaultSceneSet
          if (layer && dataSpaceAndScene) {
            const newListData = helper.removeSpace(
              dataSpaceAndScene,
              spaceDelete.info.id
            )
            const hasScenePreview = _.find(
              spaceDelete.scenes,
              (scene) => scene.info.id === sceneId
            )
            let newIdSceneActive: number | null = null
            let newLayerActive: ILayer | null = null
            let newSpaceActive: ISpace | null = null
            if (hasScenePreview) {
              const listSpaceBeforeUpdate = layer.spaces
              const layerAfterUpdate = _.find(
                newListData,
                (layerF) => layerF.info.id === layer.info.id
              )
              if (layerAfterUpdate) {
                const { idScene, newLayer, newSpace } =
                  helper.deleteSceneAndUpdateAnotherSpace(
                    newListData,
                    listSpaceBeforeUpdate,
                    spaceDelete.info.id,
                    layerAfterUpdate
                  )
                newIdSceneActive = idScene
                newLayerActive = newLayer
                newSpaceActive = newSpace
              }
            } else {
              const layerActive = _.find(
                newListData,
                (layer) => layer.info.id === layerId
              )
              const spaceActive = _.find(
                layerActive?.spaces,
                (space) => space.info.id === spaceId
              )
              if (layerActive && spaceActive && sceneId) {
                newIdSceneActive = sceneId
                newLayerActive = layerActive
                newSpaceActive = spaceActive
              }
            }
            if (newIdSceneActive && newLayerActive && newSpaceActive) {
              const dataGetScene: IRequestGetScene = {
                projectId: projectInfo.projectId,
                sceneId: newIdSceneActive,
                contextCodec,
              }
              const resGetInfoScene = await dispatch(
                getSceneAction(dataGetScene)
              ).unwrap()
              if (resGetInfoScene.data) {
                const scene: IScene = {
                  info: {
                    id: resGetInfoScene.data.sceneId,
                    title: resGetInfoScene.data.title,
                    thumbnailUrl: resGetInfoScene.data.thumbnailUrl,
                    imageThumbnailId: resGetInfoScene.data.imageThumbnailId,
                    videoThumbnailId: null,
                  },
                }
                const idLayer = newLayerActive.info.id
                const idSpace = newSpaceActive.info.id
                const newListLayerCo = helper.openScene(
                  newListData,
                  idLayer,
                  idSpace
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayerCo
                  )
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                    isTypeEdit: TYPE_SETTING.SCENE,
                    data: resGetInfoScene.data,
                  })
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                    layer: newLayerActive,
                    space: newSpaceActive,
                    scene,
                  })
                )
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayerCo)
                const resUpdateProject = await updateProject(
                  projectInfo.projectId,
                  {
                    orderStructure: {
                      layers: orderStructuer,
                    },
                    defaultSceneSet: {
                      layerId: newLayerActive.info.id,
                      spaceId: newSpaceActive.info.id,
                      sceneId: resGetInfoScene.data.sceneId,
                    },
                  }
                )
                if (resUpdateProject) {
                  const { isRedo, isUndo } =
                    sceneSettingHelper.saveUndoRedoToStorage({
                      projectId: projectInfo.projectId,
                      dataVersion: {
                        version: resUpdateProject.version,
                        data: {
                          type: TYPE_SETTING.SPACE,
                          id: resGetInfoScene.data.sceneId,
                          action: TypeActionUndoRedo.DELETE,
                          listLayer: newListLayerCo,
                        },
                      },
                    })
                  dispatch(
                    sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                      isUndo,
                      isRedo,
                    })
                  )
                }
              }
            }
          }
        }
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteSpace',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleDeleteScene = async () => {
    if (
      user &&
      projectInfo &&
      dataEditContextMenuLSS &&
      dataEditContextMenuLSS.type === TYPE_SETTING.SCENE &&
      dataSpaceAndScene
    ) {
      const idSceneDelete = dataEditContextMenuLSS.data.id
      const data: IRequestDeleteScene = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: idSceneDelete,
      }
      try {
        const res = await dispatch(deleteSceneAction(data)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteScene',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          close()
          const { space, layer } = helper.getLayerSpaceFromSceneId(
            dataSpaceAndScene,
            idSceneDelete
          )
          if (space && layer) {
            const newListSpaceAndScene = helper.removeScene(
              dataSpaceAndScene,
              idSceneDelete
            )
            const { idNewSceneActive, newLayerActive, newSpaceActive } =
              helper.deleteSceneAndUpdateActive(
                newListSpaceAndScene,
                layer,
                space,
                idSceneDelete
              )
            if (idNewSceneActive && newLayerActive && newSpaceActive) {
              const dataGetScene: IRequestGetScene = {
                projectId: projectInfo.projectId,
                sceneId: idNewSceneActive,
                contextCodec,
              }
              const resGetInfoScene = await dispatch(
                getSceneAction(dataGetScene)
              ).unwrap()
              if (resGetInfoScene.data) {
                const scene: IScene = {
                  info: {
                    id: resGetInfoScene.data.sceneId,
                    title: resGetInfoScene.data.title,
                    thumbnailUrl: resGetInfoScene.data.thumbnailUrl,
                    imageThumbnailId: resGetInfoScene.data.imageThumbnailId,
                    videoThumbnailId: null,
                  },
                }
                const idLayer = newLayerActive.info.id
                const idSpace = newSpaceActive.info.id
                const newListLayerCo = helper.openScene(
                  newListSpaceAndScene,
                  idLayer,
                  idSpace
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    newListLayerCo
                  )
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                    ...dataTypeEdit,
                    data: resGetInfoScene.data,
                  })
                )
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    ...activeView,
                    layer: newLayerActive,
                    space: newSpaceActive,
                    scene,
                  })
                )
                const orderStructuer =
                  helper.getStructureSpaceAndScene(newListLayerCo)
                const resUpdateProject = await updateProject(
                  projectInfo.projectId,
                  {
                    orderStructure: {
                      layers: orderStructuer,
                    },
                    defaultSceneSet: {
                      layerId: newLayerActive.info.id,
                      spaceId: newSpaceActive.info.id,
                      sceneId: resGetInfoScene.data.sceneId,
                    },
                  }
                )
                if (resUpdateProject) {
                  const { isRedo, isUndo } =
                    sceneSettingHelper.saveUndoRedoToStorage({
                      projectId: projectInfo.projectId,
                      dataVersion: {
                        version: resUpdateProject.version,
                        data: {
                          type: TYPE_SETTING.SCENE,
                          id: resGetInfoScene.data.sceneId,
                          action: TypeActionUndoRedo.DELETE,
                          listLayer: newListLayerCo,
                        },
                      },
                    })
                  dispatch(
                    sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                      isUndo,
                      isRedo,
                    })
                  )
                }
              }
            }
          }
        }
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteScene',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data
    }
  }

  return (
    <ContextMenu
      open={isOpenContextMenuLSS}
      position={positionContextMenuLSS}
      close={close}
      options={
        <div className="flex flex-col font-lato">
          <Button.MenuItem
            className="justify-between"
            onClick={handleOpenUpdateName}
          >
            <div className="text-white__op-900 mr-3">{t('common.rename')}</div>
            {/* <div className="text-white__op-500">⌘R</div> */}
          </Button.MenuItem>
          <Button.MenuItem
            className="justify-between"
            onClick={handleDelete}
            disabled={isDisableDeleteLSS}
          >
            <div
              className={classNames('text-white__op-900 mr-3', {
                '!text-white__op-300': isDisableDeleteLSS,
              })}
            >
              {t('common.delete')}
            </div>
            {/* <div
              className={classNames('text-white__op-500', {
                '!text-white__op-300': isDisableDeleteLSS,
              })}
            >
              Del
            </div> */}
          </Button.MenuItem>
        </div>
      }
    />
  )
}

export default SpaceSceneContextMenu
