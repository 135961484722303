import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { openNotification } from '@utils/notification'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { TYPE } from '@models/common'
import {
  ILayer,
  IScene,
  ISpace,
  IRequestCreateScene,
  IRequestCreateSpace,
  TYPE_RESOURCE_OF_PROJECT,
  TypeActionUndoRedo,
} from '@models/sceneSetting'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'
import {
  createSceneAction,
  createSpaceAction,
} from '@stores/scene/scene.action'
import { IRequestUpdateProject, IResponseProjectInfo } from '@models/project'
import { helper } from '@utils/helper/common'
import Icon from '@components/common/Icon'
import Popup from '@components/common/Popup'
import Button from '@components/common/Button'
//icon
import IconSpace2 from '@assets/icons/sceneSetting/space2.svg'
import IconLocation from '@assets/icons/sceneSetting/location.svg'
import IconLocationDis from '@assets/icons/sceneSetting/location-dis.svg'
import IconFolder from '@assets/icons/sceneSetting/folder.svg'
import IconAdd from '@assets/icons/sceneSetting/add.svg'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'

interface PropTypes {
  handleUpdateProject: (
    projectId: number,
    data: IRequestUpdateProject
  ) => Promise<IResponseProjectInfo | undefined>
}

const AddSpaceOrSceneButton: React.FC<PropTypes> = ({
  handleUpdateProject,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataSpaceAndScene, activeView } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { projectInfo } = useAppSelector((state: RootState) => state.project)

  const [isOpenPopupAddSpaceOrScene, setIsOpenPopupAddSpaceOrScene] =
    useState<boolean>(false)
  const [isCanNotAddScene, setIsCanNotAddScene] = useState<boolean>(false)

  useEffect(() => {
    if (activeView.type === TYPE_RESOURCE_OF_PROJECT.LAYER) {
      setIsCanNotAddScene(true)
    } else {
      setIsCanNotAddScene(false)
    }
  }, [activeView])

  const handelAddSpaceOrScene = async (type: string) => {
    switch (type) {
      case TYPE_RESOURCE_OF_PROJECT.LAYER:
        if (user && projectInfo && dataSpaceAndScene) {
          const dataAddLayer: IRequestCreateSpace = {
            organizationId: user.organizationId,
            projectId: projectInfo.projectId,
            title: `Layer ${dataSpaceAndScene.length + 1}`,
          }
          const res = await handleCreateSpace(dataAddLayer)
          if (!res) {
            openNotification({
              type: TYPE.ERROR,
              key: 'createSpace',
              message: t('notification.somethingBug.titleFirst'),
              description: t('notification.somethingBug.titleSecond'),
            })
          } else {
            setIsOpenPopupAddSpaceOrScene(false)
            const idLayerAfterAdd =
              activeView.layer?.info.id || projectInfo?.defaultSceneSet.layerId
            const indexAdd =
              dataSpaceAndScene.findIndex(
                (layer) => layer.info.id === idLayerAfterAdd
              ) + 1
            const newLayerSelect: ILayer = {
              info: {
                id: res.spaceId,
                title: res.title,
              },
              spaces: [],
              collapsed: true,
            }
            const listLayerClone = _.cloneDeep(dataSpaceAndScene)
            listLayerClone.splice(indexAdd, 0, newLayerSelect)
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                listLayerClone
              )
            )
            const orderStructuer =
              helper.getStructureSpaceAndScene(listLayerClone)
            const resUpdateProject = await handleUpdateProject(
              projectInfo.projectId,
              {
                orderStructure: {
                  layers: orderStructuer,
                },
              }
            )
            if (resUpdateProject) {
              const { isRedo, isUndo } =
                sceneSettingHelper.saveUndoRedoToStorage({
                  projectId: projectInfo.projectId,
                  dataVersion: {
                    version: resUpdateProject.version,
                    data: {
                      type: TYPE_SETTING.LAYER,
                      id: res.spaceId,
                      action: TypeActionUndoRedo.CREATE,
                      listLayer: listLayerClone,
                    },
                  },
                })
              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                  isUndo,
                  isRedo,
                })
              )
            }
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                type: TYPE_RESOURCE_OF_PROJECT.LAYER,
                layer: newLayerSelect,
              })
            )
            const data = {
              isTypeEdit: TYPE_SETTING.LAYER,
              data: newLayerSelect,
            }
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](data)
            )
            setIsCanNotAddScene(true)
          }
        }

        break
      case TYPE_RESOURCE_OF_PROJECT.SPACE:
        let dataAddSpace: IRequestCreateSpace | null = null
        if (activeView.type && activeView.layer && user && projectInfo) {
          dataAddSpace = {
            organizationId: user.organizationId,
            projectId: projectInfo.projectId,
            title: `Space ${activeView.layer.spaces.length + 1}`,
            layerId: activeView.layer.info.id,
          }
        } else {
          //need update***
          const layer = _.find(
            dataSpaceAndScene,
            (layerChild) =>
              layerChild.info.id === projectInfo?.defaultSceneSet.layerId
          )
          if (user && projectInfo && layer) {
            dataAddSpace = {
              organizationId: user.organizationId,
              projectId: projectInfo.projectId,
              title: `Space ${layer.spaces.length + 1}`,
              layerId: layer.info.id,
            }
          }
        }
        if (dataAddSpace && dataSpaceAndScene && projectInfo) {
          const res = await handleCreateSpace(dataAddSpace)
          if (!res) {
            openNotification({
              type: TYPE.ERROR,
              key: 'createSpace',
              message: t('notification.somethingBug.titleFirst'),
              description: t('notification.somethingBug.titleSecond'),
            })
          } else {
            setIsOpenPopupAddSpaceOrScene(false)
            const idSpace =
              activeView.space?.info.id || projectInfo?.defaultSceneSet.spaceId
            if (idSpace && projectInfo) {
              const isAddHead =
                activeView.type && !activeView.space ? true : false
              const newSpaceActive: ISpace = {
                info: {
                  id: res.spaceId,
                  title: res.title,
                  thumbnail: {
                    imageId: null,
                    url: null,
                    sceneThumbnail: {
                      url: null,
                      imageId: null,
                      videoId: null,
                    },
                  },
                },
                collapsed: true,
                scenes: [],
              }
              const newListLayerAfterAdd = helper.addSpaceToLayer(
                dataSpaceAndScene,
                newSpaceActive,
                isAddHead,
                res.layerId,
                idSpace
              )
              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                  newListLayerAfterAdd
                )
              )
              const orderStructuer =
                helper.getStructureSpaceAndScene(newListLayerAfterAdd)
              const resUpdateProject = await handleUpdateProject(
                projectInfo.projectId,
                {
                  orderStructure: {
                    layers: orderStructuer,
                  },
                }
              )
              if (resUpdateProject) {
                const { isRedo, isUndo } =
                  sceneSettingHelper.saveUndoRedoToStorage({
                    projectId: projectInfo.projectId,
                    dataVersion: {
                      version: resUpdateProject.version,
                      data: {
                        type: TYPE_SETTING.SPACE,
                        id: res.spaceId,
                        action: TypeActionUndoRedo.CREATE,
                        listLayer: newListLayerAfterAdd,
                      },
                    },
                  })
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                    isUndo,
                    isRedo,
                  })
                )
              }
              const layer = _.find(
                newListLayerAfterAdd,
                (layer) => layer.info.id === res.layerId
              )
              if (layer) {
                const newLayerActive: ILayer = {
                  info: {
                    id: res.layerId,
                    title: layer?.info.title,
                  },
                  collapsed: false,
                  spaces: layer?.spaces,
                }
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                    type: TYPE_RESOURCE_OF_PROJECT.SPACE,
                    layer: newLayerActive,
                    space: newSpaceActive,
                  })
                )
              }
              const data = {
                isTypeEdit: TYPE_SETTING.SPACE,
                data: newSpaceActive,
              }
              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](data)
              )
            }
            setIsCanNotAddScene(false)
          }
        }
        break
      case TYPE_RESOURCE_OF_PROJECT.SCENE:
        let dataAddScene: IRequestCreateScene | null = null
        if (activeView.type && activeView.space && user && projectInfo) {
          dataAddScene = {
            organizationId: user.organizationId,
            projectId: projectInfo.projectId,
            title: `Scene ${activeView.space.scenes.length + 1}`,
            spaceId: activeView.space.info.id,
          }
        } else {
          const space = _.flatMap(dataSpaceAndScene, (item) =>
            _(item.spaces).find(
              (space) => space.info.id === projectInfo?.defaultSceneSet.spaceId
            )
          )
          if (user && projectInfo && space && space[0]) {
            dataAddScene = {
              organizationId: user.organizationId,
              projectId: projectInfo.projectId,
              title: `Scene ${space[0].scenes.length + 1}`,
              spaceId: space[0].info.id,
            }
          }
        }
        if (dataAddScene && projectInfo) {
          const res = await handleCreateScene(dataAddScene)
          if (!res) {
            openNotification({
              type: TYPE.ERROR,
              key: 'createScene',
              message: t('notification.somethingBug.titleFirst'),
              description: t('notification.somethingBug.titleSecond'),
            })
          } else {
            setIsOpenPopupAddSpaceOrScene(false)
            const idLayer = activeView.layer
              ? activeView.layer.info.id
              : projectInfo?.defaultSceneSet.layerId
            const idSpace = res.spaceId
            const idScene = activeView.scene
              ? activeView.scene.info.id
              : projectInfo?.defaultSceneSet.sceneId
            if (
              idLayer &&
              idSpace &&
              idScene &&
              dataSpaceAndScene &&
              projectInfo
            ) {
              const newSceneActive: IScene = {
                info: {
                  id: res.sceneId,
                  title: res.title,
                  thumbnailUrl: null,
                  imageThumbnailId: null,
                  videoThumbnailId: null,
                },
              }
              const isAddHead =
                activeView.type && !activeView.scene ? true : false
              const newListLayer: ILayer[] = helper.addSceneToSpace(
                dataSpaceAndScene,
                newSceneActive,
                isAddHead,
                idLayer,
                idSpace,
                idScene
              )
              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                  newListLayer
                )
              )
              const orderStructuer =
                helper.getStructureSpaceAndScene(newListLayer)
              const resUpdateProject = await handleUpdateProject(
                projectInfo.projectId,
                {
                  orderStructure: {
                    layers: orderStructuer,
                  },
                  defaultSceneSet: {
                    layerId: idLayer,
                    spaceId: idSpace,
                    sceneId: res.sceneId,
                  },
                }
              )
              if (resUpdateProject) {
                const { isRedo, isUndo } =
                  sceneSettingHelper.saveUndoRedoToStorage({
                    projectId: projectInfo.projectId,
                    dataVersion: {
                      version: resUpdateProject.version,
                      data: {
                        type: TYPE_SETTING.SCENE,
                        id: res.sceneId,
                        action: TypeActionUndoRedo.CREATE,
                        listLayer: newListLayer,
                      },
                    },
                  })
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                    isUndo,
                    isRedo,
                  })
                )
              }
              const newLayerActive = _.find(
                newListLayer,
                (layer) => layer.info.id === idLayer
              )
              const newSpaceActive = _.find(
                newLayerActive?.spaces,
                (space) => space.info.id === idSpace
              )

              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                  type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                  space: newSpaceActive || activeView.space,
                  scene: newSceneActive,
                  layer: newLayerActive || activeView.layer,
                })
              )
              const data = {
                isTypeEdit: TYPE_SETTING.SCENE,
                data: res,
              }
              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](data)
              )
              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_SCENE_PREVIEW](res)
              )
              handleOpenAsset()
              setIsCanNotAddScene(false)
            }
          }
        }
        break
      default:
        break
    }
  }

  const handleCreateSpace = async (data: IRequestCreateSpace) => {
    const res = await dispatch(createSpaceAction(data)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'createSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data
    }
  }

  const handleCreateScene = async (data: IRequestCreateScene) => {
    const res = await dispatch(createSceneAction(data)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'createScene',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data
    }
  }

  const handleOpenAsset = () => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
        MODE_OF_MEDIA_LIB_POPUP.FILE_3D
      )
    )
  }

  return (
    <Popup
      open={isOpenPopupAddSpaceOrScene}
      close={() => setIsOpenPopupAddSpaceOrScene(false)}
      claassNamePopup="max-w-[200px]"
      content={
        <div className="flex flex-col font-lato">
          <Button.MenuItem
            icon={
              <img
                src={isCanNotAddScene ? IconLocationDis : IconLocation}
                alt=""
              />
            }
            title={t('common.scene')}
            onClick={() => {
              handelAddSpaceOrScene(TYPE_RESOURCE_OF_PROJECT.SCENE)
            }}
            disabled={isCanNotAddScene}
          />
          <Button.MenuItem
            icon={<img src={IconSpace2} alt="" />}
            title={t('common.space')}
            onClick={() => {
              handelAddSpaceOrScene(TYPE_RESOURCE_OF_PROJECT.SPACE)
            }}
          />
          <hr className="my-2 border-white__op-50" />
          <Button.MenuItem
            icon={<img src={IconFolder} alt="" />}
            title={t('common.layer')}
            onClick={() => {
              handelAddSpaceOrScene(TYPE_RESOURCE_OF_PROJECT.LAYER)
            }}
          />
        </div>
      }
    >
      <Icon.ButtonIcon
        icon={<img src={IconAdd} alt="" />}
        onClick={() => setIsOpenPopupAddSpaceOrScene(true)}
      />
    </Popup>
  )
}

export default AddSpaceOrSceneButton
