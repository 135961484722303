import {
  createPaymentAction,
  createProfileAction,
  forgotPasswordAction,
  getSignedUrlMediaAction,
  loginAction,
  loginWithGoogleAction,
  logoutAction,
  refreshTokenAction,
  resetPasswordAction,
  selectPackageAction,
  sendEmailConfirmRegistertAction,
  sendEmailRegisterSuccessAction,
  updateProfileAction,
} from './auth.action'
import { createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../models/auth'

import { TYPES_AUTH } from './auth.type'
import {
  getTokenCookie,
  getUserCookie,
  removeAllTokenCookie,
  setTokenCookie,
  setUserCookie,
} from '../../utils/cookies'
import { AUTHEN } from '@constants/auth'

export interface AuthState {
  accessToken: string | null
  refreshToken: string | null
  expired: number | null
  isLoading: boolean
  user: IUser | null
  emailAutofill: string | null
  features: {
    id: string
    key: string
  }[]
  partnerParam: string | null
  emailNotificationParam: string | null
}

const initialState: AuthState = {
  accessToken: getTokenCookie(AUTHEN.ACCESSTOKEN),
  refreshToken: getTokenCookie(AUTHEN.REFRESHTOKEN),
  isLoading: false,
  user: getUserCookie(),
  expired: null,
  emailAutofill: null,
  features: [],
  partnerParam: null,
  emailNotificationParam: null,
}

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    [TYPES_AUTH.REDUCERS.SET_ACCESS_TOKEN]: (state, action) => {
      state.accessToken = action.payload
      setTokenCookie(AUTHEN.ACCESSTOKEN, action.payload)
    },
    [TYPES_AUTH.REDUCERS.SET_REFRESH_TOKEN]: (state, action) => {
      state.refreshToken = action.payload
      setTokenCookie(AUTHEN.REFRESHTOKEN, action.payload)
    },
    [TYPES_AUTH.REDUCERS.SET_USER]: (state, action) => {
      state.user = action.payload
    },
    [TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]: () => {
      removeAllTokenCookie()
    },
    [TYPES_AUTH.REDUCERS.SET_EMAIL_AUTOFILL]: (state, action) => {
      state.emailAutofill = action.payload
    },
    [TYPES_AUTH.REDUCERS.SET_PARTNER_PARAM]: (state, action) => {
      state.partnerParam = action.payload
    },
    [TYPES_AUTH.REDUCERS.SET_NOTIFICATION_PARAM]: (state, action) => {
      state.emailNotificationParam = action.payload
    },
  },
  extraReducers: (builder) => {
    //login
    builder.addCase(loginAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(loginAction.fulfilled, (state, { payload }) => {
      const data = payload
      if (data) {
        setTokenCookie(AUTHEN.ACCESSTOKEN, data.auth.accessToken)
        setTokenCookie(AUTHEN.REFRESHTOKEN, data.auth.refreshToken)
        setUserCookie(data.user)
        return {
          ...state,
          isLoading: false,
          accessToken: data.auth.accessToken,
          refreshToken: data.auth.refreshToken,
          expired: data.auth.expired,
          user: data.user,
          features: data.userPlan.plan.features,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(loginAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //login with google
    builder.addCase(loginWithGoogleAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(loginWithGoogleAction.fulfilled, (state, { payload }) => {
      const data = payload
      if (data) {
        setTokenCookie(AUTHEN.ACCESSTOKEN, data.auth.accessToken)
        setTokenCookie(AUTHEN.REFRESHTOKEN, data.auth.refreshToken)
        setUserCookie(data.user)
        return {
          ...state,
          isLoading: false,
          accessToken: data.auth.accessToken,
          refreshToken: data.auth.refreshToken,
          expired: data.auth.expired,
          user: data.user,
          features: data.userPlan.plan.features,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(loginWithGoogleAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //create profile
    builder.addCase(createProfileAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(createProfileAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data && data.auth) {
        setTokenCookie(AUTHEN.ACCESSTOKEN, data.auth.accessToken)
        setTokenCookie(AUTHEN.REFRESHTOKEN, data.auth.refreshToken)
        return {
          ...state,
          isLoading: false,
          accessToken: data.auth.accessToken,
          refreshToken: data.auth.refreshToken,
          expired: data.auth.expired,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(createProfileAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getSignedUrlMediaAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getSignedUrlMediaAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getSignedUrlMediaAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateProfileAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(updateProfileAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        setTokenCookie(AUTHEN.ACCESSTOKEN, data.info.accessToken)
        setTokenCookie(AUTHEN.REFRESHTOKEN, data.info.refreshToken)
        return {
          ...state,
          isLoading: false,
          accessToken: data.info.accessToken,
          refreshToken: data.info.refreshToken,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateProfileAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(selectPackageAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(selectPackageAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(selectPackageAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(createPaymentAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(createPaymentAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(createPaymentAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(sendEmailConfirmRegistertAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(sendEmailConfirmRegistertAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(sendEmailConfirmRegistertAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(sendEmailRegisterSuccessAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(sendEmailRegisterSuccessAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(sendEmailRegisterSuccessAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(forgotPasswordAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(forgotPasswordAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(forgotPasswordAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(resetPasswordAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(resetPasswordAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(resetPasswordAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(refreshTokenAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(refreshTokenAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (!data) {
        removeAllTokenCookie()
        return {
          ...state,
          isLoading: false,
          accessToken: null,
          refreshToken: null,
        }
      }
      setTokenCookie(AUTHEN.ACCESSTOKEN, data.accessToken)
      setTokenCookie(AUTHEN.REFRESHTOKEN, data.refreshToken)
      return {
        ...state,
        isLoading: false,
        accessToken: data && data.accessToken,
        refreshToken: data && data.refreshToken,
      }
    })
    builder.addCase(refreshTokenAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(logoutAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(logoutAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
        user: null,
      }
    })
    builder.addCase(logoutAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
  },
})

export const { actions } = authReducer

export default authReducer.reducer
