import { IResponseMapInfos } from '@models/map'
import { memo, useCallback, useRef } from 'react'

interface IPropsType {
  map: IResponseMapInfos
}

const MapImage: React.FC<IPropsType> = memo((props) => {
  const { map } = props
  const refImage = useRef<HTMLImageElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)

  const handleAdjustSize = useCallback(() => {
    if (!refImage.current || !refWrapper.current) return

    const { width, height } = refImage.current
    const { width: wrapperWidth, height: wrapperHeight } =
      refWrapper.current.getBoundingClientRect()
    const imageAspect = width / height
    const wrapperAspect = wrapperWidth / wrapperHeight

    if (imageAspect > wrapperAspect) {
      refImage.current.style.width = '100%'
      refImage.current.style.height = 'auto'
    } else {
      refImage.current.style.width = 'auto'
      refImage.current.style.height = '100%'
    }
  }, [])

  return (
    <div ref={refWrapper} className="modal-map__primary__inner">
      <img
        ref={refImage}
        src={map.originObjectKey}
        alt={map.name}
        className="modal-map__primary__image"
        onLoad={handleAdjustSize}
      />
    </div>
  )
})
export default MapImage
