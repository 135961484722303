import React, { useEffect, useState } from 'react'
import IconMarkerMesh from '@assets/icons/sceneSetting/rightSidebar/marker/marker-mesh.svg'
import Icon from '@components/common/Icon'
import { ICoordinates, IRequestSetMarker } from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'

interface IPropsType {
  id: number
  coordinates: ICoordinates
  handleSetMarker: (req: IRequestSetMarker) => Promise<unknown>
  dataMarkerMesh: number
}

const MarkerMeshRange: React.FC<IPropsType> = (props) => {
  const dispatch = useAppDispatch()
  const { handleSetMarker, coordinates, dataMarkerMesh, id } = props
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [markerMesh, setMarkerMesh] = useState<number>(dataMarkerMesh)

  useEffect(() => {
    setPercenRangeMarkerMesh(markerMesh)
  }, [markerMesh, dataMarkerMesh])

  useEffect(() => {
    setMarkerMesh(dataMarkerMesh)
  }, [dataMarkerMesh])

  const getWithRange = (num: number) => {
    let newNum = num
    if (num < 0) {
      newNum = 1000 - Math.abs(num)
    } else {
      newNum = 1000 + num
    }
    const percent = Math.round((newNum / 2000) * 100)
    return `${percent}%`
  }

  const setPercenRangeMarkerMesh = (markerMesh: number) => {
    const range = document.getElementById('marker-mesh-range')
    range?.style.setProperty('--markerMesh', getWithRange(markerMesh))
  }

  const handleChangeMarkerMesh = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value)
    const { xAxis, zAxis } = coordinates
    setMarkerMesh(num)

    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_MARKER_COORDINATE]({
        id,
        coordinates: {
          xAxis,
          yAxis: num,
          zAxis,
        },
      })
    )
  }

  const handleUpdateMarkerName = async () => {
    if (projectInfo && user && dataScenePreview && markerMesh) {
      const { xAxis, zAxis } = coordinates
      const req: IRequestSetMarker = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        marker: {
          id,
          coordinates: {
            xAxis,
            yAxis: markerMesh,
            zAxis,
          },
          markerMesh,
        },
      }
      await handleSetMarker(req)
    } else {
      throw new Error('Invalid when set marker mesh')
    }
  }

  return (
    <div className="w-full px-2 flex items-center justify-center py-2.5">
      <Icon.ActionIcon
        icon={<img src={IconMarkerMesh} alt="" />}
        customClass="mr-2.5"
      />
      <input
        id="marker-mesh-range"
        type="range"
        value={markerMesh}
        min={-1000}
        max={1000}
        onChange={handleChangeMarkerMesh}
        onMouseUp={handleUpdateMarkerName}
        className="relative w-full h-[2px] bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 marker-mesh-ranger"
      />
    </div>
  )
}

export default React.memo(MarkerMeshRange)
