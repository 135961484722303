export const enum DRAG_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export const UPLOADING = {
  TRUE: true,
  FALSE: false,
} as const

export const LOADING = {
  TRUE: true,
  FALSE: false,
} as const

export interface IResponseMapInfos {
  mapId: number
  name: string
  originObjectKey: string
  thumbnailObjectKey: string
  metadata: string
  size: number
  displayNumber: number
}

export interface IRequestCreateMapInfo {
  objectKey: string
  name: string
  projectId: number
  userId: number
}

export interface IRequestUpdateMapInfo {
  mapId: number
  name: string
  displayNumber?: number
}

export interface IRequestDeleteMap {
  mapId: number
}
