export const API_TYPE_PROJECT = {
  MUTATION: {
    CREATE_PROJECT: 'createProject',
    UPDATE_PROJECT: 'updateProject',
    DELETE_PROJECT: 'deleteProject',
    UPLOAD_MEDIA: 'createMediaFile',
    DELETE_MEDIA: 'deleteResource',
    UPDATE_MEDIA: 'updateResource',
    GET_MEDIA_FOLDERS: 'getMediaFolders',
    CREATE_MEDIA_FOLDER: 'createMediaFolder',
    DELETE_MEDIA_FOLDER: 'deleteMediaFolder',
    UPDATE_MEDIA_FOLDER: 'updateMediaFolder',
    UPDATE_PROJECT_PUBLISH: 'updateProjectPublish',
  },
  QUERY: {
    GET_LIST_PROJECT: 'getListProject',
    GET_INFO_PROJECT: 'getProjectProfile',
    GET_MEDIA_LIST: 'getResources',
    GET_PROJECT_PUBLISH: 'getProjectPublish',
    AVAILABLE_STREAM: 'availableStream',
  },
}
