import { useEffect, useState } from 'react'
import { IOrganizationSubscription } from '../models/organization'
import { ORGANIZATION_SUBSCRIPTION } from '../graphql/organization/subscription'
import { graphqlApi } from '../api/graphql'
import { API_TYPE_ORGANIZATION } from '../graphql/organization/type'
import { useAppSelector } from '../store/hook'
import { RootState } from '../store/store'

const useOrganizationSubscription = () => {
  const [subscriptionData, setSubscriptionData] =
    useState<IOrganizationSubscription>()
  const [retrySubscription, setRetrySubscription] = useState(true)
  const userAuth = useAppSelector((state: RootState) => state.auth.user)

  useEffect(() => {
    const subscription = graphqlApi.subscriptionRequest({
      query: ORGANIZATION_SUBSCRIPTION,
      variables: {
        input: {
          organizationId: userAuth?.organizationId,
        },
      },
      resQuery: API_TYPE_ORGANIZATION.SUBSCRIPTION.ORGANIZATION_SUBSCRIPTION,
      onNext: (data) => {
        setSubscriptionData(data)
      },
      onError: (error) => {
        if (error && subscription.closed) {
          setTimeout(() => {
            // retry subscription connection after 1s
            setRetrySubscription(!retrySubscription)
          }, 1000)
        }
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [retrySubscription, userAuth?.organizationId])

  return subscriptionData
}

export default useOrganizationSubscription
