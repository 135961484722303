import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import SelectButton from '@components/common/SelectButton'
import { actions as projectActions } from '@stores/project/project.reducer'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'
import { RootState } from '@stores/store'
import { IRequestUpdateScene, IResponseSceneInfo } from '@models/sceneSetting'
import { UNSELECT_MEDIA } from '@models/project'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
//icon
import IconBgm from '@assets/icons/sceneSetting/bgm.svg'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import {
  DEFAULT_WIDTH_RIGHT_SETTING,
  REF_SELECT_MEDIA,
} from '@constants/sceneSetting'

interface IPropTypes {
  dataScene: IResponseSceneInfo
  updateScene: (dataReq: IRequestUpdateScene) => Promise<unknown>
}
const BgmComponent: React.FC<IPropTypes> = (props) => {
  const { t } = useTranslation()
  const { dataScene, updateScene } = props
  const dispatch = useAppDispatch()
  const { modeOfMediaLibPopup, mediaSelected, projectInfo } = useAppSelector(
    (state: RootState) => state.project
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { contextCodec } = useAppSelector((state: RootState) => state.scene)
  const [isUpdateBGM, setIsUpdateBGM] = useState<boolean>(false)

  useEffect(() => {
    if (
      mediaSelected &&
      isUpdateBGM &&
      projectInfo &&
      user &&
      modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.BGM
    ) {
      const { id } = mediaSelected
      updateScene({
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataScene.sceneId,
        bgmAudioId: id === UNSELECT_MEDIA ? null : id,
        contextCodec,
      }).finally(() => {
        setIsUpdateBGM(false)
        dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
            MODE_OF_MEDIA_LIB_POPUP.OFF
          )
        )
      })
    }
  }, [mediaSelected, isUpdateBGM])

  const handleOpenBGMAsset = (newMode: number) => {
    if (
      modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OFF ||
      modeOfMediaLibPopup !== newMode
    ) {
      const bgmElm = document.getElementById(REF_SELECT_MEDIA.BGM_OF_SCENE)
      if (bgmElm) {
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
            top: bgmElm.offsetTop,
            right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
          })
        )
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.BGM
        )
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_REF_BUTTON_SELECT_MEDIA](
          REF_SELECT_MEDIA.BGM_OF_SCENE
        )
      )
      setIsUpdateBGM(true)
    } else {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
      dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
      setIsUpdateBGM(false)
    }
  }

  return (
    <div className="px-2" id={REF_SELECT_MEDIA.BGM_OF_SCENE}>
      <SelectButton
        icon={<img src={IconBgm} alt="" />}
        onClick={() => handleOpenBGMAsset(MODE_OF_MEDIA_LIB_POPUP.BGM)}
        customClassName={classNames({
          'outline outline-2 outline-blue-500 bg-white__op-50':
            modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.BGM,
        })}
      >
        <span className="w-full text-left text-white__op-500 text-sm leading-6 truncate font-lato">
          {dataScene.bgmName ? (
            <span className="text-white__op-900">{dataScene.bgmName}</span>
          ) : (
            `${t('sceneSetting.rightSideBar.scene.bgMusic')}`
          )}
        </span>
      </SelectButton>
    </div>
  )
}

export default BgmComponent
