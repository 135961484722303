import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { IMedia, IMediaFolder, TYPE_MEDIA } from '@models/media'
import Media from './Media'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { getMediaAction } from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { actions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import IconArrowRight from '@assets/icons/sceneSetting/arrowRight.svg'

interface PropTypes {
  folder: IMediaFolder
  mediaTypes: TYPE_MEDIA[]
}

const Folder: React.FC<PropTypes> = ({ folder, mediaTypes }) => {
  const [isExtentFolder, setIsExtentFolder] = useState(false)
  const [isLoadedData, setIsloadedData] = useState(false)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo, selectedFolderInMediaLibPopup } = useAppSelector(
    (state: RootState) => state.project
  )
  const [medias, setMedias] = useState<IMedia[]>([])

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!isLoadedData && isExtentFolder) {
      getMedias()
      setIsloadedData(true)
    }
  }, [isExtentFolder])

  useEffect(() => {
    const filteredMedias = folder.children?.filter((medias) =>
      mediaTypes.includes(medias.mediaType)
    )
    setMedias(filteredMedias)
  }, [folder.children, mediaTypes])

  const getMedias = useCallback(async () => {
    try {
      const res = await dispatch(
        getMediaAction({
          organizationId: user?.organizationId,
          projectId: projectInfo?.projectId,
          folderId: folder.id,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'getMedias',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [])

  const handleExtendFolder = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation()
    setIsExtentFolder(!isExtentFolder)
  }

  const handleSelectFolder = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation()
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_MEDIA_LIB_POPUP](
        folder.id
      )
    )
  }

  return (
    <>
      <div
        className={classNames(
          'flex items-center h-10 group ease-in-out justify-between cursor-pointer',
          {
            'bg-blue-700': folder.id === selectedFolderInMediaLibPopup,
            'hover:bg-white__op-100':
              folder.id !== selectedFolderInMediaLibPopup,
          }
        )}
      >
        <button
          className="w-4 h-9 -my-2 flex-shrink-0 flex items-center justify-center"
          onMouseDown={(e) => handleExtendFolder(e)}
        >
          <img
            src={IconArrowRight}
            className={classNames(
              'hidden group-hover:!block transition-transform duration-300',
              {
                'rotate-90 h-auto': isExtentFolder,
              }
            )}
            alt=""
          />
        </button>
        <div
          className="w-full h-full flex items-center leading-5 text-xs font-semibold text-white__op-600 truncate"
          onMouseDown={(e) => handleSelectFolder(e)}
        >
          {folder.name}
        </div>
      </div>
      {isExtentFolder &&
        medias?.map((media, index) => <Media key={index} media={media} />)}
    </>
  )
}

export default Folder
