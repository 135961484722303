import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  ILayer,
  IRequestUpdateSpace,
  ISpace,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'

import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import AddSceneButton from './AddSceneButton'
import { TYPE, TypeLanguage } from '@models/common'
import { updateSpaceAction } from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import { helper } from '@utils/helper/common'
import Icon from '@components/common/Icon'
import Input from '@components/common/Input'

//icon
import IconArrowRight from '@assets/icons/sceneSetting/arrowRight.svg'
import IconSpace from '@assets/icons/sceneSetting/space.svg'

interface IPropsType {
  item: ILayer
  itemS: ISpace
  isEditing: boolean
  closeUpdateName: () => void
  onClollap: (e: React.MouseEvent<HTMLElement>) => void
}
const SpaceComponent: React.FC<IPropsType> = (props) => {
  const { t } = useTranslation()
  const { item, itemS, isEditing, closeUpdateName, onClollap } = props
  const dispatch = useAppDispatch()
  const { activeView, dataEditContextMenuLSS } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const [spaceName, setSpaceName] = useState<string>(
    dataEditContextMenuLSS?.data.title ?? ''
  )

  useEffect(() => {
    if (
      dataEditContextMenuLSS &&
      dataEditContextMenuLSS.type === TYPE_SETTING.SPACE
    ) {
      setSpaceName(dataEditContextMenuLSS.data.title)
    }
  }, [dataEditContextMenuLSS])

  const handleSetActiveSpace = async (layer: ILayer, space: ISpace) => {
    const data = {
      isTypeEdit: TYPE_SETTING.SPACE,
      data: space,
    }
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
        type: TYPE_RESOURCE_OF_PROJECT.SPACE,
        layer,
        space,
      })
    )
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](data))
  }

  const onHandleShowRightCLick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    space: ISpace
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const xPos = event.pageX + 'px'
    const yPos = event.pageY + 'px'
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_POSITION_CONTEXT_MENU_LSS]({
        xPos,
        yPos,
      })
    )
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_OPEN_CONTEXT_MENU_LSS](true))
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_EDIT_CONTEXT_MENU_LSS]({
        type: TYPE_SETTING.SPACE,
        data: {
          id: space.info.id,
          title: space.info.title,
        },
      })
    )
  }

  const handleChangeSpaceName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSpaceName(e.target.value)
  }

  const handleKeyDownSpaceName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateSpaceName()
    }
  }

  const handleUpdateSpaceName = async () => {
    if (
      helper.removeWhiteSpaceStart(spaceName) &&
      dataEditContextMenuLSS &&
      spaceName !== dataEditContextMenuLSS.data.title &&
      user &&
      projectInfo
    ) {
      const data: IRequestUpdateSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        id: dataEditContextMenuLSS.data.id,
        title: helper.removeWhiteSpaceStart(spaceName),
        langCode: TypeLanguage.ENLISH,
      }
      await updateSpace(data)
    }
    setSpaceName(dataEditContextMenuLSS?.data.title || '')
    closeUpdateName()
  }

  const updateSpace = async (dataReq: IRequestUpdateSpace) => {
    const res = await dispatch(updateSpaceAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data
    }
  }

  const genColor = () => {
    if (projectInfo && projectInfo.defaultSceneSet.spaceId === itemS.info.id) {
      return 'bg-blue-800'
    } else {
      if (
        activeView.type === TYPE_RESOURCE_OF_PROJECT.SPACE &&
        activeView.space?.info.id === itemS.info.id
      ) {
        return 'bg-blue-700'
      }
      if (
        activeView.type !== TYPE_RESOURCE_OF_PROJECT.SPACE ||
        activeView.space?.info.id !== itemS.info.id
      ) {
        return 'hover:bg-white__op-50'
      }
    }
  }

  return (
    <div className="relative cursor-pointer">
      <div
        className={classNames(
          'py-2 pr-1 group-one text-white flex justify-between relative',
          genColor()
        )}
        id={`h${TYPE_RESOURCE_OF_PROJECT.SPACE}${itemS.info.id}`}
        onContextMenu={(event) => onHandleShowRightCLick(event, itemS)}
        onMouseUp={() => handleSetActiveSpace(item, itemS)}
        onDoubleClick={onClollap}
      >
        <div className="flex w-full items-center">
          <button
            className="w-4 h-9 -my-2 flex-shrink-0 flex items-center justify-center"
            onMouseUp={onClollap}
          >
            <img
              id={`arrow${TYPE_RESOURCE_OF_PROJECT.SPACE}${itemS.info.id}`}
              src={IconArrowRight}
              className={classNames(
                'hidden group-one-hover:!block transition-transform duration-300',
                {
                  'rotate-90': !itemS.collapsed,
                }
              )}
              alt=""
            />
          </button>

          <Icon.ActionIcon
            icon={<img src={IconSpace} alt="" />}
            customClass="w-4 h-4"
          />
          {isEditing ? (
            <div className="w-full ml-2 mr-10">
              <Input
                className="!py-0 !pl-2 !pr-3 box-border !leading-5 !text-xs"
                customClassContainer="!py-0 !h-5"
                color="transparent"
                value={spaceName}
                onChange={handleChangeSpaceName}
                onBlur={handleUpdateSpaceName}
                handleKeyDown={handleKeyDownSpaceName}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                isValid={helper.removeWhiteSpaceStart(spaceName) ? false : true}
                onDoubleClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            </div>
          ) : (
            <div className="text-white text-sm cursor-pointer w-full pl-2 font-lato mr-8 truncate">
              {itemS.info.title}
            </div>
          )}
        </div>

        <AddSceneButton item={item} itemS={itemS} />
      </div>
    </div>
  )
}

export default SpaceComponent
