import React, { useState } from 'react'
import Popup from '@components/common/Popup'
import Icon from '@components/common/Icon'
import Button from '@components/common/Button'
//icon
import IconOption from '@assets/icons/sceneSetting/option.svg'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  ICoordinates,
  IRequestGetScene,
  IRequestSetInfoTag,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { getSceneAction, removeInfoTagAction } from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'

interface IPropsType {
  dataInfoTag: {
    coordinates: ICoordinates
    id: number
  }
  handleOpenEditInfoTagName: () => void
  handleChangeLoadingDelete: (isLoading: boolean) => void
}
const MoreSettingInfoTagButton: React.FC<IPropsType> = (props) => {
  const { dataInfoTag, handleOpenEditInfoTagName, handleChangeLoadingDelete } =
    props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview, activeView, contextCodec } = useAppSelector(
    (state: RootState) => state.scene
  )
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)

  const handleDeleteInfoTag = async () => {
    handleChangeLoadingDelete(true)
    if (projectInfo && user && dataInfoTag && dataScenePreview) {
      const { coordinates, id } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          id,
        },
      }
      try {
        const res = await dispatch(removeInfoTagAction(req)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'removeInfoTag',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          const newListInfoTag = dataScenePreview.infoTags?.filter(
            (infoTag) => infoTag.id !== id
          )
          const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
            projectId: projectInfo.projectId,
            dataVersion: {
              version: res.data.version,
              data: {
                type: TYPE_SETTING.INFO_TAG,
                id,
                action: TypeActionUndoRedo.DELETE,
                idScene: dataScenePreview.sceneId,
              },
            },
          })
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
              isUndo,
              isRedo,
            })
          )
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_SCENE_PREVIEW]({
              ...dataScenePreview,
              infoTags: newListInfoTag,
            })
          )
          handleSetDataSetting()
        }
        handleChangeLoadingDelete(false)
      } catch {
        handleChangeLoadingDelete(false)
        throw new Error('Erorr when remove marker')
      }
    }
  }

  const handleSetDataSetting = async () => {
    const { layer, space, scene } = activeView
    switch (activeView.type) {
      case TYPE_RESOURCE_OF_PROJECT.LAYER:
        if (layer) {
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
              isTypeEdit: TYPE_SETTING.LAYER,
              data: layer,
            })
          )
        }
        break
      case TYPE_RESOURCE_OF_PROJECT.SPACE:
        if (space) {
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
              isTypeEdit: TYPE_SETTING.SPACE,
              data: space,
            })
          )
        }
        break
      case TYPE_RESOURCE_OF_PROJECT.SCENE:
        if (scene && projectInfo) {
          const dataGetScene: IRequestGetScene = {
            projectId: projectInfo.projectId,
            sceneId: scene.info.id,
            contextCodec,
          }
          await handleGetScene(dataGetScene)
        }
        break

      default:
        break
    }
  }

  const handleGetScene = async (dataGetScene: IRequestGetScene) => {
    try {
      const resScene = await dispatch(getSceneAction(dataGetScene)).unwrap()
      if (resScene.data) {
        const dataTypeEdit = {
          isTypeEdit: TYPE_SETTING.SCENE,
          data: resScene.data,
        }
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](dataTypeEdit)
        )
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'getScene',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getScene',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleOpenEdit = () => {
    handleOpenEditInfoTagName()
    setIsOpenSetting(false)
  }
  return (
    <Popup
      open={isOpenSetting}
      close={() => setIsOpenSetting(false)}
      content={
        <div className="flex flex-col font-lato">
          <Button.MenuItem className="justify-between" onClick={handleOpenEdit}>
            <span className="text-white__op-900">{t('common.rename')}</span>
            {/* <span className="text-white__op-500">⌘R</span> */}
          </Button.MenuItem>
          <Button.MenuItem
            className="justify-between group"
            onClick={handleDeleteInfoTag}
          >
            <span className="text-white__op-900 group-disabled:text-white__op-300">
              {t('common.delete')}
            </span>
            {/* <span className="text-white__op-500 group-disabled:text-white__op-300">
              Del
            </span> */}
          </Button.MenuItem>
        </div>
      }
      claassNamePopup="!w-40"
    >
      <Icon.ButtonIcon
        icon={<img src={IconOption} alt="" />}
        onClick={() => setIsOpenSetting(true)}
        customClass={classNames('focus:bg-transparent', {
          '!bg-blue-700 !outline-transparent': isOpenSetting,
        })}
      />
    </Popup>
  )
}

export default React.memo(MoreSettingInfoTagButton)
