import { createSlice } from '@reduxjs/toolkit'
import { IBillingPeriodType, IUnitPriceCmsProduct } from '@models/organization'
import { TYPE_ORGANIZATION } from './organization.type'
// import { DETAIL_PLAN_KEY } from '@constants/common'
// import { getLocalCookie } from '@utils/cookies'

export interface OrganizationState {
  status: boolean
  currentServicePackage: IUnitServicePackage | null
  newServicePackage: IUnitServicePackageDetail | null
  newAddOn: IUnitAddOnDetail | null
}

export interface IUnitServicePackage {
  servicePackageId: number
  servicePackagePriceId: number
}

export interface IUnitServicePackageDetail {
  servicePackageId: number
  servicePackageName: string
  servicePackageKey: string
  price: IUnitPriceCmsProduct
  billingPeriodType: IBillingPeriodType
}

export interface IUnitAddOnDetail {
  id: number
  name: string
  keyView: string
  keySize: string
  price: IUnitPriceCmsProduct
  billingPeriodType: IBillingPeriodType
}

const initialState: OrganizationState = {
  status: false,
  currentServicePackage: null,
  newServicePackage: null,
  newAddOn: null,
}

export const organizationReducer = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    [TYPE_ORGANIZATION.REDUCERS.SET_NEW_SERVICE_PACKAGE](state, { payload }) {
      state.newServicePackage = payload
    },
    [TYPE_ORGANIZATION.REDUCERS.SET_NEW_ADD_ON](state, { payload }) {
      state.newAddOn = payload
    },
    [TYPE_ORGANIZATION.REDUCERS.SET_CURRENT_SERVICE_PACKAGE]: (
      state,
      { payload }
    ) => {
      state.currentServicePackage = payload
    },
  },
})

export const organizationActions = organizationReducer.actions

export default organizationReducer.reducer
