import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TYPE } from '../../../models/common'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { deleteAccountAction } from '../../../store/profile/profile.action'
import { openNotification } from '../../../utils/notification'
import { actions as authActions } from '../../../store/auth/auth.reducer'
import { TYPES_AUTH } from '../../../store/auth/auth.type'
import PopupConfirm from '../../common/PopupConfirm'
import Input from '../../common/Input'
import classNames from 'classnames'
import Button from '@components/common/Button'
import { RootState } from '@stores/store'
import { ERROR_AUTH } from '@models/auth'

const DeleteAccountButton: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  const handleDeleteAccount = async () => {
    try {
      const res = await dispatch(deleteAccountAction({ password })).unwrap()
      if (!res.error) {
        dispatch(authActions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
      } else {
        if (res.error.message === ERROR_AUTH.INCORRECT_PASSWORD) {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteAccount',
            message: t('errorFromBE.auth.incorrectPassword'),
          })
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteAccount',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }
      }
    } catch ({}) {
      openNotification({
        type: TYPE.ERROR,
        key: 'deleteAccount',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleCancelDeleteAccount = () => {
    setPassword('')
    setIsShowConfirmDelete(false)
  }

  return (
    <>
      <div className="py-1.5">
        <Button.Normal
          classNameContainer="!w-auto"
          className="font-semibold text-sm"
          color="ghost"
          title={t('common.delete')}
          onClick={() => setIsShowConfirmDelete(true)}
        />
      </div>
      {isShowConfirmDelete && (
        <PopupConfirm
          title={t('popup.confirm.confirmDeleteAccount.title')}
          visible={isShowConfirmDelete}
          messageFirst={t('popup.confirm.confirmDeleteAccount.messageFirst')}
          messageSecond={t('popup.confirm.confirmDeleteAccount.messageSecond')}
          isDelete={true}
          okLabel={t('common.delete')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCancelDeleteAccount}
          handleOk={handleDeleteAccount}
          isDisabledOk={
            !(userProfile && userProfile.info.isRegisteredByGoogle) && !password
          }
        >
          {userProfile && !userProfile.info.isRegisteredByGoogle && (
            <Input
              type="password"
              className={classNames('mt-4', {
                'focus:border-red-500': !password,
              })}
              color="ghost"
              onChange={handleChangeValue}
              required
              autoComplete="new-password"
              placeholder={t(
                'popup.confirm.confirmDeleteAccount.enterPassword'
              )}
            />
          )}
        </PopupConfirm>
      )}
    </>
  )
}

export default DeleteAccountButton
