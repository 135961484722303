import { IBillingPeriodType } from './organization'

export enum IProductType {
  SERVICE_PACKAGE = 'SERVICE_PACKAGE',
  ADD_ON = 'ADD_ON',
}
export interface IStateNavigate {
  from?: string
  upgradeSP?: IDataUpgradeSPOrAddOn
  upgradeAddOn?: IDataUpgradeSPOrAddOn
}

export interface IDataUpgradeSPOrAddOn {
  prorationPrice: number
  nextCharge: Date
  isMonthToYear: boolean
}
export interface IReqUpAndDownPlan {
  organizationId: string
  id: number
  billingPeriodType: IBillingPeriodType
}
