export const TYPE_MAP = {
  REDUCERS: {
    SET_MAP_INFOS: 'setMapInfos',
    SET_IS_UPLOADING: 'setIsUploading',
  },
  ACTIONS: {
    GET_MAP_INFOS: 'map/getMapInfos',
    CREATE_MAP_INFO: 'map/createMapInfo',
    UPDATE_MAP_INFO: 'map/updateMapInfo',
    DELETE_MAP: 'map/deleteMap',
  },
}
