export const API_TYPE_SCENE_SETTING = {
  MUTATION: {
    CREATE_SPACE: 'createSpace',
    CREATE_SCENE: 'addScene',
    DELETE_SPACE: 'deleteSpaces',
    DELETE_SCENE: 'deleteScene',
    UPDATE_SPACE: 'updateSpace',
    UPDATE_SCENE: 'updateScene',
    SET_MARKER: 'setMarkers',
    REMOVE_MARKER: 'removeMarker',
    SET_INFO_TAG: 'setInfoTags',
    REMOVE_INFO_TAG: 'removeInfoTag',
    ROLLBACK_VERSION: 'rollbackVersion',
  },
  QUERY: {
    GET_SPACE_AND_SCENE: 'getSpacesAndScenes',
    GET_SCENE_INFO: 'getScene',
    GET_SIGNED_COOKIES: 'getSignedCookies',
  },
}
