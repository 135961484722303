import React, { useRef } from 'react'

export type TooltipPlacement = 'left' | 'right'

interface Props {
  children: React.ReactNode
  title: string
  position?: TooltipPlacement
  gap?: number
}

const FixedTooltip: React.FC<Props> = (props) => {
  const { children, title, gap = 4, position } = props
  const tooltipRef = useRef<HTMLSpanElement>(null)
  const container = useRef<HTMLDivElement>(null)

  const getTooltipPossition = (container: DOMRect, tooltip: DOMRect) => {
    let left = 0,
      top = 0
    switch (position) {
      case 'right':
        left = container.left + container.width + gap
        top = container.top + container.height / 2 - tooltip.height / 2
        break
      case 'left':
        left = container.left - tooltip.width - gap
        top = container.top + container.height / 2 - tooltip.height / 2
        break
    }
    return {
      top: top + 'px',
      left: left + 'px',
    }
  }

  const handleHover = () => {
    if (!tooltipRef.current || !container.current) return
    const containerRect = container.current.getBoundingClientRect()
    const tooltipRect = tooltipRef.current.getBoundingClientRect()

    const tooltipPossition = getTooltipPossition(containerRect, tooltipRect)
    tooltipRef.current.style.left = tooltipPossition.left
    tooltipRef.current.style.top = tooltipPossition.top
  }
  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div ref={container} className="group" onMouseOver={handleHover}>
      {children}
      <span
        ref={tooltipRef}
        className="fixed opacity-0 group-hover:opacity-100 px-2 py-1 bg-black__op-500 text-white whitespace-nowrap rounded z-10 font-lato transition delay-[400ms]"
      >
        {title}
      </span>
    </div>
  )
}

export default FixedTooltip
