import Select, { IValueSelected } from '@components/common/selectInput/Select'
import React, { useState } from 'react'
import RevenueTable from './RevenueTable'

import IconTick from '@assets/icons/plan/tick.svg'
import IconNewAccount from '@assets/icons/revenueSetting/new-account.svg'

const RevenueSettingComponent: React.FC = (): React.ReactElement => {
  const optionsAccountStripe = [
    {
      value: 'accountId',
      label: (
        <div className="w-full flex justify-between">
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            AccountID
          </p>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconTick} alt="" />
          </div>
        </div>
      ),
    },
    {
      value: 'newAccount',
      label: (
        <div className="w-full flex justify-between">
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            新規アカウントを設定
          </p>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconNewAccount} alt="" />
          </div>
        </div>
      ),
    },
  ]
  const [typeAccountStripe, setTypeAccountStripe] = useState<IValueSelected>({
    value: 'newAccount',
    label: (
      <div className="w-full flex justify-between">
        <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
          新規アカウントを設定
        </p>
        <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
          <img src={IconNewAccount} alt="" />
        </div>
      </div>
    ),
  })
  const handleChangeAccountStripe = (value: IValueSelected) => {
    setTypeAccountStripe(value)
  }
  return (
    <div className="py-8 h-screen overflow-y-auto text-white__op-900">
      <div className="w-[80%] mx-auto min-w-[60rem]">
        <div className="w-full max-w-[50rem] px-10">
          <div className="flex justify-between items-center">
            <input
              className="bg-transparent font-semibold text-base h-9"
              type="month"
              min="2023-01"
              value="2023-02"
            />
            <div className="font-lato text-lg leading-8">32,000,000 円</div>
          </div>
          <RevenueTable />
          <hr className="my-6 border-white__op-50" />
          <div className="py-3 font-semibold text-base">Payee</div>
          <div className="flex items-center">
            <div className="w-60 mr-4 flex-shrink-0">Stripe account</div>
            <Select
              value={typeAccountStripe}
              onChange={handleChangeAccountStripe}
              options={optionsAccountStripe}
              classNameValue="!leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RevenueSettingComponent
