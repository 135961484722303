import React, { useMemo, useState } from 'react'
import { useTable, Column } from 'react-table'
import Select, { IValueSelected } from '@components/common/selectInput/Select'
import { TypeStatusProject } from '@constants/revenueSetting'

type Data = {
  plProject: string
  view: number
  purchases: number
  price: number
  sale: number
  status: number
}
const RevenueTable: React.FC = (): React.ReactElement => {
  const optionsStatus = [
    {
      value: TypeStatusProject.LIMITED,
      label: 'Limited',
    },
    {
      value: TypeStatusProject.PRIVATE,
      label: 'Private',
    },
    {
      value: TypeStatusProject.PUBLIC,
      label: 'Public',
    },
  ]
  const [typeStatus, setTypeStatus] = useState<IValueSelected>({
    value: TypeStatusProject.LIMITED,
    label: 'Limited',
  })

  const tableColumns = useMemo<Array<Column<Data>>>(
    () => [
      {
        Header: 'Public project',
        accessor: 'plProject',
        width: 220,
        minWidth: 180,
      },
      { Header: 'Number of views', accessor: 'view', width: 96 },
      { Header: 'Number of purchases', accessor: 'purchases', width: 96 },
      {
        Header: 'Unit price',
        accessor: 'price',
      },
      { Header: 'Sales', accessor: 'sale' },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: () => (
          <Select
            value={typeStatus}
            onChange={handleChangeStatus}
            options={optionsStatus}
            classNameValue="!text-xs !leading-5"
            classNameSelect="!w-auto -mr-2"
          />
        ),
        width: 96,
      },
    ],
    [typeStatus]
  )
  const tableData = useMemo<Array<Data>>(
    () => [
      {
        plProject: 'Public Paid Project 1',
        view: 2000,
        purchases: 3000,
        price: 1000,
        sale: 3000000,
        status: 1,
      },
      {
        plProject: 'Public Paid Project 2',
        view: 2000,
        purchases: 3000,
        price: 1000,
        sale: 3000000,
        status: 1,
      },
      {
        plProject: 'Public Paid Project 3',
        view: 2000,
        purchases: 3000,
        price: 1000,
        sale: 3000000,
        status: 1,
      },
    ],
    []
  )
  const tableInstance = useTable({
    columns: tableColumns,
    data: tableData,
  })
  const handleChangeStatus = (value: IValueSelected) => {
    setTypeStatus(value)
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance
  return (
    <table {...getTableProps()} className="w-full text-white__op-900">
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={index}
            className="text-left"
          >
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps({
                  style: { width: column.width, minWidth: column.minWidth },
                })}
                className="py-3 text-xs leading-5 font-semibold"
                key={index}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              key={index}
              className="border-t font-lato leading-6 border-white__op-50"
            >
              {row.cells.map((cell, index) => {
                return (
                  <td {...cell.getCellProps()} className="py-3" key={index}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default RevenueTable
