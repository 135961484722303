import { TYPE_FILE, TYPE_MEDIA } from '../../models/media'

class Helper {
  public detectMediaType(type: string, is360?: boolean) {
    if (TYPE_FILE.AUDIO.includes(type)) return TYPE_MEDIA.AUDIO
    if (TYPE_FILE.FILES.includes(type)) return TYPE_MEDIA.PDF
    if (TYPE_FILE.IMAGES.includes(type))
      return is360 ? TYPE_MEDIA.IMAGE360 : TYPE_MEDIA.IMAGE
    if (TYPE_FILE.VIDEOS.includes(type))
      return is360 ? TYPE_MEDIA.VIDEO360 : TYPE_MEDIA.VIDEO
    throw new Error('Type is not valid')
  }
}
export const mediaLibHelper = new Helper()
