import { gql } from '@apollo/client'

export const LOGIN = gql`
  mutation login($email: String!, $password: String!, $isRememberMe: Boolean!) {
    login(
      input: { email: $email, password: $password, isRememberMe: $isRememberMe }
    ) {
      message
      data {
        auth {
          refreshToken
          accessToken
        }
        user {
          userId
          organizationId
        }
        userPlan {
          id
          plan {
            id
            name
            price
            features {
              id
              key
            }
          }
          expirationFrom
          expirationTo
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!) {
    forgotPassword(email: $email) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const CREATE_PROFILE = gql`
  mutation createUser(
    $email: String
    $password: String
    $partner: String
    $emailNotification: String
  ) {
    createUser(
      createUserInput: {
        email: $email
        password: $password
        partner: $partner
        emailNotification: $emailNotification
      }
    ) {
      statusCode
      message
      data {
        auth {
          accessToken
          refreshToken
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateUserProfile(
    $fullName: String
    $langCode: String
    $email: String
    $password: String
    $avatar: String
  ) {
    updateUserProfile(
      updateUserInput: {
        fullName: $fullName
        langCode: $langCode
        email: $email
        password: $password
        avatar: $avatar
      }
    ) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        info {
          refreshToken
          accessToken
          rememberMeToken
          fullName
          avatar
          email
          langCode
        }
      }
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!) {
    resetPassword(resetPasswordInput: { password: $password }) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const CREATE_PACKAGE = gql`
  mutation updateRegistration($servicePackageId: Int!) {
    updateRegistration(
      updateRegistrationInput: { servicePackageId: $servicePackageId }
    ) {
      statusCode
      message
      data {
        stepRegistration
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const SEND_CONFIRM_SUCCESS = gql`
  mutation {
    confirmCreateAccount {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const CREATE_PAYMENT = gql`
  mutation updateRegistration(
    $paymentMethodId: String!
    $cardNumber: String!
    $cardHolderName: String!
    $expired: DateTime!
    $bankName: String!
    $bankCode: String!
  ) {
    updateRegistration(
      updateRegistrationInput: {
        payment: {
          paymentMethodId: $paymentMethodId
          cardNumber: $cardNumber
          cardHolderName: $cardHolderName
          expired: $expired
          bankName: $bankName
          bankCode: $bankCode
        }
      }
    ) {
      statusCode
      message
      data {
        stepRegistration
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const SEND_EMAIL_CONFIRM_REGISTER = gql`
  mutation {
    sendMailConfirmRegister {
      statusCode
      message
      error {
        message
      }
    }
  }
`

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!, $rememberMeToken: String) {
    refreshToken(
      input: { refreshToken: $refreshToken, rememberMeToken: $rememberMeToken }
    ) {
      message
      statusCode
      data {
        accessToken
        refreshToken
        rememberMeToken
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const LOGOUT = gql`
  mutation {
    logout {
      message
      statusCode
      error {
        message
        errorCode
      }
    }
  }
`

export const LOGIN_WITH_GOOGLE = gql`
  mutation authenGoogle($input: AuthenGoogleInput!) {
    authenGoogle(input: $input) {
      message
      statusCode
      data {
        auth {
          refreshToken
          accessToken
        }
        user {
          userId
          organizationId
        }
        userPlan {
          id
          plan {
            id
            name
            price
            features {
              id
              key
            }
          }
          expirationFrom
          expirationTo
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`
