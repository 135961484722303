import ContextMenu from '@components/sceneSetting/ContextMenu'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IPropsType {
  open: boolean
  position: {
    xPos: string
    yPos: string
  } | null
  close: () => void
  handleEditingName: () => void
  handleDelete: () => void
}

const MapThumbnailContextMenu: React.FC<IPropsType> = (props) => {
  const { open, position, close, handleEditingName, handleDelete } = props
  const { t } = useTranslation()

  return (
    <ContextMenu
      open={open}
      position={position}
      close={close}
      contextClassName="!z-[100000]"
      options={
        <div className="flex flex-col font-lato">
          <button
            className="flex items-center justify-between px-4 py-1.5 text-sm leading-5 hover:bg-white__op-50"
            onClick={handleEditingName}
          >
            <div className="text-white__op-900 mr-3">{t('common.rename')}</div>
          </button>
          <button
            className="flex items-center justify-between px-4 py-1.5 text-sm leading-5 hover:bg-white__op-50"
            onClick={handleDelete}
          >
            <div className="text-white__op-900 mr-3">{t('common.delete')}</div>
          </button>
        </div>
      }
    />
  )
}

export default MapThumbnailContextMenu
