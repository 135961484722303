import React, { InputHTMLAttributes, ReactNode, useState } from 'react'
import classNames from 'classnames'
import Icon from './Icon'

type IconPlacement = 'left' | 'right'
type InputTypeColor = 'transparent' | 'ghost'
interface PropsInput extends InputHTMLAttributes<HTMLElement> {
  icon?: ReactNode
  customClassContainer?: string
  iconPosition?: IconPlacement
  color?: InputTypeColor
  isValid?: boolean
  isError?: boolean
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}
const Input: React.FC<PropsInput> = ({
  color = 'transparent',
  icon,
  iconPosition,
  customClassContainer,
  className,
  isValid,
  isError,
  handleKeyDown,
  ...rest
}): React.ReactElement => {
  const [composing, setComposition] = useState<boolean>(false)
  const startComposition = () => setComposition(true)
  const endComposition = () => setComposition(false)

  const handleKeyDownSpaceName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (composing || !handleKeyDown) return
    handleKeyDown(event)
  }
  return (
    <label
      className={classNames(
        'relative w-full py-1.5 cursor-pointer block',
        customClassContainer
      )}
    >
      {icon && (
        <div
          className={classNames('absolute top-1/2 -translate-y-1/2', {
            'right-2.5': iconPosition === 'right',
            'left-2': iconPosition === 'left' || !iconPosition,
          })}
        >
          <Icon.ActionIcon icon={icon} />
        </div>
      )}
      <input
        type="text"
        className={classNames(
          'w-full py-1.5 text-sm text-white__op-900 rounded-lg outline outline-2 outline-transparent focus:outline-blue-500 font-lato disabled:text-white__op-300 ',
          className,
          {
            'pl-8 pr-2':
              (icon && iconPosition === 'left') || (icon && !iconPosition),
            'px-2': !icon,
            'pr-8 pl-2': icon && iconPosition === 'right',
          },
          {
            'bg-gray-800': color === 'transparent',
            'bg-white__op-50 hover:bg-white__op-100 autofill:shadow-dark__input autofill:transition-colors autofill:ease-in-out autofill:duration-5000 disabled:bg-white__op-50':
              color === 'ghost',
          },
          {
            'focus:outline-red-500': isValid,
            '!outline-red-500': isError,
          }
        )}
        onKeyDown={handleKeyDownSpaceName}
        onCompositionStart={startComposition}
        onCompositionEnd={endComposition}
        {...rest}
      />
    </label>
  )
}

export default Input
