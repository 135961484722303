import { TYPE } from '@models/common'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { getMapInfosAction } from '@stores/map/map.action'
import { TYPE_TOOL_SCENE_SETTING } from '@stores/scene/scene.reducer'
import { RootState } from '@stores/store'
import { openNotification } from '@utils/notification'
import { useTranslation } from 'react-i18next'

export const useGetMapInfos = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { toolSceneSetting } = useAppSelector((state: RootState) => state.scene)

  const getMapInfos = async (projectId: number) => {
    if (!projectId || toolSceneSetting !== TYPE_TOOL_SCENE_SETTING.MAP) {
      return
    }
    try {
      await dispatch(getMapInfosAction(projectId)).unwrap()
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'getMapInfos',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  return { getMapInfos }
}
