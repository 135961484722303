import { t } from 'i18next'
import React, { useState } from 'react'
import { IDataEditProfile } from '../../../models/profile'
import { useAppSelector } from '../../../store/hook'
import { RootState } from '../../../store/store'
import Input from '../../common/Input'

interface IPropsType {
  updateProfile: (data: IDataEditProfile) => Promise<void>
}
const UpdateFullNameInput: React.FC<IPropsType> = ({
  updateProfile,
}): React.ReactElement => {
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const [fullname, setFullname] = useState<string>(
    userProfile?.info.fullName || ''
  )
  const handleOnchangeFullname = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setFullname(e.target.value)
  }
  const handleUpdateFullname = () => {
    if (fullname !== userProfile?.info.fullName) {
      updateProfile({ fullName: fullname })
    }
  }

  const handleKeyDownFullname = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (fullname !== userProfile?.info.fullName) {
        updateProfile({ fullName: fullname })
      }
    }
  }
  return (
    <Input
      placeholder={t('profileSetting.account.yourName')}
      color="ghost"
      value={fullname}
      className="!leading-6 !px-3"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleOnchangeFullname(e)
      }
      onBlur={() => handleUpdateFullname()}
      handleKeyDown={handleKeyDownFullname}
    />
  )
}

export default UpdateFullNameInput
