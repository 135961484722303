import classNames from 'classnames'
import React from 'react'

import IconArrowBottom from '../../assets/icons/sceneSetting/arrowBottom.svg'

interface BaseButtonProps {
  icon?: React.ReactNode
  disabled?: boolean
  loading?: boolean
  customClassName?: string
  children?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLElement>
}
const SelectButton: React.ForwardRefRenderFunction<unknown, BaseButtonProps> = (
  props
) => {
  const { icon, onClick, children, disabled, loading, customClassName } = props

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    ;(
      onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    )?.(e)
  }

  return (
    <button
      type="button"
      className={classNames(
        'flex items-center w-full py-1.5 rounded-lg px-2 outline outline-2 outline-transparent hover:bg-white__op-50 active:outline-blue-500 active:bg-transparent',
        customClassName
      )}
      onClick={handleClick}
      disabled={disabled || loading}
    >
      {icon && (
        <div className="w-5 h-5 flex-shrink-0 flex items-center justify-center mr-3">
          {icon}
        </div>
      )}
      {children}
      <div className="w-5 h-5 flex-shrink-0 flex items-center justify-center">
        <img src={IconArrowBottom} alt="" />
      </div>
    </button>
  )
}

export default SelectButton
