import { createAsyncThunk } from '@reduxjs/toolkit'
import { GET_USER_PLAN_BY_USER_ID } from '../../graphql/userPlan/query'
import { API_TYPE_USER_PLAN } from '../../graphql/userPlan/type'
import { graphqlApi } from '../../api/graphql'
import { TYPE_USER_PLAN } from './userPlan.type'

export const getUserPlanByUserIdAction = createAsyncThunk(
  TYPE_USER_PLAN.ACTIONS.GET_USER_PLAN_BY_USER_ID,
  async (userId: number) => {
    const result = await graphqlApi.queryRequest(
      GET_USER_PLAN_BY_USER_ID,
      API_TYPE_USER_PLAN.QUERY.GET_USER_PLAN_BY_USER_ID,
      { userId }
    )
    return result
  }
)
