import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ICoordinates,
  IRequestSetInfoTag,
  ITagTypeEnum,
} from '@models/sceneSetting'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { LANGUAGE } from '@constants/common'

interface IPropsType {
  dataInfoTag: {
    coordinates: ICoordinates
    id: number
    description: string
  }
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<unknown>
}
const InfoTagDescription: React.FC<IPropsType> = (props) => {
  const { dataInfoTag, handleSetInfoTag } = props
  const { t } = useTranslation()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [infoTagDescription, setInfoTagDescription] = useState<string>(
    dataInfoTag.description ?? ''
  )
  const [composing, setComposition] = useState<boolean>(false)

  useEffect(() => {
    setInfoTagDescription(dataInfoTag.description ?? '')
  }, [dataInfoTag.id, dataInfoTag.description])

  const startComposition = () => setComposition(true)
  const endComposition = () => setComposition(false)

  const handleChageDes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setInfoTagDescription(e.target.value)
  }

  const handleUpdateInfoTagDes = async () => {
    if (
      projectInfo &&
      user &&
      dataScenePreview &&
      infoTagDescription !== dataInfoTag.description
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          description: infoTagDescription,
          langCode: LANGUAGE.EN,
          tagType: ITagTypeEnum.TEXT,
        },
      }
      await handleSetInfoTag(req)
    }
  }

  const handleKeyDownInfoTagDes = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (composing) return
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleUpdateInfoTagDes()
    }
  }

  return (
    <div className="py-1.5 px-2">
      <textarea
        id="pjDescription"
        rows={5}
        className="w-full text-sm py-1.5 px-2 leading-5 text-white__op-900 placeholder:text-white__op-500 bg-white__op-50 rounded-lg focus:outline focus:outline-2 focus:outline-blue-500 font-lato resize-none"
        placeholder={t('sceneSetting.rightSideBar.infoTag.content.plDes')}
        onChange={handleChageDes}
        value={infoTagDescription}
        onBlur={handleUpdateInfoTagDes}
        onKeyDown={handleKeyDownInfoTagDes}
        onCompositionStart={startComposition}
        onCompositionEnd={endComposition}
      />
    </div>
  )
}

export default InfoTagDescription
