import React, { useMemo } from 'react'
import { Option } from './Type'
import { useSelectContext } from './SelectProvider'
import classNames from 'classnames'

interface ItemProps {
  item: Option
}

const Item: React.FC<ItemProps> = ({ item }) => {
  const { value, handleValueChange } = useSelectContext()

  const isSelected = useMemo(() => {
    return value !== null && !Array.isArray(value) && value.value === item.value
  }, [item.value, value])

  return (
    <li
      aria-selected={isSelected}
      role={'option'}
      onClick={() => handleValueChange(item)}
      className={classNames(
        'flex items-center px-4 py-2 cursor-pointer select-none truncate font-normal text-sm font-lato text-white__op-900 hover:bg-white__op-50 active:bg-white__op-100',
        {
          'bg-blue-500': isSelected,
        }
      )}
    >
      {item.label}
    </li>
  )
}

export default Item
