import React, { MouseEvent, useEffect, useState } from 'react'
import {
  IMedia,
  ISelectedMedia,
  TYPE_MEDIA,
  TYPE_STATUS_UPLOAD,
} from '@models/media'
import { helper } from '@utils/helper/common'
//icon
import AudioIcon from '@assets/icons/mediaIcon/audio.svg'
import PdfIcon from '@assets/icons/mediaIcon/pdf.svg'
import EditingMedia from './EditingMedia'
import NormalMedia from './NormalMedia'
import UploadingMedia from './UploadingMedia'
import ConvertingMedia from './ConvertingMedia'

interface PropTypes {
  media: IMedia
  folderId?: number
  handleMediaRightClick: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
  isEditing?: boolean
  setEditedMedia: React.Dispatch<React.SetStateAction<ISelectedMedia | null>>
  handleCancelUpload?: (folderId: number | null, media: IMedia) => void
}

const Media: React.FC<PropTypes> = ({
  media,
  handleMediaRightClick,
  folderId = null,
  isEditing,
  setEditedMedia,
  handleCancelUpload,
}) => {
  const [icon, setIcon] = useState<string>()

  useEffect(() => {
    getIconSrc()
  }, [media.thumbnail, media.url])

  const getIconSrc = () => {
    switch (media.mediaType) {
      case TYPE_MEDIA.AUDIO:
        setIcon(AudioIcon)
        break
      case TYPE_MEDIA.PDF:
        setIcon(PdfIcon)
        if (!media?.url) {
          return
        }
        helper.genNumPage(media.url, 1).then((thumbnails) => {
          if (thumbnails?.[0]) {
            setIcon(thumbnails?.[0])
          }
        })
        break
      default:
        setIcon(media?.thumbnail)
        break
    }
  }

  return media.status === TYPE_STATUS_UPLOAD.UPLOADING ? (
    <UploadingMedia
      media={media}
      folderId={folderId}
      handleCancel={handleCancelUpload}
    />
  ) : media.status === TYPE_STATUS_UPLOAD.CONVERTING ? (
    <ConvertingMedia media={media} />
  ) : isEditing ? (
    <EditingMedia media={media} icon={icon} setEditedMedia={setEditedMedia} />
  ) : (
    <NormalMedia
      media={media}
      icon={icon}
      folderId={folderId}
      handleMediaRightClick={handleMediaRightClick}
    />
  )
}

export default Media
