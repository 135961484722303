import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import useOnClickOutside from '../common/hooks'

interface PropsContextMenu {
  open: boolean
  position: {
    xPos: string
    yPos: string
  } | null
  close: () => void
  options: React.ReactNode
  contextClassName?: string
}
const ContextMenu: React.FC<PropsContextMenu> = (props): React.ReactElement => {
  const refContext = useRef<HTMLDivElement>(null)
  const { open, position, close, options, contextClassName } = props
  const [newPosition, setNewPosition] = useState<{
    xPos: string
    yPos: string
  } | null>(position)

  useOnClickOutside(refContext, () => {
    close()
  })

  useEffect(() => {
    if (refContext.current && position) {
      const { yPos, xPos } = position
      const pageY = Number(yPos.substring(0, yPos.length - 2))
      const popup = refContext.current
      const innerHeight = window.innerHeight
      if (popup.clientHeight + pageY > innerHeight) {
        const newHeight = pageY - popup.clientHeight + 'px'
        setNewPosition({ xPos, yPos: newHeight })
      } else {
        setNewPosition(position)
      }
    }
  }, [position])

  return (
    <div
      className={classNames(
        contextClassName,
        'hidden fixed w-auto z-[9999] bg-gray-700 py-2 shadow-dark__popup rounded-lg',
        {
          '!block': open,
        }
      )}
      style={{
        left: newPosition?.xPos,
        top: newPosition?.yPos,
      }}
      ref={refContext}
    >
      {options}
    </div>
  )
}

export default ContextMenu
