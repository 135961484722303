export const API_TYPE_MAP = {
  MUTATION: {
    CREATE_MAP_INFO: 'createMapInfo',
    UPDATE_MAP_INFO: 'updateMapInfo',
    DELETE_MAP: 'deleteMap',
  },
  QUERY: {
    GET_MAP_INFOS: 'getMapInfos',
  },
}
