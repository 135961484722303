const ORGANIZATION = 'organization'
export const TYPE_ORGANIZATION = {
  REDUCERS: {
    SET_NEW_SERVICE_PACKAGE: `${ORGANIZATION}/setNewServicePackage`,
    SET_CURRENT_SERVICE_PACKAGE: `${ORGANIZATION}/setCurrentServicePackage`,
    SET_NEW_ADD_ON: `${ORGANIZATION}/setNewAddOn`,
  },
  ACTIONS: {
    GET_LIST_CMS_PRODUCT: `${ORGANIZATION}/getListCmsProduct`,
    REGISTER_SERVICE_PACKAGE: `${ORGANIZATION}/registerServicePackage`,
    GET_CURRENT_PLAN_AND_CARD_INFO: `${ORGANIZATION}/getCurrentPlanAndCardInfo`,
    GET_CMS_PRODUCT_BILLINGS: `${ORGANIZATION}/getCmsProductBillings`,
    CANCEL_CMS_SUBSCRIPTION: `${ORGANIZATION}/cancelCmsSubscription`,
    UPGRADE_SERVICE_PACKAGE: `${ORGANIZATION}/upgradeServicePackage`,
    DOWNGRADE_SERVICE_PACKAGE: `${ORGANIZATION}/downgradeServicePackage`,
    UPGRADE_ADD_ON: `${ORGANIZATION}/upgradeAddOn`,
    DOWNGRADE_ADD_ON: `${ORGANIZATION}/downgradeAddOn`,
    UPDATE_PAYMENT_CARD: `${ORGANIZATION}/updatePaymentCard`,
  },
}
