import Button from '@components/common/Button'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { s3Api } from '../../../api/s3'
import { IDataEditProfile } from '../../../models/profile'
import { useAppDispatch } from '../../../store/hook'
import { getSignedUrlUploadAvatarAction } from '../../../store/profile/profile.action'
interface IPropsType {
  updateProfile: (data: IDataEditProfile) => Promise<void>
}
const UpdateAvatarButton: React.FC<IPropsType> = ({
  updateProfile,
}): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const inputFileUploadRef: React.LegacyRef<HTMLInputElement> = useRef(null)
  const getSignedUrld = async (file: File) => {
    const s3PresignedUrl = await dispatch(
      getSignedUrlUploadAvatarAction({
        fileName: file.name,
        fileType: file.type,
      })
    ).unwrap()
    if (s3PresignedUrl.data) {
      const res = await s3Api.uploadMediaToS3(file, s3PresignedUrl.data)
      if (res.status === 204) {
        await updateProfile({ avatar: s3PresignedUrl.data.key })
      }
    }
  }

  const openFileUploadWindow = useCallback(() => {
    inputFileUploadRef.current?.click()
  }, [])

  return (
    <Button.Normal
      classNameContainer="!w-auto"
      className="font-semibold text-sm"
      color="ghost"
      onClick={openFileUploadWindow}
    >
      {t('common.edit')}
      <input
        className="absolute inset-0 opacity-0 cursor-pointer z-10"
        ref={inputFileUploadRef}
        type="file"
        accept="image/*"
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
            getSignedUrld(e.target.files[0])
          }
        }}
        hidden
      />
    </Button.Normal>
  )
}

export default UpdateAvatarButton
