import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
//model
import {
  ILayer,
  IRequestUpdateSpace,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import { TYPE, TypeLanguage } from '@models/common'
//utils
import { openNotification } from '@utils/notification'
import { helper } from '@utils/helper/common'
//component
import Input from '@components/common/Input'
// import Icon from '@components/common/Icon'
//store
import { updateSpaceAction } from '@stores/scene/scene.action'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
//icon
import IconArrowRight from '@assets/icons/sceneSetting/arrowRight.svg'
// import IconMap from '@assets/icons/sceneSetting/map.svg'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'

interface IPropsType {
  item: ILayer
  isEditing: boolean
  closeUpdateName: () => void
  onClollap: (e: React.MouseEvent<HTMLElement>) => void
}
const LayerComponent: React.FC<IPropsType> = (props) => {
  const { item, isEditing, closeUpdateName, onClollap } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { activeView, dataEditContextMenuLSS, dataSpaceAndScene } =
    useAppSelector((state: RootState) => state.scene)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const [layerEdit, setLayerEdit] = useState<string>(
    dataEditContextMenuLSS?.data.title ?? ''
  )

  useEffect(() => {
    if (
      dataEditContextMenuLSS &&
      dataEditContextMenuLSS.type === TYPE_SETTING.LAYER
    ) {
      setLayerEdit(dataEditContextMenuLSS.data.title)
    }
  }, [dataEditContextMenuLSS])

  const handleShowRightCLick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    layer: ILayer
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const xPos = event.pageX + 'px'
    const yPos = event.pageY + 'px'
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_POSITION_CONTEXT_MENU_LSS]({
        xPos,
        yPos,
      })
    )
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_OPEN_CONTEXT_MENU_LSS](true))
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_EDIT_CONTEXT_MENU_LSS]({
        type: TYPE_SETTING.LAYER,
        data: {
          id: layer.info.id,
          title: layer.info.title,
        },
      })
    )
  }

  const handleSelectLayer = async (layer: ILayer) => {
    const data = {
      isTypeEdit: TYPE_SETTING.LAYER,
      data: layer,
    }
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](data))
    setActiveSpaceOrScene(layer)
  }

  const setActiveSpaceOrScene = async (layer: ILayer) => {
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
        type: TYPE_RESOURCE_OF_PROJECT.LAYER,
        layer,
      })
    )
  }

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLayerEdit(e.target.value)
  }

  const handleKeyDownUpdateLayer = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateLayerName()
    }
  }

  const handleUpdateLayerName = async () => {
    if (
      helper.removeWhiteSpaceStart(layerEdit) &&
      dataEditContextMenuLSS &&
      layerEdit !== dataEditContextMenuLSS.data.title &&
      user &&
      projectInfo
    ) {
      const data: IRequestUpdateSpace = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        id: dataEditContextMenuLSS.data.id,
        title: helper.removeWhiteSpaceStart(layerEdit),
        langCode: TypeLanguage.ENLISH,
      }
      const res = await updateSpace(data)
      if (res && dataSpaceAndScene) {
        const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
          projectId: projectInfo.projectId,
          dataVersion: {
            version: res.version,
            data: {
              type: TYPE_SETTING.LAYER,
              id: res.id,
              action: TypeActionUndoRedo.UPDATE,
              listLayer: dataSpaceAndScene,
            },
          },
        })
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
            isUndo,
            isRedo,
          })
        )
      }
    }
    setLayerEdit(dataEditContextMenuLSS?.data.title || '')
    closeUpdateName()
  }

  const updateSpace = async (dataReq: IRequestUpdateSpace) => {
    const res = await dispatch(updateSpaceAction(dataReq)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data
    }
  }

  return (
    <div className="relative cursor-pointer">
      <div
        className={classNames(
          'text-white py-2 pr-1 group-one flex justify-between relative',
          {
            'bg-blue-700':
              activeView.type === TYPE_RESOURCE_OF_PROJECT.LAYER &&
              activeView.layer?.info.id === item.info.id,
            'hover:bg-white__op-50':
              activeView.type !== TYPE_RESOURCE_OF_PROJECT.LAYER ||
              activeView.layer?.info.id !== item.info.id,
          }
        )}
        id={`h${TYPE_RESOURCE_OF_PROJECT.LAYER}${item.info.id}`}
        onContextMenu={(event) => handleShowRightCLick(event, item)}
        onMouseUp={() => handleSelectLayer(item)}
        onDoubleClick={onClollap}
      >
        <div className="flex items-center w-full">
          <button
            className="w-4 h-9 -my-2 flex-shrink-0 flex items-center justify-center"
            onMouseUp={onClollap}
          >
            <img
              id={`arrow${TYPE_RESOURCE_OF_PROJECT.LAYER}${item.info.id}`}
              src={IconArrowRight}
              className={classNames(
                'hidden group-one-hover:!block transition-transform duration-300',
                {
                  'rotate-90': !item.collapsed,
                }
              )}
              alt=""
            />
          </button>
          {isEditing ? (
            <div className="w-full ml-2 mr-10">
              <Input
                className="!py-0 !pl-2 !pr-3 box-border !leading-5 !text-xs"
                customClassContainer="!py-0 h-5"
                color="transparent"
                value={layerEdit}
                onBlur={handleUpdateLayerName}
                onChange={handleChangeName}
                handleKeyDown={handleKeyDownUpdateLayer}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                isValid={helper.removeWhiteSpaceStart(layerEdit) ? false : true}
                onDoubleClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              />
            </div>
          ) : (
            <div className="text-white text-sm cursor-pointer w-full mr-8 truncate font-semibold">
              {item.info.title}
            </div>
          )}
        </div>
        {/* <Icon.ButtonIcon
          icon={<img src={IconMap} alt="" />}
          customClassContainer={classNames(
            'absolute right-1 top-1/2 -translate-y-1/2 group-one-hover:!block',
            {
              block:
                activeView.type === TYPE_RESOURCE_OF_PROJECT.LAYER &&
                activeView.layer?.info.id === item.info.id,
              hidden:
                activeView.type !== TYPE_RESOURCE_OF_PROJECT.LAYER ||
                activeView.layer?.info.id !== item.info.id,
            }
          )}
          size="xs"
          onMouseUp={(e) => {
            e.stopPropagation()
          }}
        /> */}
      </div>
    </div>
  )
}

export default LayerComponent
