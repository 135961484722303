import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import { useTranslation } from 'react-i18next'
import ModalComponent from '@components/common/Modal'
import { RootState } from '../../store/store'
import { TYPE_MAP } from '@stores/map/map.type'
import { actions as mapAction } from '@stores/map/map.reducer'
import {
  actions as sceneActions,
  TYPE_TOOL_SCENE_SETTING,
} from '@stores/scene/scene.reducer'
import Button from '@components/common/Button'
import MapThumbnail from './MapThumbnail'
import MapThumbnailContextMenu from './MapThumbnailContextMenu'
import IconUpload from '@assets/icons/common/icon-upload.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import {
  deleteMapAction,
  getMapInfosAction,
  updateMapInfoAction,
} from '@stores/map/map.action'
import 'swiper/css'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'
import { useUploadMedias } from '../../hooks/useUploadMedias'
import { TYPE_FILE, TYPE_MEDIA } from '@models/media'
import SpinComponent from '@components/common/SpinComponent'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { DRAG_POSITION, IResponseMapInfos, UPLOADING } from '@models/map'
import MapUploading from './MapUploading'
import MapImage from './MapImage'

const MapModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { sidebarWidth, toolSceneSetting } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { isLoading, isUploading, mapInfos } = useAppSelector(
    (state: RootState) => state.map
  )
  const { handleUploadMedias } = useUploadMedias()
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isOpenContextMenu, setIsOpenContextMenu] = useState<boolean>(false)
  const [mapIdContextMenu, setMapIdContextMenu] = useState<number | null>(null)
  const [positionContextMenu, setPositionContextMenu] = useState<{
    xPos: string
    yPos: string
  } | null>(null)
  const [isEditingName, setIsEditingName] = useState<boolean>(false)
  const [dragIndex, setDragIndex] = useState<number | null>(null)
  const [dragPosition, setDragPosition] = useState<DRAG_POSITION | null>(null)
  const [dndItems, setDndItems] = useState<{ id: number }[]>([])
  const refInputFileUpload: React.LegacyRef<HTMLInputElement> = useRef(null)
  const refSwiperMain = useRef<SwiperCore | null>(null)
  const refSecondaryInner = useRef<HTMLDivElement | null>(null)
  const [index, setIndex] = useState<number>()
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  )

  useEffect(() => {
    if (!mapInfos) {
      return
    }
    setDndItems(mapInfos.map((_, index) => ({ id: index + 1 }))) // dnd kitでidが0だとドラッグできないため1から始める
  }, [mapInfos])

  useEffect(() => {
    const projectId = projectInfo?.projectId
    if (
      toolSceneSetting !== TYPE_TOOL_SCENE_SETTING.MAP ||
      !isFirstLoad ||
      !projectId
    ) {
      return
    }
    const getMapInfos = async () => {
      try {
        await dispatch(getMapInfosAction(projectId)).unwrap()
        setIsFirstLoad(false)
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getMapInfos',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
    getMapInfos()
  }, [projectInfo, toolSceneSetting, isFirstLoad])

  const handleChangeFile = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const projectId = projectInfo?.projectId
      if (!e.target.files || !projectId) {
        return
      }
      try {
        dispatch(mapAction[TYPE_MAP.REDUCERS.SET_IS_UPLOADING](UPLOADING.TRUE))
        await handleUploadMedias({
          files: e.target.files,
          is360: false,
          selectedFolder: null,
          mediaTypes: [TYPE_MEDIA.MAP],
        })
        await dispatch(getMapInfosAction(projectId)).unwrap()
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getMapInfos',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
      e.target.value = ''
    },
    [mapInfos]
  )

  const handleSwiperMain = (swiper: SwiperCore) => {
    setActiveIndex(swiper.activeIndex)
    refSwiperMain.current = swiper
  }

  const handleActiveIndexChange = (swiper: SwiperCore) => {
    setActiveIndex(swiper.activeIndex)
  }

  const handleUpdateName = useCallback(
    async (mapName: string, mapInfo: IResponseMapInfos) => {
      if (!mapInfos) {
        return
      }
      try {
        await dispatch(
          updateMapInfoAction([
            {
              mapId: mapInfo.mapId,
              name: mapName,
              displayNumber: mapInfo.displayNumber,
            },
          ])
        ).unwrap()
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'updateMapInfo',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
      const renamedMapInfos = mapInfos.map((map) =>
        map.mapId === mapInfo.mapId ? { ...map, name: mapName } : map
      )
      dispatch(mapAction[TYPE_MAP.REDUCERS.SET_MAP_INFOS](renamedMapInfos))
    },
    [mapInfos]
  )

  const handleOnCancle = () => {
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING](
        TYPE_TOOL_SCENE_SETTING.OFF
      )
    )
  }

  const closeContextMenu = () => {
    setIsOpenContextMenu(false)
  }

  const handleRightCLick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, mapId: number) => {
      event.preventDefault()
      event.stopPropagation()
      setPositionContextMenu({
        xPos: `${event.pageX}px`,
        yPos: `${event.pageY}px`,
      })
      setMapIdContextMenu(mapId)
      setIsOpenContextMenu(true)
    },
    []
  )

  const handleDelete = useCallback(async () => {
    if (!mapIdContextMenu || !mapInfos) {
      return
    }
    closeContextMenu()
    try {
      const res = await dispatch(
        deleteMapAction({ mapId: mapIdContextMenu })
      ).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteMap',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
        return
      }
      const deletedMapInfos = mapInfos?.filter(
        (map) => map.mapId !== mapIdContextMenu
      )

      if (deletedMapInfos.length === 0 || activeIndex === null) {
        setActiveIndex(null)
      } else {
        const contextIndex = mapInfos.findIndex(
          (item) => item.mapId === mapIdContextMenu
        )
        if (
          activeIndex === contextIndex &&
          activeIndex === mapInfos.length - 1
        ) {
          refSwiperMain.current?.slideTo(deletedMapInfos.length - 1)
        } else if (activeIndex > contextIndex) {
          refSwiperMain.current?.slideTo(activeIndex - 1)
        } else {
          refSwiperMain.current?.slideTo(activeIndex)
        }
      }

      dispatch(mapAction[TYPE_MAP.REDUCERS.SET_MAP_INFOS](deletedMapInfos))
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'deleteMap',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [mapInfos, mapIdContextMenu])

  const handleEditingName = () => {
    setIsEditingName(true)
    closeContextMenu()
  }

  const handleClickFile = () => {
    if (!refInputFileUpload.current) {
      return
    }
    refInputFileUpload.current?.click()
  }

  const handleDragStart = (event: DragStartEvent) => {
    setDragIndex((event.active.id as number) - 1)
    document.body.style.setProperty('cursor', 'default', 'important')
  }

  const handleClickThumb = (index: number) => {
    setIndex(index)
    if (!refSwiperMain.current) {
      setIndex(0)
      return
    }
    refSwiperMain.current.slideTo(index)
  }

  const handleMouseMoveThumb = useCallback(
    (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      isSorting: boolean
    ) => {
      event.preventDefault()
      if (!isSorting) {
        return
      }
      const hoverMiddleY = event.currentTarget.clientHeight / 2
      const hoverClientY =
        event.clientY - event.currentTarget.getBoundingClientRect().top
      setDragPosition(
        hoverClientY < hoverMiddleY ? DRAG_POSITION.TOP : DRAG_POSITION.BOTTOM
      )
    },
    []
  )

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      setDragIndex(null)
      const { active, over } = event
      if (!active || !over || !mapInfos) {
        return
      }

      const dragIndex = (active.id as number) - 1
      const index = (over.id as number) - 1
      const dropIndex =
        dragPosition === DRAG_POSITION.BOTTOM ? index + 1 : index
      const newIndex = dropIndex > dragIndex ? dropIndex - 1 : dropIndex

      if (newIndex === dragIndex) {
        setDragIndex(null)
        setDragPosition(null)
        return
      }

      const activeMapId = mapInfos[activeIndex as number].mapId

      const newMapInfos = [...mapInfos]
      const [dragItem] = newMapInfos.splice(dragIndex, 1)
      newMapInfos.splice(newIndex, 0, dragItem)
      const renumberedMapInfos = newMapInfos.map((map, index) => ({
        ...map,
        displayNumber: index,
      }))
      dispatch(mapAction[TYPE_MAP.REDUCERS.SET_MAP_INFOS](renumberedMapInfos))

      const newActiveIndex = renumberedMapInfos.findIndex(
        (map) => map.mapId === activeMapId
      )
      refSwiperMain.current?.slideTo(newActiveIndex, 0)

      setDragIndex(null)
      setDragPosition(null)

      const mapInfosInput = renumberedMapInfos.map(
        ({ mapId, name, displayNumber }) => ({
          mapId,
          name,
          displayNumber,
        })
      )
      try {
        await dispatch(updateMapInfoAction(mapInfosInput)).unwrap()
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'updateMapInfo',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    },
    [dragIndex, dragPosition, mapInfos]
  )

  const handleScroll = useCallback(() => {
    if (!isOpenContextMenu) {
      return
    }
    setIsOpenContextMenu(false)
  }, [isOpenContextMenu])

  return (
    <>
      <ModalComponent
        open={toolSceneSetting === TYPE_TOOL_SCENE_SETTING.MAP}
        onClose={handleOnCancle}
        modalClassName="modal-map"
        title={
          activeIndex !== null &&
          mapInfos &&
          mapInfos?.length > 0 &&
          mapInfos[activeIndex].name !== ''
            ? mapInfos[activeIndex].name
            : t('map.title')
        }
        lowerZIndex
        closable
        centered
        sidebarEnabled
        footer
      >
        {isFirstLoad ? (
          <div className="relative aspect-[1089/625] ml-4">
            <SpinComponent isFlexible isNoMarginTop />
          </div>
        ) : mapInfos && mapInfos?.length > 0 ? (
          <div className="modal-map__inner">
            <div className="modal-map__primary">
              <Swiper
                className="swiperMainMap"
                allowTouchMove={false}
                speed={0}
                onSwiper={handleSwiperMain}
                onActiveIndexChange={handleActiveIndexChange}
              >
                {mapInfos?.map((map) => (
                  <SwiperSlide key={`main_${map.mapId}`}>
                    <MapImage
                      map={map}
                      index={index}
                      activeIndex={activeIndex}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              {(isUploading || isLoading) && <MapUploading />}
            </div>
            <div className="modal-map__secondary">
              <DndContext
                collisionDetection={closestCenter}
                sensors={sensors}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                autoScroll
              >
                <SortableContext items={dndItems}>
                  <div
                    ref={refSecondaryInner}
                    className="modal-map__secondary__inner"
                    style={{
                      height: `calc((100svw - 354px - ${sidebarWidth}px) * 626 / 886 - 65px)`,
                    }}
                    onScroll={handleScroll}
                  >
                    {mapInfos?.map((map, index) => {
                      return (
                        <MapThumbnail
                          key={`${map.mapId}`}
                          index={index}
                          map={map}
                          isEditing={
                            isEditingName && map.mapId === mapIdContextMenu
                          }
                          isActive={activeIndex === index}
                          dragPosition={dragPosition}
                          handleClickThumb={handleClickThumb}
                          handleMouseMoveThumb={handleMouseMoveThumb}
                          handleRightCLick={(
                            event: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => handleRightCLick(event, map.mapId)}
                          handleUpdateName={handleUpdateName}
                          closeUpdateName={() => {
                            setIsEditingName(false)
                          }}
                        />
                      )
                    })}
                  </div>
                </SortableContext>
                <DragOverlay
                  dropAnimation={{
                    sideEffects: defaultDropAnimationSideEffects({
                      styles: {
                        dragOverlay: {
                          display: 'none',
                        },
                      },
                    }),
                  }}
                  style={{ pointerEvents: 'none' }}
                >
                  {dragIndex !== null && (
                    <div className="modal-map__thumb modal-map__thumb--dragging">
                      <img
                        src={mapInfos[dragIndex].thumbnailObjectKey}
                        alt={mapInfos[dragIndex].name}
                        className="modal-map__thumb__image"
                      />
                      <p className="modal-map__thumb__text">
                        {mapInfos[dragIndex].name}
                      </p>
                    </div>
                  )}
                </DragOverlay>
              </DndContext>

              <div className="modal-map__secondary__footer">
                <Button.Normal
                  icon={<img src={IconUpload} alt="" />}
                  className="!py-1.8 font-semibold px-3"
                  color="solid"
                  title={t('map.upload')}
                  onClick={handleClickFile}
                />
                {/* <div className="text-xs py-1 text-center">
                  {t('map.dragAndDrop')}
                </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center aspect-[1089/625] bg-white__op-50 rounded relative ml-4">
            {isUploading || isLoading ? (
              <MapUploading className="bg-transparent" />
            ) : (
              <div>
                <Button.Normal
                  icon={<img src={IconUpload} alt="" />}
                  className="!py-1.8 font-semibold px-3 mb-1"
                  color="solid"
                  title={t('map.upload')}
                  onClick={handleClickFile}
                />
                {/* <div className="text-xs py-1 text-center">
                {t('map.dragAndDrop')}
              </div> */}
              </div>
            )}
          </div>
        )}
        <input
          type="file"
          ref={refInputFileUpload}
          accept={TYPE_FILE.IMAGES}
          onChange={handleChangeFile}
          hidden
          multiple
        />
      </ModalComponent>
      <MapThumbnailContextMenu
        open={isOpenContextMenu}
        position={positionContextMenu}
        close={closeContextMenu}
        handleDelete={handleDelete}
        handleEditingName={handleEditingName}
      />
    </>
  )
}

export default MapModal
