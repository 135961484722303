import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
// import SelectButton from '@components/common/SelectButton'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Popup from '@components/common/Popup'
import Button from '@components/common/Button'
import Icon from '@components/common/Icon'
import {
  IMarkerResponse,
  IRequestGetScene,
  IRequestSetMarker,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
import {
  getSceneAction,
  removeMarkerAction,
  setMarkerAction,
} from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import SelectLinkScene from './SelectLinkScene'
import { actions, TYPE_SETTING } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import MarkerNameComponent from './MarkerNameComponent'
//icon
import IconOption from '@assets/icons/sceneSetting/option.svg'
// import IconTransition from '@assets/icons/sceneSetting/transition.svg'
import MarkerMeshRange from './MarkerMeshRange'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import SpinComponent from '@components/common/SpinComponent'

interface IPropsType {
  markerInfo: IMarkerResponse
}
const MarkerSettingComponent: React.FC<IPropsType> = (props) => {
  const { markerInfo } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview, activeView, contextCodec } = useAppSelector(
    (state: RootState) => state.scene
  )

  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  const [isOpenEditMarkerName, setIsOpenEditMarkerName] =
    useState<boolean>(false)
  const [dataMarker, setDataMarker] = useState<IMarkerResponse>(markerInfo)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)

  useEffect(() => {
    if (markerInfo) {
      setDataMarker(markerInfo)
    }
  }, [markerInfo])

  const handleSetEitMarkerName = async () => {
    setIsOpenSetting(false)
    setIsOpenEditMarkerName(true)
  }

  const handleSetMarker = useCallback(async (req: IRequestSetMarker) => {
    const resSetMarker = await dispatch(setMarkerAction(req)).unwrap()
    if (resSetMarker.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateSpace',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
    return resSetMarker.data
  }, [])

  const handleDeleteMarker = async () => {
    setIsLoadingDelete(true)
    if (
      projectInfo &&
      user &&
      dataMarker &&
      dataScenePreview &&
      dataScenePreview.markers
    ) {
      const { id } = dataMarker
      const req = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        marker: {
          id,
        },
      }
      try {
        const resRemoveMarker = await dispatch(removeMarkerAction(req)).unwrap()
        if (resRemoveMarker.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'removeMarker',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          const newListMarker = dataScenePreview.markers?.filter(
            (marker) => marker.id !== id
          )
          const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
            projectId: req.projectId,
            dataVersion: {
              version: resRemoveMarker.data.version,
              data: {
                type: TYPE_SETTING.MARKER,
                id,
                action: TypeActionUndoRedo.DELETE,
                idScene: dataScenePreview.sceneId,
              },
            },
          })
          dispatch(
            actions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
              isUndo,
              isRedo,
            })
          )
          dispatch(
            actions[TYPE_SCENE.REDUCERS.SET_DATA_SCENE_PREVIEW]({
              ...dataScenePreview,
              markers: newListMarker,
            })
          )
          handleSetDataSetting()
          dispatch(
            actions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
              isTypeEdit: TYPE_SETTING.PROJECT,
              data: null,
            })
          )
        }
        setIsLoadingDelete(false)
      } catch {
        setIsLoadingDelete(false)
        throw new Error('Erorr when remove marker')
      }
    }
  }

  const handleSetDataSetting = async () => {
    const { layer, space, scene } = activeView
    switch (activeView.type) {
      case TYPE_RESOURCE_OF_PROJECT.LAYER:
        if (layer) {
          dispatch(
            actions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
              isTypeEdit: TYPE_SETTING.LAYER,
              data: layer,
            })
          )
        }
        break
      case TYPE_RESOURCE_OF_PROJECT.SPACE:
        if (space) {
          dispatch(
            actions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
              isTypeEdit: TYPE_SETTING.SPACE,
              data: space,
            })
          )
        }
        break
      case TYPE_RESOURCE_OF_PROJECT.SCENE:
        if (scene && projectInfo) {
          const dataGetScene: IRequestGetScene = {
            projectId: projectInfo.projectId,
            sceneId: scene.info.id,
            contextCodec,
          }
          await handleGetScene(dataGetScene)
        }
        break

      default:
        break
    }
  }

  const handleGetScene = async (dataGetScene: IRequestGetScene) => {
    try {
      const resScene = await dispatch(getSceneAction(dataGetScene)).unwrap()
      if (resScene.data) {
        const dataTypeEdit = {
          isTypeEdit: TYPE_SETTING.SCENE,
          data: resScene.data,
        }
        dispatch(
          actions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](dataTypeEdit)
        )
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'getScene',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getScene',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleCloseEditMakerName = useCallback(() => {
    setIsOpenEditMarkerName(false)
  }, [])

  return (
    <div className="bg-gray-800 h-full right-0 transform w-[240px] overflow-y-auto overflow-x-hidden">
      {isLoadingDelete ? (
        <SpinComponent />
      ) : (
        <>
          <div className="flex justify-between items-center text-white py-2 pl-4 pr-1.5">
            <MarkerNameComponent
              isOpenEditMarkerName={isOpenEditMarkerName}
              dataMarker={dataMarker}
              handleCloseEditMakerName={handleCloseEditMakerName}
              handleSetMarker={handleSetMarker}
            />

            <Popup
              open={isOpenSetting}
              close={() => setIsOpenSetting(false)}
              content={
                <div className="flex flex-col font-lato">
                  <Button.MenuItem
                    className="justify-between"
                    onClick={handleSetEitMarkerName}
                  >
                    <span className="text-white__op-900">
                      {t('common.rename')}
                    </span>
                    {/* <span className="text-white__op-500">⌘R</span> */}
                  </Button.MenuItem>
                  {/* <Button.MenuItem className="justify-between">
                    <span className="text-white__op-900">
                      {t('common.copy')}
                    </span>
                    <span className="text-white__op-500">⌘C</span>
                  </Button.MenuItem> */}
                  <Button.MenuItem
                    className="justify-between group"
                    onClick={handleDeleteMarker}
                  >
                    <span className="text-white__op-900 group-disabled:text-white__op-300">
                      {t('common.delete')}
                    </span>
                    {/* <span className="text-white__op-500 group-disabled:text-white__op-300">
                      Del
                    </span> */}
                  </Button.MenuItem>
                </div>
              }
              claassNamePopup="!w-40"
            >
              <Icon.ButtonIcon
                icon={<img src={IconOption} alt="" />}
                onClick={() => setIsOpenSetting(true)}
                customClass={classNames('focus:bg-transparent', {
                  '!bg-blue-700 !outline-transparent': isOpenSetting,
                })}
              />
            </Popup>
          </div>
          <div className="px-2 py-2">
            <div className="text-xs font-semibold leading-5 text-white__op-600 px-2">
              {t('sceneSetting.rightSideBar.marker.linkTo')}
            </div>
            <SelectLinkScene
              handleSetMarker={handleSetMarker}
              markerLink={markerInfo.linkedSceneId}
              markerName={markerInfo.linkedSceneName}
            />
          </div>
          {/* <hr className="border-white__op-100 mt-2 mx-4 mb-[14px]" />
          <div className="px-2">
            <div className="text-xs font-semibold leading-5 text-white__op-600 px-2">
              {t('sceneSetting.rightSideBar.transition')}
            </div>
            <div className="mt-1.5">
              <SelectButton icon={<img src={IconTransition} alt="" />}>
                <span className="w-full text-left text-white__op-500 text-sm leading-6 font-lato truncate">
                  Warp
                </span>
              </SelectButton>
            </div>
          </div> */}
          <hr className="border-white__op-100 mx-4" />
          <div className="px-2 py-2">
            <div className="text-xs font-semibold leading-5 text-white__op-600 px-2">
              {t('sceneSetting.rightSideBar.marker.sceneHeight')}
            </div>
            <div className="mt-1.5">
              {/* <SelectButton icon={<img src={IconTransition} alt="" />}>
            <span className="w-full text-left text-white__op-500 text-sm leading-6 font-lato truncate">
              Default
            </span>
          </SelectButton> */}
              <MarkerMeshRange
                id={dataMarker.id}
                coordinates={dataMarker.coordinates}
                handleSetMarker={handleSetMarker}
                dataMarkerMesh={dataMarker.markerMesh}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MarkerSettingComponent
