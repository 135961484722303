import React, { useEffect, useState } from 'react'
import Input from '@components/common/Input'
import { IMarkerResponse, IRequestSetMarker } from '@models/sceneSetting'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { helper } from '@utils/helper/common'

interface IPropsType {
  isOpenEditMarkerName: boolean
  dataMarker: IMarkerResponse
  handleCloseEditMakerName: () => void
  handleSetMarker: (req: IRequestSetMarker) => Promise<unknown>
}
const MarkerNameComponent: React.FC<IPropsType> = (props) => {
  const {
    isOpenEditMarkerName,
    dataMarker,
    handleCloseEditMakerName,
    handleSetMarker,
  } = props
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)

  const [markerName, setMarkerName] = useState<string>(dataMarker.name || '')
  const handleChangeMarkerName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMarkerName(e.target.value)
  }

  useEffect(() => {
    if (dataMarker) {
      setMarkerName(dataMarker.name || '')
    }
  }, [dataMarker])

  const handleKeyDownMarkerName = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateMarkerName()
    }
  }

  const handleUpdateMarkerName = async () => {
    if (
      helper.removeWhiteSpaceStart(markerName) &&
      markerName !== dataMarker.name &&
      projectInfo &&
      user &&
      dataScenePreview
    ) {
      const { id } = dataMarker

      const req = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        marker: {
          id,
          name: markerName,
        },
      }
      handleSetMarker(req).finally(() => {
        handleCloseEditMakerName()
      })
    } else {
      setMarkerName(dataMarker.name || '')
      handleCloseEditMakerName()
    }
  }

  return isOpenEditMarkerName ? (
    <div className="relative w-full">
      <Input
        id="sceneName"
        type="text"
        className="py-0 px-1 box-border leading-4 text-xs font-lato"
        customClassContainer="py-0"
        value={markerName}
        onChange={handleChangeMarkerName}
        onBlur={handleUpdateMarkerName}
        handleKeyDown={handleKeyDownMarkerName}
        isValid={helper.removeWhiteSpaceStart(markerName) ? false : true}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      {!helper.removeWhiteSpaceStart(markerName) && (
        <p className="text-red-500 absolute top-full font-lato text-xs leading-5">
          Required
        </p>
      )}
    </div>
  ) : (
    <div className="text-base font-semibold leading-6 w-full truncate">
      {markerName}
    </div>
  )
}

export default React.memo(MarkerNameComponent)
