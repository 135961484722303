import { gql } from '@apollo/client'

export const SET_COMPLETE_MULTIPART_UPLOAD = gql`
  mutation setCompleteMultipartUpload($input: CompleteMultipartUploadInput!) {
    setCompleteMultipartUpload(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const SET_ABORT_MULTIPART_UPLOAD = gql`
  mutation setAbortMultipartUpload($input: SetAbortMultipartUploadInput!) {
    setAbortMultipartUpload(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`
