import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { LANGUAGE, LANG_KEY } from '../../constant/common'
import { localStorageUtils } from '@utils/localStorage'
import { TYPE_COMMON } from './common.type'

export type ILanguageType = 'en' | 'ja'
export interface CommonState {
  lang: ILanguageType
}

const initialState: CommonState = {
  lang: localStorageUtils.getValueFromLocalStorage(LANG_KEY) ?? LANGUAGE.EN,
}

export const commonReducer = createSlice({
  name: 'common',
  initialState,
  reducers: {
    [TYPE_COMMON.REDUCERS.SET_LANGE]: (
      state,
      action: PayloadAction<ILanguageType>
    ) => {
      state.lang = action.payload
    },
  },
})

export const commonActions = commonReducer.actions

export default commonReducer.reducer
