import React from 'react'
import { useTranslation } from 'react-i18next'

interface BaseButtonProps {
  inputId: string
  autoplay?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

const AutoplayButton: React.ForwardRefRenderFunction<
  unknown,
  BaseButtonProps
> = (props) => {
  const { inputId, autoplay, onChange, disabled } = props
  const { t } = useTranslation()
  return (
    <>
      <label
        htmlFor={inputId}
        className=" relative group flex items-center cursor-pointer"
      >
        <input
          type="checkbox"
          id={inputId}
          className="sr-only peer"
          checked={autoplay}
          onChange={onChange}
          aria-checked={autoplay}
          aria-label="Autoplay toggle"
          disabled={disabled}
        />
        <div
          className={`block relative  ${
            autoplay ? 'bg-[#2067E3]' : 'bg-[#1952B5]'
          }  w-[30px] h-[16.67px]  rounded-full before:absolute before:bg-[#292929] before:w-[15px] before:h-[15px] before:left-[0.058rem] before:top-[0.058rem] before:rounded-full peer-checked:before:left-[0.86rem] peer-hover:before:bg-[#3D3D3D] peer-active:before:bg-[#525252] peer-focus:before:outline peer-focus:before:outline-2 peer-focus:before:outline-[#5694F0]  disabled:bg-[rgba(255, 255, 255, 0.24)] disabled:opacity-50`}
        ></div>
        <div
          className={`absolute hidden  group-hover:block bg-black bg-opacity-56 rounded-[4px] p-[4px_8px] px-2  w-[180px] h-[48px] -left-20 transform -translate-x-16 -ml-3 top-full  z-19 gap-2.5 mt-1.5  items-center order-19 flex-grow-0 ${
            disabled ? 'block' : 'hidden'
          }`}
        >
          <div
            className={`font-lato text-xs font-normal leading-4  ${
              disabled ? '' : 'text-justify'
            }  mt-1 text-white w-[164px] h-[40px]`}
          >
            {disabled
              ? t('projectSetting.AutoplayDisabled')
              : t('projectSetting.AutoplayText')}
          </div>
        </div>
      </label>
    </>
  )
}

export default AutoplayButton
