import React, { FC } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import IconLogo from '../../assets/icons/auth/logo.svg'
import { TYPE } from '../../models/common'
import InputField from '../../components/common/InputField'
import { useAppDispatch } from '../../store/hook'

import Button from '@components/common/Button'
import { resetPasswordAction } from '@stores/auth/auth.action'
import { openNotification } from '@utils/notification'
import { VALID_PASSWORD_REGEX, PATHNAME } from '@constants/common'
import IconWhereness from '@assets/icons/common/whereness-icon.svg'

interface IValuesResetPassword {
  email: string
  password: string
  confirmPassword: string
}

const ResetPassword: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')
  const email = queryParams.get('email')

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        t('validate.invalidFormat', {
          name: t('common.emailAddress').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.email').toLowerCase() })
      ),
    password: Yup.string()
      .min(6, t('validate.tooShort', { name: t('common.password'), length: 6 }))
      .matches(
        VALID_PASSWORD_REGEX,
        t('validate.invalidFormat', {
          name: t('common.password').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.password').toLowerCase() })
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validate.passwordNotMatch'))
      .required(t('validate.confirmPasswordRequired')),
  })

  const handleResetPassword = async (
    values: IValuesResetPassword,
    actions: FormikHelpers<IValuesResetPassword>
  ) => {
    const { setSubmitting } = actions
    const { password } = values
    setSubmitting(true)
    if (token) {
      try {
        const data = {
          password,
          headers: {
            'reset-token': token,
          },
        }
        const res = await dispatch(resetPasswordAction(data)).unwrap()
        if (!res.error) {
          navigate(PATHNAME.SIGN_IN)
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'resetPassword',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }
        setSubmitting(false)
      } catch {
        setSubmitting(false)
        throw new Error('Something bug')
      }
    } else {
      setSubmitting(false)
      throw new Error('Token invalid')
    }
  }
  return (
    <React.Fragment>
      <div className="mb-4 pb-2.5">
        <div className="flex justify-center items-center pt-3.5 py-3">
          <img src={IconLogo} alt="" className="mr-1.5" />
          <img src={IconWhereness} alt="" />
        </div>
        <div className="text-base leading-7 text-center text-white__op-600 font-lato">
          {t('auth.resetPassword.title')}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center z-10">
        <Formik
          initialValues={{
            email: email || '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={ResetPasswordSchema}
          onSubmit={handleResetPassword}
        >
          {({ isSubmitting, dirty, isValid }) => (
            <Form className="w-full" autoComplete="off" role="presentation">
              <InputField
                name="email"
                placeholder={t('common.email')}
                type="text"
                labelWrap
                required
                disabled
                isTransition
              />
              <InputField
                name="password"
                placeholder={t('common.password')}
                type="password"
                labelWrap
                required
                // autoComplete="new-password"
                isTransition
              />
              <InputField
                name="confirmPassword"
                placeholder={t('auth.resetPassword.confirmPassword')}
                type="password"
                labelWrap
                required
                isTransition
              />
              <div className="text-xs text-white__op-900 text-left py-2 font-lato mb-4">
                {t('auth.signUp.validPassword')}
              </div>
              <div className="flex items-center justify-center">
                <Button.Normal
                  type="submit"
                  className="!py-3 leading-5"
                  disabled={isSubmitting || !dirty || !isValid}
                  title={t('auth.resetPassword.reset&return')}
                  isTransition
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
