import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import config from '../constant/config'
import { getTokenCookie } from '../utils/cookies'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { AUTHEN } from '@constants/auth'
import { TYPE_ERROR } from '../models/common'

const httpLink = createHttpLink({
  uri: config.API_URI,
  credentials: 'same-origin',
})

const subscriptionClient = new SubscriptionClient(config.WS_URI as string, {
  reconnect: true,
  lazy: true,
  connectionParams: () => ({
    authorization: `Bearer ${getTokenCookie(AUTHEN.ACCESSTOKEN)}`,
  }),
})

const wsLink = new WebSocketLink(subscriptionClient)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
  if (networkError?.message?.includes(TYPE_ERROR.SUBSCRIPTION_ERROR)) {
    subscriptionClient.close(true, false)
  }
})

const authLink = setContext(async (_, { headers }) => {
  const accessToken = getTokenCookie(AUTHEN.ACCESSTOKEN)
  if (accessToken) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  } else {
    return {
      headers: {
        ...headers,
      },
    }
  }
})

const httpLinks = ApolloLink.from([errorLink, authLink, httpLink])
const wsLinks = ApolloLink.from([errorLink, authLink, wsLink])

const links = ApolloLink.split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLinks,
  httpLinks
)

const createApolloClient = new ApolloClient({
  link: links,
  cache: new InMemoryCache(),
})

export default createApolloClient
