import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import SelectButton from '@components/common/SelectButton'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP, TYPE_MEDIA } from '@models/media'
import { RootState } from '@stores/store'
import {
  ICoordinates,
  IMediaOfInfoTag,
  InfoTagMediaTypeEnum,
  IRequestSetInfoTag,
  ITagTypeEnum,
} from '@models/sceneSetting'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
//icon
import IconAdd from '@assets/icons/common/add-icon.svg'
import IconVideo from '@assets/icons/sceneSetting/video.svg'
import IconCamera from '@assets/icons/sceneSetting/camera.svg'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { DEFAULT_WIDTH_RIGHT_SETTING } from '@constants/sceneSetting'

interface IPropsType {
  dataInfoTag: {
    coordinates: ICoordinates
    id: number
    media: IMediaOfInfoTag
  }
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<unknown>
}
const TagtypeFileSelect: React.FC<IPropsType> = (props) => {
  const { dataInfoTag, handleSetInfoTag } = props
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { modeOfMediaLibPopup, mediaSelected, projectInfo } = useAppSelector(
    (state: RootState) => state.project
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [isUpdateFile, setIsUpdateFile] = useState<boolean>(false)
  useEffect(() => {
    if (
      mediaSelected &&
      isUpdateFile &&
      projectInfo &&
      dataScenePreview &&
      user
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          tagType: ITagTypeEnum.MEDIA,
          media: {
            mediaId: mediaSelected.id,
            mediaType: mediaSelected.type as InfoTagMediaTypeEnum,
          },
        },
      }
      handleSetInfoTag(req).finally(() => {
        setIsUpdateFile(false)
        dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
            MODE_OF_MEDIA_LIB_POPUP.OFF
          )
        )
      })
    }
  }, [mediaSelected, isUpdateFile])

  const openSelectFile = () => {
    const infoTagFileElm = document.getElementById('infoTagFile')
    if (infoTagFileElm) {
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
          top: infoTagFileElm.offsetTop,
          right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
        })
      )
    }
    setIsUpdateFile(true)
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
        MODE_OF_MEDIA_LIB_POPUP.OTHER_FILE_3D
      )
    )
  }

  const getIconMedia = useCallback(() => {
    switch (dataInfoTag.media.mediaType) {
      case TYPE_MEDIA.PDF:
        return IconCamera
      case TYPE_MEDIA.IMAGE:
        return IconCamera
      case TYPE_MEDIA.VIDEO:
        return IconVideo
      case TYPE_MEDIA.AUDIO:
        return IconVideo
      default:
        return IconAdd
    }
  }, [dataInfoTag.media])
  return (
    <div className="px-2" id="infoTagFile">
      <SelectButton
        icon={<img src={getIconMedia()} alt="" />}
        onClick={openSelectFile}
        customClassName={classNames({
          'outline outline-2 outline-blue-500 bg-white__op-50':
            modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OTHER_FILE_3D,
        })}
      >
        <span
          className={classNames(
            'w-full text-left text-sm leading-6 font-lato truncate',
            {
              'text-white__op-900': dataInfoTag.media.mediaName,
              'text-white__op-500': !dataInfoTag.media.mediaName,
            }
          )}
        >
          {dataInfoTag.media.mediaName
            ? dataInfoTag.media.mediaName
            : t('common.selectFile')}
        </span>
      </SelectButton>
    </div>
  )
}

export default TagtypeFileSelect
