import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PATHNAME } from '@constants/common'
import { helper } from '@utils/helper/common'
import { useAppDispatch, useAppSelector } from '../store/hook'
import { RootState, store } from '../store/store'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { getTokenCookie, getUserCookie } from '../utils/cookies'
import { AUTHEN } from '@constants/auth'
import { OrganizationSubscriptionEvent } from '@models/organization'
import { IMediaProcess, TYPE_STATUS_UPLOAD } from '@models/media'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { actions as projectActions } from '@stores/project/project.reducer'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import useOrganizationSubscription from '../hooks/organizationSubscription'
import PopupConfirm from '@components/common/PopupConfirm'
import { PROJECT_SIZE_TYPE } from '@constants/plan'
import { availableStreamAction } from '@stores/project/project.action'

interface Props {
  component: FC
  layout?: FC<{ children: ReactNode; isExtraLayout: boolean | undefined }>
  isExtraLayout: boolean | undefined
  path: string
  isAuthen: boolean | undefined
}
const Middleware: FC<Props> = (props) => {
  const {
    component: Component,
    layout: Layout,
    isExtraLayout,
    path,
    isAuthen,
  } = props
  const { t } = useTranslation()
  store.getState()
  useAppSelector((state: RootState) => state.auth.accessToken)
  const { processListFileUpload, fileUploading, sessionComplete, projectInfo } =
    useAppSelector((state: RootState) => state.project)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const dispatch = useAppDispatch()
  const [isStorageLimit, setIsStorageLimit] = useState<null | boolean>(null)
  const [isViewOverLimit, setIsViewOverLimit] = useState<null | boolean>(null)
  const user = getUserCookie()
  const accessToken = getTokenCookie(AUTHEN.ACCESSTOKEN)

  const organizationSubscriptionData = useOrganizationSubscription()

  useEffect(() => {
    if (isAuthen) {
      return
    }
    setIsStorageLimit(null)
    setIsViewOverLimit(null)
  }, [isAuthen])

  useEffect(() => {
    if (isStorageLimit === false || !userProfile || !isAuthen) {
      return
    }
    if (
      Number(userProfile.info.limitedOrganizationStorage) !== 0 &&
      Number(userProfile.info.currentOrganizationStorage) >
        Number(userProfile.info.limitedOrganizationStorage)
    ) {
      setIsStorageLimit(true)
      return
    }
    setIsStorageLimit(false)
  }, [isStorageLimit, userProfile, isAuthen])

  useEffect(() => {
    if (isViewOverLimit === false || !userProfile || !isAuthen) {
      return
    }
    const checkAvailableStream = async () => {
      try {
        const res = await dispatch(availableStreamAction()).unwrap()
        if (res.data.availableStream === false) {
          setIsViewOverLimit(true)
          return
        }
      } catch {
        openNotification({
          type: TYPE.ERROR,
          key: 'availableStream',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
      setIsViewOverLimit(false)
    }
    checkAvailableStream()
  }, [isViewOverLimit, userProfile, isAuthen])

  useEffect(() => {
    if (
      organizationSubscriptionData?.event ===
      OrganizationSubscriptionEvent.MEDIA_PROCESS_STATUS
    ) {
      handleMediaProcessStatus(organizationSubscriptionData?.data?.mediaProcess)
    }
  }, [organizationSubscriptionData])

  useEffect(() => {
    if (fileUploading && processListFileUpload) {
      const newProcessListFileUpload = helper.updateProcessListFileUpload(
        processListFileUpload,
        fileUploading
      )
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_LIST_FILE_UPLOAD_WAITTING](
          newProcessListFileUpload
        )
      )
    }
    if (sessionComplete && processListFileUpload) {
      const newProcessListFileUpload = processListFileUpload.filter(
        (sess) => sess.session !== sessionComplete
      )
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_LIST_FILE_UPLOAD_WAITTING](
          newProcessListFileUpload.length ? newProcessListFileUpload : null
        )
      )
      if (path === PATHNAME.DASHBOARD) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_STORAGE_CHANGED](true)
        )
      }
    }
  }, [fileUploading, sessionComplete, path])

  useEffect(() => {
    switch (path) {
      case PATHNAME.PROJECT:
        document.title = projectInfo
          ? `${projectInfo?.projectName} - ${t('title')}`
          : t('title')
        break

      default:
        document.title = t('title')
        break
    }
  }, [path, projectInfo])

  window.addEventListener('beforeunload', function (e) {
    if (processListFileUpload) {
      const confirmationMessage =
        'The upload is not complete. Are you sure you want to reload?'

      e.returnValue = confirmationMessage
      return confirmationMessage
    }
    return
  })

  const handleMediaProcessStatus = (mediaProcess: IMediaProcess) => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.UPDATE_MEDIA]({
        folderId: mediaProcess.folderId,
        media: mediaProcess,
      })
    )

    if (mediaProcess?.status === TYPE_STATUS_UPLOAD.COMPLETE) {
      if (path === PATHNAME.DASHBOARD) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_STORAGE_CHANGED](true)
        )
      }
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.UPDATE_CONTEXT_URL](mediaProcess)
      )
      openNotification({
        type: TYPE.SUCCESS,
        key: 'mediaProcessStatus',
        message: t('notification.success'),
      })
    }
  }

  const handleCloseAlertOrgStorageOverLimit = () => {
    setIsStorageLimit(false)
  }

  const handleCloseAlertViewOverLimit = () => {
    setIsViewOverLimit(false)
  }

  const handleRedirectToContact = () => {
    helper.redirectToContact()
  }

  if ((!accessToken && isAuthen) || (isAuthen && accessToken && !user)) {
    return <Navigate to="/" />
  }

  if (!isAuthen && accessToken && user) {
    return <Navigate to={PATHNAME.DASHBOARD} />
  }

  return (
    <>
      {Layout ? (
        <Layout isExtraLayout={isExtraLayout}>
          <Component />
        </Layout>
      ) : (
        <Component />
      )}
      {isStorageLimit && (
        <PopupConfirm
          title={t('popup.alertOrgStorageOverLimit.title')}
          visible={isStorageLimit}
          messageFirst={t('popup.alertOrgStorageOverLimit.storageUse', {
            use: helper.formatBytes(
              Number(userProfile?.info.currentOrganizationStorage),
              2,
              PROJECT_SIZE_TYPE.GB
            ),
            limit: helper.formatBytes(
              Number(userProfile?.info.limitedOrganizationStorage),
              2,
              PROJECT_SIZE_TYPE.GB
            ),
          })}
          messageSecond={t('popup.alertOrgStorageOverLimit.des')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertOrgStorageOverLimit}
          handleOk={handleRedirectToContact}
        />
      )}
      {!isStorageLimit && isViewOverLimit && (
        <PopupConfirm
          title={t('popup.alertViewOverLimit.title')}
          visible={isViewOverLimit}
          messageFirst={t('popup.alertViewOverLimit.messageFirst')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertViewOverLimit}
          handleOk={handleRedirectToContact}
        />
      )}
    </>
  )
}

export default Middleware
