import React from 'react'
import { useTranslation } from 'react-i18next'

const HeaderProfileSetting: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <h1 className="text-white__op-900 text-[28px] leading-9 font-semibold py-3">
      {t(`common.settings`)}
    </h1>
  )
}

export default HeaderProfileSetting
