import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import SelectButton from '@components/common/SelectButton'
//icon
import IconVideo from '@assets/icons/sceneSetting/media.svg'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP, TYPE_MEDIA } from '@models/media'
import { RootState } from '@stores/store'
import { IRequestUpdateScene } from '@models/sceneSetting'
import { UNSELECT_MEDIA } from '@models/project'
import { useTranslation } from 'react-i18next'
import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import {
  DEFAULT_WIDTH_RIGHT_SETTING,
  REF_SELECT_MEDIA,
} from '@constants/sceneSetting'

interface IPropTypes {
  dataScene: {
    sceneId: number
    contextName: string
  }
  updateScene: (dataReq: IRequestUpdateScene) => Promise<unknown>
}
const ContextComponent: React.FC<IPropTypes> = (props) => {
  const { dataScene, updateScene } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { modeOfMediaLibPopup, mediaSelected, projectInfo } = useAppSelector(
    (state: RootState) => state.project
  )
  const { dataTypeEdit, contextCodec } = useAppSelector(
    (state: RootState) => state.scene
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [isUpdate3D, setIsUpdate3d] = useState<boolean>(false)

  useEffect(() => {
    if (
      mediaSelected &&
      isUpdate3D &&
      projectInfo &&
      user &&
      modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.FILE_3D
    ) {
      const { id, type } = mediaSelected
      updateScene({
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataScene.sceneId,
        contextId: id === UNSELECT_MEDIA ? null : id,
        contextType:
          type === TYPE_MEDIA.VIDEO360 ? TYPE_MEDIA.VIDEO : TYPE_MEDIA.IMAGE,
        contextCodec,
      }).finally(() => {
        setIsUpdate3d(false)
        dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
            MODE_OF_MEDIA_LIB_POPUP.OFF
          )
        )
      })
    }
  }, [mediaSelected, isUpdate3D])

  useEffect(() => {
    if (modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OFF) {
      setIsUpdate3d(false)
    }
  }, [modeOfMediaLibPopup])

  useEffect(() => {
    if (
      !dataScene.contextName &&
      dataTypeEdit?.isTypeEdit === TYPE_SETTING.SCENE
    ) {
      const contextElm = document.getElementById(
        REF_SELECT_MEDIA.CONTEXT_MEDIA_OF_SCENE
      )
      if (contextElm) {
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
            top: contextElm.offsetTop,
            right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
          })
        )
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.FILE_3D
        )
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_REF_BUTTON_SELECT_MEDIA](
          REF_SELECT_MEDIA.CONTEXT_MEDIA_OF_SCENE
        )
      )
      setIsUpdate3d(true)
    } else {
      setIsUpdate3d(false)
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
    }
  }, [dataScene.sceneId])

  const handleOpen3DAsset = () => {
    if (modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OFF) {
      const contextElm = document.getElementById(
        REF_SELECT_MEDIA.CONTEXT_MEDIA_OF_SCENE
      )
      if (contextElm) {
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
            top: contextElm.offsetTop,
            right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
          })
        )
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.FILE_3D
        )
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_REF_BUTTON_SELECT_MEDIA](
          REF_SELECT_MEDIA.CONTEXT_MEDIA_OF_SCENE
        )
      )
      setIsUpdate3d(true)
    } else {
      if (dataScene.contextName) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
            MODE_OF_MEDIA_LIB_POPUP.OFF
          )
        )
        dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
      }
    }
  }

  return (
    <div className="px-2" id={REF_SELECT_MEDIA.CONTEXT_MEDIA_OF_SCENE}>
      <SelectButton
        icon={<img src={IconVideo} alt="" />}
        onClick={handleOpen3DAsset}
        customClassName={classNames({
          'outline outline-2 outline-blue-500 bg-white__op-50':
            modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.FILE_3D,
        })}
      >
        <span className="w-full text-left text-white__op-500 text-sm leading-6 font-lato truncate">
          {dataScene.contextName ? (
            <span className="text-white__op-900">{dataScene.contextName}</span>
          ) : (
            `${t('sceneSetting.rightSideBar.scene.360Video')}`
          )}
        </span>
      </SelectButton>
    </div>
  )
}

export default React.memo(ContextComponent)
