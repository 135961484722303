import React, { useEffect } from 'react'

export default function useOnClickOutside(
  ref: React.RefObject<HTMLDivElement>,
  handler: (e?: MouseEvent | TouchEvent) => void,
  id?: string | null
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const element = document.getElementById(`${id}`)
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        element?.contains(event.target as Node)
      ) {
        return
      }
      handler(event)
    }
    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
