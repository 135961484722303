import { createSlice } from '@reduxjs/toolkit'
import { TYPE_MAP } from './map.type'
import { IResponseMapInfos, LOADING, UPLOADING } from '@models/map'
import { getMapInfosAction, createMapInfoAction } from './map.action'

export interface MapState {
  isLoading: boolean
  isUploading: boolean
  mapInfos: IResponseMapInfos[] | null
}
const initialState: MapState = {
  isLoading: false,
  isUploading: false,
  mapInfos: null,
}

export const mapReducer = createSlice({
  name: 'map',
  initialState,
  reducers: {
    [TYPE_MAP.REDUCERS.SET_MAP_INFOS]: (state, action) => {
      state.mapInfos = action.payload
    },
    [TYPE_MAP.REDUCERS.SET_IS_UPLOADING]: (state, action) => {
      state.isUploading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMapInfosAction.pending, (state) => ({
      ...state,
      isLoading: LOADING.TRUE,
    }))
    builder.addCase(getMapInfosAction.fulfilled, (state, { payload }) => {
      const sortedData = payload.slice().sort((a, b) => {
        if (a.displayNumber === null && b.displayNumber === null) {
          return payload.indexOf(a) - payload.indexOf(b)
        }
        if (a.displayNumber === null) return 1
        if (b.displayNumber === null) return -1
        if (a.displayNumber === b.displayNumber) {
          return payload.indexOf(a) - payload.indexOf(b)
        }
        return a.displayNumber - b.displayNumber
      })
      return {
        ...state,
        mapInfos: sortedData,
        isLoading: LOADING.FALSE,
      }
    })
    builder.addCase(getMapInfosAction.rejected, (state) => ({
      ...state,
      isLoading: LOADING.FALSE,
    }))
    builder.addCase(createMapInfoAction.pending, (state) => ({
      ...state,
      isUploading: UPLOADING.TRUE,
    }))
    builder.addCase(createMapInfoAction.fulfilled, (state) => ({
      ...state,
      isUploading: UPLOADING.FALSE,
    }))
    builder.addCase(createMapInfoAction.rejected, (state) => ({
      ...state,
      isUploading: UPLOADING.FALSE,
    }))
  },
})

export const { actions } = mapReducer
export default mapReducer.reducer
