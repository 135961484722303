//enum
export const enum SET_SEND_EMAIL {
  TRUE = 1,
  FALSE = 0,
}

export const enum ERROR_AUTH {
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
}

export enum PARAM_KEYS {
  PARTNER = 'partner',
  NOTIFICATION = 'notification',
}

export enum NOTIFICATION_TYPES {
  EMAIL = 'email',
  EMAIL_WITH_PASSWORD = 'email-with-password',
  NONE = 'none',
}

export enum NOTIFICATION_ACTIONS {
  SEND = 'send',
  SEND_WITH_PASSWORD = 'sendWithPassword',
  NOT_SEND = 'notSend',
}

//interface
export interface IUser {
  userId: number
  organizationId: string
}

export interface IUserRegister {
  fullName: string
  langCode: string
  email: string
  avatar: string
}

export interface IUserRegistration extends IRequestCreateProfile {
  userId: number
}

export interface IAuth {
  accessToken: string
  expired: number
  refreshToken: string
}

export interface IUserPlan {
  id: string
  plan: {
    id: number
    name: string
    price: number
    metadata: {
      limited: {
        storage?: number
        upload?: number
        stream?: number
      }
    }
    features: {
      id: string
      key: string
    }[]
  }
  expirationFrom: Date
  expirationTo: Date
}

export interface IDataLogin {
  auth: IAuth
  user: IUser
  userPlan: IUserPlan
}

export interface IDataCreateProfile {
  auth: IAuth
  user: IUserRegistration
}

export interface IDataSignedUrl {
  algorithm: string
  bucket: string
  contentType: string
  credential: string
  date: string
  expires: number
  key: string
  policy: string
  signature: string
  url: string
  securityToken: string
}

export interface ICreateProfile {
  email?: string
  password?: string
  partner?: string
  emailNotification?: string
}

export interface ICreatePayment {
  paymentMethodId: string
  cardNumber: string
  cardHolderName: string
  expired: Date
  bankName: string
  bankCode: string
}

//request
export interface IRequestLogin {
  email: string
  password: string
  isRememberMe: boolean
}

export interface IRequestLoginWithGoogle {
  token: string
  partner?: string
  emailNotification?: string
}

export interface IRequestCreateProfile {
  fullName: string
  langCode: string
  email: string
  password: string
  avatar: string
}
