import React, { SetStateAction, useEffect, useState, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Select, { IValueSelected } from '@components/common/selectInput/Select'
import {
  availableStreamAction,
  updateProjectPublishAction,
} from '@stores/project/project.action'
import {
  IDateUpdateProjectPublish,
  IRequestUpdateProjectPublish,
  typeOfSidebarDisplay,
  TypePublicProject,
  TypeSidebarDisplay,
} from '@models/project'
import { openNotification } from '@utils/notification'
import { TYPE, TypeLanguage } from '@models/common'
// import Input from '@components/common/Input'
import ThumbProjectComponent from './ThumbProjectComponent'
import Button from '@components/common/Button'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { actions as projectActions } from '@stores/project/project.reducer'
//icon
import IconPublic from '@assets/icons/sceneSetting/public.svg'
import IconPrivate from '@assets/icons/sceneSetting/private.svg'
import IconPassword from '@assets/icons/sceneSetting/password.svg'
// import IconPublicLimit from '@assets/icons/sceneSetting/publicLimit.svg'
// import IconTicket from '@assets/icons/sceneSetting/ticket.svg'
// import IconMoney from '@assets/icons/sceneSetting/money.svg'
import IconLink from '@assets/icons/sceneSetting/link.svg'
// import IconMember from '@assets/icons/sceneSetting/member.svg'
// import IconCsDomain from '@assets/icons/sceneSetting/cusDomain.svg'
import PopupConfirm from '@components/common/PopupConfirm'
import { helper } from '@utils/helper/common'
import Input from '@components/common/Input'

import IconGridSpace from '@assets/icons/projectSetting/sidebarDisplayType/icon-grid-space.svg'
import IconGrid from '@assets/icons/projectSetting/sidebarDisplayType/icon-grid.svg'
import IconList from '@assets/icons/projectSetting/sidebarDisplayType/icon-list.svg'
// import SwitchButton from '@components/common/SwitchButton' // TODO: readd when adding do not display navigation

import AutoplayButton from '@components/common/AutoplayButton'
interface IPropsType {
  openPopupSuccess: () => void
  autoplay: boolean
  setautoplay: Dispatch<SetStateAction<boolean>>
}

const ProjectSettingComponent: React.FC<IPropsType> = ({
  openPopupSuccess,
  autoplay,
  setautoplay,
}): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [passwordError, setPasswordError] = useState<string>('')
  const { features } = useAppSelector((state: RootState) => state.userPlan)

  // TODO: readd when adding do not display navigation
  // const [isHideNavigation, setIsHideNavigation] = useState<boolean | undefined>(
  //   false
  // )

  const optionsSidebarDisplayType = [
    {
      value: TypeSidebarDisplay.GRID,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconGrid} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.sidebarDisplayType.grid')}
          </p>
        </>
      ),
    },
    {
      value: TypeSidebarDisplay.LIST,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconList} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.sidebarDisplayType.list')}
          </p>
        </>
      ),
    },
    {
      value: TypeSidebarDisplay.GRID_SPACE,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconGridSpace} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.sidebarDisplayType.gridSpace')}
          </p>
        </>
      ),
    },
  ]

  const sidebarDisplayTypeFromInfo = () => {
    return sidebarDisplayTypeFromValue(projectPublishInfo?.sidebarDisplayType)
  }

  const sidebarDisplayTypeFromValue = (value?: string) => {
    return (
      _.find(
        optionsSidebarDisplayType,
        (x) => x.value === value ?? TypeSidebarDisplay.GRID
      ) || null
    )
  }

  const passwordOption = features?.some((feature) => feature.key === 'password')
    ? {
        value: TypePublicProject.PASSWORD,
        label: (
          <>
            <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
              <img src={IconPassword} alt="" />
            </div>
            <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
              {t('projectSetting.select.publish.password')}
            </p>
          </>
        ),
      }
    : undefined

  const optionsPublic: { value: TypePublicProject; label: JSX.Element }[] = [
    {
      value: TypePublicProject.PRIVATE,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconPrivate} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.publish.private')}
          </p>
        </>
      ),
    },
    {
      value: TypePublicProject.PUBLIC,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconPublic} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.publish.public')}
          </p>
        </>
      ),
    },
    ...(passwordOption ? [passwordOption] : []),
  ]

  // const optionsTicket = [
  //   {
  //     value: TypeTicket.FREE,
  //     label: (
  //       <>
  //         <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //           <img src={IconTicket} alt="" />
  //         </div>
  //         <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //           {t('projectSetting.select.ticket.free')}
  //         </p>
  //       </>
  //     ),
  //   },
  //   {
  //     value: TypeTicket.PAID,
  //     label: (
  //       <>
  //         <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //           <img src={IconTicket} alt="" />
  //         </div>
  //         <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //           {t('projectSetting.select.ticket.paid')}
  //         </p>
  //       </>
  //     ),
  //   },
  // ]

  // const optionsLanguage = [
  //   {
  //     value: TypeLanguage.ENLISH,
  //     label: 'English',
  //   },
  //   {
  //     value: TypeLanguage.JAPAN,
  //     label: '日本語',
  //   },
  // ]

  // const optionsMoney = [
  //   {
  //     value: TypeMoney.USD,
  //     label: 'USD',
  //   },
  //   {
  //     value: TypeMoney.JPY,
  //     label: 'JPY',
  //   },
  // ]

  const { projectInfo, isSettingPublish, projectPublishInfo } = useAppSelector(
    (state: RootState) => state.project
  )

  const { user } = useAppSelector((state: RootState) => state.auth)
  const { scene } = useAppSelector((state: RootState) => state)

  const [pjDescription, setPjDescription] = useState<string>(
    projectPublishInfo ? projectPublishInfo.description : ''
  )
  const [typePublic, setTypePublic] = useState<IValueSelected>(
    _.find(
      optionsPublic,
      (type) => type.value === projectPublishInfo?.status
    ) || null
  )
  const [projectPassword, setProjectPassword] = useState<string | undefined>(
    projectPublishInfo?.password
  )
  const [typeSidebarDisplayType, setTypeSidebarDisplay] =
    useState<IValueSelected>(sidebarDisplayTypeFromInfo())
  // const [typeTicket, setTypeTicket] = useState<IValueSelected>({
  //   value: TypeTicket.FREE,
  //   label: (
  //     <>
  //       <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //         <img src={IconTicket} alt="" />
  //       </div>
  //       <p className="w-full truncate cursor-pointer select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //         {t('projectSetting.select.ticket.free')}
  //       </p>
  //     </>
  //   ),
  // })
  // const [typeLanguage, setTypeLanguage] = useState<IValueSelected>({
  //   value: TypeLanguage.ENLISH,
  //   label: 'English',
  // })
  // const [typeMoney, setTypeMoney] = useState<IValueSelected>({
  //   value: TypeMoney.USD,
  //   label: 'USD',
  // })
  const [dataUpdateProject, setDataUpdateProject] =
    useState<IDateUpdateProjectPublish | null>(null)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [isViewOverLimit, setIsViewOverLimit] = useState<boolean>(false)
  useEffect(() => {
    setPjDescription(projectPublishInfo?.description ?? '')
    setTypePublic(
      _.find(
        optionsPublic,
        (x) =>
          x.value ===
          (!features?.some((feature) => feature.key === 'password') &&
          projectPublishInfo?.status === TypePublicProject.PASSWORD
            ? TypePublicProject.PRIVATE
            : projectPublishInfo?.status)
      ) || null
    )
    setTypeSidebarDisplay(sidebarDisplayTypeFromInfo())
  }, [projectPublishInfo, features])

  const hasMoreThanOneScene =
    scene?.dataSpaceAndScene?.reduce((total, val) => {
      const scenesInVal = val.spaces.reduce(
        (sum, space) => sum + space.scenes.length,
        0
      )
      return total + scenesInVal
    }, 0) > 1

  useEffect(() => {
    if (!hasMoreThanOneScene) {
      if (autoplay) {
        setDataUpdateProject({
          ...dataUpdateProject,
          autoplay: false,
        })
        setautoplay(false)
      }
    }
  }, [hasMoreThanOneScene, setautoplay, autoplay])

  useEffect(() => {
    if (_.isEmpty(dataUpdateProject)) {
      setDataUpdateProject(null)
      setIsUpdate(false)
    }
    if (dataUpdateProject?.status === TypePublicProject.PUBLIC) {
      setIsUpdate(true)
    }
  }, [dataUpdateProject])

  const handleChangePublic = async (value: IValueSelected) => {
    if (projectInfo && value && !Array.isArray(value)) {
      setDataUpdateProject({
        ...dataUpdateProject,
        status: value.value as string,
      })
      setTypePublic(value)
      setProjectPassword(projectPublishInfo?.password)
      setPasswordError('')
    }
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectPassword(e.target.value as string)
    setDataUpdateProject({
      ...dataUpdateProject,
      password: e.target.value as string,
    })
    setPasswordError('')
  }
  const handleChangeSidebarDisplayType = async (value: IValueSelected) => {
    if (projectInfo && value && !Array.isArray(value)) {
      setDataUpdateProject({
        ...dataUpdateProject,
        sidebarDisplayType: value.value as typeOfSidebarDisplay,
      })
      setTypeSidebarDisplay(value)
    }
  }

  // const handleChangeTicket = (value: IValueSelected) => {
  //   setTypeTicket(value)
  // }
  // const handleChangeLanguage = (value: IValueSelected) => {
  //   setTypeLanguage(value)
  // }
  // const handleChangeMoney = (value: IValueSelected) => {
  //   setTypeMoney(value)
  // }

  useEffect(() => {
    projectPublishInfo?.autoplay && setautoplay(projectPublishInfo?.autoplay)
  }, [projectPublishInfo?.autoplay])
  const handleUpdateProjectPublish = async () => {
    if (
      !projectPassword &&
      !Array.isArray(typePublic) &&
      typePublic?.value === TypePublicProject.PASSWORD &&
      !dataUpdateProject?.password
    ) {
      setPasswordError(t('projectSetting.passwordEmpty'))
      return
    } else if (!projectInfo || !dataUpdateProject || !user) {
      return
    }

    try {
      if (dataUpdateProject.status === TypePublicProject.PUBLIC) {
        const resAvailableStream = await dispatch(
          availableStreamAction()
        ).unwrap()
        if (resAvailableStream.data.availableStream === false) {
          setIsViewOverLimit(true)
          return
        }
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'availableStream',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }

    const { projectId } = projectInfo
    const { organizationId } = user
    const req: IRequestUpdateProjectPublish = {
      organizationId,
      projectId,
      ...dataUpdateProject,
    }
    const res = await dispatch(updateProjectPublishAction(req)).unwrap()
    if (res.error) {
      setShowError(res.error.message)
    } else {
      if (
        dataUpdateProject.status &&
        [TypePublicProject.PUBLIC, TypePublicProject.PASSWORD].includes(
          dataUpdateProject.status as TypePublicProject
        )
      ) {
        openPopupSuccess()
      }
      closePublishSetting()
    }
  }

  const setShowError = (key: string) => {
    openNotification({
      type: TYPE.ERROR,
      key: 'updateProject',
      message: t('notification.somethingBug.titleFirst'),
      description: t('notification.somethingBug.titleSecond'),
    })
  }

  const handleChageDes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setPjDescription(e.target.value)
    setDataUpdateProject({
      ...dataUpdateProject,
      description: e.target.value,
      langCode: TypeLanguage.ENLISH,
    })
    if (e.target.value === projectPublishInfo?.description) {
      delete dataUpdateProject?.description
      delete dataUpdateProject?.langCode
      setDataUpdateProject({
        ...dataUpdateProject,
      })
    }
  }

  const updateCoverImage = (mediaKey: number | string) => {
    if (typeof mediaKey === 'number') {
      delete dataUpdateProject?.thumbnailUrl
      setDataUpdateProject({
        ...dataUpdateProject,
        thumbnailId: mediaKey,
      })
    } else {
      delete dataUpdateProject?.thumbnailId
      setDataUpdateProject({
        ...dataUpdateProject,
        thumbnailUrl: mediaKey,
      })
    }
  }

  const closePublishSetting = () => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_SETTING_PUBLISH](
        !isSettingPublish
      )
    )
  }

  const handleOpenPreview = () => {
    if (projectPublishInfo && projectPublishInfo.projectPublishUrl) {
      window.open(projectPublishInfo.projectPublishUrl)
    }
  }

  const handleCloseAlertViewOverLimit = () => {
    setIsViewOverLimit(false)
  }

  const handleRedirectToContact = () => {
    helper.redirectToContact()
  }

  // TODO: readd when adding do not display navigation
  // const handleChangeHideNavigation = (
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setIsHideNavigation(e.target.checked)

  //   if (e.target.checked) {
  //     setDataUpdateProject({
  //       ...dataUpdateProject,
  //       sidebarDisplayType: TypeSidebarDisplay.DO_NOT_DISPLAY,
  //     })

  //     return
  //   }

  //   const sidebarDisplayTypeValue =
  //     typeSidebarDisplayType &&
  //     !Array.isArray(typeSidebarDisplayType) &&
  //     typeSidebarDisplayType.value
  //       ? (typeSidebarDisplayType.value as typeOfSidebarDisplay)
  //       : TypeSidebarDisplay.GRID

  //   setDataUpdateProject({
  //     ...dataUpdateProject,
  //     sidebarDisplayType: sidebarDisplayTypeValue,
  //   })

  //   setTypeSidebarDisplay(sidebarDisplayTypeFromValue(sidebarDisplayTypeValue))
  // }

  const handleChangeAutoplay = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    setautoplay(checked)
    setDataUpdateProject({
      ...dataUpdateProject,
      autoplay: checked,
    })
  }

  return (
    <>
      <div className="bg-blue-700 flex flex-col h-full top-0 right-0 transform w-80 overflow-y-auto overflow-x-hidden absolute z-20">
        <div>
          <div className="flex justify-between items-center text-white pt-4 pb-2 pl-4 pr-1.5">
            <span className="text-xl font-semibold truncate">
              {t('projectSetting.publishSetting')}
            </span>
          </div>
          <div className="py-2 px-4">
            <div className="-mx-2">
              <Select
                value={typePublic}
                onChange={handleChangePublic}
                options={optionsPublic}
                isLabelIcon={true}
              />
              {/* {typePublic &&
              !Array.isArray(typePublic) &&
              typePublic.value === 'private' && (
                <button className="px-2 py-1.5 flex items-center leading-5 text-xs text-blue-300">
                  <div className="w-5 h-5 flex-shrink-0 flex items-center justify-center mr-3">
                    <img src={IconMember} alt="" />
                  </div>
                  {t('projectSetting.editMember')}
                </button>
              )} */}
              {/* <Select
                value={typeTicket}
                onChange={handleChangeTicket}
                options={optionsTicket}
                isLabelIcon={true}
              /> */}
              {/* {typeTicket &&
                !Array.isArray(typeTicket) &&
                typeTicket.value === 'paidContents' && (
                  <div className="flex justify-between py-1">
                    <Input
                      icon={<img src={IconMoney} alt="" />}
                      type="number"
                      defaultValue={1000}
                    />
                    <Select
                      value={typeMoney}
                      onChange={handleChangeMoney}
                      options={optionsMoney}
                      classNameValue="leading-5 !text-white__op-500"
                      classNameSelect="!w-auto flex items-center justify-center"
                    />
                  </div>
                )} */}
            </div>
            {!Array.isArray(typePublic) &&
              typePublic?.value === TypePublicProject.PASSWORD && (
                <div className="py-1.5">
                  <Input
                    type="text"
                    placeholder={t('projectSetting.passwordPlaceholder')}
                    className="w-full h-9 rounded-lg py-1.5 px-3 pl-3 bg-white/10 placeholder:text-white__op-500"
                    style={{
                      border: passwordError ? '2px solid #E0321B' : '',
                    }}
                    onChange={handleChangePassword}
                    value={projectPassword}
                  />
                  {passwordError && (
                    <p className="px-2 font-lato text-xs leading-5 text-[#e01b32]">
                      {passwordError}
                    </p>
                  )}
                </div>
              )}
          </div>

          {typePublic &&
            !Array.isArray(typePublic) &&
            typePublic.value !== 'private' && (
              <div className="px-4">
                <hr className="border-white__op-100" />
                <div className="py-2">
                  <div className="py-1.5 text-xs font-semibold leading-5 text-white__op-600">
                    {typePublic.value === TypePublicProject.PASSWORD
                      ? t('projectSetting.passwordUrl')
                      : t('projectSetting.publicUrl')}
                  </div>
                  <div className="w-full py-1.5 flex items-center justify-center">
                    <div className="w-5 h-5 mr-3 flex items-center justify-center shrink-0">
                      <img src={IconLink} alt="" />
                    </div>
                    <button
                      className="text-sm block w-[244px] text-white__op-900 leading-5 font-lato break-words text-left disabled:text-white__op-400"
                      onClick={handleOpenPreview}
                      disabled={isUpdate}
                    >
                      {projectPublishInfo?.projectPublishUrl}
                    </button>
                  </div>

                  {/* <button className="py-1.5 flex items-center leading-5 text-xs text-blue-300 capitalize">
                  <Icon.ActionIcon
                    icon={<img src={IconCsDomain} alt="" />}
                    customClass="mr-3"
                  />
                  {t('projectSetting.customDomain')}
                </button> */}
                </div>
              </div>
            )}

          <hr className="relative group border-white__op-100 mx-4" />
          <div className="flex items-center justify-between h-12 w-full max-w-md px-4 py-2 mx-auto">
            <div className="text-xs font-semibold text-white opacity-600 group cursor-pointer relative">
              {t('projectSetting.Autoplay')}
            </div>
            <div className="overflow-none">
              <AutoplayButton
                inputId={'Auto-play'}
                onChange={(e) => handleChangeAutoplay(e)}
                autoplay={autoplay}
                disabled={!hasMoreThanOneScene}
              />
            </div>
          </div>
          <hr className="border-white__op-100 mx-4" />
          {/* TODO: readd when adding do not display navigation
          <div className="mt-1 px-4 flex items-center justify-between">
            <div className="py-1.5 text-xs font-semibold text-white__op-600">
              {t('projectSetting.hideNavigation')}
            </div>
            <SwitchButton
              inputId={'toggle-hide-navigation'}
              onChange={handleChangeHideNavigation}
              checked={isHideNavigation}
            />
          </div> */}
          {
            /*!isHideNavigation && */ <>
              <div className="py-2 px-4">
                <div className="py-1.5 text-xs font-semibold text-white__op-600">
                  {t('projectSetting.navigationType')}
                </div>
                <div className="-mx-2">
                  <Select
                    value={typeSidebarDisplayType}
                    onChange={handleChangeSidebarDisplayType}
                    options={optionsSidebarDisplayType}
                    isLabelIcon={true}
                  />
                </div>
              </div>
            </>
          }

          <hr className="border-white__op-100 mx-4" />
          <div className="py-2 px-4">
            <div className="text-xs py-1.5 px-1 font-semibold leading-5 text-white__op-600">
              <div className="w-full mr-auto">
                {t('projectSetting.overview')}
              </div>
              {/* <Select
              value={typeLanguage}
              onChange={handleChangeLanguage}
              options={optionsLanguage}
              classNameValue="!text-xs !leading-5"
              classNameSelect="!w-auto -mr-2"
              isDisabled
            /> */}
            </div>
            <div className="py-1.5 ">
              <textarea
                id="pjDescription"
                rows={4}
                className="w-full text-sm py-1.5 px-2 leading-5 text-white__op-900 placeholder:text-white__op-500 bg-white__op-50 rounded-lg focus:outline focus:outline-2 focus:outline-blue-500 font-lato resize-none"
                placeholder={t('projectSetting.plDesProject')}
                onChange={handleChageDes}
                value={pjDescription}
              />
            </div>
          </div>

          <hr className="border-white__op-100 mx-4" />
          <div className="py-2 px-4">
            <div className="text-xs py-1.5 px-1 font-semibold leading-5 text-white__op-600">
              {t('projectSetting.coverImage')}
            </div>

            {projectPublishInfo && (
              <ThumbProjectComponent
                projectThumb={projectPublishInfo.thumbnailUrl}
                updateProjectThumb={updateCoverImage}
              />
            )}
          </div>
        </div>
        <div className="px-3 mt-auto mb-2.5">
          {dataUpdateProject && (
            <Button.BlueBg
              color="solid"
              title={
                [TypePublicProject.PUBLIC, TypePublicProject.PASSWORD].includes(
                  dataUpdateProject.status as TypePublicProject
                )
                  ? t('projectSetting.saveAndPub')
                  : t('common.save')
              }
              disabled={
                dataUpdateProject.status === TypePublicProject.PASSWORD &&
                !projectPassword
              }
              onClick={handleUpdateProjectPublish}
            />
          )}
          <Button.BlueBg
            color="ghost"
            title={
              !dataUpdateProject
                ? t('common.cancel')
                : t('projectSetting.discardChange')
            }
            onClick={closePublishSetting}
          />
        </div>
      </div>
      {isViewOverLimit && (
        <PopupConfirm
          title={t('popup.alertViewOverLimit.title')}
          visible={isViewOverLimit}
          messageFirst={t('popup.alertViewOverLimit.messageFirst')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertViewOverLimit}
          handleOk={handleRedirectToContact}
        />
      )}
    </>
  )
}

export default ProjectSettingComponent
