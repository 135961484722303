import classNames from 'classnames'
import { INotification, TYPE } from '../models/common'

export const openNotification = (option: INotification) => {
  const notificationContainer = document.createElement('div')
  notificationContainer.classList.add(
    'py-1',
    'min-w-[320px]',
    'max-w-[720px]',
    'left-1/2',
    'bottom-0',
    'absolute',
    'animate-toastrCusUp',
    'z-[99999]'
  )

  notificationContainer.innerHTML = `
    <div class="${classNames(
      'flex py-2 px-6 rounded-2xl leading-6 font-lato text-blue-50 flex-col',
      {
        'bg-blue-600': option.type === TYPE.SUCCESS,
        'bg-red-700': option.type === TYPE.ERROR,
      }
    )}">
      <span>${option.message}</span>
      <span>${option.description || ''}</span>
    </div>
  `
  notificationContainer.style.transition =
    'all 200ms cubic-bezier(.3, .3, .3, .1)'

  document.body.appendChild(notificationContainer)
  setTimeout(function () {
    hideToast()
    setTimeout(function () {
      notificationContainer.remove()
    }, 1000)
  }, 5000)

  const hideToast = () => {
    notificationContainer.classList.remove('animate-toastrCusUp')
    notificationContainer.classList.add('animate-toastrCusDown')
    notificationContainer.style.transition =
      'all 1s cubic-bezier(.5, 0, .6, .5)'
  }
}
