import React, { useCallback } from 'react'
import classNames from 'classnames'
import { ISpace } from '@models/sceneSetting'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
//icon
import IconArrowRight from '@assets/icons/sceneSetting/arrowRight.svg'
import { actions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'

interface IPropsType {
  listSpace: Array<ISpace>
  idSpace: number | null
  handleSelectSpace: (space: ISpace) => void
  handleExpandSpace: (
    id: number,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
}
const ListSpaceComponent: React.FC<IPropsType> = (
  props
): React.ReactElement => {
  const dispatch = useAppDispatch()
  const { listSpace, idSpace, handleSelectSpace, handleExpandSpace } = props
  const { projectInfo } = useAppSelector((state: RootState) => state.project)

  const handleSelectLinkScene = useCallback((idScene: number) => {
    dispatch(actions[TYPE_SCENE.REDUCERS.SET_ID_SCENE_SELECT](idScene))
  }, [])

  return (
    <React.Fragment>
      {listSpace.map((space, index) => (
        <div key={index}>
          <div
            className={classNames(
              'py-2 pr-4 flex justify-between items-center cursor-pointer group',
              {
                'bg-blue-500': space.info.id === idSpace,
                'hover:bg-white__op-50': space.info.id !== idSpace,
              }
            )}
            onMouseDown={() => handleSelectSpace(space)}
          >
            <div
              className="h-4 w-4 flex items-center justify-center"
              onMouseDown={(e) => handleExpandSpace(space.info.id, e)}
            >
              <img
                className="hidden group-hover:!block transition-transform duration-300"
                src={IconArrowRight}
                alt=""
                id={`arrowSpacePopup${space.info.id}`}
              />
            </div>
            <div className="w-full truncate leading-5 font-semibold text-white__op-600 text-xs">
              {space.info.title}
            </div>
          </div>
          <div
            id={`spacePopup${space.info.id}`}
            className="h-0 overflow-hidden"
          >
            {space &&
              space.scenes.length > 0 &&
              space.scenes.map((scene, index) => (
                <button
                  className="py-2 px-4 flex justify-center items-center hover:bg-white__op-50 w-full disabled:opacity-30"
                  key={index}
                  disabled={
                    scene.info.id === projectInfo?.defaultSceneSet.sceneId
                      ? true
                      : false
                  }
                  onMouseDown={() => handleSelectLinkScene(scene.info.id)}
                >
                  <div className="w-4 h-4 bg-white__op-300 rounded-sm overflow-hidden mr-2">
                    {scene.info.thumbnailUrl && (
                      <img
                        src={scene.info.thumbnailUrl}
                        className="W-full h-full object-cover"
                        alt="scene thumbnail"
                      />
                    )}
                  </div>
                  <div className="w-full text-left truncate leading-5 text-white__op-900 font-lato">
                    {scene.info.title}
                  </div>
                </button>
              ))}
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default React.memo(ListSpaceComponent)
