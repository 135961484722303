export const TYPE_SCENE = {
  REDUCERS: {
    SET_STATUS_SCENE_INFO_POPUP: 'changeStatusSceneInfoPopup',
    SET_STATUS_LIST_SCENE_POPUP: 'changeStatusListScenePopup',
    SET_TYPE_OF_RESOURCE: 'setTypeOfResource',
    SET_ACTIVE_VIEW: 'setActiveView',
    SET_LIST_SPACE_AND_SCENE: 'setListSpaceAndScene',
    SET_POSITION_CONTEXT_MENU: 'setPositionContextMenu',
    SET_POSITION_CONTEXT_MENU_LSS: 'setPositionContextMenuLSS',
    SET_DISABLE_DELETE_LSS: 'setDisableDeleteLSS',
    SET_OPEN_CONTEXT_MENU_LSS: 'setOpenContextMenuLSS',
    SET_DATA_EDIT_CONTEXT_MENU_LSS: 'setDataEditContextMenuLSS',
    SET_IS_EDIT_NAME_LSS: 'setIsEditNameLSS',
    SET_IS_LOADING: 'setIsLoading',
    SET_DATA_SCENE_PREVIEW: 'setDataScenePreview',
    SET_ID_SCENE_SELECT: 'setIdSceneSelect',
    SET_TOOL_SCENE_SETTING: 'setToolSceneSetting',
    UPDATE_CONTEXT_URL: 'updateContextUrl',
    SET_TOP_POPUP_ASSET: 'setTopPopupAsset',
    SET_REF_BUTTON_SELECT_MEDIA: 'setRefButtonSellectMedia',
    SET_CLEAR_DATA: 'setClearData',
    SET_DISABLED_UNDO_REDO: 'setDisabledUndoRedo',
    SET_SIDEBAR_WIDTH: 'setSidebarWidth',
    SET_MARKER_COORDINATE: 'setMarkerCoordinate',
  },
  ACTIONS: {
    CREATE_SPACE: 'scene/createSpace',
    CREATE_SCENE: 'scene/createScene',
    GET_SPACE_AND_SCENE: 'scene/getSpaceAndScene',
    DELETE_SPACE: 'scene/deleteSpace',
    DELETE_SCENE: 'scene/deleteScene',
    GET_SCENE_INFO: 'scene/getScene',
    UPDATE_SPACE: 'scene/updateSpace',
    UPDATE_SCENE: 'scene/updateScene',
    GET_SIGNED_COOKIES: 'scene/getSignedCookies',
    SET_MARKER: 'scene/setMarker',
    REMOVE_MARKER: 'scene/removeMarker',
    SET_INFO_TAG: 'scene/setInfoTag',
    REMOVE_INFO_TAG: 'scene/removeInfoTag',
    ROLLBACL_VERSION: 'scene/rollbackVersion',
  },
}
