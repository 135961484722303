import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector, useAppDispatch } from '../../store/hook'
import { RootState } from '../../store/store'
import { getUserProfileAction } from '../../store/profile/profile.action'
import { openNotification } from '../../utils/notification'
import { TYPE } from '../../models/common'
import SpinComponent from '../../components/common/SpinComponent'
import classNames from 'classnames'
import AccountSettingComponent from '../../components/profileSetting/accounts/AccountSettingComponent'
import BillingSettingComponent from '@components/profileSetting/billing/BillingSettingComponent'
import RevenueSettingComponent from '@components/profileSetting/revenue/RevenueSettingComponent'
import { TypeProfileSetting } from '@models/profile'
import { actions } from '@stores/profile/profile.reducer'
import { TYPE_PROFILE } from '@stores/profile/profile.type'
import BillingTrialComponent from '@components/profileSetting/billing/BillingTrialComponent'
import { helper } from '@utils/helper/common'

const ProfileSetting: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isLoading, typeTabSetting, userProfile } = useAppSelector(
    (state: RootState) => state.profile
  )

  const tabOption = [
    {
      title: t('profileSetting.tabBar.account'),
      key: TypeProfileSetting.ACCOUNT,
    },
  ]

  useEffect(() => {
    getUserProfile()
  }, [])

  const handleSwitchTab = (key: number) => {
    dispatch(actions[TYPE_PROFILE.REDUCERS.SET_TAB_SETTING](key))
  }

  const getUserProfile = async () => {
    try {
      const res = await dispatch(getUserProfileAction()).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getUserProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch ({}) {
      openNotification({
        type: TYPE.ERROR,
        key: 'getUserProfile',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const renderSetting = () => {
    switch (typeTabSetting) {
      case TypeProfileSetting.ACCOUNT:
        return <AccountSettingComponent />
      case TypeProfileSetting.NOTIFICATION:
        return <></>
      case TypeProfileSetting.BILLING:
        return userProfile &&
          userProfile.info.organizationRemainingDays === null ? (
          <BillingSettingComponent />
        ) : (
          <BillingTrialComponent />
        )
      case TypeProfileSetting.REVENUE:
        return <RevenueSettingComponent />
      default:
        return <></>
    }
  }

  return (
    <React.Fragment>
      <div className="px-6 flex w-[80%] mx-auto min-w-[60rem] max-w-[90rem]">
        {tabOption.map((option, index) => (
          <div
            className={classNames(
              'mx-4 py-3 font-semibold text-base cursor-pointer border-b-2 border-transparent',
              {
                '!border-blue-500 text-white__op-900':
                  option.key === typeTabSetting,
                'text-white__op-500 hover:border-white__op-50 hover:text-white__op-900':
                  option.key !== typeTabSetting,
              }
            )}
            key={index}
            onClick={() => handleSwitchTab(option.key)}
          >
            {option.title}
          </div>
        ))}
      </div>
      <div className="py-8 h-auto max-h-full w-full overflow-y-auto text-white__op-900">
        <div className="w-[80%] mx-auto min-w-[60rem] max-w-[90rem]">
          <div className="w-full max-w-[55rem]">{renderSetting()}</div>
        </div>
      </div>

      {isLoading && <SpinComponent />}
    </React.Fragment>
  )
}

export default ProfileSetting
