import React, { useCallback, useEffect, useState } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Button from '@components/common/Button'
// import Icon from '@components/common/Icon'
import Input from '@components/common/Input'

// import IconAdd from '@assets/icons/sceneSetting/add.svg'
// import IconArrowBottom from '@assets/icons/sceneSetting/arrowBt2.svg'
import IconEdit from '@assets/icons/sceneSetting/edit.svg'
import BillingTable from './BillingTable'
import UpdateCreditCardForm from './UpdateCreditCardForm'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  getCmsProductBillingsAction,
  getCurrentPlanAndCardInfoAction,
} from '@stores/organization/organization.action'
import {
  IBillingPeriodType,
  ICardInfo,
  ICmsProductBilling,
  IResCurrentPlanAndCardInfo,
} from '@models/organization'
import { LANGUAGE } from '@constants/common'
import SpinComponent from '@components/common/SpinComponent'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { helper } from '@utils/helper/common'
import {
  PROJECT_SIZE_KEY,
  PROJECT_SIZE_TYPE,
  PROJECT_VIEW_KEY,
  TAX,
} from '@constants/plan'
import CardInfoLayout from '../../../layouts/CardInfoLayout'

const BillingSettingComponent: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const lang = i18next.language
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [isUpdateinfoCreditCard, setIsUpdateinfoCreditCard] =
    useState<boolean>(false)

  const [isLoadingGetCurrentPlan, setIsLoadingGetCurrentPlan] =
    useState<boolean>(false)

  const [dataCurrentPlan, setDataCurrentPlan] =
    useState<IResCurrentPlanAndCardInfo | null>(null)

  const [listBilling, setListBilling] = useState<Array<ICmsProductBilling>>([])

  const OPTION_SERVICE_PACKAGE: { [key: string]: string } = {
    SERVICE_PACKAGE_STARTER_V1: t('plan.starter'),
    SERVICE_PACKAGE_BASIC_V1: t('plan.basic'),
    SERVICE_PACKAGE_PRO_V1: t('plan.pro'),
    SERVICE_PACKAGE_EXTREME_V1: t('plan.extreme'),
  }

  useEffect(() => {
    handleGetCurrentPlan()
    handleGetCmsProductBillings()
  }, [])

  const handleGetCurrentPlan = async () => {
    setIsLoadingGetCurrentPlan(true)
    try {
      if (!user) {
        throw new Error('OrganizationId is required')
      }
      const { organizationId } = user
      const res = await dispatch(
        getCurrentPlanAndCardInfoAction({ organizationId })
      ).unwrap()
      if (res) {
        setDataCurrentPlan(res)
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getCurrentPlan',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsLoadingGetCurrentPlan(false)
    }
  }

  const handleGetCmsProductBillings = async () => {
    try {
      if (!user) {
        throw new Error('OrganizationId is required')
      }
      const { organizationId } = user
      const res = await dispatch(
        getCmsProductBillingsAction({ organizationId })
      ).unwrap()
      if (res) {
        const { billings } = res
        setListBilling(billings)
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getCmsProductBiliings',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsLoadingGetCurrentPlan(false)
    }
  }

  const handleSetDataNewCard = (newCard: ICardInfo) => {
    if (dataCurrentPlan) {
      const newDataCurrentPlan: IResCurrentPlanAndCardInfo = {
        ...dataCurrentPlan,
        cardInfo: newCard,
      }
      setDataCurrentPlan(newDataCurrentPlan)
    }
  }

  const handleCloseUpdateCard = useCallback(() => {
    setIsUpdateinfoCreditCard(false)
  }, [])

  return (
    <React.Fragment>
      {dataCurrentPlan && (
        <div className="px-8">
          <div className="w-full py-3 px-6 bg-gray-700 rounded-2xl flex">
            <div className="flex flex-col w-full mr-8">
              <div className="py-2">
                <div className="text-xl font-semibold flex justify-between items-center mb-2">
                  {t('plan.currentPlan')}:{' '}
                  {
                    OPTION_SERVICE_PACKAGE[
                      dataCurrentPlan.servicePackage.productKey
                    ]
                  }
                  (
                  {dataCurrentPlan.servicePackage.billingPeriodType ===
                  IBillingPeriodType.BY_MONTH
                    ? t('profileSetting.billing.monthly')
                    : t('profileSetting.billing.annual')}
                  )
                  {dataCurrentPlan.servicePackage.nextPaymentDate ? (
                    <span>
                      ¥
                      {dataCurrentPlan.servicePackage.price.toLocaleString(
                        'en-US'
                      )}
                      /
                      {dataCurrentPlan.servicePackage.billingPeriodType ===
                      IBillingPeriodType.BY_MONTH
                        ? t('common.month')
                        : t('common.year')}
                    </span>
                  ) : (
                    <span className="font-semibold text-base text-white__op-600">
                      {t('profileSetting.billing.canceled')}
                    </span>
                  )}
                </div>
                {(!dataCurrentPlan.servicePackage.nextPaymentDate ||
                  dataCurrentPlan.servicePackage.nextPaymentPrice !==
                    dataCurrentPlan.servicePackage.price) && (
                  <div className="font-lato leading-6">
                    {t('profileSetting.billing.currentExpired', {
                      date: moment(
                        new Date(
                          dataCurrentPlan.servicePackage.currentPaymentExpireDate
                        )
                      ).format(lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'LL'),
                    })}
                  </div>
                )}
              </div>
              <hr className="my-3 border-white__op-50" />
              {dataCurrentPlan?.addOn.publishStorageLimit && (
                <div className="flex items-center font-lato">
                  <span className="text-lg leading-8 mr-2">
                    {dataCurrentPlan.addOn.productKey ===
                    `ADD_ON_${PROJECT_SIZE_KEY._500MB}_${PROJECT_VIEW_KEY._100VIEWS}`
                      ? helper.formatBytes(
                          Number(dataCurrentPlan?.addOn.publishStorageLimit),
                          0,
                          PROJECT_SIZE_TYPE.MB
                        )
                      : helper.formatBytes(
                          Number(dataCurrentPlan?.addOn.publishStorageLimit),
                          0,
                          PROJECT_SIZE_TYPE.GB
                        )}
                  </span>
                  <span className="text-sm leading-6">{t('plan.maxDis')}</span>
                </div>
              )}

              <div className="flex items-center font-lato">
                <span className="text-lg leading-8 mr-2 flex-shrink-0">
                  {dataCurrentPlan?.addOn.viewsLimit.toLocaleString('en-US')}{' '}
                  {t('plan.viewMonth')}
                </span>
                <div className="flex justify-between w-full">
                  <span className="leading-6 text-sm">
                    {t('profileSetting.billing.maxNumberView')}
                  </span>
                  <span className="leading-6 text-sm">
                    {dataCurrentPlan?.addOn.currentViews.toLocaleString(
                      'en-US'
                    )}
                    /{dataCurrentPlan?.addOn.viewsLimit.toLocaleString('en-US')}{' '}
                    {t('plan.views')} {t('profileSetting.billing.isUse')}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-shrink-0 flex-col justify-between w-[10.75rem]">
              <Button.Normal
                title={t('profileSetting.billing.changePlan')}
                className="font-semibold leading-5"
                color="solid"
                onClick={() => helper.redirectToContact()}
              />
              <div className="font-lato text-lg leading-8 text-right">
                ¥{dataCurrentPlan.addOn.price.toLocaleString('en-US')}/
                {dataCurrentPlan.addOn.billingPeriodType ===
                IBillingPeriodType.BY_MONTH
                  ? t('common.month')
                  : t('common.year')}
              </div>
            </div>
          </div>

          {/* <div className="py-3 px-6 bg-gray-700 rounded-2xl flex items-center">
            <Icon.ButtonIcon
              customClass="!w-5 !h-5"
              icon={<img src={IconAdd} alt="" />}
            />
            <div className="font-lato leading-6 mx-3 w-[13.72rem]">
              上限再生数を超過する配信
            </div>
            <div className="font-lato leading-6 mr-3 w-[13.72rem] text-right">
              100回再生
            </div>
            <div className="font-lato text-lg leading-8 w-48 text-right">
              +2,000 円
            </div>
          </div> */}
          <div className="px-2">
            {dataCurrentPlan.servicePackage.nextPaymentDate && (
              <div className="flex items-center p-4 font-lato text-sm leading-6">
                <div className="w-full mr-2.5">
                  {t('profileSetting.billing.nextPaymentPlan')}{' '}
                  {moment(
                    new Date(dataCurrentPlan?.servicePackage.nextPaymentDate)
                  ).format(
                    lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'YYYY/MM/DD'
                  )}
                  {lang == LANGUAGE.JA && <span>ご請求分</span>}
                </div>
                <div className="flex-shrink-0 text-lg leading-8">
                  ¥
                  {(
                    dataCurrentPlan?.servicePackage.nextPaymentPrice * TAX
                  ).toLocaleString('en-US')}
                  ({t('common.tax')})
                </div>
              </div>
            )}
            {dataCurrentPlan?.addOn.nextPaymentDate && (
              <div className="flex items-center p-4 font-lato text-sm leading-6">
                <div className="w-full mr-2.5">
                  {t('profileSetting.billing.nextPaymentPublishing')}{' '}
                  {moment(
                    new Date(dataCurrentPlan?.addOn.nextPaymentDate)
                  ).format(
                    lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'YYYY/MM/DD'
                  )}
                  {lang == LANGUAGE.JA && <span>ご請求分</span>}
                </div>
                <div className="flex-shrink-0 text-lg leading-8">
                  ¥
                  {(
                    dataCurrentPlan?.addOn.nextPaymentPrice * TAX
                  ).toLocaleString('en-US')}
                  {dataCurrentPlan?.addOn.nextPaymentPrice > 0 && (
                    <>({t('common.tax')})</>
                  )}
                </div>
              </div>
            )}

            <hr className="my-6 border-white__op-50" />
            {/* <div className="flex items-center">
              <div className="flex-shrink-0 font-semibold text-base w-60">
                上限再生数を超過する配信
              </div>
              <Input
                value="従量料金で配信を続ける (1再生あたり20円 )"
                icon={
                  <img
                    className="cursor-pointer"
                    src={IconArrowBottom}
                    alt=""
                  />
                }
                className="!leading-6 !px-3"
                iconPosition="right"
                color="ghost"
                disabled
              />
            </div>
            <hr className="my-6 border-white__op-50" /> */}
            <div className="flex">
              <div className="flex-shrink-0 font-semibold text-base w-60 items-start py-3">
                {t('profileSetting.billing.paymentInfo')}
              </div>
              {isUpdateinfoCreditCard ? (
                <CardInfoLayout>
                  <UpdateCreditCardForm
                    handleSetDataNewCard={handleSetDataNewCard}
                    handleCloseUpdateCard={handleCloseUpdateCard}
                  />
                </CardInfoLayout>
              ) : (
                <Input
                  value={`${dataCurrentPlan?.cardInfo.brand} **${dataCurrentPlan?.cardInfo.lastFour}`}
                  icon={
                    <img
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setIsUpdateinfoCreditCard(true)
                      }}
                      className="cursor-pointer"
                      src={IconEdit}
                      alt=""
                    />
                  }
                  className="!leading-6 !px-3"
                  customClassContainer="!cursor-default"
                  iconPosition="right"
                  color="ghost"
                  disabled
                />
              )}
            </div>
            <hr className="my-6 border-white__op-50" />
            <div className="py-3 font-semibold text-base">
              {t('profileSetting.billing.billingHistory')}
            </div>
            {listBilling.length > 0 && (
              <BillingTable listBilling={listBilling} />
            )}
          </div>
        </div>
      )}
      {isLoadingGetCurrentPlan && <SpinComponent />}
    </React.Fragment>
  )
}

export default BillingSettingComponent
