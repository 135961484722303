import Button from '@components/common/Button'
import Input from '@components/common/Input'
import { TYPE } from '@models/common'
import { ERROR_PARTNER_CODE } from '@models/profile'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { setPartnerCodeAction } from '@stores/profile/profile.action'
import { RootState } from '@stores/store'
import { openNotification } from '@utils/notification'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ApplyPartnerCodeForm: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { isLoadingPartnerCode } = useAppSelector(
    (state: RootState) => state.profile
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const [partnerCode, setPartnerCode] = useState<string>('')
  const [isInvalid, setIsInvalid] = useState(false)

  const handleOnchangePartnerCode = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault()
    setPartnerCode(e.target.value)
  }

  const handleApplyPartnerCode = async () => {
    if (!partnerCode || !user?.userId) {
      return
    }
    try {
      const res = await dispatch(
        setPartnerCodeAction({ userId: user?.userId, partnerCode: partnerCode })
      ).unwrap()
      if (res?.error?.message === ERROR_PARTNER_CODE.INVALID_PARTNER_CODE) {
        setIsInvalid(true)
        return
      }
      setIsInvalid(false)
    } catch (error) {
      setIsInvalid(false)
      openNotification({
        type: TYPE.ERROR,
        key: 'setPartnerCode',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  return (
    <>
      <div className="flex items-center gap-4">
        <Input
          placeholder={t('profileSetting.account.partnerCode')}
          color="ghost"
          value={partnerCode}
          className="!leading-6 !px-3 !w-60"
          isError={isInvalid}
          disabled={isLoadingPartnerCode}
          onChange={handleOnchangePartnerCode}
        />
        <Button.Normal
          classNameContainer="!w-auto"
          className="font-semibold text-sm"
          color="ghost"
          title={t('common.apply')}
          disabled={isLoadingPartnerCode}
          onClick={handleApplyPartnerCode}
        />
      </div>
      {isInvalid && (
        <p className="px-3.5 w-full font-lato text-xs text-red-400 leading-6">
          {t('profileSetting.account.errorPartnerCode')}
        </p>
      )}
    </>
  )
}

export default ApplyPartnerCodeForm
