import React, { ReactElement } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'

interface Props {
  children: React.ReactNode
}

const SidebarLayout: React.FunctionComponent<Props> = ({
  children,
}): ReactElement => {
  return (
    <React.Fragment>
      <div className="max-h-screen flex overflow-hidden">
        <Sidebar />
        <div className="w-full bg-gray-800 overflow-x-hidden">
          <div className="w-full h-full mx-auto flex flex-col">
            <Header />
            {children}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SidebarLayout
