import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Middleware from './middleware'
import { route } from './route'

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {route.map((item) => (
          <Route
            key={item.path}
            path={item.path}
            element={
              <Middleware
                component={item.component}
                isExtraLayout={item.isExtraLayout}
                layout={item.layout}
                path={item.path}
                isAuthen={item.isAuthen}
              />
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  )
}
