import React from 'react'
import classNames from 'classnames'
import { ILayer } from '@models/sceneSetting'
//icon
import IconArrowRight from '@assets/icons/sceneSetting/arrowRight.svg'

interface IPropsType {
  listLayer: Array<ILayer>
  idLayer: number | null
  handleSelectLayer: (id: number) => void
  handleExpandLayer: (
    id: number,
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}
const ListLayerComponent: React.FC<IPropsType> = (props) => {
  const { listLayer, idLayer, handleSelectLayer, handleExpandLayer } = props
  return (
    <>
      {listLayer.map((layer, index) => (
        <div
          className={classNames(
            'py-2 px-4 flex justify-between items-center cursor-pointer',
            {
              'bg-blue-500': layer.info.id === idLayer,
              'hover:bg-white__op-50': layer.info.id !== idLayer,
            }
          )}
          onMouseDown={() => handleSelectLayer(layer.info.id)}
          key={index}
        >
          <div className="w-full truncate leading-5 font-semibold text-white__op-600">
            {layer.info.title}
          </div>
          <button
            className="w-5 h-5 flex items-center justify-center"
            onMouseDown={(e) => handleExpandLayer(layer.info.id, e)}
          >
            <img className=" h-2.5" src={IconArrowRight} alt="" />
          </button>
        </div>
      ))}
    </>
  )
}

export default ListLayerComponent
