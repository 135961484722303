import { gql } from '@apollo/client'

export const UPDATE_PAYMENT_CARD = gql`
  mutation updatePaymentCard($input: UpdatePaymentCardInput!) {
    updatePaymentCard(input: $input) {
      message
      statusCode
      error {
        errorCode
        message
      }
    }
  }
`
