import { USER_PROFILE } from '@constants/auth'
import { createSlice } from '@reduxjs/toolkit'
import {
  getUserProfileCookie,
  margeLocalCookie,
  setLocalCookie,
} from '@utils/cookies'
import { IResponseUserProfile, TypeProfileSetting } from '../../models/profile'
import {
  changePasswordAction,
  deleteAccountAction,
  getSignedUrlUploadAvatarAction,
  getStorageAction,
  getUserProfileAction,
  setPartnerCodeAction,
  updateUserProfileAction,
} from './profile.action'
import { TYPE_PROFILE } from './profile.type'
export interface ProfileState {
  status: boolean
  isShowPopupDeleteAccount: boolean
  userProfile: IResponseUserProfile | null
  isLoading: boolean
  isLoadingPartnerCode: boolean
  typeTabSetting: TypeProfileSetting
}

const initialState: ProfileState = {
  status: false,
  isShowPopupDeleteAccount: false,
  userProfile: getUserProfileCookie(),
  isLoading: false,
  isLoadingPartnerCode: false,
  typeTabSetting: TypeProfileSetting.ACCOUNT,
}

export const profileReducer = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    [TYPE_PROFILE.REDUCERS.SET_STATUS_POPUP_CHANGE_PASS]: (state) => {
      state.status = !state.status
    },
    [TYPE_PROFILE.REDUCERS.SET_STATUS_POPUP_DELETE_ACCOUNT]: (state) => {
      state.isShowPopupDeleteAccount = !state.isShowPopupDeleteAccount
    },
    [TYPE_PROFILE.REDUCERS.SET_TAB_SETTING]: (state, action) => {
      state.typeTabSetting = action.payload
    },
    [TYPE_PROFILE.REDUCERS.SET_USER_PROFILE]: (state, action) => {
      state.userProfile = action.payload
      setLocalCookie(USER_PROFILE, JSON.stringify(action.payload))
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changePasswordAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(changePasswordAction.fulfilled, (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        status: !payload.error ? false : true,
      }
    })
    builder.addCase(changePasswordAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getUserProfileAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getUserProfileAction.fulfilled, (state, { payload }) => {
      if (payload.data) {
        setLocalCookie(USER_PROFILE, JSON.stringify(payload.data))
        return {
          ...state,
          isLoading: false,
          userProfile: payload.data,
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getUserProfileAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateUserProfileAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(updateUserProfileAction.fulfilled, (state, { payload }) => {
      const data = payload.data

      if (data) {
        setLocalCookie(USER_PROFILE, JSON.stringify(payload.data))
        return {
          ...state,
          isLoading: false,
          userProfile: state.userProfile && {
            ...state.userProfile,
            info: {
              ...state.userProfile.info,
              ...data.info,
            },
          },
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateUserProfileAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(deleteAccountAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(deleteAccountAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(deleteAccountAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getSignedUrlUploadAvatarAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getSignedUrlUploadAvatarAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getSignedUrlUploadAvatarAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getStorageAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getStorageAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getStorageAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(setPartnerCodeAction.pending, (state) => {
      return {
        ...state,
        isLoadingPartnerCode: true,
      }
    })
    builder.addCase(setPartnerCodeAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        margeLocalCookie(USER_PROFILE, { info: data })
        return {
          ...state,
          isLoadingPartnerCode: false,
          userProfile: state.userProfile && {
            ...state.userProfile,
            info: {
              ...state.userProfile.info,
              partnerCode: data.partnerCode,
              partnerContactUrl: data.partnerContactUrl,
            },
          },
        }
      }
      return {
        ...state,
        isLoadingPartnerCode: false,
      }
    })
    builder.addCase(setPartnerCodeAction.rejected, (state) => {
      return {
        ...state,
        isLoadingPartnerCode: false,
      }
    })
  },
})

export const { actions } = profileReducer

export default profileReducer.reducer
