import { FC, ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import HeaderDashboardComponent from '../../components/dashboard/HeaderDashboardComponent'
import HeaderProfileSetting from '../../components/profileSetting/HeaderProfileSetting'
enum ListPath {
  DASHBOARD = '/dashboard',
  PROFILE_SETTING = '/profile-setting',
}
const Header: FC = (): ReactElement => {
  const { pathname } = useLocation()
  return (
    <header className="w-[80%] pt-14 pr-10 pl-10 min-w-[60rem] max-w-[90rem] mx-auto">
      {pathname === ListPath.DASHBOARD && <HeaderDashboardComponent />}
      {pathname === ListPath.PROFILE_SETTING && <HeaderProfileSetting />}
    </header>
  )
}

export default Header
