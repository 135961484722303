import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import LeftSideBar from '../../components/sceneSetting/leftSidebar'
import RightMenuComponent from '../../components/sceneSetting/rightSidebar/RightMenuComponent'
import Context360 from '../../components/sceneSetting/Context360'
import { TYPE, TYPE_ERROR } from '../../models/common'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import {
  getInfoProjectAction,
  getMediaAction,
  getMediaFoldersAction,
} from '../../store/project/project.action'
import {
  getSceneAction,
  getSignedCookiesAction,
  getSpaceAndSceneAction,
} from '../../store/scene/scene.action'
import { RootState } from '../../store/store'
import { openNotification } from '../../utils/notification'
import SceneDefault from '@assets/images/scene-default.svg'
import SceneDefault2 from '@assets/images/scene-default-2.svg'
import { PATHNAME } from '@constants/common'
import { IRequestGetScene, TypeActionUndoRedo } from '@models/sceneSetting'
import SpinComponent from '@components/common/SpinComponent'
import classNames from 'classnames'
import {
  actions as sceneActions,
  TYPE_SETTING,
  TYPE_TOOL_SCENE_SETTING,
} from '@stores/scene/scene.reducer'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { IResponseProjectInfo } from '@models/project'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP, TYPE_CONTEXT } from '@models/media'
import ToolNavigationComponent from '@components/sceneSetting/leftSidebar/toolNavigation/ToolNavigationComponent'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { ID_PROJECT_KEY } from '@constants/sceneSetting'
import MapModal from '@components/map/MapModal'

const SceneSetting: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { isSettingPublish, isShowPublishSuccess } = useAppSelector(
    (state: RootState) => state.project
  )
  const {
    dataTypeEdit,
    toolSceneSetting,
    dataScenePreview,
    contextCodec,
    sidebarWidth,
  } = useAppSelector((state: RootState) => state.scene)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const [context, setContext] = useState<{
    contextUrl: string | undefined
    contextType: string
    contestBgmUrl: string | undefined
  } | null>(null)

  const [isLoadingScene, setIsLoadingScene] = useState<boolean>(false)
  const [isMute, setIsMute] = useState(true)

  useEffect(() => {
    handleGetSpaceAndScene()
    handleGetInfoProject()
    getSignedCookies()
    handleGetMedias()
    handleGetMediaFolders()
  }, [id])

  useEffect(() => {
    if (dataScenePreview && dataScenePreview.contextType) {
      const { contextUrl, contextThumbnailUrl, contextType, bgmUrl } =
        dataScenePreview
      let playerLibType = TYPE_CONTEXT.IMAGE
      if (contextType === TYPE_CONTEXT.VIDEO && contextUrl) {
        playerLibType = TYPE_CONTEXT.VIDEO
      }

      setContext({
        contextUrl: contextUrl || contextThumbnailUrl,
        contextType: playerLibType,
        contestBgmUrl: bgmUrl,
      })
    } else {
      setContext(null)
    }
  }, [dataScenePreview])

  useEffect(() => {
    if (dataTypeEdit.isTypeEdit !== TYPE_SETTING.SCENE) {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
      dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
    }
  }, [dataTypeEdit])

  const getSignedCookies = async () => {
    if (id) {
      const res = await dispatch(getSignedCookiesAction(Number(id))).unwrap()
      if (res.error) {
        if (res.error.message === TYPE_ERROR.RESOURCE_IS_LOCKED) {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](
              true
            )
          )
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'getSpaceAndScene',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }
      }
    }
  }

  const handleGetSpaceAndScene = async () => {
    setIsLoadingScene(true)
    if (user && id) {
      const res = await dispatch(
        getSpaceAndSceneAction({
          projectId: parseInt(id),
          organizationId: user.organizationId,
        })
      ).unwrap()
      if (res.error) {
        if (res.error.message === TYPE_ERROR.RESOURCE_IS_LOCKED) {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](
              true
            )
          )
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'getSpaceAndScene',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }

        setIsLoadingScene(false)
        navigate(PATHNAME.DASHBOARD)
      }
    }
  }

  const handleGetInfoProject = async () => {
    if (id) {
      const projectId = parseInt(id)
      window.sessionStorage.setItem(ID_PROJECT_KEY, JSON.stringify(projectId))
      try {
        const res = await dispatch(getInfoProjectAction({ projectId })).unwrap()
        if (res.error) {
          if (res.error.message === TYPE_ERROR.RESOURCE_IS_LOCKED) {
            dispatch(
              projectActions[
                TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL
              ](true)
            )
          } else {
            openNotification({
              type: TYPE.ERROR,
              key: 'getProjectInfo',
              message: t('notification.somethingBug.titleFirst'),
              description: t('notification.somethingBug.titleSecond'),
            })
          }
          navigate(PATHNAME.DASHBOARD)
          setIsLoadingScene(false)
        } else {
          await handleGetScene(res.data)
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getProjectInfo',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
        navigate(PATHNAME.DASHBOARD)
        setIsLoadingScene(false)
      }
    }
  }

  const handleGetMedias = async () => {
    const organizationId = user?.organizationId
    const projectId = id && parseInt(id)
    if (!projectId) {
      throw new Error('ProjectId is invalid')
    }

    const data = {
      organizationId,
      projectId,
    }
    try {
      const res = await dispatch(getMediaAction(data)).unwrap()
      if (res.error) {
        throw new Error(res.error)
      }
    } catch ({ message }) {
      if (message === TYPE_ERROR.RESOURCE_IS_LOCKED) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](
            true
          )
        )
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'getMedias',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleGetMediaFolders = async () => {
    try {
      const projectId = id && parseInt(id)
      if (!projectId) {
        throw new Error('ProjectId is invalid')
      }

      const res = await dispatch(getMediaFoldersAction({ projectId })).unwrap()
      if (res.error) {
        throw new Error(res.error)
      }
    } catch ({ message }) {
      if (message === TYPE_ERROR.RESOURCE_IS_LOCKED) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](
            true
          )
        )
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'getMediaFolders',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleGetScene = async (dataProject: IResponseProjectInfo) => {
    const dataGetScene: IRequestGetScene = {
      projectId: dataProject.projectId,
      sceneId: dataProject.defaultSceneSet.sceneId,
      contextCodec,
    }
    try {
      const resScene = await dispatch(getSceneAction(dataGetScene)).unwrap()
      if (resScene.data) {
        if (!sceneSettingHelper.checkHasProjectVersion(dataProject.projectId)) {
          const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
            projectId: dataProject.projectId,
            dataVersion: {
              version: dataProject.version,
              data: {
                type: TYPE_SETTING.ROOT,
                id: dataProject.projectId,
                action: TypeActionUndoRedo.CREATE,
                rootId: dataProject.defaultSceneSet, //! default id of layer, space, scene(type root only)
              },
            },
          })
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
              isUndo,
              isRedo,
            })
          )
        }
        setContext({
          contextUrl: resScene.data.contextUrl,
          contextType: resScene.data.contextType,
          contestBgmUrl: resScene.data.bgmUrl,
        })
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_SCENE_PREVIEW](
            resScene.data
          )
        )
        const dataTypeEdit = {
          isTypeEdit: TYPE_SETTING.SCENE,
          data: resScene.data,
        }
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](dataTypeEdit)
        )
      }
      setIsLoadingScene(false)
    } catch {
      setIsLoadingScene(false)
      throw new Error('Something bug')
    }
  }

  return (
    <div className="flex h-screen w-full overflow-hidden relative">
      <ToolNavigationComponent />
      <div
        className="h-full relative flex"
        style={{
          width: 'calc(100% - 4rem)',
        }}
      >
        {isLoadingScene ? (
          <SpinComponent />
        ) : (
          <React.Fragment>
            <div
              className="h-full flex overflow-hidden"
              style={
                toolSceneSetting === TYPE_TOOL_SCENE_SETTING.MAP
                  ? { width: '100%' }
                  : { width: 'calc(100% - 15rem)' }
              }
            >
              <LeftSideBar />
              <div
                className="bg-black__op-500"
                style={{
                  width: `calc(100% - ${sidebarWidth}px)`,
                }}
              >
                <div
                  className={classNames(
                    'bg-gray-700 relative self-stretch w-full h-full',
                    {
                      'cursor-crosshair':
                        toolSceneSetting === TYPE_TOOL_SCENE_SETTING.MARKER &&
                        context,
                      'cursor-infotag':
                        toolSceneSetting === TYPE_TOOL_SCENE_SETTING.INFO_TAG &&
                        context,
                    }
                  )}
                  id="body-preview-scene"
                >
                  {context && context.contextUrl ? (
                    <Context360
                      url={context.contextUrl}
                      contextType={context.contextType}
                      bgmUrl={context.contestBgmUrl}
                      isMute={isMute}
                      setIsMute={setIsMute}
                    />
                  ) : (
                    <>
                      <div className="text-white__op-900 absolute top-1/2 -translate-y-1/2 left-[4.5rem]">
                        <div className="font-semibold text-[1.75rem] leading-9">
                          {t('sceneSetting.noScene.title')}
                        </div>
                        <div className="text-base leading-7">
                          {t('sceneSetting.noScene.subTitle1')}
                        </div>
                      </div>
                      <img
                        className="w-full h-full mix-blend-soft-light absolute inset-0 object-cover"
                        src={SceneDefault2}
                        alt=""
                      />
                      <img
                        className="w-full h-full mix-blend-multiply absolute inset-0 object-cover"
                        src={SceneDefault}
                        alt=""
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {dataTypeEdit && <RightMenuComponent dataTypeEdit={dataTypeEdit} />}
            {toolSceneSetting === TYPE_TOOL_SCENE_SETTING.MAP && (
              <div
                className={classNames({
                  invisible: isSettingPublish || isShowPublishSuccess,
                })}
              >
                <MapModal />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default SceneSetting
