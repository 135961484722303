import React, { MouseEvent } from 'react'
import { IMedia, ISelectedMedia } from '@models/media'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import classNames from 'classnames'
import { RootState } from '@stores/store'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { TYPE_MEDIA } from '@models/media'
import { actions } from '@stores/project/project.reducer'

interface PropTypes {
  media: IMedia
  icon: string | undefined
  folderId?: number | null
  handleMediaRightClick: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

enum EventType {
  dragstart = 'dragstart',
  mouseup = 'mouseup',
}

const NormalMedia: React.FC<PropTypes> = ({
  media,
  icon,
  folderId = null,
  handleMediaRightClick,
}) => {
  const dispatch = useAppDispatch()
  const { selectedMediaInAsset } = useAppSelector(
    (state: RootState) => state.project
  )

  const handleEvent = (event: React.SyntheticEvent<HTMLDivElement>) => {
    switch (event.type) {
      case EventType.mouseup:
        handleSelectedMedia()
        break
      case EventType.dragstart:
        handleDragStart(event as React.DragEvent<HTMLDivElement>)
        break
    }
  }

  const handleSelectedMedia = () => {
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_MEDIA_IN_ASSET]({
        key: media.key,
        folderId,
        mediaType: media.mediaType,
        resourceId: media.resourceId,
      })
    )
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const payload: ISelectedMedia = {
      key: media.key,
      folderId,
      resourceId: media.resourceId,
      mediaType: media.mediaType,
    }
    event.dataTransfer.setData('media', JSON.stringify(payload))
    event.currentTarget.classList.add('dragging')
    event.dataTransfer.effectAllowed = 'copyMove'
  }

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove('dragging')
  }

  return (
    <div
      draggable={true}
      onDragStart={handleEvent}
      onDragEnd={handleDragEnd}
      className={classNames(
        'flex items-center px-4 py-1 hover:cursor-pointer text-xs',
        {
          'bg-blue-700': media.key === selectedMediaInAsset?.key,
          'hover:bg-white__op-100': media.key !== selectedMediaInAsset?.key,
        }
      )}
      onContextMenu={(event) => handleMediaRightClick(event)}
      onMouseUp={handleEvent}
    >
      <img
        src={icon}
        className="mr-2 w-8 h-8 rounded-lg flex-shrink-0"
        alt=""
      />
      <div className="flex flex-1 items-center justify-between overflow-hidden text-start">
        <span className="truncate text-white__op-800 text-xs font-lato  max-w-[calc(100%-1rem)] leading-5">
          {media.name}
        </span>
        {(media.mediaType === TYPE_MEDIA.IMAGE360 ||
          media.mediaType === TYPE_MEDIA.VIDEO360) && (
          <div className="ml-2 flex-shrink-0">
            <div className="flex flex-row justify-center items-center h-5 bg-[rgba(255,255,255,0.16)] rounded px-1">
              <div className="h-3 font-montserrat text-[10px] text-white font-semibold leading-[12.19px] tracking[-0.01em]">
                360
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NormalMedia
