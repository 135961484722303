import { gql } from '@apollo/client'

export const GET_LIST_SPACE_AND_SCENE = gql`
  query getSpacesAndScenes($input: GetSpacesAndScenesInput) {
    getSpacesAndScenes(input: $input) {
      message
      statusCode
      data {
        layers {
          id
          title
        }
        spaces {
          id
          title
          thumbnail {
            url
            imageId
            sceneThumbnail {
              url
              imageId
              videoId
            }
          }
        }
        scenes {
          id
          title
          thumbnailUrl
          imageThumbnailId
          videoThumbnailId
        }
        orderStructure {
          layers {
            id
            spaces {
              id
              scenes {
                id
              }
            }
          }
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_RESOURCE_AND_PROJECT = gql`
  query getProjectInfoAndListSpaceScene(
    $input: GetSpacesAndScenesInput
    $projectId: Int!
  ) {
    getProjectProfile(projectId: $projectId) {
      statusCode
      message
      data {
        projectId
        projectPublishUrl
        isPublishPassword
        isPublish
        projectName
        projectDescription
        thumbnailUrl
        audioUrl
        langCode
        showTourInstruction
        startDate
        endDate
      }
      error {
        errorCode
        message
      }
    }
    getSpacesAndScenes(input: $input) {
      message
      statusCode
      data {
        id
        title
        spaces {
          id
          title
          thumbnailUrl
          scenes {
            id
            thumbnail
            title
            isHide
          }
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_SCENE_INFO = gql`
  query getScene($input: GetSceneInput!) {
    getScene(input: $input) {
      statusCode
      message
      data {
        spaceId
        sceneId
        thumbnailUrl
        bgmUrl
        bgmName
        contextType
        contextUrl
        contextThumbnailUrl
        contextName
        title
        description
        langCode
        metadata {
          isHide
        }
        markers {
          id
          coordinates {
            xAxis
            yAxis
            zAxis
          }
          linkedSceneId
          linkedSceneName
          name
          markerMesh
          version
        }
        infoTags {
          id
          coordinates {
            xAxis
            yAxis
            zAxis
          }
          tagType
          color
          description
          langCode
          media {
            mediaUrl
            mediaType
            mediaName
            mediaThumbnailUrl
          }
          size
          name
          title
          credit
          version
        }
        information
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_SIGNED_COOKIES = gql`
  query getSignedCookies($projectId: Int!) {
    getSignedCookies(projectId: $projectId) {
      statusCode
      message
      data {
        cloudFrontPolicy
        cloudFrontSignature
        cloudFrontKeyPairId
        expireTime
      }
      error {
        errorCode
        message
      }
    }
  }
`
