import SpinComponent from '@components/common/SpinComponent'
import classNames from 'classnames'
import React from 'react'

interface IPropsType {
  className?: string
}

const MapUploading: React.FC<IPropsType> = (props) => {
  const { className } = props

  return (
    <div className={classNames('modal-map__uploading', className)}>
      <SpinComponent isInline />
      <span className="pl-6 text-xl font-semibold">Uploading Maps ...</span>
    </div>
  )
}

export default MapUploading
