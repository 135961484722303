import React, { useEffect, useRef, useState } from 'react'
import { TypeLanguage } from '@models/common'
import { IRequestUpdateScene, IResponseSceneInfo } from '@models/sceneSetting'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { MAX_LENGTH_DES } from '@constants/sceneSetting'

interface IPropTypes {
  dataScene: IResponseSceneInfo
  updateScene: (dataReq: IRequestUpdateScene) => Promise<unknown>
}
const DescriptionTextarea: React.FC<IPropTypes> = (props) => {
  const { dataScene, updateScene } = props
  const { t } = useTranslation()
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { contextCodec } = useAppSelector((state: RootState) => state.scene)
  const [sceneDescription, setSceneDescription] = useState<string>(
    dataScene.description ?? ''
  )
  const [composing, setComposition] = useState<boolean>(false)

  const ref = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    setSceneDescription(dataScene.description ?? '')
  }, [dataScene])

  const startComposition = () => setComposition(true)
  const endComposition = () => setComposition(false)

  const handleChageDes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSceneDescription(e.target.value.substring(0, MAX_LENGTH_DES))
  }

  const handleKeyDownSceneDes = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (composing) return
    if (event.key === 'Enter') {
      event.preventDefault()
      handleUpdateSceneDes()
    }
  }

  const handleUpdateSceneDes = async () => {
    if (
      !projectInfo ||
      !user ||
      sceneDescription.length > MAX_LENGTH_DES ||
      sceneDescription === dataScene.description ||
      (!sceneDescription && !dataScene.description)
    ) {
      return
    }
    await updateScene({
      projectId: projectInfo.projectId,
      organizationId: user.organizationId,
      sceneId: dataScene.sceneId,
      description: sceneDescription,
      langCode: TypeLanguage.ENLISH,
      contextCodec,
    })
  }

  return (
    <div className="py-1.5">
      <textarea
        id="message"
        rows={5}
        className={classNames(
          'w-full text-sm py-1.5 px-2 leading-5 text-white__op-900 placeholder:text-white__op-500 bg-white__op-50 rounded-lg focus:outline focus:outline-2 focus:outline-blue-500 font-lato resize-none',
          {
            '!outline-red-500':
              document.activeElement === ref.current &&
              sceneDescription &&
              sceneDescription.length === MAX_LENGTH_DES,
          }
        )}
        placeholder={t('sceneSetting.rightSideBar.scene.plDesScene')}
        value={sceneDescription}
        onChange={handleChageDes}
        onBlur={handleUpdateSceneDes}
        onKeyDown={handleKeyDownSceneDes}
        onCompositionStart={startComposition}
        onCompositionEnd={endComposition}
        ref={ref}
      />
      {ref.current &&
        document.activeElement === ref.current &&
        sceneDescription &&
        sceneDescription.length === MAX_LENGTH_DES && (
          <p className="px-2 text-red-500 font-lato text-xs leading-5">
            {t('validate.tooLong', {
              name: t('sceneSetting.rightSideBar.scene.sceneSum'),
              length: MAX_LENGTH_DES,
            })}
          </p>
        )}
    </div>
  )
}

export default DescriptionTextarea
