export const API_TYPE_ORGANIZATION = {
  MUTATION: {
    REGISTER_SERVICE_PACKAGE: 'registerServicePackage',
    CANCEL_CMS_SUBSCRIPTION: 'cancelCmsProductSubscription',
    UPGRADE_SERVICE_PACKAGE: 'upgradeServicePackage',
    DOWNGRADE_SERVICE_PACKAGE: 'downgradeServicePackage',
    UPGRADE_ADD_ON: 'upgradeAddOn',
    DOWNGRADE_ADD_ON: 'downgradeAddOn',
  },
  QUERY: {
    GET_LIST_CMS_PRODUCT: 'listCmsProduct',
    GET_CURRENT_PLAN_AND_CARD_INFO: 'getCurrentPlanAndCardInfo',
    GET_CMS_PRODUCT_BILLINGS: 'getCmsProductBillings',
  },
  SUBSCRIPTION: {
    ORGANIZATION_SUBSCRIPTION: 'organizationListener',
  },
}
