import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'

import Button from '@components/common/Button'
import { CARD_OPTIONS } from '@constants/payment'
import Input from '@components/common/Input'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import SpinComponent from '@components/common/SpinComponent'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { ICardInfo, IReqUpdatePaymentCard } from '@models/organization'
import { updatePaymentCardAction } from '@stores/organization/organization.action'

interface IPropsType {
  handleSetDataNewCard: (newCard: ICardInfo) => void
  handleCloseUpdateCard: () => void
}
const UpdateCreditCardForm: React.FC<IPropsType> = (
  props
): React.ReactElement => {
  const { handleCloseUpdateCard, handleSetDataNewCard } = props
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()

  const { user } = useAppSelector((state: RootState) => state.auth)
  const [fullName, setFullName] = useState<string>('')
  const [isUpdateCardInfo, setIsUpdateCardInfo] = useState<boolean>(false)
  const handleSubmitCard = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsUpdateCardInfo(true)
    try {
      const card = elements?.getElement(CardElement)
      if (!stripe || !card) {
        throw new Error('Card or stripe invalid!')
      }
      const { error, token } = await stripe.createToken(card, {
        name: fullName.toUpperCase(),
      })
      if (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'getCard',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
      if (token && token.card) {
        const card: ICardInfo = {
          lastFour: Number(token.card.last4),
          brand: token.card.brand,
        }
        await handleUpdatePaymentCard(token.id, card)
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getCard',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsUpdateCardInfo(false)
    }
  }

  const handleUpdatePaymentCard = async (token: string, card: ICardInfo) => {
    try {
      if (!user) {
        throw new Error('Error')
      }
      const { organizationId } = user
      const reqUpdate: IReqUpdatePaymentCard = {
        organizationId,
        sourceToken: token,
      }
      const res = await dispatch(updatePaymentCardAction(reqUpdate)).unwrap()
      if (res) {
        handleSetDataNewCard(card)
        handleCloseUpdateCard()
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'updatePaymentCard',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleChangeFullName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value)
  }
  return (
    <div className="w-full">
      <form onSubmit={handleSubmitCard}>
        <fieldset className="py-1.5">
          <div className="bg-white__op-50 py-1.5 px-3 rounded-lg font-lato">
            <CardElement options={CARD_OPTIONS} />
          </div>
        </fieldset>
        <Input
          color="ghost"
          placeholder={t('common.fullName')}
          className="!leading-6 uppercase"
          onChange={handleChangeFullName}
          value={fullName}
        />
        <div className="w-full flex justify-end">
          <Button.Normal
            type="submit"
            className="font-semibold text-sm"
            classNameContainer="!w-auto"
            title={t('common.update')}
            disabled={!fullName}
          />
        </div>
      </form>
      {isUpdateCardInfo && <SpinComponent />}
    </div>
  )
}

export default UpdateCreditCardForm
