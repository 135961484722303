import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import _ from 'lodash'
import Select, { IValueSelected } from '@components/common/selectInput/Select'
import {
  ICoordinates,
  IRequestSetInfoTag,
  TypeColorInfoTag,
} from '@models/sceneSetting'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'

interface IPropsType {
  dataInfoTag: {
    coordinates: ICoordinates
    color: string
    id: number
  }
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<unknown>
}
const InfoTagColorSelect: React.FC<IPropsType> = (props) => {
  const { dataInfoTag, handleSetInfoTag } = props
  const { t } = useTranslation()
  const dataColors = [
    {
      value: TypeColorInfoTag.BLUE,
      color: 'bg-blue-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.blue'),
    },
    {
      value: TypeColorInfoTag.TEAL,
      color: 'bg-teal-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.lightBlue'),
    },
    {
      value: TypeColorInfoTag.GREEN,
      color: 'bg-green-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.green'),
    },
    {
      value: TypeColorInfoTag.YERROW,
      color: 'bg-yerrow-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.yellow'),
    },
    {
      value: TypeColorInfoTag.RED,
      color: 'bg-red-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.red'),
    },
    {
      value: TypeColorInfoTag.MAGENDA,
      color: 'bg-magenda-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.mageda'),
    },
    {
      value: TypeColorInfoTag.GRAY,
      color: 'bg-gray-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.grey'),
    },
    {
      value: TypeColorInfoTag.WHITE,
      color: 'bg-white__op-500',
      title: t('sceneSetting.rightSideBar.infoTag.color.transparent'),
    },
  ]
  const optionsColor = dataColors.map((data) => {
    return {
      value: data.value,
      label: (
        <>
          <span className={classNames('w-5 h-5 mr-3', data.color)} />
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {data.title}
          </p>
        </>
      ),
    }
  })
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [typeColor, setTypeColor] = useState<IValueSelected>(
    _.find(optionsColor, (color) => color.value === dataInfoTag.color) || null
  )

  useEffect(() => {
    if (dataInfoTag) {
      setTypeColor(
        _.find(optionsColor, (color) => color.value === dataInfoTag.color) ||
          null
      )
    }
  }, [dataInfoTag.id, dataInfoTag.color])

  const handleChangeColor = async (value: IValueSelected) => {
    if (
      projectInfo &&
      user &&
      dataScenePreview &&
      value &&
      !Array.isArray(value)
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          color: value.value as string,
        },
      }
      const resUpdateColor = await handleSetInfoTag(req)
      if (resUpdateColor) {
        setTypeColor(value)
      }
    }
  }

  return (
    <Select
      value={typeColor}
      onChange={handleChangeColor}
      options={optionsColor}
      isLabelIcon={true}
    />
  )
}

export default React.memo(InfoTagColorSelect)
