import { gql } from '@apollo/client'

export const GET_MAP_INFOS = gql`
  query getMapInfos($projectId: Int!) {
    getMapInfos(projectId: $projectId) {
      mapId
      name
      originObjectKey
      thumbnailObjectKey
      metadata
      drop_items
      displayNumber
    }
  }
`
