import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Input from '../../common/Input'
import { TYPE, TypeLanguage } from '@models/common'
import { updateUserProfileAction } from '@stores/profile/profile.action'
import { openNotification } from '@utils/notification'
import { IDataEditProfile } from '@models/profile'
import moment from 'moment'
import Select, { IValueSelected } from '../../common/selectInput/Select'
import UpdateAvatarButton from './UpdateAvatarButton'
import UpdateFullNameInput from './UpdateFullNameInput'
import UpdateEmailForm from './UpdateEmailForm'
import UpdatePasswordForm from './UpdatePasswordForm'
import DeleteAccountButton from './DeleteAccountButton'
import ImageAvatarDefault from '@assets/images/default-avt.svg'
import IconEdit from '@assets/icons/common/edit-icon.svg'
import { commonActions, ILanguageType } from '@stores/common/common.reducer'
import { TYPE_COMMON } from '@stores/common/common.type'
import i18next from 'i18next'
import Button from '@components/common/Button'
import { helper } from '@utils/helper/common'
import ApplyPartnerCodeForm from './ApplyPartnerCodeForm'

const AccountSettingComponent: React.FC = (): React.ReactElement => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const lang = i18next.language
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const optionsLanguage = [
    {
      value: TypeLanguage.ENLISH,
      label: 'English',
    },
    {
      value: TypeLanguage.JAPAN,
      label: '日本語',
    },
  ]

  const [langCode, setLangCode] = useState<IValueSelected>(
    _.find(optionsLanguage, (data) => data.value === lang) || null
  )
  const [isOpenEditEmail, setIsOpenEditEmail] = useState<boolean>(false)
  const [isOpenEditPassword, setIsOpenEditPassword] = useState<boolean>(false)

  useEffect(() => {
    if (lang) {
      setLangCode(
        _.find(optionsLanguage, (data) => data.value === lang) || null
      )
    }
  }, [lang])

  const handleOpenEditEmail = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpenEditEmail(true)
  }
  const handleOpenEditPassword = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setIsOpenEditPassword(true)
  }

  const updateProfile = async (data: IDataEditProfile) => {
    try {
      const res = await dispatch(updateUserProfileAction(data)).unwrap()
      if (res.error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'updateProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch ({}) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProfile',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleChangeLanguage = (value: IValueSelected) => {
    setLangCode(value)
    if (value && !Array.isArray(value) && typeof value.value === 'string') {
      i18n.changeLanguage(value.value)
      dispatch(
        commonActions[TYPE_COMMON.REDUCERS.SET_LANGE](
          value.value as ILanguageType
        )
      )
    }
  }

  return (
    <>
      <div className="py-3 px-10 flex items-center">
        <label
          htmlFor="photo"
          className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0"
        >
          {t('profileSetting.account.photo')}
        </label>
        <div className="flex items-center">
          <div className="w-[4.5rem] h-[4.5rem] rounded-full overflow-hidden mr-4">
            <img
              src={
                userProfile?.info.avatar
                  ? userProfile.info.avatar
                  : ImageAvatarDefault
              }
              className="w-full h-full object-cover"
              alt=""
            />
          </div>
          <UpdateAvatarButton updateProfile={updateProfile} />
        </div>
      </div>
      <div className="px-10 flex items-center">
        <label
          htmlFor="fullname"
          className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0"
        >
          {t('common.fullName')}
        </label>
        <UpdateFullNameInput updateProfile={updateProfile} />
      </div>
      <hr className="mx-10 my-6 border-white__op-100" />
      <div className="px-10 flex ">
        <label
          htmlFor="fullname"
          className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0"
        >
          {t('common.email')}
        </label>
        {isOpenEditEmail ? (
          <UpdateEmailForm closeEditEmail={() => setIsOpenEditEmail(false)} />
        ) : (
          <Input
            value={userProfile?.info.email}
            placeholder="Your Email"
            icon={
              <img
                onClick={(e) => handleOpenEditEmail(e)}
                className="cursor-pointer"
                src={IconEdit}
                alt=""
              />
            }
            className="!leading-6 !px-3"
            iconPosition="right"
            color="ghost"
            disabled
          />
        )}
      </div>
      {userProfile && !userProfile.info.isRegisteredByGoogle && (
        <div className="px-10 flex">
          <label
            htmlFor="fullname"
            className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0"
          >
            {t('common.password')}
          </label>
          {isOpenEditPassword ? (
            <UpdatePasswordForm
              closeEditPassword={() => setIsOpenEditPassword(false)}
            />
          ) : (
            <Input
              value="........."
              type="password"
              placeholder="Your Password"
              icon={
                <img
                  className="cursor-pointer"
                  onClick={(e) => handleOpenEditPassword(e)}
                  src={IconEdit}
                  alt=""
                />
              }
              iconPosition="right"
              color="ghost"
              className="!leading-6 !px-3"
              disabled
            />
          )}
        </div>
      )}

      <hr className="mx-10 my-6 border-white__op-100" />
      <div className="px-10 flex items-center">
        <label
          htmlFor="fullname"
          className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0"
        >
          {t('profileSetting.account.displayLang')}
        </label>
        <div className="py-1.5 w-full">
          <Select
            value={langCode}
            onChange={handleChangeLanguage}
            options={optionsLanguage}
            classNameValue="!text-sm !leading-6"
            classNameSelect="!w-auto"
            classNameControl="!px-3"
            color="ghost"
          />
        </div>
      </div>

      <hr className="mx-10 my-6 border-white__op-100" />
      <div className="px-10 flex">
        <div className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0">
          {t('profileSetting.account.contractPlan')}
        </div>
        <div className="flex flex-col items-end">
          <p className="py-3 w-full font-lato text-sm leading-6">
            {t('profileSetting.account.contractPlanDescription')}
          </p>

          <Button.Normal
            classNameContainer="!w-fit"
            className="font-semibold text-sm"
            color="solid"
            title={t('common.contact')}
            onClick={() => helper.redirectToContact()}
          />
        </div>
      </div>

      <hr className="mx-10 my-6 border-white__op-100" />
      <div className="px-10 flex items-center">
        <label
          htmlFor="partnercode"
          className="w-60 py-3 text-base font-semibold text-white__op-900 flex-shrink-0"
        >
          {t('profileSetting.account.partnerCode')}
        </label>

        <div>
          {userProfile?.info.partnerCode ? (
            <p className="py-3 w-full font-lato text-sm leading-6">
              {userProfile?.info.partnerCode}
            </p>
          ) : (
            <ApplyPartnerCodeForm />
          )}
        </div>
      </div>

      <hr className="mx-10 my-6 border-white__op-100" />
      <div className="px-10 flex items-center justify-between">
        <div className="text-base font-semibold text-white__op-900">
          {t('profileSetting.account.delAccountAndData')}
        </div>
        <DeleteAccountButton />
      </div>
      <div className="px-10 py-1.5 font-lato text-sm leading-6 text-white__op-600">
        <p>
          {t('profileSetting.account.ownedPj')} :{' '}
          {userProfile?.lastActivities?.totalProjects}
        </p>
        <p>
          {t('profileSetting.account.accountCreated')} :{' '}
          {moment(userProfile?.info.createdAt).format('ll')}
        </p>
      </div>
    </>
  )
}

export default AccountSettingComponent
