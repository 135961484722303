export const LANGUAGE = {
  EN: 'en',
  JA: 'ja',
}

export const PATHNAME = {
  DASHBOARD: '/dashboard',
  PROFILE_SETTING: '/profile-setting',
  SCENE_SETTING: '/project',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PROJECT: '/project/:id',
  PLAN: '/plan',
  CONFIRM_PAYMENT: '/confirm-payment',
}

export const SIGNED_COOKIES = {
  POLICY: 'CloudFront-Policy',
  SIGNATURE: 'CloudFront-Signature',
  KEY_PAIR: 'CloudFront-Key-Pair-Id',
}

export const LINK_FEATURE_COMPARISION =
  'https://drive.google.com/file/d/1p_UpUJGcqJ7H-s-yRjzc7LvrPbtE6fAb/view'
export const LINK_USER_GUIDE =
  'https://actualinc.notion.site/WHERENESS-2b305676e0dd4941a14f0fa8fda993e3'
export const LINK_RELEASE_NOTE =
  'https://actualinc.notion.site/f5e798fc14544aaf8d7bca1a5d5411ff'
export const IS_SHOWED_ALERT_KEY = 'is_showed_alert'
export const DETAIL_PLAN_KEY = 'detail_plan'
export const LANG_KEY = 'i18nextLng'

//regex
export const VALID_EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/
export const VALID_PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
export const VALID_WHITE_SPACE_START_REGEX = /^\s+/g
