import { IResponseMapInfos } from '@models/map'
import { memo, useCallback, useRef, useState, useEffect } from 'react'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { useDispatch } from 'react-redux'
import { updateMapInfoAction } from '@stores/map/map.action'
import { actions as mapAction } from '@stores/map/map.reducer'
import { useTranslation } from 'react-i18next'
import { TYPE_MAP } from '@stores/map/map.type'
import classNames from 'classnames'
interface IPropsType {
  map: IResponseMapInfos
  index: number
  activeIndex: number
}
interface DropItem {
  id: number
  position: {
    x: number
    y: number
  }
  info: [
    {
      thumbnailUrl: string
      title: string
    }
  ]
  mapId: number
}
const MapImage: React.FC<IPropsType> = memo((props) => {
  const { map, index, activeIndex } = props
  const { mapInfos } = useAppSelector((state: RootState) => state.map)
  const { dropDataItem } = useAppSelector((state: RootState) => state.drag)
  const refImage = useRef<HTMLImageElement>(null)
  const refWrapper = useRef<HTMLDivElement>(null)
  const refCoordinateBase = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const [dropItems, setDropItems] = useState<any>([])
  const [hoveredThumbnailId, setHoveredThumbnailId] = useState<number | null>(
    null
  )
  const dispatch = useDispatch()
  const [selectedSceneId, setSelectedSceneId] = useState<number | null>(null)
  const { sidebarWidth } = useAppSelector((state: RootState) => state.scene)

  const THUMBNAIL_POSITION = {
    RIGHT_SIDE: 13,
    LEFT_SIDE: -125,
    TOP_SIDE: -125,
    BOTTOM_SIDE: 13,
  }

  const handleAdjustSize = useCallback(() => {
    if (!refImage.current || !refWrapper.current || !refCoordinateBase.current)
      return

    const { width, height } = refImage.current
    const { width: wrapperWidth, height: wrapperHeight } =
      refWrapper.current.getBoundingClientRect()
    const imageAspect = width / height
    const wrapperAspect = wrapperWidth / wrapperHeight
    const ua = navigator.userAgent.toLowerCase()
    const isMacSafari =
      ua.includes('macintosh') &&
      ua.includes('safari') &&
      !ua.includes('chrome') &&
      !ua.includes('edg')

    if (imageAspect > wrapperAspect) {
      refImage.current.style.width = refCoordinateBase.current.style.width =
        '100%'
      refImage.current.style.height = refCoordinateBase.current.style.height =
        'auto'
    } else {
      refImage.current.style.width = refCoordinateBase.current.style.width =
        'auto'
      if (isMacSafari) {
        // Countermeasure for collapsed layout in mac safari
        refImage.current.style.height =
          refCoordinateBase.current.style.height = `calc((100svw - 354px - ${sidebarWidth}px) * 626 / 886)`
        refImage.current.style.minHeight =
          refCoordinateBase.current.style.minHeight = '313px'
        refImage.current.style.maxHeight =
          refCoordinateBase.current.style.maxHeight = 'calc(100svh - 196px)'
      } else {
        refImage.current.style.height = refCoordinateBase.current.style.height =
          '100%'
      }
    }
  }, [])

  useEffect(() => {
    const filterDropItems = dropItems?.filter((item: any) => {
      return item.mapId === map.mapId
    })
    setDropItems(filterDropItems)
  }, [map.mapId, activeIndex])

  const checkSceneData = useAppSelector((state: RootState) => state.drag)

  useEffect(() => {
    if (mapInfos[activeIndex]?.drop_items) {
      setDropItems(mapInfos[activeIndex].drop_items)
    }
  }, [mapInfos, activeIndex])

  const handleDrop = useCallback(
    async (e: React.DragEvent<HTMLImageElement>) => {
      e.preventDefault()
      const img = refImage.current
      if (!img) return
      const x = (e.nativeEvent.offsetX / img.width) * 100
      const y = (e.nativeEvent.offsetY / img.height) * 100
      const mapId = map.mapId
      const id = e.dataTransfer.getData('text/plain')
      if (!id) {
        return
      }
      let updatedDropItems = dropItems.map((item: DropItem) =>
        item.id === id ? { ...item, mapId, position: { x, y } } : item
      )
      if (!dropItems.find((item: DropItem) => item.id === id)) {
        updatedDropItems.push({
          id,
          mapId,
          position: { x, y },
          info: dropDataItem,
        })
      }
      setDropItems(updatedDropItems)
      if (!mapInfos) {
        return
      }
      try {
        await dispatch(
          updateMapInfoAction({
            mapId: mapInfos[activeIndex].mapId,
            name: mapInfos[activeIndex]?.name || mapInfos[activeIndex].mapName,
            displayNumber: mapInfos[activeIndex].displayNumber,
            drop_items: updatedDropItems,
          })
        ).unwrap()
        const renamedMapInfos = mapInfos.map((map) =>
          map.mapId === mapInfos[activeIndex].mapId
            ? { ...map, drop_items: updatedDropItems }
            : map
        )
        dispatch(mapAction[TYPE_MAP.REDUCERS.SET_MAP_INFOS](renamedMapInfos))
      } catch (error) {
        console.error('Error updating map info:', error)
      }
    },
    [dropItems, dispatch, checkSceneData, mapInfos, t]
  )
  const handleDragStart = (
    e: React.DragEvent<HTMLImageElement>,
    id: number
  ) => {
    setSelectedSceneId(id)
    e.dataTransfer.setData('text/plain', id.toString())
  }
  const onDelete = async (id: number) => {
    const updatedDropItems = dropItems.filter(
      (item: DropItem) => item.id !== id
    )
    setDropItems(updatedDropItems)
    try {
      await dispatch(
        updateMapInfoAction({
          mapId: mapInfos[activeIndex].mapId,
          name: mapInfos[activeIndex]?.name || mapInfos[activeIndex].mapName,
          displayNumber: mapInfos[activeIndex].displayNumber,
          drop_items: updatedDropItems,
        })
      ).unwrap()
      setSelectedSceneId(null)
    } catch (error) {
      console.error('Error updating map info:', error)
    }
  }
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        event.key === 'Delete' ||
        (event.key === 'Backspace' && selectedSceneId)
      ) {
        onDelete(selectedSceneId)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [selectedSceneId, dropItems, mapInfos, activeIndex, dispatch])

  return (
    <div ref={refWrapper} className="modal-map__primary__inner">
      <div ref={refCoordinateBase} className="modal-map__droparea">
        <img
          ref={refImage}
          className="modal-map__primary__image"
          src={map.originObjectKey}
          alt={map.name}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onLoad={handleAdjustSize}
        />
        {dropItems &&
          dropItems.map((item: DropItem, index: number) => {
            const x =
              item.position.x > 50
                ? THUMBNAIL_POSITION.LEFT_SIDE
                : THUMBNAIL_POSITION.RIGHT_SIDE
            const y =
              item.position.y > 50
                ? THUMBNAIL_POSITION.TOP_SIDE
                : THUMBNAIL_POSITION.BOTTOM_SIDE
            const thumbnailPosition = { x: x, y: y }

            return (
              <div
                key={`dropped_${item.id}_${index}`}
                style={{
                  position: 'absolute',
                  top: `${item.position.y}%`,
                  left: `${item.position.x}%`,
                }}
              >
                <div
                  style={{
                    height: '26px',
                    width: '26px',
                    borderWidth: '5px',
                    borderStyle: 'solid',
                    borderColor:
                      selectedSceneId === item.id ? '#0046D7' : '#8EC2F5',
                    borderRadius: '50%',
                    boxShadow:
                      '0px 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 4px 12px 0px rgba(0, 0, 0, 0.1)',
                    transform: 'translate(-50%, -50%)',
                    cursor: 'pointer',
                  }}
                  draggable
                  onClick={() => setSelectedSceneId(item.id)}
                  onDragStart={(e) => handleDragStart(e, item.id)}
                  onMouseEnter={() => setHoveredThumbnailId(item.id)}
                  onMouseLeave={() => setHoveredThumbnailId(null)}
                />
                {hoveredThumbnailId === item.id && (
                  <div
                    className={classNames(
                      'absolute w-28 h-28 bg-rgba[255, 255, 255, 0.16] z-50',
                      { invisible: hoveredThumbnailId !== item.id }
                    )}
                    style={{
                      top: thumbnailPosition.y,
                      left: thumbnailPosition.x,
                    }}
                  >
                    <img
                      src={item.info?.thumbnailUrl}
                      alt={item.info?.title}
                      className="h-28 w-28 border-2 rounded-lg"
                    />
                    <div className="absolute bottom-0 left-0 right-0 rounded-b-lg bg-[rgba(0,0,0,0.56)] text-[rgba(255,255,255,0.97)] text-center py-1 px-2 font-lato text-xs font-normal leading-5 w-28 ]  text-ellipsis">
                      {item.info?.title}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
})
export default MapImage
