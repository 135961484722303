export const API_TYPE_AUTH = {
  MUTATION: {
    LOGIN: 'login',
    LOGIN_WITH_GOOGLE: 'authenGoogle',
    CREATE_USER: 'createUser',
    UPDATE_USER_PROFILE: 'updateUserProfile',
    UPDATE_REGISTRATION: 'updateRegistration',
    SEND_MAIL_CONFIRM_REGISTER: 'sendMailConfirmRegister',
    CONFIRM_CREATE_ACCOUNT: 'confirmCreateAccount',
    FORGOT_PASSWORD: 'forgotPassword',
    RESET_PASSWORD: 'resetPassword',
    REFRESH_TOKEN: 'refreshToken',
    CHANGE_PASSWORD: 'changePassword',
    LOGOUT: 'logout',
  },
  QUERY: {
    GET_SIGNED_URL_UPLOAD_MEDIA: 'getSignedUrlUploadMedia',
  },
}
