import React from 'react'
import { IMedia } from '@models/media'
import { useTranslation } from 'react-i18next'
import FixedTooltip, { TooltipPlacement } from '@components/common/FixedTooltip'

interface PropTypes {
  media: IMedia
  tooltipPosition?: TooltipPlacement
}

const ConvertingMedia: React.FC<PropTypes> = ({
  media,
  tooltipPosition = 'right',
}) => {
  const { t } = useTranslation()

  return (
    <FixedTooltip
      title={t('popup.mediaLib.converting')}
      position={tooltipPosition}
    >
      <div className="flex items-center px-4 py-1 hover:cursor-default font-lato text-xs">
        <div className="mr-2 w-8 h-8 rounded-lg flex-shrink-0 bg-white__op-300" />
        <span className="truncate w-full text-white__op-800 mr-2">
          {media.name}
        </span>
        <div className="flex-none flex w-5 h-5 justify-center items-center">
          <div className="bg-blue-300 h-[5px] w-[16.67px]">
            <div
              className="bg-gray-700 h-full"
              style={{ width: media.progress + '%' }}
            ></div>
          </div>
        </div>
      </div>
    </FixedTooltip>
  )
}

export default ConvertingMedia
