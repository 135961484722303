import { createAsyncThunk } from '@reduxjs/toolkit'
import { graphqlApi } from '../../api/graphql'
import {
  CREATE_SCENE,
  CREATE_SPACE,
  DELETE_SCENE,
  DELETE_SPACE,
  REMOVE_INFO_TAG,
  REMOVE_MARKER,
  ROLLBACK_VERSION,
  SET_INFO_TAG,
  SET_MARKER,
  UPDATE_SCENE,
  UPDATE_SPACE,
} from '../../graphql/sceneSetting/mutation'
import {
  GET_LIST_SPACE_AND_SCENE,
  GET_SCENE_INFO,
  GET_SIGNED_COOKIES,
} from '../../graphql/sceneSetting/query'
import { API_TYPE_SCENE_SETTING } from '../../graphql/sceneSetting/type'
import {
  IRequestCreateScene,
  IRequestCreateSpace,
  IRequestDeleteScene,
  IRequestDeleteSpace,
  IRequestGetScene,
  IRequestGetSpaceAndScene,
  IRequestRemoveMarker,
  IRequestRollbackVer,
  IRequestSetInfoTag,
  IRequestSetMarker,
  IRequestUpdateScene,
  IRequestUpdateSpace,
} from '@models/sceneSetting'
import { TYPE_SCENE } from './scene.type'

export const createSpaceAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.CREATE_SPACE,
  async (createSpaceInput: IRequestCreateSpace) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_SPACE,
      API_TYPE_SCENE_SETTING.MUTATION.CREATE_SPACE,
      { createSpaceInput }
    )
    return result
  }
)

export const createSceneAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.CREATE_SCENE,
  async (input: IRequestCreateScene) => {
    const result = await graphqlApi.mutationRequest(
      CREATE_SCENE,
      API_TYPE_SCENE_SETTING.MUTATION.CREATE_SCENE,
      { input }
    )
    return result
  }
)

export const getSpaceAndSceneAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.GET_SPACE_AND_SCENE,
  async (input: IRequestGetSpaceAndScene) => {
    const result = await graphqlApi.queryRequest(
      GET_LIST_SPACE_AND_SCENE,
      API_TYPE_SCENE_SETTING.QUERY.GET_SPACE_AND_SCENE,
      { input }
    )
    return result
  }
)

export const deleteSpaceAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.DELETE_SPACE,
  async (input: IRequestDeleteSpace) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_SPACE,
      API_TYPE_SCENE_SETTING.MUTATION.DELETE_SPACE,
      { input }
    )
    return result
  }
)

export const deleteSceneAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.DELETE_SCENE,
  async (input: IRequestDeleteScene) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_SCENE,
      API_TYPE_SCENE_SETTING.MUTATION.DELETE_SCENE,
      { input }
    )
    return result
  }
)

export const getSceneAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.GET_SCENE_INFO,
  async (input: IRequestGetScene) => {
    const result = await graphqlApi.queryRequest(
      GET_SCENE_INFO,
      API_TYPE_SCENE_SETTING.QUERY.GET_SCENE_INFO,
      { input }
    )
    return result
  }
)

export const updateSpaceAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.UPDATE_SPACE,
  async (updateSpaceInput: IRequestUpdateSpace) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_SPACE,
      API_TYPE_SCENE_SETTING.MUTATION.UPDATE_SPACE,
      { updateSpaceInput }
    )
    return result
  }
)

export const updateSceneAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.UPDATE_SCENE,
  async (input: IRequestUpdateScene) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_SCENE,
      API_TYPE_SCENE_SETTING.MUTATION.UPDATE_SCENE,
      { input }
    )
    return result
  }
)

export const getSignedCookiesAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.GET_SIGNED_COOKIES,
  async (projectId: number) => {
    const result = await graphqlApi.queryRequest(
      GET_SIGNED_COOKIES,
      API_TYPE_SCENE_SETTING.QUERY.GET_SIGNED_COOKIES,
      { projectId }
    )
    return result
  }
)

export const setMarkerAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.SET_MARKER,
  async (input: IRequestSetMarker) => {
    const result = await graphqlApi.mutationRequest(
      SET_MARKER,
      API_TYPE_SCENE_SETTING.MUTATION.SET_MARKER,
      { input }
    )
    return result
  }
)

export const removeMarkerAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.REMOVE_MARKER,
  async (input: IRequestRemoveMarker) => {
    const result = await graphqlApi.mutationRequest(
      REMOVE_MARKER,
      API_TYPE_SCENE_SETTING.MUTATION.REMOVE_MARKER,
      { input }
    )
    return result
  }
)

export const setInfoTagAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.SET_INFO_TAG,
  async (input: IRequestSetInfoTag) => {
    const result = await graphqlApi.mutationRequest(
      SET_INFO_TAG,
      API_TYPE_SCENE_SETTING.MUTATION.SET_INFO_TAG,
      { input }
    )
    return result
  }
)

export const removeInfoTagAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.REMOVE_INFO_TAG,
  async (input: IRequestSetInfoTag) => {
    const result = await graphqlApi.mutationRequest(
      REMOVE_INFO_TAG,
      API_TYPE_SCENE_SETTING.MUTATION.REMOVE_INFO_TAG,
      { input }
    )
    return result
  }
)

export const rollbackVersionAction = createAsyncThunk(
  TYPE_SCENE.ACTIONS.ROLLBACL_VERSION,
  async (input: IRequestRollbackVer) => {
    const result = await graphqlApi.mutationRequest(
      ROLLBACK_VERSION,
      API_TYPE_SCENE_SETTING.MUTATION.ROLLBACK_VERSION,
      { input }
    )
    return result
  }
)
