import React from 'react'
import _ from 'lodash'
import {
  TreeViewItem,
  TreeViewItemRow,
} from '@components/common/draggableTree/react-draggable-tree'
import { Node, TypeOfNode } from '@components/common/draggableTree/node'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'

import {
  actions as sceneActions,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import LayerComponent from './listLayer/LayerComponent'
import SpaceComponent from './listSpace/SpaceComponent'
import SceneComponent from './listScene/SceneComponent'
import { helper } from '@utils/helper/common'

interface NodeTreeViewItem extends TreeViewItem {
  readonly node: Node
}
interface IPropsType {
  rows: readonly TreeViewItemRow<NodeTreeViewItem>[]
  index: number
  item: NodeTreeViewItem
  depth: number
  indentation: number
}
const TreeRowSpaceOrScene: React.FC<IPropsType> = ({ item }) => {
  const node = item.node
  const dispatch = useAppDispatch()
  const { dataEditContextMenuLSS, isEditNameLSS, dataSpaceAndScene } =
    useAppSelector((state: RootState) => state.scene)
  const onCollapseButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (node.metadata && dataSpaceAndScene) {
      const newListLayer = helper.setCollapsedLayerOrSpace(
        dataSpaceAndScene,
        node.metadata?.id,
        node.type
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](newListLayer)
      )
    }
  }

  const checkEditName = (id: number, type: string) => {
    if (
      dataEditContextMenuLSS &&
      dataEditContextMenuLSS.type === type &&
      isEditNameLSS &&
      dataEditContextMenuLSS.data.id === id
    ) {
      return true
    }
    return false
  }

  const closeUpdateName = () => {
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_IS_EDIT_NAME_LSS](false))
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_DATA_EDIT_CONTEXT_MENU_LSS](null)
    )
  }

  if (
    node.type === TypeOfNode.LAYER &&
    node.metadata &&
    dataSpaceAndScene &&
    dataSpaceAndScene?.length > 1
  ) {
    const layer = _.find(
      dataSpaceAndScene,
      (layer) => layer.info.id === node.metadata?.id
    )
    if (layer)
      return (
        <LayerComponent
          item={layer}
          isEditing={checkEditName(node.metadata.id, TYPE_SETTING.LAYER)}
          closeUpdateName={closeUpdateName}
          onClollap={onCollapseButtonClick}
        />
      )
  }
  if (node.type === TypeOfNode.SPACE && node.metadata) {
    const layer = _.find(
      dataSpaceAndScene,
      (layer) => layer.info.id === node.parent?.metadata?.id
    )
    const space = _.find(
      layer?.spaces,
      (space) => space.info.id === node.metadata?.id
    )
    if (layer && space)
      return (
        <SpaceComponent
          item={layer}
          itemS={space}
          isEditing={checkEditName(node.metadata.id, TYPE_SETTING.SPACE)}
          closeUpdateName={closeUpdateName}
          onClollap={onCollapseButtonClick}
        />
      )
  }
  if (node.type === TypeOfNode.SCENE && node.metadata) {
    const layer = _.find(
      dataSpaceAndScene,
      (layer) => layer.info.id === node.parent?.parent?.metadata?.id
    )
    const space = _.find(
      layer?.spaces,
      (space) => space.info.id === node.parent?.metadata?.id
    )
    const scene = _.find(
      space?.scenes,
      (scene) => scene.info.id === node.metadata?.id
    )
    if (layer && space && scene)
      return (
        <SceneComponent
          itemLayer={layer}
          itemSpace={space}
          itemScene={scene}
          isEditing={checkEditName(node.metadata.id, TYPE_SETTING.SCENE)}
          closeUpdateName={closeUpdateName}
          isSelected={node.selected}
        />
      )
  }
  return <></>
}

export default TreeRowSpaceOrScene
