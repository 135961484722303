import { gql } from '@apollo/client'

export const CREATE_MAP_INFO = gql`
  mutation createMapInfo($createMapInfoInput: [CreateMapInfoInput!]!) {
    createMapInfo(createMapInfoInput: $createMapInfoInput) {
      id
    }
  }
`

export const UPDATE_MAP_INFO = gql`
  mutation updateMapInfo($updateMapInfoInput: [UpdateMapInfoInput!]!) {
    updateMapInfo(updateMapInfoInput: $updateMapInfoInput) {
      id
    }
  }
`

export const DELETE_MAP = gql`
  mutation deleteMap($deleteMapInput: DeleteMapInput!) {
    deleteMap(deleteMapInput: $deleteMapInput) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`
