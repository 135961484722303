import { IMediaFolder } from '../../../../../models/media'
import { useAppDispatch, useAppSelector } from '../../../../../store/hook'
import { RootState } from '../../../../../store/store'
import { updateMediaFolderAction } from '../../../../../store/project/project.action'
import { openNotification } from '../../../../../utils/notification'
import { TYPE } from '../../../../../models/common'
import { useTranslation } from 'react-i18next'
import { actions } from '../../../../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../../../../store/project/project.type'
import Input from '@components/common/Input'

interface PropTypes {
  folder: IMediaFolder
  setEditedFolder: React.Dispatch<React.SetStateAction<number | null>>
}

const EditingFolder: React.FC<PropTypes> = ({ folder, setEditedFolder }) => {
  const { projectInfo, editedItemNameInAsset } = useAppSelector(
    (state: RootState) => state.project
  )

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const handleChangeEditedItemName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_EDITED_ITEM_NAME_IN_ASSET](
        e.target.value
      )
    )
  }

  const handleUpdateFolder = async () => {
    setEditedFolder(null)
    if (editedItemNameInAsset === folder.name || !editedItemNameInAsset) {
      return
    }

    try {
      if (!projectInfo?.projectId) {
        throw new Error('projectId is invalid')
      }
      const res = await dispatch(
        updateMediaFolderAction({
          projectId: projectInfo?.projectId,
          folderId: folder.id,
          name: editedItemNameInAsset,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch (error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateMediaFolder',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleEnterToUpdateFolder = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      handleUpdateFolder()
    }
  }

  return (
    <div className="flex px-2 items-center h-10 bg-blue-700">
      <Input
        className="w-full text-xs font-semibold text-white__op-600 h-6 bg-gray-800 px-2 rounded-lg"
        customClassContainer="py-0"
        type="text"
        value={editedItemNameInAsset}
        onChange={handleChangeEditedItemName}
        onBlur={handleUpdateFolder}
        handleKeyDown={handleEnterToUpdateFolder}
        isValid={editedItemNameInAsset ? false : true}
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
      />
    </div>
  )
}

export default EditingFolder
