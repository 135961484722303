import React, { useEffect, useState } from 'react'
import Icon from '@components/common/Icon'
//icon
import IconAdd from '@assets/icons/common/add-icon.svg'
import IconDelete from '@assets/icons/common/delete-icon.svg'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'
import { RootState } from '@stores/store'
import { IRequestUpdateScene } from '@models/sceneSetting'
import { UNSELECT_MEDIA } from '@models/project'
import { useTranslation } from 'react-i18next'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import {
  DEFAULT_WIDTH_RIGHT_SETTING,
  REF_SELECT_MEDIA,
} from '@constants/sceneSetting'

interface IPropTypes {
  dataScene: {
    sceneId: number
    thumbnailUrl: string
    contextThumbnailUrl: string
  }
  updateScene: (dataReq: IRequestUpdateScene) => Promise<unknown>
}
const ThumbSceneComponent: React.FC<IPropTypes> = (
  props
): React.ReactElement => {
  const { t } = useTranslation()
  const { dataScene, updateScene } = props
  const dispatch = useAppDispatch()
  const { mediaSelected, projectInfo, modeOfMediaLibPopup } = useAppSelector(
    (state: RootState) => state.project
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { contextCodec } = useAppSelector((state: RootState) => state.scene)

  const [isUpdateThubnail, setIsUpdateThubnail] = useState<boolean>(false)

  useEffect(() => {
    if (
      mediaSelected &&
      isUpdateThubnail &&
      projectInfo &&
      user &&
      modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.IMAGE
    ) {
      const { id } = mediaSelected
      updateScene({
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataScene.sceneId,
        thumbnailId: id === UNSELECT_MEDIA ? null : id,
        contextCodec,
      }).finally(() => {
        setIsUpdateThubnail(false)
        dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
            MODE_OF_MEDIA_LIB_POPUP.OFF
          )
        )
      })
    }
  }, [mediaSelected, isUpdateThubnail])

  const handleOpenImageAsset = (newMode: number) => {
    if (
      modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.OFF ||
      modeOfMediaLibPopup !== newMode
    ) {
      const thumbSceneElm = document.getElementById(
        REF_SELECT_MEDIA.THUMB_OF_SCENE
      )
      if (thumbSceneElm) {
        let offsetTop = thumbSceneElm.offsetTop
        if (offsetTop + 480 > window.innerHeight) {
          offsetTop = window.innerHeight - 480
        }
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]({
            top: offsetTop,
            right: DEFAULT_WIDTH_RIGHT_SETTING.OTHER + 4,
          })
        )
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.IMAGE
        )
      )
      setIsUpdateThubnail(true)
    } else {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
      dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
      setIsUpdateThubnail(false)
    }
  }

  const handleRomoveThumbnail = async () => {
    if (user && projectInfo && dataScene.sceneId) {
      await updateScene({
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
        sceneId: dataScene.sceneId,
        thumbnailId: null,
        contextCodec,
      })
    } else {
      throw new Error('SceneId invalid')
    }
  }

  const ButtonOpenAsset = () => (
    <button
      className="p-1"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        handleOpenImageAsset(MODE_OF_MEDIA_LIB_POPUP.IMAGE)
      }}
    >
      <div className="py-1.5 pl-2 pr-3 flex">
        <Icon.ActionIcon
          customClass="mr-3"
          icon={<img src={IconAdd} alt="" />}
        />
        <span className="text-xs leading-5 font-semibold text-blue-300">
          {t('common.selectImage')}
        </span>
      </div>
    </button>
  )

  return (
    <div className="py-1.5 h-[13.75rem]" id={REF_SELECT_MEDIA.THUMB_OF_SCENE}>
      {!dataScene.thumbnailUrl && !dataScene.contextThumbnailUrl ? (
        <div className="w-full h-full flex items-end bg-white__op-50 rounded-lg group">
          <div className="justify-between items-center w-full hidden group-hover:!flex">
            <ButtonOpenAsset />
          </div>
        </div>
      ) : dataScene.thumbnailUrl ? (
        <div className="w-full h-full relative group rounded-lg overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={dataScene.thumbnailUrl}
            alt=""
          />
          <div className="absolute inset-0 bg-black__op-500 justify-between items-end w-full hidden group-hover:!flex z-10">
            <ButtonOpenAsset />
            <Icon.ButtonIcon
              icon={<img src={IconDelete} alt="" />}
              size="sm"
              onClick={handleRomoveThumbnail}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-full relative group rounded-lg overflow-hidden">
          <img
            className="w-full h-full object-cover"
            src={dataScene.contextThumbnailUrl}
            alt=""
          />
          <div className="absolute inset-0 bg-black__op-500 justify-between items-end w-full hidden group-hover:!flex">
            <ButtonOpenAsset />
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(ThumbSceneComponent)
