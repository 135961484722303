class LocalStorageUtils {
  public getValueFromLocalStorage(key: string, isParse?: boolean) {
    const data = localStorage.getItem(key)
    if (data) {
      return isParse ? JSON.parse(data) : data
    }
    return null
  }

  public setValueToLocalStorage(
    key: string,
    value: string | object | number | boolean
  ) {
    value = typeof value === 'string' ? value : JSON.stringify(value)
    localStorage.setItem(key, value)
  }
}

export const localStorageUtils = new LocalStorageUtils()
