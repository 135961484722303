import classNames from 'classnames'
import React from 'react'

type TooltipPlacement =
  | 'top'
  | 'left'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'leftTop'
  | 'leftBottom'
  | 'rightTop'
  | 'rightBottom'

interface Props {
  children: React.ReactNode
  position?: TooltipPlacement
  title: string
  tooltipClassName?: string
  containerClassName?: string
}
const Tooltip: React.FC<Props> = (props) => {
  const { children, position, title, tooltipClassName, containerClassName } =
    props
  const renderPlacement = () => {
    switch (position) {
      case 'right':
        return 'left-14 top-1/2 -translate-y-1/2'
      case 'left':
        return 'right-14 top-1/2 -translate-y-1/2'
      case 'bottom':
        return 'left-1/2 top-12 -translate-x-1/2'
      case 'top':
        return 'left-1/2 bottom-12 -translate-x-1/2'
      default:
        return 'left-14 top-1/2 -translate-y-1/2'
    }
  }
  return (
    <div className={classNames(containerClassName)}>
      <div className="group relative">
        {children}
        <div
          className={classNames(
            'text-xs absolute hidden group-hover:!inline-block px-2 py-1 bg-black__op-500 text-white whitespace-nowrap rounded z-10 font-lato',
            renderPlacement(),
            tooltipClassName
          )}
        >
          {title}
        </div>
      </div>
    </div>
  )
}

export default Tooltip
