import { useAppSelector, useAppDispatch } from '@stores/hook'
import { actions as projectActions } from '../store/project/project.reducer'
import { RootState } from '@stores/store'
import { openNotification } from '../utils/notification'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { S3MultipleUploader } from '../api/s3'
import { TYPE, TYPE_ERROR } from '../models/common'
import { TYPE_PROJECT } from '../store/project/project.type'
import { useStorage } from '../hooks/useStorage'
import { mediaLibHelper } from '../utils/helper/mediaLib'
import {
  TYPE_MEDIA,
  IRequestUploadMedia,
  IMedia,
  TYPE_STATUS_UPLOAD,
} from '../models/media'
import { IOnProgressFnEvent } from '@models/s3'
import { uploadMediaAction } from '../store/project/project.action'
import { createMapInfoAction } from '@stores/map/map.action'
import { MAX_SIZE_FILE } from '@constants/media'

type handleUploadMediasType = {
  files: FileList
  is360: boolean
  selectedFolder: number | null
  mediaTypes: TYPE_MEDIA[]
}

export const useUploadMedias = () => {
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { mapInfos } = useAppSelector((state: RootState) => state.map)
  const dispatch = useAppDispatch()
  const { updateCurrentStorage } = useStorage()
  const { t } = useTranslation()

  const uploadMediasToS3 = useCallback(
    async (
      files: FileList,
      projectId: number,
      is360: boolean,
      selectedFolder: number | null,
      mediaTypes: TYPE_MEDIA[]
    ) => {
      const objectKeys: string[] = []
      const uploadPromises = Array.from(files).map(async (item) => {
        if (item.size > MAX_SIZE_FILE.IMAGE) {
          openNotification({
            type: TYPE.ERROR,
            key: 'uploadMedia',
            message: t('notification.imageFileSize.titleFirst'),
            description: t('notification.imageFileSize.titleSecond'),
          })
          return
        }
        const uploader = new S3MultipleUploader({
          file: item,
          projectId,
          is360: is360,
          mediaType: TYPE_MEDIA.MAP,
        })
        const resSignedUrl = await uploader.initialize()
        if (!resSignedUrl.data) {
          setShowErrorUpload(resSignedUrl.error.message)
          return
        }
        const { key, bucket } = uploader.getFileInfo()
        if (!key || !bucket) {
          throw new Error('Key or bucket invalid')
        }
        const mediaType = mediaLibHelper.detectMediaType(item.type, is360)
        const media: IMedia = {
          key,
          mediaType,
          name: item.name,
          status: TYPE_STATUS_UPLOAD.UPLOADING,
          progress: 0,
        }
        objectKeys.push(key)
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.ADD_MEDIA]({
            projectId,
            folderId: selectedFolder,
            media,
          })
        )
        uploader.onProgress((event: IOnProgressFnEvent) => {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.UPDATE_MEDIA]({
              folderId: selectedFolder,
              media: {
                ...media,
                progress: event.percentage,
              },
            })
          )
        })
        await uploader.start()

        await createRecordsOfMedias(
          {
            projectId,
            fileInput: {
              name: item.name,
              mediaType,
              folderId: selectedFolder,
              key,
              bucket,
            },
          },
          mediaTypes
        )

        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.REMOVE_UPLOADING_MEDIAS_GLOBAL]({
            projectId,
            media,
            folderId: selectedFolder,
          })
        )
      })
      await Promise.all(uploadPromises)
      await createMapInfo(objectKeys)
    },
    [mapInfos]
  )

  const setShowErrorUpload = (key: string) => {
    updateCurrentStorage()
    switch (key) {
      case TYPE_ERROR.RESOURCE_IS_LOCKED:
        if (userProfile) {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_OVER_SPACE_S3](
              true
            )
          )
          break
        }
      case TYPE_ERROR.PUBLISH_PROJECT_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_PRJ_STORAGE_OVER_LIMIT](
            true
          )
        )
        break
      case TYPE_ERROR.ORGANIZATION_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_ORG_STORAGE_OVER_LIMIT](
            true
          )
        )
        break

      default:
        throw new Error()
    }
  }

  const createRecordsOfMedias = async (
    data: IRequestUploadMedia,
    mediaTypes: TYPE_MEDIA[]
  ) => {
    const res = await dispatch(uploadMediaAction(data)).unwrap()
    if (res.error) {
      setShowErrorUpload(res.error.message)
      return
    }
    if (!res.data) {
      return
    }
    if (res.data && mediaTypes.includes(res.data.mediaType)) {
      const id = res.data.resourceId
      const type = res.data.mediaType
      const url = res.data.url
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT]({
          id,
          type,
          url,
        })
      )
    }
  }

  const createMapInfo = useCallback(
    async (objectKeys: string[]) => {
      const projectId = projectInfo?.projectId
      const userId = user?.userId
      if (!projectId || !userId) {
        return
      }
      const mapInfoData = objectKeys.map((objectKey, index) => ({
        objectKey,
        name: `Map ${(mapInfos?.length ?? 0) + index + 1}`,
        projectId,
        userId,
      }))
      await dispatch(createMapInfoAction(mapInfoData)).unwrap()
    },
    [projectInfo, user, mapInfos]
  )

  const handleUploadMedias = useCallback(
    async ({
      files,
      is360,
      selectedFolder,
      mediaTypes,
    }: handleUploadMediasType) => {
      const projectId = projectInfo?.projectId
      if (!projectId) {
        throw new Error('ProjectId is invalid')
      }

      try {
        await uploadMediasToS3(
          files,
          projectId,
          is360,
          selectedFolder,
          mediaTypes
        )
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'uploadMedia',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    },
    [projectInfo, mapInfos]
  )

  return { handleUploadMedias }
}
