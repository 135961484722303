import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  IProject,
  IRequestListProject,
  IRequestUpdateProjectPublish,
  TypePublicProject,
} from '@models/project'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import {
  deleteProjectAction,
  updateProjectPublishAction,
} from '@stores/project/project.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { actions as projectAction } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import Button from '@components/common/Button'
import Icon from '@components/common/Icon'
import useOnClickOutside from '@components/common/hooks'
import PopupConfirm from '@components/common/PopupConfirm'
//icon
import IconEdit from '@assets/icons/common/edit-icon.svg'
import IconPrivate from '@assets/icons/common/private-icon.svg'
import IconPublic from '@assets/icons/common/public-icon.svg'
// import IconDuplicate from '@assets/icons/common/duplicate-icon.svg'
import IconDelete from '@assets/icons/common/delete-icon.svg'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { ProjectPublishStatusName } from '@models/organization'
import { useNavigate } from 'react-router-dom'
import { PATHNAME } from '@constants/common'

interface IPropsType {
  index: number
  project: IProject | null
  getListProject: (reqGetList: IRequestListProject) => void
  handleGetInfoProject: (projectId: number) => void
  clearProjectEdit: () => void
}
const MoreSettingPopup: React.FC<IPropsType> = (props): React.ReactElement => {
  const { project, getListProject, handleGetInfoProject, clearProjectEdit } =
    props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { listProject } = useAppSelector((state: RootState) => state.project)
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState<boolean>(false)
  const [idProjectDelete, setIdProjectDelete] = useState<number | null>(null)
  const refs = useRef<HTMLDivElement>(null)

  useOnClickOutside(refs, () => closeCurrentPopupMoreSetting())

  const handleChangePublicProject = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault()
    e.stopPropagation()
    if (project && user) {
      const req: IRequestUpdateProjectPublish = {
        projectId: project.projectId,
        organizationId: user.organizationId,
        status: TypePublicProject.PRIVATE,
      }
      const res = await updateProject(req)
      if (res.data) {
        closeCurrentPopupMoreSetting()
      }
    }
  }

  const updateProject = async (req: IRequestUpdateProjectPublish) => {
    const res = await dispatch(updateProjectPublishAction(req)).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
    return res
  }

  const handleDeleteProject = async () => {
    if (user && idProjectDelete) {
      const data = {
        organizationId: user.organizationId,
        projectId: idProjectDelete,
      }
      try {
        const res = await dispatch(deleteProjectAction(data)).unwrap()
        if (!res.error) {
          removeProjectInList()
          sceneSettingHelper.clearCatchProject(idProjectDelete)
          setIsShowConfirmDelete(false)
        } else {
          openNotification({
            type: TYPE.ERROR,
            key: 'deleteProject',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        }
      } catch {
        openNotification({
          type: TYPE.ERROR,
          key: 'deleteProject',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const removeProjectInList = () => {
    if (idProjectDelete) {
      const newList = listProject.projects.filter(
        (data) => data.projectId !== idProjectDelete
      )
      if (newList.length === 0) {
        dispatch(
          projectAction[TYPE_PROJECT.REDUCERS.SET_LIST_PROJECT]({
            projects: [],
            pager: null,
          })
        )
      } else {
        if (
          user &&
          user.organizationId &&
          listProject.pager &&
          listProject.pager.hasNext
        ) {
          dispatch(
            projectAction[TYPE_PROJECT.REDUCERS.SET_LIST_PROJECT]({
              ...listProject,
              projects: newList,
            })
          )
          const dataReq = {
            organizationId: user.organizationId,
            pager: { take: 1, skip: listProject.projects.length - 1 },
          }
          getListProject(dataReq)
        }
        if (listProject.pager && !listProject.pager.hasNext) {
          dispatch(
            projectAction[TYPE_PROJECT.REDUCERS.SET_LIST_PROJECT]({
              pager: {
                total: listProject.pager.total - 1,
                take: listProject.pager.total - 1,
                skip: 0,
                hasNext: false,
                hasPrevious: false,
              },
              projects: newList,
            })
          )
        }
      }
      closeCurrentPopupMoreSetting()
    }
  }

  const closeCurrentPopupMoreSetting = () => {
    const popup = document.querySelector('#popupMoreSettingProject')
    if (popup) {
      popup.classList.remove('!block')
      clearProjectEdit()
    }
  }

  const handleOpenConfirmDelete = (projectId: number) => {
    closeCurrentPopupMoreSetting()
    setIdProjectDelete(projectId)
    setIsShowConfirmDelete(true)
  }

  const handleCancelDeleteProject = () => {
    setIdProjectDelete(null)
    setIsShowConfirmDelete(false)
  }

  const handleSwitchProjectPublishSetting = (idProject: number) => {
    navigate(`${PATHNAME.SCENE_SETTING}/${idProject}`, {
      state: {
        from: PATHNAME.DASHBOARD,
      },
    })
  }

  return (
    <>
      {project && (
        <div
          id="popupMoreSettingProject"
          className={classNames(
            'fixed top-full z-10 bg-gray-700 py-2 shadow-dark__popup rounded-lg right-0 mt-1.5 w-[12.5rem] hidden font-lato'
          )}
          ref={refs}
        >
          <div className="flex flex-col">
            <Button.MenuItem
              icon={<img src={IconEdit} alt="" />}
              title={t('dashboard.moreSetting.edit')}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleGetInfoProject(project.projectId)
              }}
            />
            {project.publishStatus === ProjectPublishStatusName.PUBLISH && (
              <Button.MenuItem
                icon={<img src={IconPrivate} alt="" />}
                title={t('dashboard.moreSetting.stopPublic')}
                onClick={handleChangePublicProject}
              />
            )}
            <Button.MenuItem
              icon={<img src={IconPublic} alt="" />}
              title={t('dashboard.moreSetting.publicSetting')}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleSwitchProjectPublishSetting(project.projectId)
              }}
            />
            {/* <Button.MenuItem
              className="justify-between"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <div className="text-white__op-900 flex items-center mr-3">
                <Icon.ActionIcon
                  icon={<img src={IconDuplicate} alt="icon" />}
                  customClass="mr-3"
                />
                <div className="whitespace-nowrap">{t('common.duplicate')}</div>
              </div>
              <div className="text-white__op-500">⌘D</div>
            </Button.MenuItem> */}
            <Button.MenuItem
              className="justify-between"
              onClick={() => handleOpenConfirmDelete(project.projectId)}
            >
              <div className="text-white__op-900 flex items-center mr-3">
                <Icon.ActionIcon
                  icon={<img src={IconDelete} alt="icon" />}
                  customClass="mr-3"
                />
                <div className="whitespace-nowrap">{t('common.delete')}</div>
              </div>
              {/* <div className="text-white__op-500">⌘Del</div> */}
            </Button.MenuItem>
          </div>
        </div>
      )}
      {isShowConfirmDelete && (
        <PopupConfirm
          title={t('popup.confirm.confirmDeleteProject.title')}
          visible={isShowConfirmDelete}
          messageFirst={t('popup.confirm.confirmDeleteProject.description')}
          isDelete={true}
          okLabel={t('common.delete')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCancelDeleteProject}
          handleOk={handleDeleteProject}
        />
      )}
    </>
  )
}

export default MoreSettingPopup
