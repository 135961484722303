import React, { ReactNode } from 'react'
import { useField } from 'formik'
import classNames from 'classnames'

interface InputFieldProps {
  name: string
  label?: ReactNode
  labelWrap?: boolean
  placeholder?: string
  type: string
  customClassFormControl?: string
  customClassFormInput?: string
  customClassFormLabel?: string
  required?: boolean
  autoComplete?: string
  disabled?: boolean
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  isTransition?: boolean
}
const InputField: React.FC<InputFieldProps> = (props) => {
  const [field, { error, touched }] = useField(props)
  const {
    name,
    label,
    placeholder,
    type,
    customClassFormInput,
    customClassFormControl,
    customClassFormLabel,
    labelWrap,
    required,
    autoComplete,
    disabled = false,
    onBlur,
    isTransition = false,
  } = props
  const option = {
    name,
    placeholder,
    type,
    onBlur,
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e)
    field.onBlur(e)
  }

  return (
    <div
      className={classNames('relative w-full', customClassFormControl, {
        'flex flex-col': label && labelWrap,
        flex: label && !labelWrap,
      })}
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames(
            'text-white text-lg',
            customClassFormLabel,
            {
              'mr-2 flex-1': !labelWrap,
              'mb-1': labelWrap,
            },
            {
              "after:content-['*'] after:ml-1 after:text-red-700": required,
            }
          )}
        >
          {label}
        </label>
      )}
      <label
        className="relative w-full cursor-pointer py-1.5 block"
        htmlFor={name}
      >
        <input
          id={name}
          className={classNames(
            'w-full text-base leading-7 rounded-lg bg-white__op-50 px-3 py-1.5 text-white__op-900 outline outline-2 outline-transparent focus-visible:outline-blue-500 disabled:text-white__op-300 font-lato hover:bg-white__op-100 autofill:shadow-dark__input autofill:transition-colors autofill:ease-in-out autofill:duration-5000',
            customClassFormInput,
            {
              'focus-visible:outline-red-500': error && touched,
            },
            { 'transition-all duration-0.12 ease-0.3': isTransition }
          )}
          autoComplete={autoComplete}
          disabled={disabled}
          {...option}
          {...field}
          onBlur={handleBlur}
        />
      </label>

      {error && touched && (
        <div className="text-red-400 mb-1.5 text-xs leading-5 font-lato px-3">
          {error}
        </div>
      )}
    </div>
  )
}

export default InputField
