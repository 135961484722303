import { TYPE_MEDIA } from '@models/media'
import { helper } from '@utils/helper/common'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

interface IPropsType {
  media: {
    type: string
    url: string
    thumb: string
  }
}
const MediaContentComponent: React.FC<IPropsType> = ({ media }) => {
  const [listUrl, setListUrl] = useState<Array<string>>([])
  useEffect(() => {
    if (media.type === TYPE_MEDIA.PDF) {
      helper.genNumPage(media.url).then((url) => {
        if (url) {
          setListUrl(url)
        }
      })
    }
  }, [media.url])

  if (media.type === TYPE_MEDIA.PDF) {
    return (
      <div className="px-2 flex flex-wrap">
        {listUrl.slice(0, 8).map((data, index) => (
          <img
            className={classNames('rounded-lg', {
              'w-full h-40 my-1.5': index === 0,
              'w-10 h-10 mr-2 my-1.5': index !== 0,
            })}
            src={data}
            alt=""
            key={index}
          />
        ))}
        {listUrl.length > 8 && (
          <div className="w-10 h-10 rounded-lg flex items-center justify-center bg-white__op-100 my-1.5 text-white__op-600 font-semibold leading-5">
            +{listUrl.length - 8}
          </div>
        )}
      </div>
    )
  }
  if (media.type === TYPE_MEDIA.IMAGE) {
    return (
      <div className="px-2 py-1.5">
        <img
          className="object-cover w-full rounded-lg"
          src={media.url}
          alt=""
        />
      </div>
    )
  }
  if (media.type === TYPE_MEDIA.VIDEO) {
    return (
      <div className="px-2 py-1.5">
        <img
          className="object-cover w-full rounded-lg"
          src={media.thumb}
          alt=""
        />
      </div>
    )
  }
  return <></>
}

export default MediaContentComponent
