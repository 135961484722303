import { TreeViewItem } from '@components/common/draggableTree/react-draggable-tree'
import { Node } from '@components/common/draggableTree/node'
import { TYPE_SETTING } from '@stores/scene/scene.reducer'
import { IDefaultSceneSet } from './project'
//enum
export const enum TYPE_DROP {
  DROPPABLE_LAYER = 'DROPPABLE_LAYER',
  DROPPABLE_SPACE = 'DROPPABLE_SPACE',
  DROPPABLE_SCENE = 'DROPPABLE_SCENE',
}

export const enum TYPE_RESOURCE_OF_PROJECT {
  LAYER = 'layer',
  SPACE = 'space',
  SCENE = 'scene',
}

export const enum TYPE_SPOT_SCENE {
  MARKER = 'marker',
  MARKER_MESH = 'markerMesh',
  INFO_TAG = 'infoTag',
}

export const enum TypeColorInfoTag {
  BLUE = '2067E3',
  TEAL = '009EA8',
  GREEN = '00A81B',
  YERROW = 'CD8C09',
  RED = 'E0321B',
  MAGENDA = 'C729AE',
  GRAY = '3D3D3D',
  WHITE = 'FFFFFF',
}
export const enum TypeSizeInfoTag {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const enum TypeContentInfoTag {
  TEXT = 'text',
  FILE = 'media',
  INFO = 'info',
}

export const enum TypeActionUndoRedo {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  DRAG_DROP = 'DRAG_DROP',
}

//request
export interface IRequestCreateSpace {
  organizationId: string
  projectId: number
  title: string
  layerId?: number
}

export interface IRequestCreateScene {
  organizationId: string
  projectId: number
  title: string
  spaceId: number
}

export interface IRequestGetSpaceAndScene {
  organizationId: string
  projectId: number
  langCode?: string
}

export interface IRequestDeleteSpace {
  organizationId: string
  projectId: number
  spaceId: number
}

export interface IRequestDeleteScene {
  organizationId: string
  projectId: number
  sceneId: number
}

export interface IRequestGetScene {
  projectId: number
  sceneId: number
  langCode?: string
  contextCodec: IContextCodec
}

export type IContextCodec = 'VP9' | 'H265_HLS' | 'H265_DASH'

export interface IRequestUpdateSpace {
  organizationId: string
  projectId: number
  id: number
  layerId?: number
  title?: string
  thumbnailId?: number | null
  langCode?: string
}

export interface IRequestUpdateScene {
  organizationId: string
  projectId: number
  sceneId: number
  spaceId?: number
  langCode?: string
  title?: string
  description?: string
  contextType?: string
  contextId?: number | null
  isHide?: boolean
  bgmAudioId?: number | null
  information?: string
  thumbnailId?: number | null
  contextCodec: IContextCodec
}

export interface IRequestSetMarker {
  organizationId: string
  projectId: number
  spaceId: number
  sceneId: number
  marker: IMarkerReqest
}

export interface IRequestRemoveMarker {
  organizationId: string
  projectId: number
  spaceId: number
  sceneId: number
  marker: {
    id: number
  }
}

export interface IRequestSetInfoTag {
  organizationId: string
  projectId: number
  spaceId: number
  sceneId: number
  infoTag: IReqInfoTag
}

export interface IRequestRollbackVer {
  projectId: number
  version: number
}

//response
export interface ISpace {
  info: IResSpaceOfGetSpaceAndScene
  collapsed: boolean
  scenes: IScene[]
}

export interface IScene {
  info: IResSceneOfGetSpaceAndScene
}

export interface ILayer {
  info: IResLayerOfGetSpaceAndScene
  spaces: ISpace[]
  collapsed: boolean
}

export interface IResSpaceOfGetSpaceAndScene
  extends IResLayerOfGetSpaceAndScene {
  thumbnail: ISpaceThumbnail
}

export interface IResSceneOfGetSpaceAndScene
  extends IResLayerOfGetSpaceAndScene {
  thumbnailUrl: string | null
  imageThumbnailId: number | null
  videoThumbnailId: number | null
}

export interface ISpaceThumbnail {
  url: string | null
  imageId: number | null
  sceneThumbnail: ISceneThumbnail
}

export interface ISceneThumbnail {
  url: string | null
  imageId: number | null
  videoId: number | null
}

export interface IResLayerOfGetSpaceAndScene {
  id: number
  title: string
}

export interface ICoordinates {
  xAxis: number
  yAxis: number
  zAxis: number
}

export interface IMarkerReqest {
  id?: number
  coordinates?: ICoordinates
  linkedSceneId?: number | null
  name?: string
  markerMesh?: number
}

export interface IMarkerResponse {
  id: number
  coordinates: ICoordinates
  linkedSceneId: number | null
  name: string
  linkedSceneName: string | null
  markerMesh: number
  version: number
}

export type InfoTagSize = 'small' | 'medium' | 'large'
export type TagTypeInfoTagEnum = 'text' | 'media'
export type InfoTagMediaTypeEnum = 'image' | 'pdf' | 'audio' | 'video'
export const enum ITagTypeEnum {
  TEXT = 'text',
  MEDIA = 'media',
}

export interface IMediaOfInfoTag {
  mediaType: InfoTagMediaTypeEnum
  mediaUrl: string
  mediaName: string
  mediaThumbnailUrl: string
}

export interface IMediaOfInfoTagReq {
  mediaType: InfoTagMediaTypeEnum
  mediaId: number
}

export interface IInfoTagResponse {
  id: number
  coordinates: ICoordinates
  tagType: TagTypeInfoTagEnum
  color: string
  description: string
  langCode: string
  media: IMediaOfInfoTag
  size: InfoTagSize
  name: string
  title: string
  credit: string
  version: number
}

export interface IReqInfoTag {
  id?: number
  coordinates: ICoordinates
  tagType?: TagTypeInfoTagEnum
  color?: string
  description?: string
  langCode?: string
  media?: IMediaOfInfoTagReq
  size?: InfoTagSize
  name?: string
  title?: string
  credit?: string
}

export interface IResponseSceneInfo {
  spaceId: number
  sceneId: number
  thumbnailUrl?: string
  bgmUrl?: string
  bgmName?: string
  contextType?: string
  contextThumbnailUrl?: string
  contextUrl?: string
  contextName?: string
  title: string
  description?: string
  langCode: string
  metadata?: {
    isHide: boolean
  }
  markers?: Array<IMarkerResponse>
  infoTags: Array<IInfoTagResponse>
  level?: number
  information?: string
}

export interface IResponseUpdateSpace {
  id: number
  title: string
  layerId: number
  level: number
  isLayer: boolean
  thumbnailUrl: string
}

export interface IResponseUpdateScene {
  sceneId: number
  spaceId: number
  thumbnailUrl: string
  contextThumbnailUrl: string
  contextType: string
  contextUrl: string
  title: string
  description: string
  langCode: string
  metadata: {
    isHide: boolean
  }
  level: number
  bgmUrl: string
}

export interface IResponseCreateScene {
  sceneId: number
  spaceId: number
  thumbnailUrl: string | null
  title: string
  metadata: {
    isHide: boolean
  }
  level: number
}
//#
export type ISpot = ISpotMarker | ISpotInfoTag

export type TypeOfSpot = 'marker' | 'infoTag'
export type TagTypeOfInfoTag =
  | 'media'
  | 'image'
  | 'pdf'
  | 'audio'
  | 'video'
  | 'info'
  | undefined

export interface ISpotInfoTag {
  spotType: TypeOfSpot
  id: number
  coordinates: {
    xAxis: number
    yAxis: number
    zAxis: number
  }
  size: InfoTagSize
  tagType: TagTypeOfInfoTag
  color: string
  isSelected: boolean
}

export interface ISpotMarker {
  spotType: TypeOfSpot
  id: number
  coordinates: {
    xAxis: number
    yAxis: number
    zAxis: number
  }
  sceneId: number | null
  isSelected: boolean
}

export type TYPE_SPOT = 'marker' | 'markerMesh' | 'infoTag'

export interface IEditSpot {
  type?: TYPE_SPOT
  markerMeshY?: string | number
}

export interface NodeTreeViewItem extends TreeViewItem {
  readonly node: Node
}

export interface structureSpaceAndScene {
  layers: Array<{
    id: number
    spaces: Array<{
      id: number
      scenes: Array<{
        id: number
      }>
    }>
  }>
}

export interface IValueSetUndoRedo {
  projectId: number
  dataVersion: {
    version: number
    data: {
      type: TYPE_SETTING
      id: number
      action: TypeActionUndoRedo
      listLayer?: Array<ILayer>
      idScene?: number
      rootId?: IDefaultSceneSet
    }
  }
}

export interface IValueGetUndoRedo {
  projectId: number
  listVersion: Array<{
    version: number
    data: {
      type: TYPE_SETTING
      id: number
      action: TypeActionUndoRedo
      listLayer?: Array<ILayer>
      idScene?: number
      rootId?: IDefaultSceneSet
    }
  }>
  idCurrentVersion: number
}

export interface IDataSelectMarkerInfoTag {
  type: TYPE_SETTING
  id: number
}
