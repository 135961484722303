import React, { useEffect, useState } from 'react'
import Select, { IValueSelected } from '@components/common/selectInput/Select'

//icon
import IconLargeSize from '@assets/icons/sceneSetting/rightSidebar/infoTag/large-size.svg'
import IconMediumSize from '@assets/icons/sceneSetting/rightSidebar/infoTag/medium-size.svg'
import IconSmallSize from '@assets/icons/sceneSetting/rightSidebar/infoTag/small-size.svg'
import {
  ICoordinates,
  InfoTagSize,
  IRequestSetInfoTag,
  TypeSizeInfoTag,
} from '@models/sceneSetting'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'

interface IPropsType {
  dataInfoTag: {
    coordinates: ICoordinates
    size: InfoTagSize
    id: number
  }
  handleSetInfoTag: (req: IRequestSetInfoTag) => Promise<unknown>
}
const InfoTagSizeSelect: React.FC<IPropsType> = (props) => {
  const { dataInfoTag, handleSetInfoTag } = props
  const { t } = useTranslation()
  const optionSize = [
    {
      value: TypeSizeInfoTag.LARGE,
      label: (
        <>
          <div className="w-5 h-5 mr-3 flex items-center justify-center">
            <img src={IconLargeSize} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('sceneSetting.rightSideBar.infoTag.size.large')}
          </p>
        </>
      ),
    },
    {
      value: TypeSizeInfoTag.MEDIUM,
      label: (
        <>
          <div className="w-5 h-5 mr-3 flex items-center justify-center">
            <img src={IconMediumSize} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('sceneSetting.rightSideBar.infoTag.size.medium')}
          </p>
        </>
      ),
    },
    {
      value: TypeSizeInfoTag.SMALL,
      label: (
        <>
          <div className="w-5 h-5 mr-3 flex items-center justify-center">
            <img src={IconSmallSize} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('sceneSetting.rightSideBar.infoTag.size.small')}
          </p>
        </>
      ),
    },
  ]
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { dataScenePreview } = useAppSelector((state: RootState) => state.scene)
  const [typeSize, setTypeSize] = useState<IValueSelected>(
    _.find(optionSize, (size) => size.value === dataInfoTag.size) || null
  )

  useEffect(() => {
    if (dataInfoTag) {
      setTypeSize(
        _.find(optionSize, (size) => size.value === dataInfoTag.size) || null
      )
    }
  }, [dataInfoTag.id, dataInfoTag.size])

  const handleChangeSize = async (value: IValueSelected) => {
    if (
      projectInfo &&
      user &&
      dataScenePreview &&
      value &&
      !Array.isArray(value)
    ) {
      const { coordinates } = dataInfoTag
      const { xAxis, yAxis, zAxis } = coordinates
      const req: IRequestSetInfoTag = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
        spaceId: dataScenePreview.spaceId,
        sceneId: dataScenePreview.sceneId,
        infoTag: {
          id: dataInfoTag.id,
          coordinates: {
            xAxis,
            yAxis,
            zAxis,
          },
          size: value.value as InfoTagSize,
        },
      }
      const resUpdateSize = await handleSetInfoTag(req)
      if (resUpdateSize) {
        setTypeSize(value)
      }
    }
  }
  return (
    <Select
      value={typeSize}
      onChange={handleChangeSize}
      options={optionSize}
      isLabelIcon={true}
    />
  )
}

export default React.memo(InfoTagSizeSelect)
