import { S3MultipleUploader } from '../api/s3'
import { ProjectPublishStatusName } from './organization'
import { structureSpaceAndScene } from './sceneSetting'

export const UNSELECT_MEDIA = -1

export const enum TAB {
  TAB_GENERAL_SETTING = 'general',
  TAB_PRIVACY_SETTING = 'privacy',
  TAB_MEMBER_SETTING = 'member',
  TAB_FORM_CUSTOM = 'formCustom',
}

export const enum PROJECT_ROLE {
  OWNER = 'Owner',
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
}

export const enum GET_PROJECT_TYPE {
  SCENE = 'scene',
  SETTING = 'setting',
}

export enum TypePublicProject {
  PRIVATE = 'private',
  PUBLIC = 'public',
  PASSWORD = 'password',
  LIMIT = 'limit',
}

export enum ValuePublicProject {
  PRIVATE = 2,
  PUBLIC = 1,
}

export enum TypeTicket {
  FREE = 'free',
  PAID = 'paidContents',
}

export enum TypeSidebarDisplay {
  DO_NOT_DISPLAY = 'do_not_display',
  GRID = 'grid_scene',
  LIST = 'list_scene',
  GRID_SPACE = 'grid_space',
}

export enum TypeMoney {
  USD = 'usd',
  JPY = 'jpy',
}

export enum TypeFilterProject {
  RECENT = 'Recents',
  SHARED = 'Shared',
  YOUR_PROJECT = 'Your Projects',
}

//request
export interface IRequestCreateProject {
  organizationId: string
  projectName: string
  projectDescription?: string
  langCode?: string
}

export interface IRequestUpdateProject {
  projectName?: string
  langCode?: string
  isVisited?: boolean
  defaultSceneSet?: {
    layerId?: number
    spaceId?: number
    sceneId?: number
  }
  orderStructure?: structureSpaceAndScene
}

export interface IRequestUpdateProjectPublish
  extends IDateUpdateProjectPublish {
  organizationId: string
  projectId: number
  autoplay?: boolean
}

export interface IDateUpdateProjectPublish {
  description?: string
  langCode?: string
  thumbnailId?: number | null
  thumbnailUrl?: string | null
  status?: string
  ticketPrice?: number
  ticketType?: typeOfTicket
  password?: string
  sidebarDisplayType?: typeOfSidebarDisplay
  autoplay?: boolean
}

export type SortType = 'ASC' | 'DESC'

export interface IRequestOrderBy {
  fieldName: string
  sortType: SortType
}

export interface IRequestPager {
  take: number
  skip: number
  orderBy?: Array<IRequestOrderBy>
}

export interface IRequestListProject {
  organizationId: string
  pager?: IRequestPager
}

export interface IRequestGetProjectPublish {
  organizationId: string
  projectId: number
  langCode?: string
}
//response
export interface IProject {
  projectId: number
  projectName: string
  projectRole: string
  description: string
  publishStatus: ProjectPublishStatusName
  lastEditedAt: Date
  lastEditedBy: string
  projectImageUrl: string
  currentProjectStorage: string
}
export interface IPager {
  hasNext: boolean
  hasPrevious: boolean
  skip: number
  take: number
  total: number
}
export interface IResponseListProject {
  projects: Array<IProject>
  pager: IPager | null
}
export interface IProjectStorage {
  projectId: number
  currentProjectStorage: string
}
export interface IResponseListProjectStorage {
  projects: Array<IProjectStorage>
}

export interface IDefaultSceneSet {
  layerId: number
  spaceId: number
  sceneId: number
}

export interface IResponseProjectInfo {
  projectId: number
  projectName: string
  langCode: string
  lastEditedAt: Date
  defaultSceneSet: IDefaultSceneSet
  projectPreviewUrl: string
  version: number
}

export type typeOfTicket = 'free' | 'free'
export type typeOfSidebarDisplay =
  | 'do_not_display'
  | 'grid_space'
  | 'grid_scene'
  | 'list_scene'
export interface IResponseProjectPublish {
  projectId: number
  description: string
  langCode: string
  thumbnailUrl: string
  thumbnailId: number
  projectPublishUrl: string
  password: string
  status: string
  ticketType: typeOfTicket
  ticketPrice: number
  sidebarDisplayType: typeOfSidebarDisplay
  autoplay: boolean
}

export interface IResponseSignedCookies {
  cloudFrontPolicy: string
  cloudFrontSignature: string
  cloudFrontKeyPairId: string
  expireTime: Date
}

export interface IWaitingS3Uploader {
  isUploading: boolean
  uploaders: S3MultipleUploader[]
}
