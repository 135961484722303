import React from 'react'

import ImageBg from '../assets/images/auth/bg-auth.svg'

interface Props {
  children: React.ReactNode
}

const UnAuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="w-screen h-screen min-h-screen flex items-center justify-center flex-col bg-gray-800 overflow-hidden">
      <div className="w-[26.25rem] z-10 p-4 xs:w-[375px]">{children}</div>
      <img className="absolute bottom-0 right-0 z-0" src={ImageBg} alt="" />
      <div className="text-blue-600 uppercase absolute bottom-0 right-5 text-xs leading-[3.5rem]">
        welcome to the world out of frame.
      </div>
    </div>
  )
}

export default UnAuthLayout
