import React, { useState } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useGoogleLogin } from '@react-oauth/google'
import IconLogo from '../assets/icons/auth/logo.svg'
import InputField from '../components/common/InputField'
import { openNotification } from '../utils/notification'
import { useAppDispatch, useAppSelector } from '../store/hook'
import {
  createProfileAction,
  loginWithGoogleAction,
} from '../store/auth/auth.action'
import { TYPE } from '../models/common'
import { ERROR_AUTH, IRequestLoginWithGoogle } from '../models/auth'
import { actions as authActions } from '../store/auth/auth.reducer'
import { TYPES_AUTH } from '../store/auth/auth.type'
import Button from '../components/common/Button'
import { PATHNAME } from '@constants/common'
import IconWhereness from '@assets/icons/common/whereness-icon.svg'
import IconGoogle from '@assets/icons/auth/logo-gg.svg'
import SpinComponent from '@components/common/SpinComponent'
import { useParamsToStore } from '../hooks/useParamsToStore'
import { RootState } from '@stores/store'

export const Home: React.FC = () => {
  useParamsToStore()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { partnerParam, emailNotificationParam } = useAppSelector(
    (state: RootState) => state.auth
  )
  const [isLogin, setIsLogin] = useState<boolean>(false)

  const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        t('validate.invalidFormat', {
          name: t('common.emailAddress').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.email').toLowerCase() })
      ),
  })

  const handleSignUp = async (
    values: { email: string },
    actions: FormikHelpers<{ email: string }>
  ) => {
    const { email } = values
    const { setSubmitting } = actions
    setSubmitting(true)
    try {
      dispatch(authActions[TYPES_AUTH.REDUCERS.SET_EMAIL_AUTOFILL](email))
      const result = await dispatch(createProfileAction({ email })).unwrap()
      if (result.error) {
        const error = result.error
        if (error.message === ERROR_AUTH.EMAIL_ALREADY_EXISTS) {
          navigate(PATHNAME.SIGN_IN)
        }
        if (error.message === ERROR_AUTH.USER_NOT_FOUND) {
          navigate(PATHNAME.SIGN_UP)
        }
      }
      setSubmitting(false)
    } catch ({}) {
      setSubmitting(false)
      openNotification({
        type: TYPE.ERROR,
        key: 'createProfile',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const payload = {
        token: tokenResponse.access_token,
        ...(partnerParam && { partner: partnerParam }),
        ...(emailNotificationParam && {
          emailNotification: emailNotificationParam,
        }),
      }
      await handleAuthGoogle(payload)
    },
    onError: () => {
      openNotification({
        type: TYPE.ERROR,
        key: 'login',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    },
  })

  const handleAuthGoogle = async (payload: IRequestLoginWithGoogle) => {
    setIsLogin(true)
    try {
      await dispatch(loginWithGoogleAction(payload)).unwrap()
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'login',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsLogin(false)
    }
  }

  return (
    <React.Fragment>
      <div className="mb-4 pb-2.5">
        <div className="flex justify-center items-center pt-3.5 py-3">
          <img src={IconLogo} alt="" className="mr-1.5" />
          <img src={IconWhereness} alt="" />
        </div>
        <div className="text-base leading-7 text-center text-white__op-600 font-lato">
          {t('auth.welcome.login&Create')}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={SignInSchema}
          onSubmit={handleSignUp}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form className="w-full">
              <InputField
                name="email"
                placeholder={t('common.email')}
                type="text"
                customClassFormControl="mb-4"
                labelWrap
                required
                isTransition
              />
              <Button.Normal
                type="submit"
                className="!py-3 font-semibold text-sm"
                title={t('auth.welcome.signUpIn')}
                disabled={isSubmitting || !dirty || !isValid}
                isTransition
              />
            </Form>
          )}
        </Formik>
        <button
          className="relative w-full overflow-hidden py-1.5"
          onClick={() => handleGoogleLogin()}
        >
          <div className="flex items-center justify-center bg-white__op-900 rounded-2xl">
            <div className="w-10 h-10 overflow-hidden object-cover">
              <img
                className="w-full h-full object-cover"
                src={IconGoogle}
                alt="icon google"
              />
            </div>
            <div className="text-left font-semibold text-black text-sm">
              　{t('auth.welcome.signInWithGG')}
            </div>
          </div>
        </button>
      </div>
      {isLogin && <SpinComponent />}
    </React.Fragment>
  )
}
