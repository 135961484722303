import { SIGNED_COOKIES } from '@constants/common'
import { IResponseSignedCookies } from '@models/project'
import { createSlice } from '@reduxjs/toolkit'
import { setTokenCookie } from '@utils/cookies'
import { IPositionContextMenu } from '../../models/common'
import {
  IContextCodec,
  ICoordinates,
  IInfoTagResponse,
  ILayer,
  IMarkerResponse,
  IResponseSceneInfo,
  IResponseUpdateScene,
  IResponseUpdateSpace,
  IScene,
  ISpace,
  TypeActionUndoRedo,
} from '../../models/sceneSetting'
import { helper } from '@utils/helper/common'
import {
  createSceneAction,
  deleteSceneAction,
  getSceneAction,
  getSignedCookiesAction,
  getSpaceAndSceneAction,
  removeInfoTagAction,
  removeMarkerAction,
  setInfoTagAction,
  setMarkerAction,
  updateSceneAction,
  updateSpaceAction,
} from './scene.action'
import { TYPE_SCENE } from './scene.type'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import { DEFAULT_WIDTH_SIDEBAR, ID_PROJECT_KEY } from '@constants/sceneSetting'
import { IMediaProcess } from '@models/media'

export enum TYPE_SETTING {
  LAYER = 'LAYER',
  SPACE = 'SPACE',
  SCENE = 'SCENE',
  MARKER = 'MARKER',
  INFO_TAG = 'INFO_TAG',
  PROJECT = 'PROJECT',
  ROOT = 'ROOT',
}

export enum TYPE_TOOL_SCENE_SETTING {
  OFF,
  MARKER,
  INFO_TAG,
  MAP,
}

export interface IContextMenu {
  xPos: string
  yPos: string
}

export interface IActiveView {
  type: string | null
  layer: ILayer | null
  space?: ISpace | null
  scene?: IScene | null
}

export interface IDataTypeEdit {
  isTypeEdit: TYPE_SETTING
  data: IDataEdit
}

export interface IDataEditContextMenuLSS {
  type: TYPE_SETTING
  data: {
    id: number
    title: string
  }
}

type IDataEdit =
  | ILayer
  | ISpace
  | IResponseSceneInfo
  | IMarkerResponse
  | IInfoTagResponse
  | null
export interface SceneState {
  isShowSceneInfoPopup: boolean
  dataTypeEdit: IDataTypeEdit
  activeView: IActiveView
  dataSpaceAndScene: ILayer[] | null
  posContextMenu: IContextMenu | null
  isShowListScenePopup: boolean
  isLoading: boolean
  positionContextMenuLSS: IPositionContextMenu | null
  isDisableDeleteLSS: boolean
  isOpenContextMenuLSS: boolean
  dataEditContextMenuLSS: IDataEditContextMenuLSS | null
  isEditNameLSS: boolean
  dataScenePreview: IResponseSceneInfo | null
  idSceneSelect: number | null
  toolSceneSetting: TYPE_TOOL_SCENE_SETTING
  positionPopupAsset: { top: number; right: number }
  refButtonSelectMedia: string | null
  disabledUndoRedo: {
    isUndo: boolean
    isRedo: boolean
  }
  contextCodec: IContextCodec
  sidebarWidth: number
}

const initialState: SceneState = {
  isShowSceneInfoPopup: false,
  isShowListScenePopup: false,
  dataTypeEdit: { isTypeEdit: TYPE_SETTING.PROJECT, data: null },
  dataSpaceAndScene: null,
  activeView: {
    type: null,
    layer: null,
    space: null,
    scene: null,
  },
  posContextMenu: null,
  isLoading: false,
  positionContextMenuLSS: null,
  isDisableDeleteLSS: false,
  isOpenContextMenuLSS: false,
  dataEditContextMenuLSS: null,
  isEditNameLSS: false,
  dataScenePreview: null,
  idSceneSelect: null,
  toolSceneSetting: TYPE_TOOL_SCENE_SETTING.OFF,
  positionPopupAsset: { top: 0, right: 0 },
  refButtonSelectMedia: null,
  disabledUndoRedo: {
    isRedo: false,
    isUndo: false,
  },
  contextCodec: sceneSettingHelper.checkCodecBrowser(),
  sidebarWidth: DEFAULT_WIDTH_SIDEBAR,
}
export const sceneReducer = createSlice({
  name: 'scene',
  initialState,
  reducers: {
    [TYPE_SCENE.REDUCERS.SET_STATUS_SCENE_INFO_POPUP]: (state, action) => {
      state.isShowSceneInfoPopup = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]: (state, action) => {
      state.dataTypeEdit = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_STATUS_LIST_SCENE_POPUP]: (state, action) => {
      state.isShowListScenePopup = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]: (state, action) => {
      state.activeView = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE]: (state, action) => {
      state.dataSpaceAndScene = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_POSITION_CONTEXT_MENU]: (state, action) => {
      state.posContextMenu = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_POSITION_CONTEXT_MENU_LSS]: (state, action) => {
      state.positionContextMenuLSS = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_DISABLE_DELETE_LSS]: (state, action) => {
      state.isDisableDeleteLSS = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_OPEN_CONTEXT_MENU_LSS]: (state, action) => {
      state.isOpenContextMenuLSS = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_DATA_EDIT_CONTEXT_MENU_LSS]: (state, action) => {
      state.dataEditContextMenuLSS = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_IS_EDIT_NAME_LSS]: (state, action) => {
      state.isEditNameLSS = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_IS_LOADING]: (state, action) => {
      state.isLoading = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_DATA_SCENE_PREVIEW]: (state, action) => {
      state.dataScenePreview = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_ID_SCENE_SELECT]: (state, action) => {
      state.idSceneSelect = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING]: (state, action) => {
      state.toolSceneSetting = action.payload
    },
    [TYPE_SCENE.REDUCERS.UPDATE_CONTEXT_URL]: (state, action) => {
      const { sceneId, contextUrl, mediaType } = action.payload as IMediaProcess
      let url: string | null = null
      switch (state.contextCodec) {
        case 'VP9':
          url = contextUrl.vp9
          break
        case 'H265_DASH':
          url = contextUrl.h265Dash
          break
        case 'H265_HLS':
          url = contextUrl.h265Hls
          break
        default:
          url = null
          break
      }
      const convertedContextType = mediaType.replace('360', '')
      if (
        state.dataScenePreview?.sceneId === sceneId &&
        convertedContextType === state.dataScenePreview?.contextType
      ) {
        state.dataScenePreview = state.dataScenePreview && {
          ...state.dataScenePreview,
          contextUrl: url ?? '',
        }
      }
    },
    [TYPE_SCENE.REDUCERS.SET_TOP_POPUP_ASSET]: (state, action) => {
      state.positionPopupAsset = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_CLEAR_DATA]: (state) => {
      return {
        ...state,
        dataTypeEdit: { isTypeEdit: TYPE_SETTING.PROJECT, data: null },
      }
    },
    [TYPE_SCENE.REDUCERS.SET_REF_BUTTON_SELECT_MEDIA]: (state, action) => {
      state.refButtonSelectMedia = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]: (state, action) => {
      state.disabledUndoRedo = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_SIDEBAR_WIDTH]: (state, action) => {
      state.sidebarWidth = action.payload
    },
    [TYPE_SCENE.REDUCERS.SET_MARKER_COORDINATE]: (state, action) => {
      const data = action.payload as { id: number; coordinates: ICoordinates }
      const { dataScenePreview } = state
      if (data && dataScenePreview && dataScenePreview.markers) {
        const { id, coordinates } = data
        const newData = helper.updateMarkerCoordinate(
          dataScenePreview.markers,
          id,
          coordinates
        )
        return {
          ...state,
          dataScenePreview: {
            ...dataScenePreview,
            markers: newData,
          },
        }
      }
      return {
        ...state,
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getSpaceAndSceneAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getSpaceAndSceneAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        const newss = helper.sortSpaceAndScene(
          data.layers,
          data.spaces,
          data.scenes,
          data.orderStructure
        )
        return {
          ...state,
          isLoading: false,
          dataSpaceAndScene: newss && newss.length > 0 ? newss : null,
        }
      }

      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getSpaceAndSceneAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(createSceneAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(createSceneAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(createSceneAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //delete scene
    builder.addCase(deleteSceneAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(deleteSceneAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(deleteSceneAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateSpaceAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(updateSpaceAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      const { dataSpaceAndScene, dataTypeEdit } = state
      if (
        data &&
        dataSpaceAndScene &&
        dataTypeEdit &&
        (dataTypeEdit.isTypeEdit === TYPE_SETTING.SPACE ||
          dataTypeEdit.isTypeEdit === TYPE_SETTING.LAYER)
      ) {
        const { isUpdateInfo, newListLayer } = helper.updateSpace(
          dataSpaceAndScene,
          data as IResponseUpdateSpace
        )
        if (isUpdateInfo) {
          if (data.isLayer) {
            const newDataEdit: ILayer = {
              ...(state.dataTypeEdit.data as ILayer),
              info: {
                ...(state.dataTypeEdit.data as ILayer).info,
                title: data.title,
              },
            }
            return {
              ...state,
              isLoading: false,
              dataSpaceAndScene: newListLayer,
              dataTypeEdit: {
                ...state.dataTypeEdit,
                data: newDataEdit,
              },
            }
          }
          const prId = window.sessionStorage.getItem(ID_PROJECT_KEY)
          if (prId) {
            const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage(
              {
                projectId: JSON.parse(prId),
                dataVersion: {
                  version: data.version,
                  data: {
                    type: data.isLayer
                      ? TYPE_SETTING.LAYER
                      : TYPE_SETTING.SPACE,
                    id: data.id,
                    action: TypeActionUndoRedo.UPDATE,
                    listLayer: newListLayer,
                  },
                },
              }
            )
            return {
              ...state,
              isLoading: false,
              dataSpaceAndScene: newListLayer,
              dataTypeEdit: {
                ...dataTypeEdit,
                data: {
                  ...(dataTypeEdit.data as ISpace),
                  info: {
                    ...(dataTypeEdit.data as ISpace).info,
                    title: data.title,
                    thumbnail: {
                      ...(dataTypeEdit.data as ISpace).info.thumbnail,
                      url: data.thumbnailUrl,
                    },
                  },
                } as ISpace,
              },
              disabledUndoRedo: {
                isRedo,
                isUndo,
              },
            }
          }

          return {
            ...state,
            isLoading: false,
            dataSpaceAndScene: newListLayer,
            dataTypeEdit: {
              ...dataTypeEdit,
              data: {
                ...(dataTypeEdit.data as ISpace),
                info: {
                  ...(dataTypeEdit.data as ISpace).info,
                  title: data.title,
                  thumbnail: {
                    ...(dataTypeEdit.data as ISpace).info.thumbnail,
                    url: data.thumbnailUrl,
                  },
                },
              } as ISpace,
            },
          }
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateSpaceAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //update scene
    builder.addCase(updateSceneAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(updateSceneAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      const { dataSpaceAndScene, dataTypeEdit, dataScenePreview } = state
      if (
        data &&
        dataSpaceAndScene &&
        dataTypeEdit &&
        dataScenePreview &&
        dataTypeEdit.isTypeEdit === TYPE_SETTING.SCENE
      ) {
        const { isUpdateStructure, newListLayer } = helper.updateScene(
          dataSpaceAndScene,
          data as IResponseUpdateScene,
          (dataTypeEdit.data as IResponseSceneInfo).spaceId
        )
        if (!isUpdateStructure) {
          const prId = window.sessionStorage.getItem(ID_PROJECT_KEY)
          if (prId) {
            const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage(
              {
                projectId: JSON.parse(prId),
                dataVersion: {
                  version: data.version,
                  data: {
                    type: TYPE_SETTING.SCENE,
                    id: data.sceneId,
                    action: TypeActionUndoRedo.UPDATE,
                    listLayer: newListLayer,
                  },
                },
              }
            )
            return {
              ...state,
              disabledUndoRedo: {
                isRedo,
                isUndo,
              },
              isLoading: false,
              dataSpaceAndScene: newListLayer,
              dataTypeEdit: {
                ...state.dataTypeEdit,
                data: {
                  ...state.dataTypeEdit.data,
                  ...data,
                } as IResponseSceneInfo,
              },
              dataScenePreview: {
                ...state.dataScenePreview,
                ...data,
              },
            }
          }
          return {
            ...state,
            isLoading: false,
            dataSpaceAndScene: newListLayer,
            dataTypeEdit: {
              ...state.dataTypeEdit,
              data: {
                ...state.dataTypeEdit.data,
                ...data,
              } as IResponseSceneInfo,
            },
            dataScenePreview: {
              ...state.dataScenePreview,
              ...data,
            },
          }
        }
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(updateSceneAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //Get Scene
    builder.addCase(getSceneAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        return {
          ...state,
          dataScenePreview: data,
        }
      }
      return {
        ...state,
      }
    })
    //Get Signed cookies
    builder.addCase(getSignedCookiesAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(getSignedCookiesAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      if (data) {
        const { cloudFrontKeyPairId, cloudFrontPolicy, cloudFrontSignature } =
          data as IResponseSignedCookies
        setTokenCookie(SIGNED_COOKIES.POLICY, cloudFrontPolicy)
        setTokenCookie(SIGNED_COOKIES.SIGNATURE, cloudFrontSignature)
        setTokenCookie(SIGNED_COOKIES.KEY_PAIR, cloudFrontKeyPairId)
      }
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(getSignedCookiesAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //set marker
    builder.addCase(setMarkerAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(setMarkerAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      const { dataScenePreview } = state
      if (data && dataScenePreview) {
        let newData = Array(data) as Array<IMarkerResponse>
        if (dataScenePreview.markers) {
          newData = helper.updateListMarker(dataScenePreview.markers, data)
        }
        const prId = window.sessionStorage.getItem(ID_PROJECT_KEY)
        if (prId) {
          const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
            projectId: JSON.parse(prId),
            dataVersion: {
              version: data.version,
              data: {
                type: TYPE_SETTING.MARKER,
                id: (data as IMarkerResponse).id,
                action: TypeActionUndoRedo.CREATE,
                idScene: dataScenePreview.sceneId,
              },
            },
          })
          return {
            ...state,
            disabledUndoRedo: {
              isRedo,
              isUndo,
            },
            isLoading: false,
            dataScenePreview: {
              ...dataScenePreview,
              markers: newData,
            },
            dataTypeEdit: {
              isTypeEdit: TYPE_SETTING.MARKER,
              data,
            },
          }
        }

        return {
          ...state,
          isLoading: false,
          dataScenePreview: {
            ...dataScenePreview,
            markers: newData,
          },
          dataTypeEdit: {
            isTypeEdit: TYPE_SETTING.MARKER,
            data,
          },
        }
        // return {
        //   ...state,
        //   isLoading: false,
        //   dataScenePreview: {
        //     ...dataScenePreview,
        //     markers: [...data],
        //   },
        // }
      }

      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(setMarkerAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //remove marker
    builder.addCase(removeMarkerAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(removeMarkerAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(removeMarkerAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //set info tag
    builder.addCase(setInfoTagAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(setInfoTagAction.fulfilled, (state, { payload }) => {
      const data = payload.data
      const { dataScenePreview } = state
      if (data && dataScenePreview) {
        let newData = Array(data) as Array<IInfoTagResponse>
        if (dataScenePreview.infoTags) {
          newData = helper.updateListInfoTag(dataScenePreview?.infoTags, data)
        }
        const prId = window.sessionStorage.getItem(ID_PROJECT_KEY)
        if (prId) {
          const { isRedo, isUndo } = sceneSettingHelper.saveUndoRedoToStorage({
            projectId: JSON.parse(prId),
            dataVersion: {
              version: data.version,
              data: {
                type: TYPE_SETTING.INFO_TAG,
                id: (data as IInfoTagResponse).id,
                action: TypeActionUndoRedo.CREATE,
                idScene: dataScenePreview.sceneId,
              },
            },
          })
          return {
            ...state,
            disabledUndoRedo: {
              isRedo,
              isUndo,
            },
            isLoading: false,
            dataScenePreview: {
              ...dataScenePreview,
              infoTags: newData,
            },
            dataTypeEdit: {
              isTypeEdit: TYPE_SETTING.INFO_TAG,
              data,
            },
          }
        }

        return {
          ...state,
          isLoading: false,
          dataScenePreview: {
            ...dataScenePreview,
            infoTags: newData,
          },
          dataTypeEdit: {
            isTypeEdit: TYPE_SETTING.INFO_TAG,
            data,
          },
        }
      }

      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(setInfoTagAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    //remove info tag
    builder.addCase(removeInfoTagAction.pending, (state) => ({
      ...state,
      isLoading: true,
    }))
    builder.addCase(removeInfoTagAction.fulfilled, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
    builder.addCase(removeInfoTagAction.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
      }
    })
  },
})

export const { actions } = sceneReducer

export default sceneReducer.reducer
